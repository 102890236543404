
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton, DatePicker } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';
import TableView from '../../../../contents/AntTableResponsive';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ldatepdf, lpowerdbypdf } from '../../../utils/pdf';
import { SelectExambySection } from '../../../select/SelectExambySection';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectGroupbySection } from '../../../select/SelectGroupbySection';
require('jspdf-autotable');

const blank = null;
let pdfcol: any = [
    { title: "Student ID", dataKey: "customStudentId" },
    { title: "Student Name", dataKey: "studentName" },
    { title: "Roll", dataKey: "studentRoll" },
]
export default function ExamAttendance() {

    const [sectionWiseReportForm] = Form.useForm();
    const fetchExamblankSheet = useStoreActions((state) => state.student.fetchExamblankSheet);
    const examblankSheet = useStoreState((state) => state.student.examblankSheet);
    const loading = useStoreState((state) => state.student.loading);
    const fetchSemesterListByClassConfigId = useStoreActions((state) => state.exam.fetchSemesterListByClassConfigId);
    const fetchlistGroupbyClassConfig = useStoreActions((state) => state.student.fetchlistGroupbyClassConfig);

    const formSubmit = (value) => {
        pdfcol = [];
        value.groupId = value.groupId.groupId;
        fetchExamblankSheet(value);
    }

    const [tableData, setTableData] = useState<any>([]);
    const [subjectData, setsubjectData] = useState<any>([]);

    useEffect(() => {
        setTableData(examblankSheet?.studentList);
        setsubjectData(examblankSheet?.subjectInfos);
    }, [examblankSheet]);



    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        subjectData?.firstSubject != '' && { title: subjectData?.firstSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.secondSubject != '' && { title: subjectData?.secondSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.thirdSubject != '' && { title: subjectData?.thirdSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.fourthSubject != '' && { title: subjectData?.fourthSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.fifthSubject != '' && { title: subjectData?.fifthSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.sixthSubject != '' && { title: subjectData?.sixthSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.seventhSubject != '' && { title: subjectData?.seventhSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.eighthSubject != '' && { title: subjectData?.eighthSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.ninethSubject != '' && { title: subjectData?.ninethSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.tenthSubject != '' && { title: subjectData?.tenthSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.eleventhSubject != '' && { title: subjectData?.eleventhSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.twelvethSubject != '' && { title: subjectData?.twelvethSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.thirteenthSubject != '' && { title: subjectData?.thirteenthSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.fourteenthSubject != '' && { title: subjectData?.fourteenthSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.fifteenthSubject != '' && { title: subjectData?.fifteenthSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.sixteenthSubject != '' && { title: subjectData?.sixteenthSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.seventeenthSubject != '' && { title: subjectData?.seventeenthSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.eighteenthSubject != '' && { title: subjectData?.eighteenthSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.nineteenthSubject != '' && { title: subjectData?.nineteenthSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },
        subjectData?.twentythSubject != '' && { title: subjectData?.twentythSubject, dataIndex: blank, key: blank, showOnResponse: true, showOnDesktop: true },

    ];

    function exportPdf() {

        pdfcol = [
            { title: "Student ID", dataKey: "customStudentId" },
            { title: "Student Name", dataKey: "studentName" },
            { title: "Roll", dataKey: "studentRoll" },
            subjectData?.firstSubject != ''&&{ title: subjectData?.firstSubject, dataKey: 11, key: 11 },
            subjectData?.secondSubject != ''&&{ title: subjectData?.secondSubject, dataKey: 22, key: 22 },
            subjectData?.thirdSubject != ''&&{ title: subjectData?.thirdSubject, dataKey: 23, key: 23 },
            subjectData?.fourthSubject != ''&&{ title: subjectData?.fourthSubject, dataKey: 24, key: 24 },
            subjectData?.fifthSubject != ''&&{ title: subjectData?.fifthSubject, dataKey: 25, key: 25 },
            subjectData?.sixthSubject != ''&&{ title: subjectData?.sixthSubject, dataKey: 26, key: 26 },
            subjectData?.seventhSubject != ''&&{ title: subjectData?.seventhSubject, dataKey: 27, key: 27 },
            subjectData?.eighthSubject != ''&&{ title: subjectData?.eighthSubject, dataKey: 28, key: 28 },
            subjectData?.ninethSubject != ''&&{ title: subjectData?.ninethSubject, dataKey: 29, key: 29 },
            subjectData?.tenthSubject != ''&&{ title: subjectData?.tenthSubject, dataKey: 30, key: 30 },
            subjectData?.eleventhSubject != ''&&{ title: subjectData?.eleventhSubject, dataKey: 31, key: 31 },
            subjectData?.twelvethSubject != ''&&{ title: subjectData?.twelvethSubject, dataKey: 32, key: 32 },
            subjectData?.thirteenthSubject != ''&&{ title: subjectData?.thirteenthSubject, dataKey: 33, key: 33 },
            subjectData?.fourteenthSubject != ''&&{ title: subjectData?.fourteenthSubject, dataKey: 34, key: 34 },
            subjectData?.fifteenthSubject != ''&&{ title: subjectData?.fifteenthSubject, dataKey: 35, key: 35 },
            subjectData?.sixteenthSubject != ''&&{ title: subjectData?.sixteenthSubject, dataKey: 36, key: 36 },
            subjectData?.seventeenthSubject != ''&&{ title: subjectData?.seventeenthSubject, dataKey: 37, key: 37 },
            subjectData?.eighteenthSubject != ''&&{ title: subjectData?.eighteenthSubject, dataKey: 38, key: 38 },
            subjectData?.nineteenthSubject != ''&&{ title: subjectData?.nineteenthSubject, dataKey: 39, key: 39 },
            subjectData?.twentythSubject != ''&&{ title: subjectData?.twentythSubject, dataKey: 40, key: 40 },
        ];


        // subjectData?.firstSubject != '' && (pdfcol.push({ title: subjectData?.firstSubject, dataKey: blank, key: blank }));
        // subjectData?.secondSubject != '' && (pdfcol.push({ title: subjectData?.secondSubject, dataKey: blank, key: blank }));
        // subjectData?.thirdSubject != '' && (pdfcol.push({ title: subjectData?.thirdSubject, dataKey: blank, key: blank }));
        // subjectData?.fourthSubject != '' && (pdfcol.push({ title: subjectData?.fourthSubject, dataKey: blank, key: blank }));
        // subjectData?.fifthSubject != '' && (pdfcol.push({ title: subjectData?.fifthSubject, dataKey: blank, key: blank }));
        // subjectData?.sixthSubject != '' && (pdfcol.push({ title: subjectData?.sixthSubject, dataKey: blank, key: blank }));
        // subjectData?.seventhSubject != '' && (pdfcol.push({ title: subjectData?.seventhSubject, dataKey: blank, key: blank }));
        // subjectData?.eighthSubject != '' && (pdfcol.push({ title: subjectData?.eighthSubject, dataKey: blank, key: blank }));
        // subjectData?.ninethSubject != '' && (pdfcol.push({ title: subjectData?.ninethSubject, dataKey: blank, key: blank }));
        // subjectData?.tenthSubject != '' && (pdfcol.push({ title: subjectData?.tenthSubject, dataKey: blank, key: blank }));
        // subjectData?.eleventhSubject != '' && (pdfcol.push({ title: subjectData?.eleventhSubject, dataKey: blank, key: blank }));
        // subjectData?.twelvethSubject != '' && (pdfcol.push({ title: subjectData?.twelvethSubject, dataKey: blank, key: blank }));
        // subjectData?.thirteenthSubject != '' && (pdfcol.push({ title: subjectData?.thirteenthSubject, dataKey: blank, key: blank }));
        // subjectData?.fourteenthSubject != '' && (pdfcol.push({ title: subjectData?.fourteenthSubject, dataKey: blank, key: blank }));
        // subjectData?.fifteenthSubject != '' && (pdfcol.push({ title: subjectData?.fifteenthSubject, dataKey: blank, key: blank }));
        // subjectData?.sixteenthSubject != '' && (pdfcol.push({ title: subjectData?.sixteenthSubject, dataKey: blank, key: blank }));
        // subjectData?.seventeenthSubject != '' && (pdfcol.push({ title: subjectData?.seventeenthSubject, dataKey: blank, key: blank }));
        // subjectData?.eighteenthSubject != '' && (pdfcol.push({ title: subjectData?.eighteenthSubject, dataKey: blank, key: blank }));
        // subjectData?.nineteenthSubject != '' && (pdfcol.push({ title: subjectData?.nineteenthSubject, dataKey: blank, key: blank }));
        // subjectData?.twentythSubject != '' && (pdfcol.push({ title: subjectData?.twentythSubject, dataKey: blank, key: blank }));

        var doc = new jsPDF("l", "mm", "a4");

        var details = `Section Wise list of ${$(".sectionSelect").text()} and Academic Year-${sectionWiseReportForm.getFieldValue("yearId")}`;
        var detailsx = `Section Wise list of Section:  and Academic Year: ${sectionWiseReportForm.getFieldValue("yearId")}`;
        pdfDataL(doc, "");
        doc.text('Student Exam Attendance List', 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            //HEADER
            if (data?.pageCount!==1){
                doc.text("Section:", data.settings.margin.left + 0, 22);
                doc.text(`${$(".sectionSelect").text()}`, data.settings.margin.left + 18, 22);
                doc.text("Group:", data.settings.margin.left + 125, 22);
                doc.text(`${$(".classSelectBySection").text()}`, data.settings.margin.left + 140, 22);
                doc.text("Exam:", data.settings.margin.left + 215, 22);
                doc.text(`${$(".examsectionSelect").text()}`, data.settings.margin.left + 228, 22);
            }
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
            { title: "", dataKey: "b3" },
            { title: "", dataKey: "b4" },
            { title: "", dataKey: "b5" },
            { title: "", dataKey: "b6" },
        ], [
            {
                b1: "Section:",
                b2: `${$(".sectionSelect").text()}`,
                b3: "Group",
                b4: `${$(".classSelectBySection").text()}`,
                b5: "Exam",
                b6: `${$(".examsectionSelect").text()}`,
            }
        ], {
            startY: 45,
            showHeader: "never",
            theme: 'grid',
            styles: { lineColor: 224, },
            columnStyles: {
                b1: {
                    fontStyle: 'bold'
                },
                b3: {
                    fontStyle: 'bold'
                },
                b5: {
                    fontStyle: 'bold'
                },

            },

            // addPageContent: pageContent,
        });
        doc.autoTable(pdfcol.filter(Boolean), tableData, {
            startY: doc.autoTable.previous.finalY + 5,
            showHeader: "everyPage",
            theme: 'grid',
            styles: {
                overflow: 'linebreak',
                fontSize: 6,
            },
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                halign: "center",
                cellPadding: 3,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                customStudentId: {
                    halign: "left",
                    fontSize: 6,
                    columnWidth: 16
                },
                studentName: {
                    halign: "left",
                    fontSize: 6,
                    columnWidth: 30
                },
                studentRoll: {
                    halign: "left",
                    fontSize: 6,
                    columnWidth: 11
                },

            },
            addPageContent: pageContent,
            margin: {top: 25}
        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save('Student Exam Attendance List' + ".pdf");

    };

    const [groupId, setGroupId] = useState<any>();
    const [exam, setExam] = useState<any>();



    return (
        <>
            <Card title="Student Exam Attendance List">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 3 }} xl={{ span: 20, offset: 3 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={sectionWiseReportForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="yearId"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="sectionId"
                                        label="Select Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig onChange={e => {
                                            setExam(null);
                                            setGroupId(null);
                                            fetchSemesterListByClassConfigId(e);
                                            fetchlistGroupbyClassConfig(e);
                                        }
                                        } />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="exam"
                                        label="Select Exam"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select exam" },
                                        ]}
                                    >
                                        <SelectExambySection selected={exam} onChange={e => setExam(e)} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="groupId"
                                        className="title-Text"
                                        label="Group"
                                        rules={[
                                            { required: true, message: "Select group list" },
                                        ]}
                                    >
                                        <SelectGroupbySection selected={groupId} onChange={e => setGroupId(e?.groupId)} />

                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
                {/* <Skeleton loading={loading} paragraph={{ rows: 10 }} /> */}
                <Row style={{ display: tableData?.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: studentListColumn,
                                rowKey: "studentId",
                                dataSource: tableData,
                                filterData: tableData,
                                pagination: true,
                                bordered: true,
                            }}
                            mobileBreakPoint={768}
                        />
                        {/* <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} onClick={() => deleteStudent()} className="float-right">Delete</Button> */}
                    </Col>
                    <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>

                            <Button type="primary" icon={<DownloadOutlined />} onClick={() => exportPdf()} >PDF Download</Button>
                        </Space>
                    </Col>
                </Row>

            </Card>
        </>
    )
}
