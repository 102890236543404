import { Select } from "antd";
import * as React from "react";
import { useStoreActions, useStoreState } from "../../store/hooks/easyPeasy";
const { Option } = Select;

export interface SelectInventoryCategory {
  onChange?: any;
  selected?: any;
  defaultSelected?: any;
  style?: any
}

export const SelectInventoryCategory = ({
  onChange,
  selected,
  defaultSelected,
  style
}: SelectInventoryCategory) => {
  const itemList = useStoreState((state) => state.inventory.inventoryCategoryList);
  const itemListFetch = useStoreActions((state) => state.inventory.fetchInventoryCategoryList);

  React.useEffect(()=>{
    itemListFetch()
  },[])
  
  const onSelect = (id) => {
    if (itemList) {
      const value = itemList.find((item) => item.categroyId === id);
      onChange(value.categroyId);
    }
  };



  return (
    <Select
      onChange={onSelect}
      showSearch
      defaultValue={defaultSelected}
      value={selected}
      style={style}
      placeholder="Select Category"
      filterOption={(input, option:any) =>
        option !== undefined &&
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {itemList ? (
        itemList.map((type, idx) => (
          <Option key={type.categroyId} value={type.categroyId}>
            {type.categoryName}
          </Option>
        ))
      ) : (
        <Option value="fetching">Fetching Category</Option>
      )}
    </Select>
  );
};
