import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, DatePicker } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import SessionYear from '../Students/settings/SessionYear.page';
import { SelectAcademicYear } from '../../select/SelectAcademicYear';
import { PlusOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

export default function TetimonialAdd() {
    const [searchForm] = Form.useForm();
    const [addForm] = Form.useForm();
    const fetchStudentInfoTransferCertificate = useStoreActions((state) => state.testimonials.fetchStudentInfoTransferCertificate );
    const studentInfoTransferCertificate = useStoreState((state) => state.testimonials.studentInfoTransferCertificate);
    const saveTestimonialsTransferCertificate = useStoreActions((state) => state.testimonials.saveTestimonialsTransferCertificate);
    const [studentInfoData, setStudentInfoData] = useState<any>();
    const [studentidentIficationId, setStudentIdentificationId] = useState<any>();
    const searchStudentForm = (value) => {
        let postData:any = {
            customStudentId: value.student_id,
            academicYear: value.academic_year,
        }
        fetchStudentInfoTransferCertificate(postData);
        
    }

    useEffect(() => {
        setStudentInfoData(studentInfoTransferCertificate);  
        setStudentIdentificationId(studentInfoTransferCertificate?.identificationId)      
    }, [studentInfoTransferCertificate])

    const addStudentForm = (value) => {
        
        let postData:any = {
            "leftDate": moment(value?.leftDate).format("YYYY-MM-DD"),
            "tcReason": value.tc_reason,
            "identificationId": studentidentIficationId,
        }
        saveTestimonialsTransferCertificate(postData);
    }

    return (
        <>
            <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                    <Card title="Student Information">
                        <Form
                            layout="vertical"
                            id="sessionInfo"
                            onFinish={searchStudentForm}
                            form={searchForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                    <Form.Item
                                        name="student_id"
                                        label="Student Id"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write student id" },
                                        ]}
                                    >
                                        <Input placeholder="student id" />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                    <Form.Item
                                        name="academic_year"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select academic year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                    <Button type="primary" htmlType="submit" className="mt-0" icon={<SearchOutlined />} style={{ float: "right" }}>
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        <br />
                        <Row>
                            <Col span={24}>
                                <div className={studentInfoData == "" || studentInfoData == undefined ? 'ant-table-wrapper d-none' : 'ant-table-wrapper'}>
                                    <div className="ant-spin-nested-loading">
                                        <div className="ant-spin-container">
                                            <div className="ant-table ant-table-bordered">
                                                <div className="ant-table-container">
                                                <div className="ant-table-content">
                                                    <table style={{ tableLayout: "auto" }}>
                                                    <tbody className="ant-table-tbody">
                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">Name</td>
                                                                <td className="ant-table-cell">{studentInfoData?.studentName}</td>
                                                            </tr>
                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">Father's Name</td>
                                                                <td className="ant-table-cell">{studentInfoData?.fatherName}</td>
                                                            </tr>
                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">Mother's Name</td>
                                                                <td className="ant-table-cell">{studentInfoData?.motherName}</td>
                                                            </tr>
                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">Date of Birth</td>
                                                                <td className="ant-table-cell">{studentInfoData?.studentDOB}</td>
                                                            </tr>
                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">Present Village</td>
                                                                <td className="ant-table-cell">{studentInfoData?.presentVillege}</td>
                                                            </tr>                                                            
                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">Present Post Office</td>
                                                                <td className="ant-table-cell">{studentInfoData?.presentPostOffice}</td>
                                                            </tr>
                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">Present Upazila/ P.S</td>
                                                                <td className="ant-table-cell">{studentInfoData?.presentThanaName}</td>
                                                            </tr>
                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">Present District</td>
                                                                <td className="ant-table-cell">{studentInfoData?.presentDistrictName}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }} xl={{ span: 16 }} className={studentInfoData == "" || studentInfoData == undefined ? 'd-none' : ''}>
                    <Card title="Add Transfer Certificate">
                        <Form
                            layout="vertical"
                            id="sessionInfo"
                            onFinish={addStudentForm}
                            form={addForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                    <Form.Item
                                            name="tc_reason"
                                            label="Tc Reason"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please write Tc Reason" },
                                            ]}
                                        >
                                            <Input placeholder="Tc Reason" />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                    <Form.Item
                                        name="leftDate"
                                        label="Left Date"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select late date" },
                                        ]}
                                    >
                                        <DatePicker placeholder="dd/mm/yyyy" style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                    <Button type="primary" htmlType="submit" className="mt-0" icon={<PlusOutlined />} style={{ float: "right" }}>
                                        Add
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </>
    )
}