import React from 'react'
import { Card, Tabs } from 'antd';
import OFPSSBL from './OFPSSBL';
import OFPSBkash from './OFPSBkash';
import UpayPgw from './UpayPgwCongiguration';


export default function OFPS(props) {

    const { TabPane } = Tabs;

    const [activeTab, setActiveTab] = React.useState<any>("1");

    return (
        <>
            <Card title="Online Fees Payment System Configuration">
                <Tabs defaultActiveKey="1" onChange={(e) => { setActiveTab(e) }}>
                    <TabPane tab="SBL" key="1">
                        {activeTab === "1" && <OFPSSBL />}
                    </TabPane>                    
                    <TabPane tab="Bkash" key="2">
                        {activeTab === "2" && <OFPSBkash />}
                    </TabPane>

                    <TabPane tab="UpayPgw" key="3">
                        {activeTab === "3" && <UpayPgw />}
                    </TabPane>

                </Tabs>
            </Card>
        </>
    )
}