import React, { useEffect } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Card from 'antd/lib/card/Card';
import { Col, Row } from 'antd';
import TableView from '../../../contents/AntTableResponsive';

export default function OfpsBkashList(props) {

    const bkashOfpsConfigurationList = useStoreState((state) => state.saccount.bkashOfpsConfigurationList);
    const fetchBkashOfpsConfigurationList = useStoreActions((state) => state.saccount.fetchBkashOfpsConfigurationList);
    
    useEffect( () => {
        fetchBkashOfpsConfigurationList();
    },[]);

    const columns = [
      { title: 'Institute ID', dataIndex: 'instituteId', key: "instituteId", showOnResponse: true, showOnDesktop: true },
      { title: 'Institute Name', dataIndex: 'instituteName', key: "instituteName", showOnResponse: true, showOnDesktop: true },
      { title: 'Merchant Number', dataIndex: 'merchantNumber', key: "merchantNumber", showOnResponse: true, showOnDesktop: true },
      { title: 'Bkash Username', dataIndex: 'bkashUsername', key: "bkashUsername", showOnResponse: true, showOnDesktop: true },
      { title: 'Bkash Password', dataIndex: 'bkashPassword', key: "bkashPassword", showOnResponse: true, showOnDesktop: true },
      { title: 'app Key', dataIndex: 'appKey', key: "appKey", showOnResponse: true, showOnDesktop: true },
      { title: 'app Secret', dataIndex: 'appSecret', key: "appSecret", showOnResponse: true, showOnDesktop: true },
      { title: 'Debit Ledger', dataIndex: 'debitLedgerName', key: "debitLedgerName", showOnResponse: true, showOnDesktop: true },
      { title: 'Credit Ledger', dataIndex: 'creditLedgerName', key: "creditLedgerName", showOnResponse: true, showOnDesktop: true },
      { title: 'Live Date', dataIndex: 'liveDate', key: "liveDate", showOnResponse: true, showOnDesktop: true },
  ];

  return (
     
      <>

         <Card title="Institute List">
           <Row className="m-t-mo-30">
                <Col span={24}>
                    <div className="datatable-responsive-demo">
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: bkashOfpsConfigurationList,
                                filterData: bkashOfpsConfigurationList,
                                pagination: true,
                                bordered: true,
                                rowKey: "instituteId",
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                </Col>
            </Row>
           </Card>

    </>
  )
}
