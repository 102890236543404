import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag } from 'antd';
import TableView from '../../../../contents/AntTableResponsive';

export default function GroupConfiurationCustomerSupport() {

    const fetchGroupConfigList = useStoreActions((state) => state.student.fetchGroupConfigList);
    const groupConfigurationAmountUpdate = useStoreActions((state) => state.student.groupConfigurationAmountUpdate);
    const groupConfigList = useStoreState((state) => state.student.groupConfigList);
    const [formS] = Form.useForm();
    const [updateForm] = Form.useForm();
    const loading = useStoreState((state) =>  state.student.loading);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tableData, setTableData] = useState<any>([]);

    useEffect(() => {
        fetchGroupConfigList();
    }, []);

    useEffect(() => {
        setTableData(groupConfigList)
    }, [groupConfigList])

    const [id, setId] = useState<any>();
    const columns = [

        {
            title: 'Class Name',
            dataIndex: 'className',
            key: 'className',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Group Name',
            dataIndex: 'groupName',
            key: 'groupName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        
        {
            title: 'Admission Charge',
            dataIndex: 'admissionCharge',
            key: 'admissionCharge',
            showOnResponse: true,
            showOnDesktop: true,
        },
        
        {
            title: 'Service Charge',
            dataIndex: 'serviceCharge',
            key: 'serviceCharge',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Admission Status',
            dataIndex: 'onlineAdmissionStatusString',
            key: 'onlineAdmissionStatusString',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Admission Exam DateTime',
            dataIndex: 'admissionExamDateTime',
            key: 'admissionExamDateTime',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Action',
            key: 'id',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                             setId(record.id);
                            updateForm.setFieldsValue({
                                admissionCharge: record.admissionCharge,
                                onlineAdmissionSatus: record.onlineAdmissionStatus===1?true:false,
                                serviceCharge: record.serviceCharge,
                                admissionExamDateTime: record.admissionExamDateTime,
                            });
                        }}
                        />
                    </Tooltip>


                </Space>
            ),
        },
    ];

    const updateFomrSubmit = (value) => {
        let postdata ={
            admissionCharge: value.admissionCharge,
            groupConfigId: id,
            serviceCharge: value.serviceCharge,
            onlineAdmissionSatus: value.onlineAdmissionSatus===true?1:0,
            admissionExamDateTime:value.admissionExamDateTime,
        }
        groupConfigurationAmountUpdate(postdata);
        setIsModalVisible(false);
    }

  return (
    <>

<Card title="Group Configuration List for Customer Support">

<Row className="m-t-mo-30">
                <Col span="24">
                    <div className="datatable-responsive-demo">
                        {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: tableData,
                                filterData:tableData,
                                pagination: false,
                                bordered: true,
                                rowKey: "id",
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                </Col>
            </Row>

            <Modal
                title="Update Group Configuration List"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"

                    onFinish={updateFomrSubmit}
                    id="update"
                    form={updateForm}
                >

                    <Form.Item
                        name="admissionCharge"
                        label="Admission Charge:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please input Admission Charge" },
                        ]}
                    >
                        <Input placeholder='Admission Charge' />
                    </Form.Item>


                    <Form.Item
                        name="serviceCharge"
                        label="Service Charge:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please input Service Charge" },
                        ]}
                    >
                        <Input placeholder='Service Charge' />
                    </Form.Item>
                    
                    <Form.Item
                        name="onlineAdmissionSatus"
                        label="Status:"
                        valuePropName="checked"
                        className="title-Text"
                    >
                       <Checkbox >Status</Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="admissionExamDateTime"
                        label="Admission Exam DateTime:"
                        className="title-Text"
                    >
                       <Input placeholder='Admission Exam DateTime' />
                    </Form.Item>
                </Form>

            </Modal>
</Card>
    </>
  )
}
