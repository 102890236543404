import { EditOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Modal, notification, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react'
import TableView from '../../../contents/AntTableResponsive';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { SelectPartnerList } from '../../select/SelectPartnerList';
import { SelectDistrict } from '../../select/SelectDistrict';
import { SelectThana } from '../../select/SelectThana';

export default function PartnerAssignInstitute() {
  const fetchInstiuteList = useStoreActions((state) => state.customerSupport.fetchInstiuteList);
  const updateInstituteThana = useStoreActions((state) => state.customerSupport.updateInstituteThana);
  const instiuteList = useStoreState((state) => state.customerSupport.instiuteList);
  const [insId, setinsId] = useState<any>('');
  const [disttrict, setDistrict] = useState<any>();
  const [thana, setThana] = useState<any>();
  const [updateForm] = Form.useForm();
  const districsIdStore = (val) => {
    setDistrict(val);
  }

  const assignPartnerInInstitutes = useStoreActions((state) => state.customerSupport.savePartnerInstituteAssign);
  const thanaListFetch = useStoreActions((state) => state.common.thanaListFetch);

  useEffect(() => {
    fetchInstiuteList();
  }, []);

  const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
  const [selectedValue, setselectedValue] = useState<any>([]);

  const onSelectChange = (selectedRowKeys, value) => {
    setselectedRowKeys(selectedRowKeys);
    setselectedValue(value);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const openModal = () => {

    if (selectedValue.length === 0) {
      notification.error({ message: "Please select institute" });
      return;
    }

    setIsModalVisible(true)
  }

  const assignPartnerToInstitute = (value) => {

    let instituteIds = selectedValue.map(item => item.instituteId);
    let postData: any = {
      "instituteIds": instituteIds,
      "partnerId": value.partnerId
    }
    assignPartnerInInstitutes(postData);
    setIsModalVisible(false);

  }

  const columns = [
    { title: 'Institute ID', dataIndex: 'instituteId', key: "instituteId", showOnResponse: true, showOnDesktop: true },
    { title: 'Institute Name', dataIndex: 'instituteName', key: "instituteName", showOnResponse: true, showOnDesktop: true },
    { title: 'Partner Name', dataIndex: 'partnerName', key: "partnerName", showOnResponse: true, showOnDesktop: true },
    { title: 'Package', dataIndex: 'packageName', key: "packageName", showOnResponse: true, showOnDesktop: true },
    { title: 'Address', dataIndex: 'address', key: "address", showOnResponse: true, showOnDesktop: true },
    { title: 'Thana', dataIndex: 'thanaName', key: "thanaName", showOnResponse: true, showOnDesktop: true },
    { title: 'District', dataIndex: 'districtName', key: "districtName", showOnResponse: true, showOnDesktop: true },
    { title: 'Institute Status', dataIndex: 'instituteStatus', key: "instituteStatus", showOnResponse: true, showOnDesktop: true },
    
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      showOnResponse: true,
      showOnDesktop: true,
      render: (text, record, index) => (
        <Button type='primary' icon={<EditOutlined />}
          onClick={() => {
            setIsModalVisible2(true);
            setinsId(record?.instituteId);
            setDistrict(record.districtId);
            updateForm.setFieldsValue({
              district: record.districtId,
            });
            setTimeout(() => {
              setThana(record.thanaId);
              updateForm.setFieldsValue({
                thana: record.thanaId,
              });

            }, 1000);
          }}
        />
      ),
    },

  ];

  const updateThana = (value) => {
    let payload = {
      "instituteId": insId,
      "thanaId": value?.thana
    }
    updateInstituteThana(payload);
    setIsModalVisible2(false);
  }
  return (
    <>

      <Card title="Institute List">
        <Row className="m-t-mo-30">
          <Col span={24}>
            <div className="datatable-responsive-demo">
              <TableView
                antTableProps={{
                  showHeader: true,
                  columns,
                  dataSource: instiuteList,
                  filterData: instiuteList,
                  pagination: true,
                  bordered: true,
                  rowKey: "instituteId",
                  rowSelection: rowSelection,
                }}
                mobileBreakPoint={768}
              />
            </div>

            <Space size="middle" style={{ float: 'right' }}>
              <Button type="primary" icon={<SettingOutlined />} onClick={() => openModal()}> Process</Button>
            </Space>

          </Col>

        </Row>




      </Card>

      <Modal
        title="Assign"
        visible={isModalVisible}
        okButtonProps={{ form: 'assign', htmlType: 'submit' }}
        onCancel={() => setIsModalVisible(false)}
        cancelText="Close"
        okText="Assign"
        centered
      >
        <Form
          layout="vertical"
          onFinish={assignPartnerToInstitute}
          id="assign"
        >
          <Form.Item
            name="partnerId"
            label="Partner Name"
            className="title-Text"
            rules={[
              { required: true, message: "Please select Partner" },
            ]}
            style={{ width: "100%" }}
          >

            <SelectPartnerList />

          </Form.Item>

        </Form>

      </Modal>

      <Modal
        title="Update"
        visible={isModalVisible2}
        okButtonProps={{ form: 'update', htmlType: 'submit' }}
        onCancel={() => setIsModalVisible2(false)}
        cancelText="Close"
        okText="Update"
        centered
      >
        <Form
          layout="vertical"
          onFinish={updateThana}
          id="update"
          form={updateForm}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="district"
                label="District"
                className="title-Text"
                rules={[
                  { required: true, message: "Select district name" },
                ]}
              >
                <SelectDistrict selected={disttrict} onChange={e => { districsIdStore(e); setThana(null) }} />

              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="thana"
                label="Thana"
                className="title-Text"
                rules={[
                  { required: true, message: "Select thana" },
                ]}
              >
                <SelectThana selected={thana} onChange={e => setThana(e)} />

              </Form.Item>
            </Col>
          </Row>

        </Form>

      </Modal>

    </>
  )
}
