
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Skeleton, Space, Tooltip, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import NoImage from './no.png'
import { SelectReligion } from '../../../select/SelectReligion';
import { SelectDesignation } from '../../../select/SelectDesignation';
import { SelectBloodGroup } from '../../../select/SelectBloodGroup';
import { SelectGender } from '../../../select/SelectGender';
import { Typography } from 'antd';
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import { SelectSubjectByClassNGroup } from '../../../select/SelectSubjectByClassNGroup';
import { SelectThana } from '../../../select/SelectThana';
import { SelectDistrict } from '../../../select/SelectDistrict';
import { SelectDistrict2 } from '../../../select/SelectDistrict2';
import { SelectThana2 } from '../../../select/SelectThana2';
const { Option } = Select;
export default function StaffDocumentUpload() {

    const updateSingleStaffPhoto = useStoreActions((state) => state.staff.updateSingleStaffPhoto);
    const updateSingleStaffSignature = useStoreActions((state) => state.staff.updateSingleStaffSignature);
    const instituteInfo = useStoreState(state => state.auth.instituteInfo)
    const fetchstaffBasicSingleViewByStaff = useStoreActions((state) => state.staff.fetchstaffBasicSingleViewByStaff);
    const loading = useStoreState((state) => state.staff.loading);
    const [updateForm] = Form.useForm();
    const staffBasicSingleViewByStaff = useStoreState((state) => state.staff.staffBasicSingleViewByStaff);

    useEffect(() => {
        fetchstaffBasicSingleViewByStaff(instituteInfo?.staffId);
    }, [])


    const uploadImage = (val, id) => {
        let image_as_files = val.target.files[0];
        let data = {
            file: image_as_files,
            staffId: id
        }
        updateSingleStaffPhoto(data)
    }


    const uploadSignature = (val, id) => {
        let signature_image_as_files = val.target.files[0];
        let signatureData = {
            file: signature_image_as_files,
            staffId: id
        }
        updateSingleStaffSignature(signatureData)
    }

    const updateFomrSubmit = (value) => {
        value.birthDate = value.birthDate === null ? null : moment(value?.birthDate).format("YYYY-MM-DD");
        value.employmentDate = value.employmentDate === null ? null : moment(value?.employmentDate).format("YYYY-MM-DD");
        value.promotionDate = value.promotionDate === null ? null : moment(value?.promotionDate).format("YYYY-MM-DD");
        value.staffStatus = value.staffStatus === true ? 1 : 0;
      
        // console.log(value)
        //updateDesignation(postdata);
   
    }
  return (
    <>
            
            < >
                <Form
                    layout="vertical"

                    onFinish={updateFomrSubmit}
                    id="update"
                    form={updateForm}
                >
                    <Row gutter={8}>
                    

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>Staff Image</b><br/>
                        <img src={staffBasicSingleViewByStaff?.imageName == '' ? NoImage : ("data:image/png;base64," + staffBasicSingleViewByStaff?.imageName)} alt="Staff Image" style={{ height: 50, width: 50, marginBottom:5 }} />
                    <br/>
                        <input type="file" name="my_file" id="my_file" accept="image/*" onChange={(e) => uploadImage(e, staffBasicSingleViewByStaff?.staffId)} />
                    </Col>

                        
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>Signature</b><br/>
                        <img src={staffBasicSingleViewByStaff?.signatureName == '' ? NoImage : ("data:image/png;base64," + staffBasicSingleViewByStaff?.signatureName)} alt="Staff Signature" style={{ height: 50, width: 50, marginBottom:5 }} />
                        <br />
                        <input type="file" name="signature_file" id="signature_file" accept="image/*" onChange={(e) => uploadSignature(e, staffBasicSingleViewByStaff?.staffId)} />
                    </Col>                    
                       
                    </Row>
                 
                </Form>
            </>
            {/* 
            </Modal> */}
        </>
  )
}
