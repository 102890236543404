import { Select } from "antd";
import * as React from "react";
import { useStoreActions, useStoreState } from "../../store/hooks/easyPeasy";
const { Option } = Select;

export interface SelectSubjectByClassNGroupMultiple {
  onChange?: any;
  selected?: any;
  defaultSelected?: any;
  style?: any
}

export const SelectSubjectByClassNGroupMultiple = ({
  onChange,
  selected,
  defaultSelected,
  style
}: SelectSubjectByClassNGroupMultiple) => {
  const itemList = useStoreState((state) => state.exam.subjectConfigurationList);

  
  const onSelect = (id) => {
    if (itemList) {
     // const val = itemList.find((item) => item.ledgerId === id);
      onChange(id);
    }
  };


  return (
    <Select
      onChange={onSelect}
      // loading={loading}
      showSearch
      // allowClear
      className="subjectSelectByGroup"
      defaultValue={defaultSelected}
      value={selected}
      style={style}
      mode="multiple"
      placeholder="Select Subject"
      filterOption={(input, option:any) =>
        option !== undefined &&
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {itemList ? (
        itemList.map((type, idx) => (
          <Option key={type.subjectId} value={type.subjectId}>
            {type.subjectName}
          </Option>
        ))
      ) : (
        <Option value="fetching">Fetching Subject</Option>
      )}
    </Select>
  );
};
