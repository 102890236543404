
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';
import TableView from '../../../../contents/AntTableResponsive';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { FilePdfOutlined } from '@ant-design/icons';
import moment from 'moment';
import ReactExport from "react-export-excel";

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

require('jspdf-autotable');
let year = new Date().getFullYear();

const { Option } = Select;
export default function YearlyFeeDetails() {

    const [justForm] = Form.useForm();
    const fetchshowStudentYearlyDetails = useStoreActions((state) => state.saccount.fetchshowStudentYearlyDetails);
    const showStudentYearlyDetails = useStoreState((state) => state.saccount.showStudentYearlyDetails);
    const loading = useStoreState((state) => state.saccount.loading);


    const formSubmit = (value) => {
        let postData: any = {
            academicYear: value.yearId,
            classId: value.classId,
            genealYear: value.genealYear,
        }
        fetchshowStudentYearlyDetails(postData);
    }

    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        { title: 'Section', dataIndex: 'sectionName', key: 'sectionName', showOnResponse: true, showOnDesktop: true },
        { title: 'January', dataIndex: 'jan', key: 'jan', showOnResponse: true, showOnDesktop: true },
        { title: 'February', dataIndex: 'feb', key: 'feb', showOnResponse: true, showOnDesktop: true },
        { title: 'March', dataIndex: 'mar', key: 'mar', showOnResponse: true, showOnDesktop: true },
        { title: 'April', dataIndex: 'apr', key: 'apr', showOnResponse: true, showOnDesktop: true },
        { title: 'May', dataIndex: 'may', key: 'may', showOnResponse: true, showOnDesktop: true },
        { title: 'June', dataIndex: 'jun', key: 'jun', showOnResponse: true, showOnDesktop: true },
        { title: 'July', dataIndex: 'jul', key: 'jul', showOnResponse: true, showOnDesktop: true },
        { title: 'August', dataIndex: 'aug', key: 'aug', showOnResponse: true, showOnDesktop: true },
        { title: 'September', dataIndex: 'sep', key: 'sep', showOnResponse: true, showOnDesktop: true },
        { title: 'October', dataIndex: 'oct', key: 'oct', showOnResponse: true, showOnDesktop: true },
        { title: 'November', dataIndex: 'nov', key: 'nov', showOnResponse: true, showOnDesktop: true },
        { title: 'December', dataIndex: 'dec', key: 'dec', showOnResponse: true, showOnDesktop: true },
        { title: 'Total', dataIndex: 'total', key: 'total', showOnResponse: true, showOnDesktop: true },
    ];

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");
        var details = `Yearly Fee Details of Class: ${$(".classSelect").text()} Academic Year: ${justForm.getFieldValue("yearId")}`;
        var detailsx = `Yearly Fee Details of Class: ${$(".classSelect").text()}, Academic Year: ${justForm.getFieldValue("yearId")}, Calendar Year: ${justForm.getFieldValue("genealYear")}, Date: ${moment(new Date).format('DD/MM/YYYY')}`;
        pdfDataL(doc, "");
        doc.text(detailsx, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        var col = [
            "Student ID",
            "Student Name",
            "Roll",
            "Section",
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
            "Total"
        ].filter(Boolean);

        var rows: any = [];


        showStudentYearlyDetails.forEach(element => {
            var temp: any = [
                element.customStudentId,
                element.studentName,
                element.studentRoll,
                element.sectionName,
                element.jan,
                element.feb,
                element.mar,
                element.apr,
                element.may,
                element.jun,
                element.jul,
                element.aug,
                element.sep,
                element.oct,
                element.nov,
                element.dec,
                element.total,
            ];
            rows.push(temp);
        });


        let first = doc.autoTable.previous;
        doc.autoTable(col, rows, {
            startY: 45,
            showHeader: "everyPage",
            theme: 'grid',
            styles: {
                overflow: 'linebreak',
                fontSize: 7,
            },
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
               
                // guardianMobile: {
                //     halign: "left",
                //     fontSize: 8,
                //     columnWidth: 250
                // }
            },
            addPageContent: pageContent,
        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    return (
        <>
            <Card title="Yearly Fee Details">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={justForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="yearId"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="classId"
                                        label="Select Class"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select class" },
                                        ]}
                                    >
                                        <SelectClass />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="genealYear"
                                        label="Calendar Year:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                        style={{ width: "100%" }}
                                    >
                                        <Select placeholder="Select Year" allowClear style={{ width: "100%" }}>
                                            <Option value={year - 2}>{year - 2}</Option>
                                            <Option value={year - 1}>{year - 1}</Option>
                                            <Option value={year}>{year}</Option>
                                            <Option value={year + 1}>{year + 1}</Option>
                                            <Option value={year + 2}>{year + 2}</Option>
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: showStudentYearlyDetails !== null ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: studentListColumn,
                                rowKey: "identificationId",
                                dataSource: showStudentYearlyDetails,
                                filterData: showStudentYearlyDetails,
                                pagination: true,
                                bordered: true,
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                        {/* <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} onClick={() => deleteStudent()} className="float-right">Delete</Button> */}
                    </Col>
                    <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>

                        <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Yearly Fee Details`}
                            >
                                <ExcelSheet data={showStudentYearlyDetails} name="Yearly Fee Details">
                                    <ExcelColumn label="Student Id" value="customStudentId" />
                                    <ExcelColumn label="Student Name" value="studentName" />
                                    <ExcelColumn label="Student Roll" value="studentRoll" />
                                    <ExcelColumn label="Section Name" value="sectionName" />
                                    <ExcelColumn label="January" value="jan" />
                                    <ExcelColumn label="February" value="feb" />
                                    <ExcelColumn label="March" value="mar" />
                                    <ExcelColumn label="April" value="apr" />
                                    <ExcelColumn label="May" value="may" />
                                    <ExcelColumn label="June" value="jun" />
                                    <ExcelColumn label="July" value="jul" />
                                    <ExcelColumn label="August" value="aug" />
                                    <ExcelColumn label="September" value="sep" />
                                    <ExcelColumn label="October" value="oct" />
                                    <ExcelColumn label="November" value="nov" />
                                    <ExcelColumn label="December" value="dec" />
                                    <ExcelColumn label="Total" value="total" />


                                </ExcelSheet>
                            </ExcelFile>


                            <Button type="primary" icon={<DownloadOutlined />} onClick={() => exportPdf()} >PDF Download</Button>
                        </Space>
                    </Col>
                </Row>

            </Card>
        </>
    )
}
