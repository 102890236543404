import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { fetchAlumniApplicantList, fetchAlumniPaymentProblemView, solveAlumniPaymentProblem } from '../../../http/alumniStudent/alumniStudent';
require("dotenv").config();

export interface AlumniStudent {

    loading: boolean;
    setLoading: Action<AlumniStudent, boolean>;


    fetchAlumniApplicantList: Thunk<AlumniStudent>;
    setAlumniApplicantList: Action<AlumniStudent, any>;
    alumniApplicantList:any;
    
    alumniSpgProblemView: any;
    setAlumniSpgProblemView: Action<AlumniStudent, any>;
    fetchAlumniSpgProblemView: Thunk<AlumniStudent, any>; 

    solveAlumniSpgProblem: Thunk<AlumniStudent, any>;

}

export const alumniStudentStore: AlumniStudent ={

    loading: false,
    setLoading: action((state, payload) => {
        state.loading = payload;
    }),

    
    alumniApplicantList :[],
    fetchAlumniApplicantList:thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await fetchAlumniApplicantList(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body?.item.length > 0){
                actions.setAlumniApplicantList(body.item);
                actions.setLoading(false);
            }else{
                actions.setLoading(false);
                actions.setAlumniApplicantList([]);
                notification.error({ message: "No Applicant Found" })
            }
            
        } else {
            const body = await response.json();
            actions.setLoading(false);
            notification.error({ message: body.message })
        }
    }),
    setAlumniApplicantList: action((state, payload) => {
        state.alumniApplicantList = payload;
    }),



    alumniSpgProblemView :[],
    fetchAlumniSpgProblemView:thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await fetchAlumniPaymentProblemView(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body?.item.length > 0){
                actions.setAlumniSpgProblemView(body.item);
                actions.setLoading(false);
            }else{
                actions.setLoading(false);
                actions.setAlumniSpgProblemView([]);
                notification.error({ message: "No Applicant Found" })
            }
            
        } else {
            const body = await response.json();
            actions.setLoading(false);
            notification.error({ message: body.message })
        }
    }),
    setAlumniSpgProblemView: action((state, payload) => {
        state.alumniSpgProblemView = payload;
    }),


    solveAlumniSpgProblem: thunk(async (actions, payload) => {
        const response = await solveAlumniPaymentProblem(payload);
        if (response.status === 201 || response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }
        } else {
            notification.error({ message: "Something went wrong" })
        }
    }),

}