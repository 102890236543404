import { createStore } from 'easy-peasy';
import { Auth, authStore } from './states/auth/auth';
import { Common, commonStore } from './states/common/common';
import { Ledger, ledgerStore } from './states/ledger/ledger';
import { User, userStore } from './states/user/user';
import { Student, studentStore } from './states/student/student';
import { Saccount, saccountStore } from './states/saccount/saccount';
import { Staff, staffStore } from './states/staff/staff';
import { Exam, examStore } from './states/exam/exam';
import { Sms, smsStore } from './states/sms/sms';
import { MasterSettings, masterSettingsStore } from './states/masterSettings/masterSettings';
import { Testimonials, testimonialsStore } from './states/testimonials/testimonials';
import { customerSupport, CustomerSupport } from './states/customerSupport/customerSupport';
import { Payslip, payslipStore } from './states/payslip/payslip';
import { Dashboard,  dashboardStore} from './states/dashboard/dashboard';
import { OnlineAdmission, onlineAdmissionStore } from './states/onlineAdmision/onlineAdmission';
import { Library, libraryStore } from './states/library/Library';
import { AlumniStudent, alumniStudentStore } from './states/alumni/alumniStudent';
import { Inventory, inventoryStore } from './states/inventory/Inventory';

export interface StoreModel {
	auth: Auth;
	dashboard: Dashboard;
	common: Common;
	ledger: Ledger;
	user: User;
	student: Student;
	library: Library;
	saccount: Saccount;
	staff: Staff;
	exam: Exam;
	sms:Sms;
	masterSettings:MasterSettings;
	testimonials:Testimonials;
	payslip: Payslip;
	customerSupport:CustomerSupport;
	onlineAdmission:OnlineAdmission;
	alumniStudent:AlumniStudent;
	inventory: Inventory;
	
}

const storeModel: StoreModel = {
	auth: authStore,
	dashboard: dashboardStore,
	common: commonStore,
	ledger: ledgerStore,
	user: userStore,
	student: studentStore,
	library: libraryStore,
	saccount: saccountStore,
	staff: staffStore,
	exam: examStore,
	sms:smsStore,
	masterSettings:masterSettingsStore,
	testimonials:testimonialsStore,
	customerSupport:customerSupport,
	payslip: payslipStore,
	onlineAdmission: onlineAdmissionStore,
	alumniStudent : alumniStudentStore,
	inventory: inventoryStore,
}

export const store = createStore(storeModel);
