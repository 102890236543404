export const genederData = [
  "Male",
  "Female",
  "Other"
]
export const medium = ["Bangla", "English"]

export const religionData = [
  "Islam",
  "Hinduism",
  "Buddist",
  "Christian",
  "Other"
]

export const quotaData = [
  "Not Applicable",
  "Teacher of Udayan",
  "Freedom Fighter",
  "Catchment Area",
  "Teacher/Officer of Dhaka University"
]

export const maritalData = {
  "Unmarried": "Unmarried",
  "Married": "Married",
}

export const bloodData = [
  "A+",
  "A-",
  "AB+",
  "AB-",
  "B+",
  "B-",
  "O+",
  "O-"
]
  
export const autismData = [{
  0: "No",
  1: "Yes",
}]

export const fatheroccupationdata = [
  "Business",
  "Service",
  "Private Service",
  "Govt. Service",
  "Teacher",
  "Banker",
  "Doctor",
  "Engineer",
  "Lawyer",
  "Journalist",
  "Farmer",
  "Driver",
  "Physician",
  "Army",
  "Police Officer",
  "Navy",
  "Airforce",
  "Retired Person",
  "NRB",
  "BGB",
  "N/A",
  "NSI"
]
export const motheroccupationdata = [
  "House Wife",
  "Business",
  "Service",
  "Private Service",
  "Govt. Service",
  "Teacher",
  "Banker",
  "Doctor",
  "Engineer",
  "Lawyer",
  "Journalist",
  "Farmer",
  "Driver",
  "Physician",
  "Army",
  "Police Officer",
  "Navy",
  "Airforce",
  "Retired Person",
  "NRB",
  "BGB",
  "N/A",
  "NSI"
]

export const examNameData = {
  "SSC": "SSC",
  "Dakhil": "Dakhil",
  "Vocational ": "Vocational",
  "HSC ": "HSC",
  "Alim ": "Alim",
  "Others": "Others",
}

export const examGroupData = {
  "Science": "Science",
  "Humanities": "Humanities",
  "Business Studies": "Business Studies",
  "General": "General",
  "Other": "Other",
}

export const gradeData = {
  "A+": "A+",
  "A": "A",
  "A-": "A-",
  "B": "B",
  "C": "C",
  "D": "D",
}

export const boardData = {
  "Barisal": "Barisal",
  "Chittagong": "Chittagong",
  "Comilla": "Comilla",
  "Dhaka": "Dhaka",
  "Dinajpur": "Dinajpur",
  "Jashore": "Jashore",
  "Mymensingh": "Mymensingh",
  "Rajshahi": "Rajshahi",
  "Sylhet ": "Sylhet",
  "Technical ": "Technical",
  "Madrasah": "Madrasah",
}
export const boardData2 = [
  "Barisal",
  "Chittagong",
  "Comilla",
  "Dhaka",
  "Dinajpur",
  "Jashore",
  "Mymensingh",
  "Rajshahi",
  "Sylhet",
  "Technical",
  "Madrasah",
]

export const examNameData2 = [
  "SSC",
  "Dakhil",
  "Vocational ",
  "HSC",
  "Alim",
  "Polytechnic",
  "Others",
]
export const examNameData3 = [
  "SSC/Equivalent",
  "HSC/Equivalent",
  "BS/Equivalent",
  "MS//Equivalent",
]

export const graduateProgrameData = {
  "MS": "MS",
  "PhD": "PhD",
}

export const termOfAdmissionData = {
  "Summer": "Summer",
  "Autumn": "Autumn",
  "Winter": "Winter",
}
export const freedomFigherData = {
  "Yes": "Yes",
  "No": "No"
}