import React, { useEffect } from 'react'
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import MarkSheet from './MarkSheet';
import GrandFinalMarkSheet from './GrandFinalMarkSheet';
import RemarksTemplate from './RemarksTemplate';



export default function ExamTemplate() {

    const { TabPane } = Tabs;


    return (
        <>
            <Card title="Marksheet Template Configurations" bordered={false}>
                <Tabs defaultActiveKey="2">
                    <TabPane tab="General Marksheet" key="1">
                        <MarkSheet />
                    </TabPane>
                    <TabPane tab="Grand Final Marksheet" key="2">
                        <GrandFinalMarkSheet />
                    </TabPane>
                    <TabPane tab="Remark" key="3">
                        <RemarksTemplate />
                    </TabPane>
                </Tabs>

            </Card>

        </>
    )
}