import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Skeleton, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Table from "../../../contents/AntTableResponsive";
import { SelectFeeHead } from '../../select/SelectFeeHead';
import { SelectLedgerRegularIncome } from '../../select/SelectLedgerRegularIncome';
import { SelectClass } from '../../select/SelectClass';
import { SelectSanmasikClass } from '../../select/SelectSanmasikClass';



export default function SanmasikClassInstituteClassConfiguration(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();

    const instituteClassSanmasikclassConfigurationList = useStoreState((state) => state.customerSupport.instituteClassSanmasikclassConfigurationList);
    const fetchInstituteClassSanmasikClassConfigurationList = useStoreActions((state) => state.customerSupport.fetchInstituteClassSanmasikClassConfigurationList);
    const saveInstituteClassSanmasikclassConfiguration = useStoreActions((state) => state.customerSupport.saveInstituteClassSanmasikclassConfiguration);
    const deleteInstituteClassSanmasikclassConfiguration = useStoreActions((state) => state.customerSupport.deleteInstituteClassSanmasikclassConfiguration);
    const loading = useStoreState((state) =>  state.saccount.loading);

    useEffect(() => {
        fetchInstituteClassSanmasikClassConfigurationList();
    }, [])

    const formSubmit = (value) => {
        saveInstituteClassSanmasikclassConfiguration(value);
    }


    const [id, setId] = useState<any>();

    const columns = [

        {
            title: 'Institute Class Name',
            dataIndex: 'instituteClassName',
            key: 'instituteClassName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Sanmasik Class Name',
            dataIndex: 'sanmasikClassName',
            key: 'sanmasikClassName',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Action',
            key: 'configId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">

                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteInstituteClassSanmasikclassConfiguration(record?.configId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        },
    ];

  
    return (
        <>
            <Card title="Institute Class Sanmasik Class Configuration" >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="instituteClassId"
                                label="Institute Class "
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select institute class" },
                                ]}
                            >

                                <SelectClass/>
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="sanmasikClassId"
                                label="Sanmasik Class"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select sanmasik class" },
                                ]}
                            >

                                <SelectSanmasikClass />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                                    Save
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>
               { instituteClassSanmasikclassConfigurationList=== null && < Skeleton loading={loading} paragraph={{rows:10}} />}             
               { instituteClassSanmasikclassConfigurationList!== null &&
                <div className="datatable-responsive">

                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: instituteClassSanmasikclassConfigurationList,
                            filterData: instituteClassSanmasikclassConfigurationList,
                            bordered: true
                        }}
                        mobileBreakPoint={768}
                    />
                </div>
            }
            </Card>
        </>
    )
}
