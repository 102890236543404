import { Select } from "antd";
import * as React from "react";
import { useStoreActions, useStoreState } from "../../store/hooks/easyPeasy";
const { Option } = Select;

export interface SelectSanmasikChpater {
  onChange?: any;
  selected?: any;
  defaultSelected?: any;
  style?: any
}

export const SelectSanmasikChpater = ({
  onChange,
  selected,
  defaultSelected,
  style
}: SelectSanmasikChpater) => {
  const itemList = useStoreState((state) => state.customerSupport.chapterInfoList);
  
  const onSelect = (chapterId) => {
    if (itemList) {
      const value = itemList.find((item) => item.chapterId === chapterId);
      onChange(value.chapterId);
    }
  };


  return (
    <Select
      onChange={onSelect}
      // loading={loading}
      showSearch
      // allowClear
      defaultValue={defaultSelected}
      value={selected}
      style={style}
      placeholder="Select Chapter"
      filterOption={(input, option:any) =>
        option !== undefined &&
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {itemList ? (
        itemList.map((type, idx) => (
          <Option key={type.chapterId} value={type.chapterId}>
            {type.chapterName}
          </Option>
        ))
      ) : (
        <Option value="fetching">Fetching Chapter</Option>
      )}
    </Select>
  );
};
