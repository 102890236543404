import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton, notification } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../select/SelectAcademicYear';
import TableView from '../../../contents/AntTableResponsive';
import Table from '../../../contents/AntTableResponsive';

import $ from 'jquery';
import jsPDF from "jspdf";

import { FilePdfOutlined } from '@ant-design/icons';
import ReactExport from "react-export-excel";
import { SelectGroupConfiguration } from '../../select/SelectGroupConfiguration';
import { SelectClassConfigurationListByGroupConfig } from '../../select/SelectClassConfigurationListByGroupConfig';
import { SelectCategory } from '../../select/SelectCategory';

require('jspdf-autotable');
const { Option } = Select;

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

export default function OnlineAdmissionStudentRegistration() {


    const [paidUnpaidReportForm] = Form.useForm();
    const fetchOnlineAdmissionReportPaidUnpaid = useStoreActions((state) => state.onlineAdmission.fetchOnlineAdmissionReportGroupByPaidUnpaid);
    const onlineAdmissionReportPaidUnpaidList = useStoreState((state) =>  state.onlineAdmission.onlineAdmissionReportGroupByPaidUnpaid);
    const doStudentRegistrationFromOnlineAdmission = useStoreActions((state) => state.student.doStudentRegistrationFromOnlineAdmission);

    const fetchClassConfigurationListByGroupConfig = useStoreActions((state) => state.student.fetchClassConfigurationListByGroupConfig);

    const loading = useStoreState((state) =>  state.student.loading);


    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [selectedValue, setselectedValue] = useState([]);

    const [modalVisible, setModalVisible] = useState(false);
    const [datax,setData]= useState<any>([]);
    const [groupConfigurationId,setGroupConfigurationId]= useState<any>(null);

    const showModal = () => {
        let checkSelectedRow = selectedRowKeys.length;
        if (checkSelectedRow > 0) {
            setModalVisible(true)
        } else {
            notification.error({ message: 'Select the table row first' });
        }
    }

    
    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const formSubmit = (value) => {
        let postData:any = {
            academicYear: value.academicYear,
            paymentStatus: value.paymentStatus,
            groupConfigId: value.groupConfigurationId
        }
        fetchOnlineAdmissionReportPaidUnpaid(postData);
        fetchClassConfigurationListByGroupConfig(groupConfigurationId);
    }

    const registerStudents = (value) => {
        
        let applicantIds = selectedValue.map((item: any) => item.applicantId);
        
        let payload = {

            applicantIds : applicantIds,
            classConfigurationId : value.classConfigurationId,
            studentCategoryId :value.studentCategoryId,
        }

        doStudentRegistrationFromOnlineAdmission(payload);

        setselectedValue([]);
        setselectedRowKeys([]);
        setModalVisible(false);
    }

    const studentListColumn = [
        { title: 'Registration Id', dataIndex: 'registrationId', key: 'registrationId', showOnResponse: true, showOnDesktop: true  },
        { title: 'Class Name', dataIndex: 'className', key: 'className', showOnResponse: true, showOnDesktop: true  },
        { title: 'Applicant Name', dataIndex: 'applicantName', key: 'applicantName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Gender', dataIndex: 'gender', key: 'gender', showOnResponse: true, showOnDesktop: true  },
        { title: 'Date of Birth', dataIndex: 'dateOfBirthString', key: 'dateOfBirthString', showOnResponse: true, showOnDesktop: true  },
        { title: 'Father Name', dataIndex: 'fatherName', key: 'fatherName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Present District', dataIndex: 'presentDistrict', key: 'presentDistrict', showOnResponse: true, showOnDesktop: true  },
        { title: 'Guardian Mobile', dataIndex: 'localGuardianMobile', key: 'localGuardianMobile', showOnResponse: true, showOnDesktop: true  },
        { title: 'Admission Completed', dataIndex: 'admissionCompleted', key: 'admissionCompleted', showOnResponse: true, showOnDesktop: true  },
        { title: 'Admission Charge', dataIndex: 'admissionCharge', key: 'admissionCharge', showOnResponse: true, showOnDesktop: true  },
        { title: 'Payment Status', dataIndex: 'paymentStatus', key: 'paymentStatus', showOnResponse: true, showOnDesktop: true  },
        { title: 'Payment Date', dataIndex: 'paymentDate', key: 'paymentDate', showOnResponse: true, showOnDesktop: true  },
        {
            title: 'Photo',
            dataIndex: 'applicantId',
            key: 'applicantId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => {
                let imgDataLink = record.studentImageLink;
                return (
                    <div style={{ display: "flex" }}>
                        <img src={imgDataLink} height='35' width='30' />
                    </div>
                )
               
            },
        },

    ];

  return (
    <>
    <Card title="Student Registration From Online Admission">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 3 }} xl={{ span: 20, offset: 3 }}>
                        <Form
                            layout="vertical"
                            id="paidUnpaidReport"
                            onFinish={formSubmit}
                            form={paidUnpaidReportForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]} 
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="groupConfigurationId"
                                        label="Class-Group"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select class" },
                                        ]} 
                                    >
                                        <SelectGroupConfiguration onChange={e => setGroupConfigurationId(e)}/>
                                       
                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="paymentStatus"
                                        label="Payment Status"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select Payment Status" },
                                        ]} 
                                    >
                                    <Select placeholder='Payment Status'  style={{ width: "100%" }} >
                                        <Option value="1">Paid</Option>
                                        <Option value="0">Unpaid</Option>
                                   </Select>

                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>

                <Row style={{ display: onlineAdmissionReportPaidUnpaidList.length > 0 ? '' : 'none' }}>
                    
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}}>
                        
                        <div className="datatable-responsive-demo" style={{ width: "100%", overflow: "auto" }}>

                            <Table
                                antTableProps={{
                                    showHeader: true,
                                    rowSelection: rowSelection,
                                    columns:studentListColumn,
                                    dataSource: onlineAdmissionReportPaidUnpaidList,
                                    filterData: onlineAdmissionReportPaidUnpaidList,
                                    pagination: false,
                                    bordered: true,
                                    rowKey: "applicantId",
                                }}
                                mobileBreakPoint={768}

                            />
                        </div>

                    </Col>

                    

                    <Col span={24}>

                        <br />
                        
                        <Space size="small" style={{ float: "right" }}>
                        
                        <ExcelFile element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Online Applicant List`}>
                                
                                <ExcelSheet data={onlineAdmissionReportPaidUnpaidList} name="Class Wise Report">
                                    <ExcelColumn label="Registration ID" value="registrationId" />
                                    <ExcelColumn label="Class Name" value="className" />
                                    <ExcelColumn label="Applicant Name" value="applicantName" />
                                    <ExcelColumn label="Gender" value="gender" />                                    
                                    <ExcelColumn label="Version " value="medium" />
                                    <ExcelColumn label="Date of Birth" value="dateOfBirthString" />
                                    <ExcelColumn label="Age" value="age" />
                                    <ExcelColumn label="Father Name" value="fatherName" />
                                    <ExcelColumn label="Mother Name" value="motherName" />
                                    <ExcelColumn label="Father Designation" value="fatherDesignation" />
                                    <ExcelColumn label="Mother Designation" value="motherDesignation" />
                                    <ExcelColumn label="Guardian Mobile" value="localGuardianMobile" />
                                    <ExcelColumn label="Father Work Place" value="fatherWorkPlace" />
                                    <ExcelColumn label="Mother Work Place" value="motherWorkPlace" />
                                    <ExcelColumn label="Present Address" value="presentAddress" />
                                    <ExcelColumn label="presentDistrict" value="presentDistrict" />
                                    <ExcelColumn label="Admission Charge" value="admissionCharge" />
                                    <ExcelColumn label="Payment Status" value="paymentStatus" />
                                    <ExcelColumn label="Payment Date" value="paymentDate" />
                                    <ExcelColumn label="Quota" value="quota" />
                                </ExcelSheet>

                            </ExcelFile>

                            <Button type="primary" onClick={() => showModal()}  >
                             Process
                            </Button>
                            
                        </Space>
                    </Col>
                    
                </Row>

            </Card>


            <Modal
                title="Register Student"
                visible={modalVisible}
                okButtonProps={{ form: 'register', htmlType: 'submit' }}
                onCancel={() => {setModalVisible(false)}}
                cancelText="Close"
                okText="Register"
                maskClosable={false}
                centered
                destroyOnClose
            >
                <Form
                    layout="vertical"
                    id="register"
                    onFinish={registerStudents}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="classConfigurationId"
                                label="Section"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Select section" },
                                ]}
                            >
                                <SelectClassConfigurationListByGroupConfig style={{ width: "100%" }} />
                            </Form.Item>
                            
                            <Form.Item
                                name="studentCategoryId"
                                label="Student Category"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Select section" },
                                ]}
                            >
                                <SelectCategory style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
    
    </>
  )
}
