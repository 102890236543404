
import { notification } from 'antd';
import {Action, Thunk, action, thunk } from 'easy-peasy';
import { createBookCoWriter, createBookWriter, createbookcategory, fetchBookCategoryList, fetchBookCoWriter, fetchBookWriter } from '../../../http/student/student';

export interface Library {

    createBookCategory: Thunk<Library, any>;
    bookcategoryList: any;
    fetchBookCategoryList: Thunk<Library>;
    setBookcategoryList: Action<Library, any>;


    createBookWriter: Thunk<Library, any>;
    bookWriterList: any;
    fetchBookWriterList: Thunk<Library>;
    setBookWriterList: Action<Library, any>;


    createCoBookWriter: Thunk<Library, any>;
    bookCoWriterList: any;
    fetchBookCoWriterList: Thunk<Library>;
    setBookCoWriterList: Action<Library, any>;


    loading: boolean;
    setLoading: Action<Library, boolean>;


}

export const libraryStore: Library = {

    bookcategoryList: [],
    bookWriterList: [],
    bookCoWriterList: [],

    loading: false,
    setLoading: action((state, payload) => {
        state.loading = payload;
    }), 


      /////////////Book Category Create//////////////////

      createBookCategory: thunk(async (actions, payload) => {
        const response = await createbookcategory(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchBookCategoryList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),


    fetchBookCategoryList: thunk(async (actions) => {
        actions.setLoading(true);
        const response = await fetchBookCategoryList();
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setBookcategoryList(body.item);
        } else {
            const body = await response.json();
            actions.setLoading(false);
        }
    }),
    setBookcategoryList: action((state, payload) => {
        state.bookcategoryList = payload;
    }),


    /////////////Book Writer Create//////////////////

    createBookWriter: thunk(async (actions, payload) => {
            const response = await createBookWriter(payload);
            if (response.status === 201 || response.status === 200) {
                const body = await response.json();
                if (body.messageType == 1) {
                    notification.success({ message: body.message })
                    actions.fetchBookWriterList();
                } else {
                    notification.error({ message: body.message })
                }
            } else {
                const body = await response.json();
                notification.error({ message: body.message })
            }
        }),


        fetchBookWriterList: thunk(async (actions) => {
            actions.setLoading(true);
            const response = await fetchBookWriter();
            if (response.status === 201 || response.status === 200) {
                actions.setLoading(false);
                const body = await response.json();
                actions.setBookWriterList(body.item);
            } else {
                const body = await response.json();
                console.log(body);
                actions.setLoading(false);
            }
        }),
        setBookWriterList: action((state, payload) => {
            state.bookWriterList = payload;
        }),



        /////////////Book Co-Writer Create//////////////////

    createCoBookWriter: thunk(async (actions, payload) => {
        const response = await createBookCoWriter(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchBookCoWriterList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),


    fetchBookCoWriterList: thunk(async (actions) => {
        actions.setLoading(true);
        const response = await fetchBookCoWriter();
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setBookCoWriterList(body.item);
        } else {
            const body = await response.json();
            console.log(body);
            actions.setLoading(false);
        }
    }),
    setBookCoWriterList: action((state, payload) => {
        state.bookCoWriterList = payload;
    }),

}
