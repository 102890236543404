import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Tooltip,
  message,
  Modal,
  Checkbox,
  Skeleton,
} from "antd";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Table, Tag } from "antd";
import { useStoreActions, useStoreState } from "../../../store/hooks/easyPeasy";
import TableView from "../../../contents/AntTableResponsive";

export default function BookCategory() {

  const createBookCategory = useStoreActions(
    (state) => state.library.createBookCategory
  );

  const fetchBookCategoryList = useStoreActions(
    (state) => state.library.fetchBookCategoryList
  );
  const bookCategoryList = useStoreState(
    (state) => state.library.bookcategoryList
  );

  const [bookCategoryForm] = Form.useForm();

  const loading = useStoreState((state) => state.student.loading);

  const bookCategoryCreateForm = (value) => {
    let postData = {
      bookCategoryName: value.bookCategoryName,
      serial: value.serial,
      status: 1,
      note: value.note,
    };
    console.log("postData", postData);

    createBookCategory(postData);
    bookCategoryForm.resetFields();
  };


  useEffect(() => {
    fetchBookCategoryList();
    
},[])


const columns = [
    {
        title: 'Serial',
        dataIndex: 'serial',
        key: 'serial',
        showOnResponse: true,
        showOnDesktop: true
    },
    {
        title: 'Name',
        dataIndex: 'bookCategoryName',
        key: 'bookCategoryName',
        showOnResponse: true,
        showOnDesktop: true
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        showOnResponse: true,
        showOnDesktop: true,
        render: (text: any, record: any, index) => (
            <>
               <span>{ record.status == 1 ? 'Enable' : 'Disable'}</span>
            </>
        )
    },
    // {
    //     title: 'Action',
    //     key: 'ledgerId',
    //     showOnResponse: true,
    //     showOnDesktop: true,
    //     render: (text: any, record: any, index) => (
    //         <Space size="middle">
    //             <Tooltip title="Edit">
    //                 <Button type='primary' icon={<EditOutlined />} onClick={() => {
    //                     setIsModalVisible(true);
    //                     setselectedStatus(record.status);
    //                     setupdateClassId(record.id);
    //                     updateForm.setFieldsValue({
    //                         serialUpdate: record.serial,
    //                         nameUpdate: record.name,
    //                         status: record.status,
    //                     });
    //                 }} />
    //             </Tooltip>
    //             <Popconfirm
    //                 title="Are you sure to delete this?"
    //                 okText="Yes"
    //                 cancelText="No"
    //                 onConfirm={() => deleteClass(record?.id)}
    //             >
    //                 <Tooltip title="Delete">
    //                     <Button danger  icon={<DeleteOutlined />} />
    //                 </Tooltip>
    //             </Popconfirm>

    //         </Space>
    //     ),
    // }
  ];

  return (
    <>
    <Card title="Student Registration From Online Admission">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 3 }} xl={{ span: 20, offset: 3 }}>
            <Form
              layout="vertical"
              id="classInfo"
              onFinish={bookCategoryCreateForm}
              form={bookCategoryForm}
            >
              <Row>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="bookCategoryName"
                    label="Category Name"
                    className="title-Text"
                    rules={[
                      {
                        required: true,
                        message: "Please write book category name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter book category name" />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="serial"
                    label="Serial Number"
                    className="title-Text"
                    rules={[
                      { required: true, message: "Please write serial number" },
                    ]}
                  >
                    <InputNumber placeholder="Enter serial number" />
                  </Form.Item>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item name="note" label="Note" className="title-Text">
                    <InputNumber placeholder="Enter Note" />
                  </Form.Item>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Space size="small">
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<SaveOutlined />}
                    >
                      Save
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Skeleton loading={loading} paragraph={{ rows: 10 }} />

        <Row className="m-t-mo-30">
                <Col span="24">
                    <div className="datatable-responsive-demo">
                        {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: bookCategoryList,
                                filterData: bookCategoryList,
                                pagination: true,
                                bordered: true,
                                rowKey:"id",
                                
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                </Col>
            </Row>

      </Card>
    </>
  );
}
