import { post, get, del, put, delBulk} from "../http";

export const staffSmsCount = () => get("/sms/report/balance/info");
export const staffListForSendSms = () => get("/staff/report/basic/info/list");
export const sendStaffSms = (payload) => post("/sms/send/staff", payload);
export const smsTemplate = () => get("/sms/template/list");
export const searchClassStudentIthAcademicYear = (payload) => get("/student/report/basic/info/list/by/class-id?classId="+payload.classId+"&academicYear="+payload.academicYear);
export const fetchstudentRegistrationListByClassConfigurations = (payload) => get("/student/report/basic/info/list/by/multiple/class-configuration-id?academicYear="+payload.academicYear+"&classConfigurationIds="+payload.classConfigurationIds);
export const sendStudentSms = (payload) => post("/sms/send/student", payload);
export const sendAdmissionSms = (payload) => post("/sms/send/student-admission", payload);
export const sendInstiuteSms = (payload) => post("/sms/send/all/student", payload);
export const sectionDueListUrl = (payload) => get("/student-fee/report/section/due-details?classConfigId="+payload.classConfigId+"&academicYear="+payload.academicYear+"&dueType="+payload.dueType);
export const sendDueListSms = (payload) => post("/sms/send/student/many-to-many", payload);
export const sendManyToManySms = (payload) => post("/sms/send/many-to-many", payload);
export const templateSaveUrl = (payload) => post("/sms/template/save", payload);
export const templateUpdateUrl = (payload) => post("/sms/template/update", payload);
export const smsBlanceurl = (payload) => get("/sms/balance/view"); 
export const smsSearchReportUrl = (payload) => get("/sms/report/"+payload.msgType+"/view?fromDate="+payload.fromDate+"&toDate="+payload.toDate);
export const smsSearchFeePaidSmsReportUrl = (payload) => get("/student-fee/report/all/paid/list?ledgerIds="+payload.leadgerId+"&fromDate="+payload.fromDate+"&toDate="+payload.toDate);
export const sendFeePaidBillSmsUrl = (payload) => post("/sms/send/student/many-to-many", payload);
export const deleteSmsTemplate = (payload) => del("/sms/template/delete?templateId="+payload);
export const smsPurchaseLogUrl = (payload) => get("/sms/purchase/report/date-to-date/trn-log?fromDate="+payload.fromDate+"&toDate="+payload.toDate); 

export const saveGlobalSmsTemplate = (payload) => post("/global/sms/template/save", payload);
export const fetchGlobalSmsTemplateList = () => get("/global/sms/template/list");
export const updateGlobalSmsTemplate = (payload) => post("/global/sms/template/update", payload);
export const deleteGlobalSmsTemplate = (payload) => del("/global/sms/template/delete?templateId="+payload);