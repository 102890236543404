
import { notification } from 'antd';
import {Action, Thunk, action, thunk } from 'easy-peasy';
import { createInventoryCategory, createSaler, deleteInventoryCategory, deleteInventoryItem, deleteInventoryItemDestroyRecord, deleteInventoryItemInRecord, deleteInventoryItemOutRecord, deletePurchaseInventory, deleteSaler, deleteSalesInventory, downloadPurchaseInventory, downloadSalesInventory, fetchInventoryCategory, fetchInventoryItemList, fetchInventoryItemListByCategory, fetchInventoryLedgerConfiguration, fetchPurchaseInventoryItemViewList, fetchSalesInventoryItemViewList, fetchsalerList, fetchviewInventorySales, inventoryItemDestroyRecordList, inventoryItemInRecordList, inventoryItemOutRecordList, purchaseItemInventory, recordDestroyInventoryItem, recordInInventoryItem, recordOutInventoryItem, saveInventoryItem, saveInventoryLedgerConfiguration, savesalestemInventory, updateInventoryCategory, updateInventoryItem, updateSaler } from '../../../http/inventory/inventory';
import FileSaver from 'file-saver';

export interface Inventory {

    createInventoryCategory: Thunk<Inventory, any>;
    inventoryCategoryList: any;
    fetchInventoryCategoryList: Thunk<Inventory>;
    setInventorycategoryList: Action<Inventory, any>;

    updateInventoryCategory: Thunk<Inventory, any>;
    deleteInventoryCategory: Thunk<Inventory, any>;

    saveInventoryItem: Thunk<Inventory, any>;
    updateInventoryItem: Thunk<Inventory, any>;
    deleteInventoryItem: Thunk<Inventory, any>;

    inventoryItemList: any;
    fetchInventoryItemList: Thunk<Inventory>;
    setInventoryItemList: Action<Inventory, any>;

    inventoryItemListByCategory: any;
    fetchInventoryItemListByCategory: Thunk<Inventory, any>;
    setInventoryItemListByCategory: Action<Inventory, any>;

    saveInventoryLedgerConfiguraion: Thunk<Inventory, any>;
    
    inventoryLedgerConfigurationView: any;
    fetchInventoryLedgerConfigurationView: Thunk<Inventory>;
    setInventoryLedgerConfigurationView: Action<Inventory, any>;
    
    purchaseItemInventory: Thunk<Inventory, any>;
    inventoryItemPurchaseViewList: any;
    fetchInventoryItemPurchaseViewList: Thunk<Inventory, any>;
    setinventoryItemPurchaseViewList: Action<Inventory, any>;

    downloadInventoryPurchaseInvoice: Thunk<Inventory, any>
    deleteInventoryPurchaseInvoice: Thunk<Inventory, any>


    inventoryItemRecordIn: Thunk<Inventory, any>
    deleteInventoryItemRecordIn: Thunk<Inventory, any>;

    inventoryItemRecordInList: any;
    fetchInventoryItemRecordInList: Thunk<Inventory, any>;
    setInventoryItemRecordInList: Action<Inventory, any>;


    inventoryItemRecordOut: Thunk<Inventory, any>
    deleteInventoryItemRecordOut: Thunk<Inventory, any>;
    
    inventoryItemRecordOutList: any;
    fetchInventoryItemRecordOutList: Thunk<Inventory, any>;
    setInventoryItemRecordOutList: Action<Inventory, any>;



    inventoryItemRecordDestroy: Thunk<Inventory, any>
    deleteInventoryItemRecordDestroy: Thunk<Inventory, any>;
    
    inventoryItemRecordDestroyList: any;
    fetchInventoryItemRecordDestroyList: Thunk<Inventory, any>;
    setInventoryItemRecordDestroyList: Action<Inventory, any>;

    createSaler: Thunk<Inventory, any>;
    salerList: any;
    fetchsalerList: Thunk<Inventory>;
    setsalerList: Action<Inventory, any>;

    updateSaler: Thunk<Inventory, any>;
    deleteSaler: Thunk<Inventory, any>;


    savesalestemInventory: Thunk<Inventory, any>;
    salesInventoryItemViewList : any;
    fetchSalesInventoryItemViewList: Thunk<Inventory, any>;
    setsavesalestemInventory: Action<Inventory, any>;
	
	downloadSalesInventory: Thunk<Inventory, any>
    deleteSalesInventory: Thunk<Inventory, any>

    viewInventorySales: any;
    setviewInventorySales : Action<Inventory, any>;
    fetchviewInventorySales: Thunk<Inventory, any>;
    

    loading: boolean;
    setLoading: Action<Inventory, boolean>;


}

export const inventoryStore: Inventory = {

    inventoryCategoryList: [],


    loading: false,
    setLoading: action((state, payload) => {
        state.loading = payload;
    }), 


      /////////////Book Category Create//////////////////

      createInventoryCategory: thunk(async (actions, payload) => {
        const response = await createInventoryCategory(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchInventoryCategoryList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    updateInventoryCategory: thunk(async (actions, payload) => {
        const response = await updateInventoryCategory(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchInventoryCategoryList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    deleteInventoryCategory: thunk(async (actions, payload) => {
        const response = await deleteInventoryCategory(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchInventoryCategoryList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),


    fetchInventoryCategoryList: thunk(async (actions) => {
        actions.setLoading(true);
        const response = await fetchInventoryCategory();
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setInventorycategoryList(body.item);
        } else {
            const body = await response.json();
            actions.setLoading(false);
        }
    }),
    setInventorycategoryList: action((state, payload) => {
        state.inventoryCategoryList = payload;
    }),


    

    saveInventoryItem: thunk(async (actions, payload) => {
        const response = await saveInventoryItem(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchInventoryItemList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    updateInventoryItem: thunk(async (actions, payload) => {
        const response = await updateInventoryItem(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchInventoryItemList()
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    deleteInventoryItem: thunk(async (actions, payload) => {
        const response = await deleteInventoryItem(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchInventoryItemList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),


    inventoryItemList:[],

    fetchInventoryItemList: thunk(async (actions) => {
        actions.setLoading(true);
        const response = await fetchInventoryItemList();
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setInventoryItemList(body.item);
        } else {
            const body = await response.json();
            actions.setLoading(false);
        }
    }),
    setInventoryItemList: action((state, payload) => {
        state.inventoryItemList = payload;
    }),

    inventoryItemListByCategory:[],

    fetchInventoryItemListByCategory: thunk(async (actions,payload) => {
        const response = await fetchInventoryItemListByCategory(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setInventoryItemListByCategory(body.item);
        } else {
            const body = await response.json();;
        }
    }),
    setInventoryItemListByCategory: action((state, payload) => {
        state.inventoryItemListByCategory = payload;
    }),


    saveInventoryLedgerConfiguraion: thunk(async (actions, payload) => {
        const response = await saveInventoryLedgerConfiguration(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchInventoryLedgerConfigurationView();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),    
    
    purchaseItemInventory: thunk(async (actions, payload) => {
        const response = await purchaseItemInventory(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchInventoryLedgerConfigurationView();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    inventoryLedgerConfigurationView:[],

    fetchInventoryLedgerConfigurationView: thunk(async (actions) => {
        actions.setLoading(true);
        const response = await fetchInventoryLedgerConfiguration();
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setInventoryLedgerConfigurationView(body.item);
        } else {
            const body = await response.json();
            actions.setLoading(false);
        }
    }),

    setInventoryLedgerConfigurationView: action((state, payload) => {
        state.inventoryLedgerConfigurationView = payload;
    }),


    inventoryItemPurchaseViewList:[],

    fetchInventoryItemPurchaseViewList: thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await fetchPurchaseInventoryItemViewList(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setinventoryItemPurchaseViewList(body.item);
        } else {
            const body = await response.json();
            actions.setLoading(false);
        }
    }),

    setinventoryItemPurchaseViewList: action((state, payload) => {
        state.inventoryItemPurchaseViewList = payload;
    }),


    downloadInventoryPurchaseInvoice: thunk(async (actions, payload) => {
		const downloadFile = await downloadPurchaseInventory(payload);
		if (downloadFile.status === 201 || downloadFile.status === 200) {
			const bodyx = await downloadFile.blob();
			var blobx = new Blob([bodyx], { type: "application/octet-stream" });
			var fileName = "InventoryPurchaseInvoice.pdf";
			FileSaver.saveAs(blobx, fileName);
			return;
		}
		else {
			notification.error({ message: "Something went wrong" })
		}
	}),

    deleteInventoryPurchaseInvoice: thunk(async (actions, payload) => {
		actions.setLoading(true);
        const response = await deletePurchaseInventory(payload.purchaseInvoiceId);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.fetchInventoryItemPurchaseViewList(payload);
        } else {
            const body = await response.json();
            actions.setLoading(false);
        }
	}),

    
     inventoryItemRecordIn: thunk(async (actions, payload) => {
		
        actions.setLoading(true);
        const response = await recordInInventoryItem(payload);
       
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message });
                actions.fetchInventoryItemRecordInList(payload);
            } else {
                notification.error({ message: body.message })
            }

        } else {
            const body = await response.json();
            actions.setLoading(false);
            notification.error({ message: body.message })
        }
	}),


    inventoryItemRecordInList:[],

    fetchInventoryItemRecordInList: thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await inventoryItemInRecordList(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setInventoryItemRecordInList(body.item);
        } else {
            const body = await response.json();
            actions.setLoading(false);
        }
    }),

    setInventoryItemRecordInList: action((state, payload) => {
        state.inventoryItemRecordInList = payload;
    }),

    deleteInventoryItemRecordIn: thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await deleteInventoryItemInRecord(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            notification.success({ message: body.message });
            actions.fetchInventoryItemRecordInList(payload);
        } else {
            const body = await response.json();
            actions.setLoading(false);
        }
    }),



    inventoryItemRecordOut: thunk(async (actions, payload) => {
		
        actions.setLoading(true);
        const response = await recordOutInventoryItem(payload);
       
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message });
                actions.fetchInventoryItemRecordOutList(payload);
            } else {
                notification.error({ message: body.message })
            }

        } else {
            const body = await response.json();
            actions.setLoading(false);
            notification.error({ message: body.message })
        }
	}),


    inventoryItemRecordOutList:[],

    fetchInventoryItemRecordOutList: thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await inventoryItemOutRecordList(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setInventoryItemRecordOutList(body.item);
        } else {
            const body = await response.json();
            actions.setLoading(false);
        }
    }),

    setInventoryItemRecordOutList: action((state, payload) => {
        state.inventoryItemRecordOutList = payload;
    }),

    deleteInventoryItemRecordOut: thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await deleteInventoryItemOutRecord(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            notification.success({ message: body.message });
            actions.fetchInventoryItemRecordOutList(payload);
        } else {
            const body = await response.json();
            actions.setLoading(false);
        }
    }),



    inventoryItemRecordDestroy: thunk(async (actions, payload) => {
		
        actions.setLoading(true);
        const response = await recordDestroyInventoryItem(payload);
       
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message });
                actions.fetchInventoryItemRecordDestroyList(payload);
            } else {
                notification.error({ message: body.message })
            }

        } else {
            const body = await response.json();
            actions.setLoading(false);
            notification.error({ message: body.message })
        }
	}),


    inventoryItemRecordDestroyList:[],

    fetchInventoryItemRecordDestroyList: thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await inventoryItemDestroyRecordList(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setInventoryItemRecordDestroyList(body.item);
        } else {
            const body = await response.json();
            actions.setLoading(false);
        }
    }),

    setInventoryItemRecordDestroyList: action((state, payload) => {
        state.inventoryItemRecordDestroyList = payload;
    }),

    deleteInventoryItemRecordDestroy: thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await deleteInventoryItemDestroyRecord(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            notification.success({ message: body.message });
            actions.fetchInventoryItemRecordDestroyList(payload);
        } else {
            const body = await response.json();
            actions.setLoading(false);
        }
    }),

    salerList:[],

    fetchsalerList: thunk(async (actions) => {
        actions.setLoading(true);
        const response = await fetchsalerList();
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setsalerList(body.item);
        } else {
            const body = await response.json();
            actions.setLoading(false);
        }
    }),
    setsalerList: action((state, payload) => {
        state.salerList = payload;
    }),
    
    updateSaler: thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await updateSaler(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            notification.success({ message: body.message });
            actions.fetchsalerList();
        } else {
            const body = await response.json();
            actions.setLoading(false);
        }
    }),    
    
    createSaler: thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await createSaler(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            notification.success({ message: body.message });
            actions.fetchsalerList();
        } else {
            const body = await response.json();
            actions.setLoading(false);
        }
    }),    
    deleteSaler: thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await deleteSaler(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            notification.success({ message: body.message });
            actions.fetchsalerList();
        } else {
            const body = await response.json();
            actions.setLoading(false);
        }
    }),

    savesalestemInventory: thunk(async (actions, payload) => {
        const response = await savesalestemInventory(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchviewInventorySales(body?.item);
                setTimeout(() => {
                    // actions.setviewInventorySales(null);
                }, 1000);
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

	salesInventoryItemViewList :[],

    fetchSalesInventoryItemViewList: thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await fetchSalesInventoryItemViewList(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setsavesalestemInventory(body.item);
        } else {
            const body = await response.json();
            actions.setLoading(false);
        }
    }),

    setsavesalestemInventory: action((state, payload) => {
        state.salesInventoryItemViewList  = payload;
    }),


    downloadSalesInventory: thunk(async (actions, payload) => {
		const downloadFile = await downloadSalesInventory(payload);
		if (downloadFile.status === 201 || downloadFile.status === 200) {
			const bodyx = await downloadFile.blob();
			var blobx = new Blob([bodyx], { type: "application/octet-stream" });
			var fileName = "InventoryPurchaseInvoice.pdf";
			FileSaver.saveAs(blobx, fileName);
			return;
		}
		else {
			notification.error({ message: "Something went wrong" })
		}
	}),

    deleteSalesInventory: thunk(async (actions, payload) => {
		actions.setLoading(true);
        const response = await deleteSalesInventory(payload.salesInvoiceId);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.fetchSalesInventoryItemViewList(payload);
        } else {
            const body = await response.json();
            actions.setLoading(false);
        }
	}),

    viewInventorySales :null,

    fetchviewInventorySales: thunk(async (actions, payload) => {
        const response = await fetchviewInventorySales(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setviewInventorySales(body.item);
        } else {
            actions.setviewInventorySales(null);
        }
    }),

    setviewInventorySales: action((state, payload) => {
        state.viewInventorySales  = payload;
    }),
    
}
