import React, { useEffect } from 'react'
import { useState } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Upload, Skeleton } from 'antd'
import TableView from '../../../../contents/AntTableResponsive';
import { SaveOutlined, SearchOutlined, SendOutlined, SettingOutlined, UploadOutlined } from '@ant-design/icons';
import xlsxParser from 'xlsx-parse-json';
import XLSX from "xlsx";
import $ from 'jquery';
import Table from "../../../../contents/AntTableResponsive";
import { SelectClass } from '../../../select/SelectClass';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
const { Option } = Select;

export default function SectionDueSms() {

    const [form] = Form.useForm();
    const [shortCodeSelections, setShortCodeSelections] = useState([]);
    const [selectedSmsValue, setselectedSmsValue] = useState<any>([]);
    const fetchSectionDueSmsReportList = useStoreActions((state) => state.sms.fetchSectionDueSmsReportList);
    const sectionDueSmsReportList = useStoreState((state) => state.sms.sectionDueSmsReportList);
    const sendSectionDueSmsReportList = useStoreActions((state) => state.sms.sendSectionDueSmsReportList);
    const instituteInfo = useStoreState(state => state.auth.instituteInfo)
    /**********/

    useEffect(() => {
    }, []);

    const columns = [
        {title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true},
        {title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true},
        {title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true},
        {title: 'Due Details', dataIndex: 'details', key: 'details', showOnResponse: true, showOnDesktop: true},
        {title: 'Total Due', dataIndex: 'totalDue', key: 'totalDue', showOnResponse: true, showOnDesktop: true},
    ];
    const loading = useStoreState((state) =>  state.sms.loading);

    const onSelectChangeSectionDueDetails = (selectedRowKeys, value) => {
        setShortCodeSelections(selectedRowKeys);
        setselectedSmsValue(value);
    };


    const staffRowSelection = {
        selectedRowKeys: shortCodeSelections,
        onChange: onSelectChangeSectionDueDetails,
    };

    const searchDueList = (value) =>{
        let postdata:any ={
            classConfigId: value.selectSesection,
            academicYear:value.academicYear,
            dueType:value.dueType
        }
        fetchSectionDueSmsReportList(postdata)
        //console.log('sectionDueSmsReportList', sectionDueSmsReportList);
    }

    const sendSmsToDueList = (e) => {
        let payLoad = selectedSmsValue.map((item) => {
            return {
                identificationId: item.identificationId,
                message: `${item.studentName}, ID: ${item.customStudentId}, Institute ID: ${instituteInfo?.instiltuteId} has payable fees of Tk ${item.totalDue}. Login studentsheba.com to pay. From ${instituteInfo?.instituteName}.`,
            }
        })
         sendSectionDueSmsReportList(payLoad);
         setselectedSmsValue([]);
         setShortCodeSelections([]);
    }


    return(
        <>
            <Card title="Section Due SMS">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 16 , offset:4 }} lg={{ span: 16, offset: 4 }} xl={{ span: 16, offset: 4 }}>
                        <Form
                            layout="vertical"
                            onFinish={searchDueList}
                            id="basic-info"
                            form={form}
                        >
                            <Row>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 7, offset: 0 }} lg={{ span: 7, offset: 0 }} xl={{ span: 7, offset: 0 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Select Academic year "
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select academic year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>    
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 7, offset: 0 }} lg={{ span: 7, offset: 0 }} xl={{ span: 7, offset: 0 }}>
                                    <Form.Item
                                        name="selectSesection"
                                        label="Select Section "
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig />
                                    </Form.Item>    
                                </Col>

                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} xl={{ span: 6, offset: 0 }}>
                                    <Form.Item
                                        name="dueType"
                                        label="Due Type "
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select Due Type" },
                                        ]}
                                    >
                                        <Select placeholder='Due Type' style={{ width: "100%" }} >
                                            <Option value="current">Current Due</Option>
                                            <Option value="whole">Whole Due</Option>
                                        </Select>
                                    </Form.Item>    
                                </Col>

                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 2, offset: 0 }} lg={{ span: 2, offset: 0 }} xl={{ span: 4, offset: 0 }}>
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >Search</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{rows:10}} />
                {sectionDueSmsReportList.length > 0 ? 
                <Row>
                    <br />
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>
                        <div className="datatable-responsive-demo" style={{ width: "100%", overflow: "auto" }}>
                            <Table
                                antTableProps={{
                                    showHeader: true,
                                    rowSelection: staffRowSelection,
                                    columns: columns,
                                    dataSource: sectionDueSmsReportList,
                                    filterData: sectionDueSmsReportList,
                                    pagination: false,
                                    bordered: true,
                                    rowKey: "studentId",
                                }}
                                mobileBreakPoint={768}

                            />
                        </div>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>
                        <Button type="primary" style={{marginTop:10}} className="pull-right" onClick={(e) => sendSmsToDueList(e)} icon={<SendOutlined />} >Send SMS</Button>
                    </Col>
                </Row>
                : ""}
            </Card>
        </>
    )
}