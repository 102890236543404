import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag } from 'antd';
import { SelectSanmasikClass } from '../../../select/SelectSanmasikClass';
import { SelectSanmasikSubject } from '../../../select/SelectSanmasikSubject';


export default function SanMasikCompetency(props) {

    const createsanmasikCompetency = useStoreActions((state) => state.customerSupport.createsanmasikCompetency);
    const fetchsanmasikCompetencyList = useStoreActions((state) => state.customerSupport.fetchsanmasikCompetencyList);
    const fetchsanmasikSubjectList = useStoreActions((state) => state.customerSupport.fetchsanmasikSubjectList);
    const sanmasikCompetencyList = useStoreState((state) => state.customerSupport.sanmasikCompetencyList);
    const updatesanmasikCompetency = useStoreActions((state) => state.customerSupport.updatesanmasikCompetency);
    const deletesanmasikCompetency = useStoreActions((state) => state.customerSupport.deletesanmasikCompetency);
    const [sanmasikForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const loading = useStoreState((state) => state.customerSupport.loading);

    const sanmasikClassForm = (value) => {
        let postData = {
            subjectId: value.subjectId,
            competencyName: value.competencyName,
            competencyCode: value.competencyCode,
        }
        createsanmasikCompetency(postData);
        setTimeout(() => {
            fetchsanmasikCompetencyList(subjectId)
          //  sanmasikForm.resetFields();
        }, 1500);

    }


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [updateId, setupdateId] = useState<any>();

    const updateExamForm = (value) => {
        let updatePostData = {
            competencyId: updateId,
            competencyCode: value.competencyCode,
            competencyName: value.competencyName,
        }
        updatesanmasikCompetency(updatePostData);
        setIsModalVisible(false);
        setTimeout(() => {
            fetchsanmasikCompetencyList(subjectId)
            updateForm.resetFields();
        }, 1500);
    }

    const columns = [
        {
            title: 'Competency Name',
            dataIndex: 'competencyName',
            key: 'competencyName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Competency Code',
            dataIndex: 'competencyCode',
            key: 'competencyCode',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Action',
            key: 'ledgerId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setupdateId(record.competencyId);
                            updateForm.setFieldsValue({
                                competencyCode: record.competencyCode,
                                competencyName: record.competencyName,
                            });
                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() =>  {
                            deletesanmasikCompetency(record?.competencyId);
                            setTimeout(() => {
                                fetchsanmasikCompetencyList(subjectId)
                            }, 1500);
                        }
                        }
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        }
    ];
    const [classid, setclassId] = useState<any>(null);
    const [subjectId, setsubjectId] = useState<any>(null);
    const onChangeFetchSubject = (val) => {
        setclassId(val);
        fetchsanmasikSubjectList(val);
    }   
     const onChangeFetchCom = (val) => {
        setsubjectId(val);
        fetchsanmasikCompetencyList(val);
    }
    return (
        <Card title="Sanmasik Competency">

            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24, offset: 1 }} xl={{ span: 24, offset: 1 }}>
                    <Form
                        layout="vertical"
                        id="sanmasikClass"
                        onFinish={sanmasikClassForm}
                        form={sanmasikForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="classId"
                                    label="Class Name"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select class name" },
                                    ]}
                                >
                                    <SelectSanmasikClass onChange={e => onChangeFetchSubject(e)} />
                                </Form.Item>
                            </Col>                            
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="subjectId"
                                    label="Subject"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select competency name" },
                                    ]}
                                >
                                    <SelectSanmasikSubject onChange={e => onChangeFetchCom(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5}}>
                                <Form.Item
                                    name="competencyName"
                                    label="Competency Name"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write competency name" },
                                    ]}
                                >
                                    <Input placeholder="Enter competency name" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="competencyCode"
                                    label="Competency Code"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write competency code" },
                                    ]}
                                >
                                    <Input placeholder="Enter competency code" />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                        Save
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            {sanmasikCompetencyList?.length > 0 &&
                <Row className="m-t-mo-30">
                    <Col span="24">
                        <div className="datatable-responsive-demo">
                            {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}
                            <Table bordered={true} dataSource={sanmasikCompetencyList} columns={columns} className="p-datatable-responsive-demo" />
                        </div>
                    </Col>
                </Row>
            }
            <Modal
                title="Edit"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
                destroyOnClose
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={updateExamForm}
                    form={updateForm}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="competencyName"
                                label="Competency Name"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write competency name" },
                                ]}
                            >
                                <Input placeholder="Enter competency name" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="competencyCode"
                                label="Competency Code"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write competency code" },
                                ]}
                            >
                                <Input placeholder="Enter competency code" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Card>
    )
}