import { DeleteOutlined, EditOutlined, EyeOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Descriptions, Form, Input, Modal, notification, Popconfirm, Row, Select, Skeleton, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import moment from 'moment';
const { Option } = Select;

export default function BkashRefundView() {
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();

    const ofpsTransactionProblemView = useStoreState((state) => state.customerSupport.ofpsBkashTransactionProblemList);
    const isofps = useStoreState((state) => state.customerSupport.isofps);
    const fetchofpsTransactionProblemView = useStoreActions((state) => state.customerSupport.fetchBkashOfpsTransactionProblemList);
    const refundBkashProblemTransaction = useStoreActions((state) => state.customerSupport.refundBkashOfpsTransaction);

    const expenseLedgerList = useStoreState((state) => state.ledger.expenseLedgerList);
    const fetchExpenseLedgerList = useStoreActions((state) => state.ledger.fetchExpenseLedgerList);

    const loading = useStoreState((state) =>  state.ledger.loading);
    
    const [paymentId, setPaymentId] = useState<any>(null);
    const [requestLogId,setRequestLogId] = useState<any>(null);

    const refundTransaction = (value) => {
        
        // alert('paymentId= '+paymentId+', requestLogId= '+requestLogId+', expenseLedgerId= '+value.expenseLedgerId +', reason= '+value.reason+', sku= '+value.sku)

        let postData = {
            "paymentId": paymentId,
            "requestLogId": requestLogId,
            "expenseLedgerId": value.expenseLedgerId,
            "reason": value.reason,
            "sku": value.sku,
        };
        
        refundBkashProblemTransaction(postData);
        setIsModalVisible(false);
    }


    const [data, setData] = useState<any>({});
    
    const formSubmit = (value) => {

        value.trnDate = moment(value?.trnDate).format("YYYY-MM-DD")
        fetchofpsTransactionProblemView(value);
        setData(value);
        fetchExpenseLedgerList(value);

    }

    useEffect(() => {
        if (isofps === true) {
            setIsModalVisible(true)
        }
    }, [isofps])

    const columns = [

        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Trx ID',
            dataIndex: 'trxID',
            key: 'trxID',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Log Date',
            dataIndex: 'logDate',
            key: 'logDate',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Fees Payment',
            dataIndex: 'feesPayment',
            key: 'feesPayment',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Service Charge',
            dataIndex: 'serviceCharge',
            key: 'serviceCharge',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'payment ID',
            dataIndex: 'paymentID',
            key: 'paymentID',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Merchant Invoice Number',
            dataIndex: 'merchantInvoiceNumber',
            key: 'merchant Invoice Number',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Status',
            dataIndex: 'statusMessage',
            key: 'statusMessage',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Check',
            key: 'id',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Check Problem">
                        <Button type='primary' icon={<EyeOutlined />} onClick={() => {
                            setPaymentId(record.paymentID);
                            setRequestLogId(record.requestLogId);
                            setIsModalVisible(true);
                            updateForm.setFieldsValue({
                                reason: '',
                                sku: record.feeInvoiceId,
                            });



                        }}
                        />
                    </Tooltip>

                </Space>
            ),
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <>
            <Card title="OFPS View (Bkash)" >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="customStudentId"
                                label="Student Id"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input student id" },
                                ]}
                            >

                                <Input placeholder="Student Id" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="instituteId"
                                label="Institute Id"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input institute id" },
                                ]}
                            >

                                <Input placeholder="Institute Id" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="trnDate"
                                label="Tran. Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select tran date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    </Row>
                </Form>


                
                {ofpsTransactionProblemView?.length > 0 &&
                    <div className="datatable-responsive">
                        <Skeleton loading={loading} paragraph={{ rows: 2 }} />
                        <Table
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: ofpsTransactionProblemView,
                                filterData: ofpsTransactionProblemView,
                                pagination: false,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />
                       
                    </div>
                }
            </Card>


            <Modal
                title="Refund the Bkash Transaction"
                visible={isModalVisible}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Refund"
                centered
            >
                <Form
                    layout="vertical"

                    onFinish={refundTransaction}
                    id="update"
                    form={updateForm}
                >
                    <Form.Item
                        name="paymentId"
                        label="Payment Id"
                        className="title-Text"
                    >
                        <Input placeholder={paymentId} disabled/>
                    </Form.Item>
                    
                    <Form.Item
                        name="expenseLedgerId"
                        label="Refund Ledger"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please Select Refund Ledger" },
                        ]}
                    >
                        <Select className='subSelect' placeholder="Ledger list" style={{ width: "100%" }} >
                                    {expenseLedgerList ? (
                                        expenseLedgerList.map((type, idx) => (
                                            <Option key={type.ledgerId} value={type.ledgerId}>
                                                {type.ledgerName}
                                            </Option>
                                        ))
                                    ) : (
                                        <Option value="fetching">Fetching Ledger</Option>
                                    )}
                        </Select>
                    
                    </Form.Item>
                    
                    <Form.Item
                        name="reason"
                        label="Reason"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Write Reason" },
                        ]}
                    >
                        <Input placeholder="Write Reason" />

                    </Form.Item>
                    <Form.Item
                        name="sku"
                        label="SKU"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Write SKU" },
                        ]}
                    >
                        <Input placeholder="Write SKU" />

                    </Form.Item>
                </Form>

            </Modal>

           
        </>
    )
}
