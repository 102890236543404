import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, InputNumber, Popconfirm, Row, Space, Tooltip, Modal, Skeleton } from "antd";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { useStoreActions, useStoreState } from "../../../store/hooks/easyPeasy";
import TableView from "../../../contents/AntTableResponsive";

export default function InventorySaler() {

  const createSaler = useStoreActions((state) => state.inventory.createSaler);
  const fetchsalerList = useStoreActions((state) => state.inventory.fetchsalerList);
  const salerList = useStoreState((state) => state.inventory.salerList);
  const updateSaler = useStoreActions((state) => state.inventory.updateSaler);
  const deleteSaler = useStoreActions((state) => state.inventory.deleteSaler);
  const [salerForm] = Form.useForm();
  const [salerUpdateForm] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [salerId, setsalerId] = useState<any>();
  const loading = useStoreState((state) => state.inventory.loading);

  const inventoryCategoryCreateForm = (value) => {
    let postData = {
      salerName: value.salerName,
      address: value.address,
      contactNo: value.contactNo,
    };

    createSaler(postData);

  };

  const updateInventoryCategoryInfo = (value) => {
    let postData = {
      salerId: salerId,
      salerName: value.salerName,
      address: value.address,
      contactNo: value.contactNo,
    };
    updateSaler(postData);
    setIsModalVisible(loading);

  };

  useEffect(() => {
    fetchsalerList();

  }, [])


  const columns = [

    {
      title: 'Saler Name',
      dataIndex: 'salerName',
      key: 'salerName',
      showOnResponse: true,
      showOnDesktop: true
    },    
    {
      title: 'Contact No',
      dataIndex: 'contactNo',
      key: 'contactNo',
      showOnResponse: true,
      showOnDesktop: true
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      showOnResponse: true,
      showOnDesktop: true
    },
    {
      title: 'Action',
      key: 'salerId',
      showOnResponse: true,
      showOnDesktop: true,
      render: (text: any, record: any, index) => (
        <Space size="middle">

          <Tooltip title="Edit">
            <Button type='primary' icon={<EditOutlined />} onClick={() => {
              setIsModalVisible(true);
              setsalerId(record.salerId);
              salerUpdateForm.setFieldsValue({
                salerName: record.salerName,
                address: record.address,
                contactNo: record.contactNo,
              });
            }}
            />
          </Tooltip>
          <Popconfirm
            title="Are you sure to delete this?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteSaler(record?.salerId)}
          >
            <Tooltip title="Delete">
              <Button danger icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>

        </Space>
      ),
    },


  ];

  return (
    <>
      <Card title="Inventory Saler">
        <Row>
          <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 3 }} xl={{ span: 20, offset: 3 }}>
            <Form
              layout="vertical"
              id="classInfo"
              onFinish={inventoryCategoryCreateForm}
              form={salerForm}
            >
              <Row>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="salerName"
                    label="Saler Name"
                    className="title-Text"
                    rules={[
                      {
                        required: true,
                        message: "Please write inventory saler name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter inventory saler name" />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="contactNo"
                    label="Contact No"
                    className="title-Text"
                    rules={[
                      { required: true, message: "Please write contact no" },
                    ]}
                  >
                    <Input placeholder="Enter contact no" />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="address"
                    label="Address"
                    className="title-Text"
                    rules={[
                      { required: true, message: "Please write address" },
                    ]}
                  >
                    <InputNumber placeholder="Enter address " />
                  </Form.Item>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Space size="small">
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<SaveOutlined />}
                    >
                      Save
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Skeleton loading={loading} paragraph={{ rows: 10 }} />

        <Row className="m-t-mo-30">
          <Col span="24">
            <div className="datatable-responsive-demo">
              <TableView
                antTableProps={{
                  showHeader: true,
                  columns,
                  dataSource: salerList,
                  filterData: salerList,
                  pagination: true,
                  bordered: true,
                  rowKey: "id",

                }}
                mobileBreakPoint={768}
              />
            </div>
          </Col>
        </Row>

      </Card>

      <Modal
        title="Category Edit"
        visible={isModalVisible}
        okButtonProps={{ form: 'update', htmlType: 'submit' }}
        onCancel={() => setIsModalVisible(false)}
        cancelText="Close"
        okText="Update"
        centered
      >
        <Form
          layout="vertical"
          onFinish={updateInventoryCategoryInfo}
          id="update"
          form={salerUpdateForm}
        >

          <Form.Item
            name="salerName"
            label="Saler Name : "
            className="title-Text"
            rules={[
              { required: true, message: "Please input saler Name" },
            ]}
          >
            <Input placeholder='Saler Name' />
          </Form.Item>
          <Form.Item
            name="contactNo"
            label="Contact No"
            className="title-Text"
            rules={[
              { required: true, message: "Please write contact no" },
            ]}
          >
            <Input placeholder="Enter contact no" />
          </Form.Item>

          <Form.Item
            name="address"
            label="Address : "
            className="title-Text"
            rules={[
              { required: true, message: "Please Input address" },
            ]}
          >
            <InputNumber placeholder='Address' />
          </Form.Item>

        </Form>

      </Modal>

    </>
  );
}
