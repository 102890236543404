import { useEffect } from 'react';
import { useState } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { SelectAcademicYear3 } from '../../../select/SelectAcademicYear3';

const SMSHelper = require('smshelper');
const { TextArea } = Input;

export default function InstiuteSms() {

    const [smsTemplateForm] = Form.useForm();
    const { Option } = Select;
    const fetchSMSCount = useStoreActions((state) => state.sms.fetchSMSCount);
    const smsCount = useStoreState((state) => state.sms.staffCount);
    const fetchSmsTemplateForInstiute = useStoreActions((state) => state.sms.fetchSmsTemplate);
    const smsTemplateListForInstiute = useStoreState((state) => state.sms.smsTemplateList);
    const sendStudentSmsForInstiute = useStoreActions((state) => state.sms.sendStudentSmsForInstiute);
    const [templateContent, setTemplateContent] = useState<any>("");
    const loading = useStoreState((state) => state.sms.loading);
    /**********/

    useEffect(() => {
        fetchSMSCount();
        fetchSmsTemplateForInstiute();
    }, []);

    const smsSendForm = (value) => {
        let postdata: any = {
            "academicYear": value.academicYear,
            "message": value.smsContent
        }
        sendStudentSmsForInstiute(postdata);
    }

    const onChangeRemarksList = (e) => {
        smsTemplateForm.setFieldsValue({
            "smsContent": e
        })
        setTemplateContent(e);
    }




    return (
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>

                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>
                            <Card title="All Student SMS Send">
                                <Form
                                    layout="vertical"
                                    id="classConfigInfo"
                                    form={smsTemplateForm}
                                    onFinish={smsSendForm}

                                >
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8, offset: 8 }} lg={{ span: 8, offset: 8 }} xl={{ span: 8, offset: 8 }}>
                                            <Form.Item
                                                name="academicYear"
                                                label="Academic Year"
                                                className="title-Text"
                                                rules={[
                                                    { required: true, message: "Please select year" },
                                                ]}
                                            >
                                                <SelectAcademicYear3 />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8, offset: 8 }} lg={{ span: 8, offset: 8 }} xl={{ span: 8, offset: 8 }}>
                                            <Form.Item
                                                name="templateSelect"
                                                label="Select Template"
                                                className="title-Text"
                                                rules={[
                                                    { required: true, message: "Please select template" },
                                                ]}
                                            >
                                                <Select placeholder="Select SMS Template" onChange={(e) => onChangeRemarksList(e)}>
                                                    {smsTemplateListForInstiute ? (
                                                        smsTemplateListForInstiute.map((type, idx) => (
                                                            <Option key={type.templateId} value={type.templateBody}>
                                                                {type.templateTitle}
                                                            </Option>
                                                        ))
                                                    ) : (
                                                        <Option value="fetching">Fetching sms template</Option>
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8, offset: 8 }} lg={{ span: 8, offset: 8 }} xl={{ span: 8, offset: 8 }}>
                                            <Form.Item
                                                name="smsContent"
                                                label="Enter SMS Content"
                                                className="title-Text"
                                                rules={[
                                                    { required: true, message: "Write sms content" },
                                                ]}
                                            >
                                                <TextArea placeholder="Write sms content" rows={5} value={templateContent} onChange={e => setTemplateContent(e.target.value)} className="placeholderColorChange" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8, offset: 8 }} lg={{ span: 8, offset: 8 }} xl={{ span: 8, offset: 8 }}>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <div>
                                                    {templateContent.length > 0 &&
                                                        <>
                                                            SMS Count: {SMSHelper.parts(templateContent)}
                                                        </>
                                                    }
                                                </div>
                                                <div>{smsCount}</div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8, offset: 8 }} lg={{ span: 8, offset: 8 }} xl={{ span: 8, offset: 8 }}>
                                            <Button type="primary" htmlType="submit" className="pull-right" icon={<SendOutlined />} >
                                                Send
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    )
}