import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../../../store/hooks/easyPeasy';
import { Table, Tag} from 'antd';
import TableView from '../../../../../contents/AntTableResponsive';
import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import Column from 'rc-table/lib/sugar/Column';
import { render } from '@testing-library/react';
import { SelectClass } from '../../../../select/SelectClass';
import markSheetTemplate1 from '../../exam-template/mark-sheet-template-1.png';
import markSheetTemplate2 from '../../exam-template/mark-sheet-template-2.png';
import markSheetTemplate3 from '../../exam-template/mark-sheet-template-3.png';
import markSheetTemplate4 from '../../exam-template/mark-sheet-template-4.png';
import generalExamTemplate1 from '../../exam-template/general-exam-template-1.jpg';
import Meta from 'antd/lib/card/Meta';

export default function GrandFinalMarkSheet(){

    const { Option } = Select;
    const [templateSubmitForm] = Form.useForm();
    const fetchMarkSheetList =useStoreActions((state) => state.exam.fetchMarkSheetList);
    const markSheetListArray = useStoreState((state) => state.exam.markSheetListArray);
    const markSheetTemplateSave = useStoreActions((state) => state.exam.markSheetTemplateSave)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [templateId, setTemplateId] = useState("");
    const [templateType, setTemplateType] = useState("");
    const [defaultid, setDefaultid] = useState("");
    const [temptname, setTemptname] = useState("");
    const [templateList, setTemplateList] = useState([]);
    const [templateSelected, setTemplateSelected] = useState([]);
    const [templateSelectedValue, setTemplateSelectedValue] = useState<any>([]);

    useEffect(() => {
        fetchMarkSheetList();
        setTemplateList(markSheetListArray);
    }, [])

    const selectTempalte = (type,typeid,defaultid,temptname) =>  {
        setIsModalVisible(true);
        setTemplateId(typeid);
        setTemplateType(type);
        setDefaultid(defaultid);
        setTemptname(temptname);
        // console.log('template', templateId);
        
    }

    const templateCofigColumn = [
        { title: 'Class', dataIndex: 'className', key: 'className', showOnResponse: true, showOnDesktop: true},  
        { title: 'Marksheet Type', dataIndex: 'marksheetType', key: 'marksheetType', showOnResponse: true, showOnDesktop: true},  
        { title: 'Template Name', dataIndex: 'templateName', key: 'templateName', showOnResponse: true, showOnDesktop: true}, 
        { title: 'Marksheet Header', dataIndex: 'marksheetHeader', key: 'marksheetHeader', showOnResponse: true, showOnDesktop: true},
        { title: 'Merit Position', dataIndex: 'choosenMeritPosition', key: 'choosenMeritPosition', showOnResponse: true, showOnDesktop: true}, 
        { title: 'Student Img Status', dataIndex: 'studentImgStatus', key: 'studentImgStatus', showOnResponse: true, showOnDesktop: true},
        { title: 'Water Mark Status', dataIndex: 'waterMarkStatus', key: 'waterMarkStatus', showOnResponse: true, showOnDesktop: true} 

    ]

    const onSelectChangeTemplateSelect = (selectedRowKeys, value) => {
        setTemplateSelected(selectedRowKeys);
        setTemplateSelectedValue(value);
    };


    const templateRowSelection = {
        templateList,
        onChange: onSelectChangeTemplateSelect,
    };

    const templateSubmit = (value) => {
        let postData = {
            choosenMeritPosition: 1,
            classId: value.classList,
            marksheetHeader: value.markSheetHeader,
            marksheetType: templateType,
            marksheetTypeId: templateId,
            studentImageStatus: value.studentImage,
            templateDefaultId: defaultid,
            templateName: temptname,
            waterMarkStatus: value.waterMark
        }
        markSheetTemplateSave(postData);
        setIsModalVisible(false);
        fetchMarkSheetList();
        setTemplateList(markSheetListArray);
    }

    useEffect(() => {
        setTemplateList(markSheetListArray);
    }, [markSheetListArray])

    return(
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6}} lg={{ span: 6}} xl={{ span: 6}}>
                    <Card>
                        {/* <img src={markSheetTemplate1} style={{ width: "100%" }} /> */}
                       
                        <Meta title="Grand Final Marksheet Template-1" description="Description : 4 short code 2 exam process 1" />
                        <br /> <br />
                        <Button type="primary" onClick={(e) => selectTempalte('Grand Final','200','201','Grand Final Marksheet Template-1') } style={{float:'right'}}>Take</Button>
                       
                    </Card>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6}} lg={{ span: 6}} xl={{ span: 6}}>
                    <Card>
                        {/* <img src={markSheetTemplate2} style={{ width: "100%" }} /> */}
                       
                        <Meta title="Grand Final Marksheet Template-2" description="Description : 4 short code 3 exam process 1" />
                        <br /> <br />
                        <Button type="primary" onClick={(e) => selectTempalte('Grand Final','200','202','Grand Final Marksheet Template-2') } style={{float:'right'}}>Take</Button>
                       
                    </Card>
                </Col>


                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6}} lg={{ span: 6}} xl={{ span: 6}}>
                    <Card>
                        {/* <img src={markSheetTemplate2} style={{ width: "100%" }} /> */}
                        
                        <Meta title="Grand Final Marksheet Template-3" description="Description : 6 short code 2 exam process 1" />
                        <br /> <br />
                        <Button type="primary" onClick={(e) => selectTempalte('Grand Final','200','203','Grand Final Marksheet Template-3') } style={{float:'right'}}>Take</Button>
                        
                    </Card>
                </Col>


                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6}} lg={{ span: 6}} xl={{ span: 6}}>
                    <Card>
                        {/* <img src={markSheetTemplate2} style={{ width: "100%" }} /> */}
                        
                        <Meta title="Grand Final Marksheet Template-4" description="Description : 4 short code 2 exam process 2 & 4" />
                        <br /> <br />
                        <Button type="primary" onClick={(e) => selectTempalte('Grand Final','200','204','Grand Final Marksheet Template-4') } style={{float:'right'}}>Take</Button>
                        
                    </Card>
                </Col>

              
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6}} lg={{ span: 6}} xl={{ span: 6}} >
                    <Card style={{marginTop:'5px'}}>
                        {/* <img src={markSheetTemplate2} style={{ width: "100%" }} /> */}
                        
                        <Meta title="Grand Final Marksheet Template-5" description="Description : 4 short code 3 exam process 2 & 4" />
                        <br /> <br />
                        <Button type="primary" onClick={(e) => selectTempalte('Grand Final','200','205','Grand Final Marksheet Template-5') } style={{float:'right'}}>Take</Button>
                        
                    </Card>
                </Col>


                
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6}} lg={{ span: 6}} xl={{ span: 6}} >
                    <Card style={{marginTop:'5px'}}>
                        {/* <img src={markSheetTemplate2} style={{ width: "100%" }} /> */}
                        
                        <Meta title="Grand Final Marksheet Template-6" description="Description : 3 short code 3 exam process 2 & 4" />
                        <br /> <br />
                        <Button type="primary" onClick={(e) => selectTempalte('Grand Final','200','206','Grand Final Marksheet Template-6') } style={{float:'right'}}>Take</Button>
                        
                    </Card>
                </Col>

                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6}} lg={{ span: 6}} xl={{ span: 6}} >
                    <Card style={{marginTop:'5px'}}>
                        {/* <img src={markSheetTemplate2} style={{ width: "100%" }} /> */}
                        
                        <Meta title="Grand Final Marksheet Template-7" description="Description : 4 short code 4 exam process 2 & 4" />
                        <br /> <br />
                        <Button type="primary" onClick={(e) => selectTempalte('Grand Final','200','207','Grand Final Marksheet Template-7') } style={{float:'right'}}>Take</Button>
                        
                    </Card>
                </Col>


                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6}} lg={{ span: 6}} xl={{ span: 6}} >
                    <Card style={{marginTop:'5px'}}>
                        {/* <img src={markSheetTemplate2} style={{ width: "100%" }} /> */}
                        
                        <Meta title="Grand Final Marksheet Template-20" description="Description : 4 short code 2 exam process 2 & 4 (customized)" />
                        <br /> <br />
                        <Button type="primary" onClick={(e) => selectTempalte('Grand Final','200','220','Grand Final Marksheet Template-220') } style={{float:'right'}}>Take</Button>
                        
                    </Card>
                </Col>




            </Row>
            <br />
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}} lg={{ span: 24}} xl={{ span: 24}}>
                <TableView
                        antTableProps={{
                            // rowSelection:templateRowSelection,
                            showHeader: true,
                            columns:templateCofigColumn,  
                            rowKey:"marksheetId",
                            dataSource: templateList,
                            filterData: templateList,
                            pagination: false,
                            bordered: true
                        }}
                        mobileBreakPoint={768}
                    />
                </Col>
            </Row>
            <Modal
                title="Marksheet Template"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={templateSubmit}
                    form={templateSubmitForm}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="classList"
                                label="Class"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select class" },
                                ]}
                            >
                                <SelectClass />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="markSheetHeader"
                                label="Marksheet Header"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please marksheet header" },
                                ]}
                            >
                                <Select>
                                    <Option key={1} value="ACADEMIC TRANSCRIPT">ACADEMIC TRANSCRIPT</Option>
                                    <Option key={2} value="PROGRESS REPORT">PROGRESS REPORT</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="waterMark"
                                label="Water Mark"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please water mark" },
                                ]}
                            >
                                <Select>
                                    <Option key={1} value="1">Yes</Option>
                                    <Option key={2} value="2">No</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="studentImage"
                                label="Student Image"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please student image" },
                                ]}
                            >
                                <Select>
                                    <Option key={1} value="1">Yes</Option>
                                    <Option key={2} value="2">No</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}