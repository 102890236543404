import { useState } from 'react';
import { Button, Card, Col, Form, InputNumber, Row, Select} from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { DownloadOutlined } from '@ant-design/icons';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectGroup } from '../../../select/SelectGroup';

export default function AdmitCardWithRoutine (){

    const { Option } = Select;
    const [admitCardWithRoutineForm] = Form.useForm();
    const fetchSemesterListByClassConfigId = useStoreActions((state) => state.exam.fetchSemesterListByClassConfigId);
    const semesterListByClassConfigIdArray = useStoreState((state) => state.exam.semesterListByClassConfigIdArray);
    const fetchAdmitCardPdfWithRoutine = useStoreActions((state) => state.exam.fetchAdmitCardPdfWithRoutine);
    const [group, setGroup] = useState<any>();
    const onChangeClassList = (val) => {
        fetchSemesterListByClassConfigId(val);        
    }


    const admitCardDownloadWithRoutine = (value) => {
            let postdataAdmit:any = {
                startRoll : value.fromRoll,
                endRoll : value.toRoll,
                examConfigId : value.semesterYear,
                classConfigId : value.sectionList,
                academicYear : value.academicYear,
                groupId : group,
            }
            fetchAdmitCardPdfWithRoutine(postdataAdmit);
   
    }


    return(
        <>
            <Card title="Exam Admit Card Download With Exam Routine">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 16, offset: 4 }} xl={{ span: 16, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="sessionInfo"
                            onFinish={admitCardDownloadWithRoutine}
                            form={admitCardWithRoutineForm}
                          
                        >
                            <Row>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select academicYear" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="sectionList"
                                        label="Section List"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig onChange={(e) => onChangeClassList(e)} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="semesterYear"
                                        label="Exam List"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select semester" },
                                        ]}
                                    >
                                        <Select placeholder="Semester list" style={{ width: "100%" }}>
                                            {semesterListByClassConfigIdArray ? (
                                                    semesterListByClassConfigIdArray.map((type, idx) => (
                                                    <Option key={type.examConfigId} value={type.examConfigId}>
                                                        {type.examName}
                                                    </Option>
                                                    ))
                                                ) : (
                                                    <Option value="fetching">Fetching semester</Option>
                                                )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>

                                
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="groupId"
                                    label="Group"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select group" },
                                    ]}
                                >
                                    <SelectGroup onChange={e => setGroup(e)} />
                                </Form.Item>
                            </Col>


                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="fromRoll"
                                        label="From Roll"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write from roll" },
                                        ]}
                                    >
                                        <InputNumber placeholder="From roll" />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="toRoll"
                                        label="To Roll"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write to roll" },
                                        ]}
                                    >
                                        <InputNumber placeholder="To roll" />
                                    </Form.Item>             
                                </Col>                

                            </Row>
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                    <Button type="primary" htmlType="submit" icon={<DownloadOutlined />} className="float-right mt-0">Download</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </>
    )
}