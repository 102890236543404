import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { SelectFeeHead } from '../../../select/SelectFeeHead';
import { SelectLedgerIncome } from '../../../select/SelectLedgerIncome';
import { SelectLedgerOFPS } from '../../../select/SelectLedgerOFPS';



export default function UpayPgwCongiguration(props) {

    const [form] = Form.useForm();


    const ofpsconfigurationView = useStoreState((state) => state.saccount.upayPgwconfigurationView);
    const fetchofpsconfigurationView = useStoreActions((state) => state.saccount.fetchUpayPgwconfiguration);
    const saveofpsconfigurationView = useStoreActions((state) => state.saccount.saveUpayPgwconfiguration);


    useEffect(() => {
        fetchofpsconfigurationView();
    }, [])

    const formSubmit = (value) => {
        let postData = {
            debitLedgerId: value.debitLedgerId,
            creditLedgerId:value.creditLedgerId
        }
        saveofpsconfigurationView(postData);
        form.resetFields();
    }
    return (
        <>
            <>
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <Form
                            layout="vertical"
                            onFinish={formSubmit}
                            id="basic-info"
                            form={form}
                        >

                            <Card title="Save" >
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        name="debitLedgerId"
                                        label="Debit Ledger"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select debit ledger" },
                                        ]}
                                    >

                                        <SelectLedgerOFPS style={{ width: 180 }} />
                                    </Form.Item>

                                    <br></br>
                                  
                                    <Form.Item
                                        name="creditLedgerId"
                                        label="Credit Ledger"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select credit ledger" },
                                        ]}
                                    >

                                        <SelectLedgerIncome style={{ width: 180, marginLeft:10 }} />
                                    </Form.Item>

                                    <Button type="primary" htmlType="submit" style={{ height: 40, marginLeft: 20 }} icon={<SaveOutlined />}>
                                        Save
                                    </Button>
                                </div>
                            </Card>






                        </Form>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Card title="Configuration View" >
                            <div>
                                Merchant Mobile: {ofpsconfigurationView?.merchantMobile} <br /> <br />
                                Debit Ledger Name  : {ofpsconfigurationView?.debitLedgerName} <br /> <br />
                                Credit Ledger Name : {ofpsconfigurationView?.creditLedgerName}
                            </div>
                        </Card>
                    </Col>
                </Row>


            </>
        </>
    )
}
