import { DeleteOutlined, EditOutlined, PlusCircleOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Descriptions, Form, Input, InputNumber, Modal, Popconfirm, Row, Space, Table, Tooltip, message, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import { SelectInventoryCategory } from '../../../select/SelectInventoryCategory';
import { SelectInventoryItemWithoutCategory } from '../../../select/SelectInventoryItemWithoutCategory';
import { SelectLedgerCashEqui } from '../../../select/SelectLedgerCashEqui';
import ReactToPrint from 'react-to-print';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';

function roundThree ( val) {
    return Math.round(parseFloat(val) * 1000) / 1000
}

const ComponentToPrint = React.forwardRef((props: any, ref: any) => {
    const { tableData } = props;
    return (
        <div className='print-source' ref={ref}>
            <div style={{
                padding: 40,
                position: 'absolute',
                // height: 384,
                width: 500,
                top: 0,
                // bottom:0,
                right: 0,
                left: 0,
                margin: 'auto'
            }}>

                <div style={{ marginTop: 100, marginLeft: -11, padding: 10 }}>
                    <div>
                        <div style={{ fontWeight: 'bold', marginBottom: 0, display: "flex", justifyContent: "space-between", }}>

                            <span> INVOICE NO </span>
                            <span>{moment().format("T1YYMDDhhmm")}</span>
                        </div>
                    </div>
                    <div style={{ fontWeight: 'bold', marginBottom: 10, display: "flex", justifyContent: "space-between", fontSize: 12, }}>
                        <div>
                            <span> Date: </span>
                            <span>{tableData?.salesDate}</span>
                        </div>
                        <div>
                            <span> Entry: </span>
                            <span>{tableData?.entryBy}</span>
                        </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <table className="tableSales" style={{ marginBottom: 10 }}>

                            <tr>
                                <th style={{ width: "40%", textAlign: "left" }} >ID:</th>
                                <td style={{ textAlign: "center", width: "60%" }}> {tableData?.studentId}</td>
                            </tr>
                            <tr>
                                <th style={{ width: "40%", textAlign: "left" }} >Name:</th>
                                <td style={{ textAlign: "center", width: "60%" }}> {tableData?.studentName}</td>
                            </tr>
                            <tr>
                                <th style={{ width: "40%", textAlign: "left" }} >Class:</th>
                                <td style={{ textAlign: "center", width: "60%" }}> {tableData?.className}</td>
                            </tr>
                        </table>

                        <table className="tableSales" style={{}}>
                            {tableData?.details?.length > 0 &&
                                <>
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Price</th>
                                        </tr>
                                    </thead>
                                    {tableData?.details?.map(item => (
                                        <>

                                            <tbody>
                                                <tr>
                                                    <th scope="row">{item?.itemName}</th>
                                                    <td>{roundThree(item?.unitPrice)}*{roundThree(item?.quantity)}={roundThree(item?.totalAmount)}</td>
                                                </tr>
                                            </tbody>
                                        </>

                                    ))}
                                    <tfoot>
                                        <tr>
                                            <th scope="row">Total Pay Amount</th>
                                            <td>{roundThree(tableData?.payableAmount)}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Discount</th>
                                            <td>{roundThree(tableData?.discount)}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Paid Amount</th>
                                            <td>{roundThree(tableData?.paidAmount)}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Due</th>
                                            <td>{roundThree(tableData?.dueAmount)}</td>
                                        </tr>
                                    </tfoot>
                                </>
                            }

                        </table>
                    </div>
                </div>

                <div style={{ marginTop: 5, fontWeight: "bold", textAlign: "center" }}>
                    <span>THANKS FOR COMING</span>
                    <p> Powered by: Sheba Digital Limited</p>
                </div>
            </div>
        </div>
    );
});





export default function InventorySalesSave(props) {



    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const fetchStudentInfo = useStoreActions((state) => state.testimonials.fetchStudentInfo);
    const singleStudentInfo = useStoreState((state) => state.testimonials.studentInfo);
    const savesalestemInventory = useStoreActions((state) => state.inventory.savesalestemInventory);
    const itemList = useStoreState((state) => state.inventory.inventoryItemList);
    const viewInventorySales = useStoreState((state) => state.inventory.viewInventorySales);
    const [tableData, setTableData] = useState<any>([]);

    useEffect(() => {
        if (viewInventorySales != null) {
            buttonRef.current.click();
        }
    }, [viewInventorySales])

    const fetchStudentInfox = (value) => {
        let postData: any = {
            customStudentId: value.student_id,
            academicYear: value.academic_year,
        }
        fetchStudentInfo(postData);
    }


    const addToTable = (value) => {

        let itemName = itemList?.find(item => item?.itemId == value?.itemId)?.itemName
        let save = {
            key: uuidv4(),
            itemName: itemName,
            itemId: value?.itemId,
            unitPrice: unitPrice,
            quantity: quantity,
            total: total,
        }
        setunitPrice(0);
        setquantity(0);
        setTotal(0);
        const newData = [...tableData, save];
        setTableData(newData);
        settotAmount(roundThree(newData.reduce(function (acc, obj) { return acc + obj.total; }, 0)));
        setpayableAmount(roundThree(newData.reduce(function (acc, obj) { return acc + obj.total; }, 0)));
        setpaidAmount(roundThree(newData.reduce(function (acc, obj) { return acc + obj.total; }, 0)));
        form.resetFields();
    }

    function deleteItem(key) {
        let temp = tableData.filter((item) => item.key !== key);
        setTableData(temp);
        settotAmount(roundThree(temp.reduce(function (acc, obj) { return acc + obj.total; }, 0)));
        setpayableAmount(roundThree(temp.reduce(function (acc, obj) { return acc + obj.total; }, 0)));
        setpaidAmount(roundThree(temp.reduce(function (acc, obj) { return acc + obj.total; }, 0)));
    }



    const columns = [
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            key: 'itemName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Unit Price',
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Action",
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Delete">
                        <Popconfirm
                            title="Are you sure to delete this?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteItem(record?.key)}
                        >
                            <Button danger icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ]
    const [total, setTotal] = useState<any>("0");
    const [unitPrice, setunitPrice] = useState<any>(0);
    const [quantity, setquantity] = useState<any>(0);
    const [totAmount, settotAmount] = useState<any>(0);
    const [discount, setdiscount] = useState<any>(0);
    const [payableAmount, setpayableAmount] = useState<any>(0);
    const [paidAmount, setpaidAmount] = useState<any>(0);
    const [dueAmount, setdueAmount] = useState<any>(0);
    const [debitLedgerId, setdebitLedgerId] = useState<any>(null);
    const [salerId, setsalerId] = useState<any>(null);
    const [salesDate, setsalesDate] = useState<any>(moment());

    const onSaveInventory = () => {

        let details = tableData?.map(item => {
            return {
                "itemId": item?.itemId,
                "quantity": item?.quantity,
                "unitPrice": roundThree(item?.unitPrice),
                "totalPrice": roundThree(item?.total)
            }
        })
        if (details?.length === 0) {
            notification.error({ message: "Please add in inventory" })
            return;
        }
        if (singleStudentInfo == null) {
            notification.error({ message: "Please select student" })
            return;
        }
        if (salesDate == null) {
            notification.error({ message: "Date cannot be empty" })
            return;
        }
        if (debitLedgerId == null) {
            notification.error({ message: "Please select ledger" })
            return;
        }
        /* if (paidAmount < 1) {
            notification.error({ message: "Invalid paid amount" })
            return;
        } */
        let payload = {
            "debitLedgerId": debitLedgerId,
            "identificationId": singleStudentInfo?.identificationId,
            "items": details,
            "discount": roundThree(discount),
            "paidAmount": roundThree(paidAmount),
            "salesDate": moment(salesDate).format("YYYY-MM-DD")
        }
        savesalestemInventory(payload);
        setTableData([])
        settotAmount(0);
        setdiscount(0);
        setpaidAmount(0);
        setpayableAmount(0);
        setdueAmount(0);
        // setdebitLedgerId(null);
    }

    const onChangeItem = (e) => {
        let itemData = itemList?.find(item => item?.itemId == e);
        setunitPrice(roundThree(itemData?.salesPrice));
        setTotal(roundThree(quantity * itemData?.salesPrice));
        form.setFieldsValue({ unitPrice: itemData?.salesPrice })
    }

    const componentRef: any = useRef();
    const buttonRef: any = useRef(null);

    return (
        <>
            <Card title={"Inventory Sales"}>
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Form
                            layout="vertical"
                            onFinish={fetchStudentInfox}
                            id="basic-info-student"
                            form={form2}
                        >

                            <Row>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Form.Item
                                        name="academic_year"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select academic year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={7} xl={8}>
                                    <Form.Item
                                        name="student_id"
                                        label="Student Id"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write student id" },
                                        ]}
                                    >

                                        <Input placeholder='Student Id' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={4} xl={4} >
                                    <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        <Form
                            layout="vertical"
                            onFinish={addToTable}
                            id="basic-info"
                            form={form}
                        >


                            <Row gutter={8}>

                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Form.Item
                                        name="itemId"
                                        label="Item"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select item" },
                                        ]}
                                    >

                                        <SelectInventoryItemWithoutCategory onChange={(e) => onChangeItem(e)} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Form.Item
                                        name="unitPrice"
                                        label="Unit Price"
                                        className="title-Text"
                                        initialValue={0}
                                        rules={[
                                            { required: true, message: "Please input unit price" },
                                        ]}
                                    >

                                        <InputNumber min={0} value={unitPrice} placeholder='Unit Price' onChange={(e) => { setunitPrice(roundThree(e)); setTotal(roundThree(quantity * e)) }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Form.Item
                                        name="quantity"
                                        label="Quantity"
                                        className="title-Text"
                                        initialValue={0}
                                        rules={[
                                            { required: true, message: "Please input quantity" },
                                        ]}
                                    >

                                        <InputNumber min={0} value={quantity} placeholder='Quantity' onChange={(e) => { setquantity(roundThree(e)); setTotal(roundThree(unitPrice * e)) }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <div className="ant-col ant-form-item-label">
                                        <label className="ant-form-item" style={{ marginTop: 0 }}>Total</label></div>
                                    <Input value={total} disabled style={{ height: 40 }} />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<PlusCircleOutlined />}>
                                        Add
                                    </Button>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Descriptions
                                    // title="User Info"
                                    bordered
                                    column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                                >
                                    <Descriptions.Item label={<strong>Name</strong>}>{singleStudentInfo?.studentName}</Descriptions.Item>
                                    <Descriptions.Item label={<strong>Academic Year</strong>}>{singleStudentInfo?.academicYear}</Descriptions.Item>
                                    <Descriptions.Item label={<strong>Section</strong>}>{singleStudentInfo?.classConfigName}</Descriptions.Item>
                                    <Descriptions.Item label={<strong>Roll</strong>}>{singleStudentInfo?.studentRoll}</Descriptions.Item>
                                </Descriptions>
                            </Col>
                            <br />
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Table bordered={true} dataSource={tableData} columns={columns} pagination={false} />
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginTop: 10 }}>
                                <div className="ant-col ant-form-item-label">
                                    <label className="ant-form-item" style={{ marginBottom: -3 }}>Total Amount</label></div>
                                <Input value={totAmount} style={{ height: 40 }} disabled />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginTop: 10 }} >
                                <div className="ant-col ant-form-item-label">
                                    <label className="ant-form-item" style={{ marginBottom: -3 }}>Discount</label></div>
                                <InputNumber value={discount} style={{ height: 40 }} onChange={(e) => { setpayableAmount(roundThree(totAmount - e)); setdueAmount(roundThree(totAmount - e)); setdiscount(roundThree(e)) }} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginTop: 10 }}>
                                <div className="ant-col ant-form-item-label">
                                    <label className="ant-form-item" style={{ marginBottom: -3 }}>Payable Amount</label></div>
                                <Input value={payableAmount} disabled style={{ height: 40 }} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginTop: 10 }} >
                                <div className="ant-col ant-form-item-label">
                                    <label className="ant-form-item" style={{ marginBottom: -3 }}>Paid Amount</label></div>
                                <InputNumber value={paidAmount} style={{ height: 40 }} onChange={(e) => { setdueAmount(roundThree(payableAmount - e)); setpaidAmount(roundThree(e)) }} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginTop: 10 }}>
                                <div className="ant-col ant-form-item-label">
                                    <label className="ant-form-item" style={{ marginBottom: -3 }}>Due Amount</label></div>
                                <Input value={dueAmount} disabled style={{ height: 40 }} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginTop: 10 }} >
                                <div className="ant-col ant-form-item-label">
                                    <label className="ant-form-item" style={{ marginBottom: -3 }}>Date</label></div>
                                <DatePicker style={{ width: '100%' }} value={salesDate} onChange={e => setsalesDate(e)} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginTop: 10 }} >
                                <div className="ant-col ant-form-item-label">
                                    <label className="ant-form-item" style={{ marginBottom: -3 }}>ReceiveLedger</label></div> <br />
                                <SelectLedgerCashEqui style={{ width: "100%" }} selected={debitLedgerId} onChange={e => setdebitLedgerId(e)} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{ marginTop: 40 }} >
                                <Button
                                    type="primary"
                                    onClick={onSaveInventory}
                                    icon={<SaveOutlined />}
                                >
                                    Save
                                </Button>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4} style={{ marginTop: 40 }} >


                                <div>
                                    <ReactToPrint
                                        trigger={() => <Button type='primary' ref={buttonRef} id='printIt' htmlType="submit" icon={<SaveOutlined />} style={{ visibility: "hidden" }}>Save</Button>}
                                        content={() => componentRef.current}
                                    />
                                    <ComponentToPrint
                                        ref={componentRef}
                                        tableData={viewInventorySales}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>


        </>
    )
}
