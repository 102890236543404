import React from 'react'
import { Card, Tabs } from 'antd';
import FeeHeadCollectionDateWise from './FeeHeadCollectionDateWise';
import FeeHeadCollectionClass from './FeeHeadCollectionClass';
import FeeHeadCollectionClassMulti from './FeeHeadCollectionClassMulti';
import FeeHeadCollectionStudent from './FeeHeadCollectionStudent';
import FeeHeadCollectionSectionDatetoDate from './FeeHeadCollectionSectionDatetoDate';
import FeeHeadCollectionStudentDateToDate from './FeeHeadCollectionStudentDateToDate';
import FeeHeadCollectionStudentDateToDateAll from './FeeHeadCollectionStudentDateToDateAll';
import FeeHeadCollectionDateToDateAllSection from './FeeHeadCollectionDateToDateAllSection';
import FeeHeadCollectionDateToDateFeeHeadSubHead from './FeeHeadCollectionDateToDateFeeHeadSubHead';


export default function StaffIdMappingWrapper(props) {

    const { TabPane } = Tabs;

    return ( 
        <>
            <Card title="Fee Head Collection">
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Date Wise" key="1">
                        <FeeHeadCollectionDateWise />
                    </TabPane>
                    <TabPane tab="Class Wise" key="2">
                        <FeeHeadCollectionClass />
                    </TabPane>
                    <TabPane tab="Class Wise Multi" key="6">
                        <FeeHeadCollectionClassMulti />
                    </TabPane>
                    <TabPane tab="Student Wise" key="3">
                        <FeeHeadCollectionStudent />
                    </TabPane>
                    <TabPane tab="Date to Date" key="4">
                        <FeeHeadCollectionStudentDateToDate />
                    </TabPane>                    
                    <TabPane tab="Date to Date (Section)" key="5">
                        <FeeHeadCollectionSectionDatetoDate />
                    </TabPane>               
                    <TabPane tab="Date to Date (All Class All Fee)" key="7">
                        <FeeHeadCollectionStudentDateToDateAll />
                    </TabPane>
                    <TabPane tab="Date to Date (All Section All Fee)" key="8">
                        <FeeHeadCollectionDateToDateAllSection />
                    </TabPane>                    
                    <TabPane tab="Fee Head, Sub Head" key="9">
                        <FeeHeadCollectionDateToDateFeeHeadSubHead />
                    </TabPane>
                </Tabs>
            </Card>
        </>
    )
}