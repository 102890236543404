import React, { useEffect, useState } from 'react';
import { Card, Tabs } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import MultipleRegistrationExcel from './MultipleRegistrationExcel';
import MultipleRegistrationWithCustomIdExcel from './MultipleRegistrationWithCustomIdExcel';
import MultipleRegistrationWithCustomIdExcelInternational from './MultipleRegistrationWithCustomIdExcelInternational';

    export default function MultipleRegistrationExeclWrapper(){
        const { TabPane } = Tabs;

        return(
            <>
                <Card title="Excel Registration">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Auto ID" key="1">
                            <MultipleRegistrationExcel />
                        </TabPane>
                        <TabPane tab="Custom ID" key="2">
                            <MultipleRegistrationWithCustomIdExcel />
                        </TabPane>

                        <TabPane tab="Custom ID(International)" key="3">
                            <MultipleRegistrationWithCustomIdExcelInternational/>
                        </TabPane>
                    </Tabs>
                </Card>
            </>
        )
    }