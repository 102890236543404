import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag } from 'antd';
import TableView from '../../../../contents/AntTableResponsive';
import { DeleteOutlined, DownloadOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import Column from 'rc-table/lib/sugar/Column';
import { render } from '@testing-library/react';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectClass } from '../../../select/SelectClass';
import { SelectSemesterYearByClass } from '../../../select/SelectSemesterYearByClass';
import { SelectSemesterYear } from '../../../select/SelectSemesterYear';
import { v4 as uuidv4 } from "uuid";
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { FilePdfOutlined } from '@ant-design/icons';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
require('jspdf-autotable');

export default function ExamReportSectionWiseUninputtedSubjects() {
    const { Option } = Select;
    const [examReportSectionWiseForm] = Form.useForm();
    const fetchExamUnassignedSubjectExamList = useStoreActions((state) => state.exam.fetchExamUnassignedSubjectExamList);
    const examUnassignedSubjectExamList = useStoreState((state) => state.exam.examUnassignedSubjectExamList);
    const fetchExamUnassignedSubjectMarkList = useStoreActions((state) => state.exam.fetchExamUnassignedSubjectMarkList);
    const examUnassignedSubjectMarkList = useStoreState((state) => state.exam.examUnassignedSubjectMarkList);

    const examReportSearch = (value) => {
          
        let payload : any = {
            examId : value.examconfigid,
            academicYear : value.academicYear,
        }

        fetchExamUnassignedSubjectMarkList(payload);
    }

    useEffect(() => {
        fetchExamUnassignedSubjectExamList();
    }, []);

    const unassignedSubjectMarkListColumn = [
        { title: 'Section', dataIndex: 'sectionName', key: uuidv4(), showOnResponse: true, showOnDesktop: true },
        { title: 'Total Subject', dataIndex: 'totalSubjects', key: uuidv4(), showOnResponse: true, showOnDesktop: true },
        { title: 'Inputted Subject', dataIndex: 'inputtedMarkSubjects', key: uuidv4(), showOnResponse: true, showOnDesktop: true },
        { title: 'Remaining Subject', dataIndex: 'remainingSubjects', key: uuidv4(), showOnResponse: true, showOnDesktop: true },
        { title: 'Remaining Subjects Names', dataIndex: 'remainingSubjectNames', key: uuidv4(), showOnResponse: true, showOnDesktop: true },
    ];
    const loading = useStoreState((state) => state.exam.loading);

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");

        var details = `Section Wise Unassigned Subject Mark`;
        pdfDataL(doc, "");
        doc.text(details, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        var col = [
            "Section",
            "Total Subject",
            "Inputted Subject",
            "Remaining Subject",
            "Remaining Subjects Names",
        ].filter(Boolean);

        var rows: any = [];


        examUnassignedSubjectMarkList.forEach(element => {
            var temp: any = [
                element.sectionName,
                element.totalSubjects,
                element.inputtedMarkSubjects,
                element.remainingSubjects,
                element.remainingSubjectNames,
            ];
            rows.push(temp);
        });

        doc.autoTable([
            { title: "", dataKey: "b5" },
            { title: "", dataKey: "b6" },
        ], [
            {

                b5: 'Exam',
                b6: $(".examSelectx").text(),
            }
        ], {
            startY: 45,
            showHeader: "never",
            theme: 'grid',

            // addPageContent: pageContent,
        });
        let first = doc.autoTable.previous;
        doc.autoTable(col, rows, {
            startY: doc.autoTable.previous.finalY + 5,
            showHeader: "firstPage",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                ledgerName: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 75
                },
                debit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                },
                credit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                }
            },


        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    return (
        <>
            <Card title="Section Wise Unassigned Subject Mark">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={examReportSearch}
                            form={examReportSectionWiseForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                        name="academicYear"
                                        label="Select Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select Academic Year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="examconfigid"
                                        label="Select Exam"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select exam" },
                                        ]}
                                    >
                                        <Select placeholder="Exam List" style={{ width: "100%" }} className="examSelectx">
                                            {examUnassignedSubjectExamList ? (
                                                examUnassignedSubjectExamList.map((type, idx) => (
                                                    <Option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching exam</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>

                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: examUnassignedSubjectMarkList.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: unassignedSubjectMarkListColumn,
                                rowKey: "subjectSerial",
                                dataSource: examUnassignedSubjectMarkList,
                                filterData: examUnassignedSubjectMarkList,
                                pagination: true,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                        <Button type="primary" htmlType="submit" icon={<DownloadOutlined />} onClick={() => exportPdf()} className="float-right">Download</Button>
                    </Col>
                </Row>
            </Card>
        </>
    )

}