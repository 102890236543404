import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TableView from '../../../../contents/AntTableResponsive';
import { DeleteOutlined, EditOutlined, FilePdfOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectClass } from '../../../select/SelectClass';
import { render } from '@testing-library/react';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';


export default function MarkDelete(props) {

    const { Option } = Select;
    const [markInputUpdateForm] = Form.useForm();
    const fetchSemesterListByClassConfigId = useStoreActions((state) => state.exam.fetchSemesterListByClassConfigIdMarkDelete);
    const semesterListByClassConfigIdArray = useStoreState((state) => state.exam.semesterListByClassConfigIdArrayMarkDelete);

    const fetchlistGroupbyClassConfig = useStoreActions((state) => state.exam.fetchlistGroupbyClassConfigMarkDelete);
    const listGroupbyClassConfigMarkUpdate = useStoreState((state) =>  state.exam.listGroupbyClassConfigMarkDelete);
    
    const fetchSubjectByGroupConfigId = useStoreActions((state) => state.exam.fetchSubjectByGroupConfigIdMarkDelete);
    const subjectByGroupConfigIdArray = useStoreState((state) => state.exam.subjectByGroupConfigIdArrayMarkDelete);

    const fetchExamMarkCofigureListByExamConfigId = useStoreActions((state) => state.exam.fetchExamMarkCofigureListByExamConfigIdMarkDelete);
    const examMarkCofigureListByExamConfigId = useStoreState((state) =>  state.exam.examMarkCofigureListByExamConfigIdMarkDelete);

    const fetchExamMarkInputStudentList = useStoreActions((state) => state.exam.fetchExamMarkStudentListMarkDelete);
    const examMarkInputStudentList = useStoreState<any>((state) => state.exam.examMarkStudentListMarkDelete);
    
    const fetchExamMarkDelete = useStoreActions((state) => state.exam.fetchExamMarkDelete);

    const [classConfigId, setClassConfigId] = useState<any>("");
    const [examConfigId, setExamConfigId] = useState<any>("");
    const [markInputTableDatavisibility, setmarkInputTableDatavisibility] = useState<any>();
    const [studentSelected, setStudentSelected] = useState<any>([]);
    const [studentSelectedValue, setstudentSelectedValue] = useState<any>([]);
    const [examMarkConfigList, setExamMarkConfigList] = useState<any>();
    const [markInputTableData, setMarkInputTableData] = useState<any>();
    const [shortCode1Name, setShortCode1Name] = useState<any>();
    const [shortCode2Name, setShortCode2Name] = useState<any>();
    const [shortCode3Name, setShortCode3Name] = useState<any>();
    const [shortCode4Name, setShortCode4Name] = useState<any>();
    const [shortCode5Name, setShortCode5Name] = useState<any>();
    const [shortCode6Name, setShortCode6Name] = useState<any>();
    const [markInputTableHeaderVisibility, setmarkInputTableHeaderVisibility] = useState<any>();
    const loading = useStoreState((state) =>  state.exam.loading);

    const [markSaveObject, setMarkSaveObject] = useState<any>();

    let markInputTableColumn:any = [];
    
    useEffect(() => {
        setMarkInputTableData(examMarkInputStudentList.studentList);
        setmarkInputTableHeaderVisibility(examMarkInputStudentList.shortCodeVisibilty);
    }, [examMarkInputStudentList]);

    const onChangeClassList = (e) => {
        fetchSemesterListByClassConfigId(e);
        fetchlistGroupbyClassConfig(e);
        setClassConfigId(e)
        setMarkInputTableData([]);
        setExamMarkConfigList([]);
    }

    const searchSubjectByGroupId = (e) => {
        
        let payload:any = {
            classConfigId : classConfigId,
            groupId : e
        }
        fetchSubjectByGroupConfigId(payload);
    }

    const configTableColumn = [
        { title: 'Short Code Title', dataIndex: 'shortCodeName', key: 'shortCodeName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Total', dataIndex: 'totalMark', key: 'totalMark', showOnResponse: true, showOnDesktop: true},
        { title: 'Pass Mark', dataIndex: 'passMark', key: 'passMark', showOnResponse: true, showOnDesktop: true},
        { title: 'Acceptance', dataIndex: 'acceptance', key: 'acceptance', showOnResponse: true, showOnDesktop: true},
    ]

    const changeShortCode1NameValue = (value, details) => {
        markInputTableData.map((item, index) => {
            if(item.shortCode1 ==  details.shortCode1){
                item.shortCode1 = value;
            }
        })
        setMarkInputTableData(markInputTableData);    
         
    }

    const changeShortCode2NameValue = (value, details) => {
        markInputTableData.map((item, index) => {
            if(item.shortCode2 ==  details.shortCode2){
                item.shortCode2 = value;
            }
        })
        setMarkInputTableData(markInputTableData);        
    }

    const changeShortCode3NameValue = (value, details) => {
        markInputTableData.map((item, index) => {
            if(item.shortCode3 ==  details.shortCode3){
                item.shortCode3 = value;
            }
        })
        setMarkInputTableData(markInputTableData);     
    }

    const changeShortCode4NameValue = (value, details) => {
        markInputTableData.map((item, index) => {
            if(item.shortCode4 ==  details.shortCode4){
                item.shortCode4 = value;
            }
        })
        setMarkInputTableData(markInputTableData);     
    }

    const changeShortCode5NameValue = (value, details) => {
        markInputTableData.map((item, index) => {
            if(item.shortCode5 ==  details.shortCode5){
                item.shortCode5 = value;
            }
        })
        setMarkInputTableData(markInputTableData);       
    }

    const changeShortCode6NameValue = (value, details) => {
        markInputTableData.map((item, index) => {
            if(item.shortCode6 ==  details.shortCode6){
                item.shortCode6 = value;
            }
        })
        setMarkInputTableData(markInputTableData); 
        
    }

    const getMarkConfigListBySubject = (e) => {
        let payload:any = {
            examConfigId : examConfigId,
            subjectId : e
        }
        fetchExamMarkCofigureListByExamConfigId(payload);        
    }

    useEffect(() => {
        setExamMarkConfigList(examMarkCofigureListByExamConfigId == null ? null : examMarkCofigureListByExamConfigId);
    },[examMarkCofigureListByExamConfigId])

    const saveMarkDelete = (e) => {
        let finalData:any = {
            deleteIds: {
                markInputIds: studentSelected
            },
            markSaveObject: markSaveObject
        }
        console.log('finalData', finalData);
        if(finalData?.deleteIds.markInputIds == 0){
            notification.error({ message: "Select row first" });
        }else{
            fetchExamMarkDelete(finalData);
            setMarkInputTableData([]);
        }
    }

    const searchMarkInputSearch = (value) => {
        let payload:any = {
            classConfigId: value.sectionList,
            examConfigId: value.semesterYear,
            subjectId: value.selectSubject,
            academicYear: value.academicYear,
        }
        fetchExamMarkInputStudentList(payload);
        let saveObject = {
            classConfigId: value.sectionList,
            examConfigId: value.semesterYear,
            groupId: value.classGroup,
            subjectId: value.selectSubject,
            type: "update",
            academicYear: value.academicYear,
        }        
        setMarkSaveObject(saveObject); 
        markInputTableData([]);
    }
    
    markInputTableColumn = [
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true, className: "none"},
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true},
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true},
        { title: 'Total Marks', dataIndex: 'totalMark', key: 'totalMark', showOnResponse: true, showOnDesktop: true},
        { title: 'Grade', dataIndex: 'letterGrade', key: 'letterGrade', showOnResponse: true, showOnDesktop: true},
        { title: shortCode1Name? shortCode1Name : '', dataIndex: 'shortCode1', key: 'shortCode1', showOnResponse: true, showOnDesktop: true, className:shortCode1Name? shortCode1Name : 'd-none', 
            render: (text, record, index) => (
                <InputNumber defaultValue={record.shortCode1} disabled onChange={(e) => changeShortCode1NameValue(e, record)} />
            )
        },
        { title: shortCode2Name? shortCode2Name : '', dataIndex: 'shortCode2', key: 'shortCode2', showOnResponse: true, showOnDesktop: true, className:shortCode2Name? shortCode2Name : 'd-none',
            render: (text, record, index) => (
                <InputNumber defaultValue={record.shortCode2} disabled onChange={(e) => changeShortCode2NameValue(e, record)} />
            )
        },
        { title: shortCode3Name? shortCode3Name : '', dataIndex: 'shortCode3', key: 'shortCode3', showOnResponse: true, showOnDesktop: true, className:shortCode3Name? shortCode3Name : 'd-none',
            render: (text, record, index) => (
                <InputNumber defaultValue={record.shortCode3} disabled onChange={(e) => changeShortCode3NameValue(e, record)} />
            )
        },
        { title: shortCode4Name? shortCode4Name : '', dataIndex: 'shortCode4', key: 'shortCode4', showOnResponse: true, showOnDesktop: true, className:shortCode4Name? shortCode4Name : 'd-none',
            render: (text, record, index) => (
                <InputNumber defaultValue={record.shortCode4} disabled onChange={(e) => changeShortCode4NameValue(e, record)} />
            )
        },
        { title: shortCode5Name? shortCode5Name : '', dataIndex: 'shortCode5', key: 'shortCode5', showOnResponse: true, showOnDesktop: true, className:shortCode5Name? shortCode5Name : 'd-none',
            render: (text, record, index) => (
                <InputNumber defaultValue={record.shortCode5} disabled onChange={(e) => changeShortCode5NameValue(e, record)} />
            )
        },
        { title: shortCode6Name? shortCode6Name : '', dataIndex: 'shortCode6', key: 'shortCode6', showOnResponse: true, showOnDesktop: true, className:shortCode6Name? shortCode6Name : 'd-none',
            render: (text, record, index) => (
                <InputNumber defaultValue={record.shortCode6} disabled onChange={(e) => changeShortCode6NameValue(e, record)} />
            )
        },
        
    ]

    useEffect(() => {
        setShortCode1Name(markInputTableHeaderVisibility?.shortCode1Name);
        setShortCode2Name(markInputTableHeaderVisibility?.shortCode2Name);
        setShortCode3Name(markInputTableHeaderVisibility?.shortCode3Name);
        setShortCode4Name(markInputTableHeaderVisibility?.shortCode4Name);
        setShortCode5Name(markInputTableHeaderVisibility?.shortCode5Name);
        setShortCode6Name(markInputTableHeaderVisibility?.shortCode6Name);
    }, [markInputTableHeaderVisibility])


    const onSelectChangeStudentSelect = (selectedRowKeys, value) => {
        setStudentSelected(selectedRowKeys);
        setstudentSelectedValue(value);
    };


    const studentRowSelection = {
        markInputTableData,
        onChange: onSelectChangeStudentSelect,
    };

   
     
    return(
        <>
            <Card title="Mark Delete">
                <Form
                    layout="vertical"
                    id="sessionInfo"
                    onFinish={searchMarkInputSearch}
                    form={markInputUpdateForm}
                >
                    <Row>

                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 4 }} lg={{ span: 4}} xl={{ span: 4}}>
                            <Form.Item
                                name="academicYear"
                                label="academicYear"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select academicYear" },
                                ]}
                            >
                                <SelectAcademicYear />
                            </Form.Item>
                        </Col> 

                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 5 }} lg={{ span: 5}} xl={{ span: 5}}>
                            <Form.Item
                                name="sectionList"
                                label="Section List"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select section" },
                                ]}
                            >
                                <SelectClassConfig onChange={(e) => {markInputUpdateForm.setFieldsValue({semesterYear:null,  selectSubject:null ,classGroup:null });  onChangeClassList(e)}} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 5 }} lg={{ span: 5}} xl={{ span: 5}}>
                            <Form.Item
                                name="semesterYear"
                                label="Exam List"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select semester" },
                                ]}
                            >
                                <Select placeholder="Semester list" style={{ width: "100%" }} onChange={(e) => setExamConfigId(e)}>
                                    {semesterListByClassConfigIdArray ? (
                                            semesterListByClassConfigIdArray.map((type, idx) => (
                                            <Option key={type.examConfigId} value={type.examConfigId}>
                                                {type.examName}
                                            </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching semester</Option>
                                        )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 5 }} lg={{ span: 5}} xl={{ span: 5}}>
                            <Form.Item
                                name="classGroup"
                                className="title-Text"
                                label="Group"
                                rules={[
                                    { required: true, message: "Select group list" },
                                ]}
                            >
                                <Select placeholder="Semester list" style={{ width: "100%" }} onChange={(e) => searchSubjectByGroupId(e)}>
                                    {listGroupbyClassConfigMarkUpdate ? (
                                            listGroupbyClassConfigMarkUpdate.map((type, idx) => (
                                            <Option key={type.groupId} value={type.groupId}>
                                                {type.groupName}
                                            </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching Group</Option>
                                        )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 5 }} lg={{ span: 5}} xl={{ span: 5}}>
                            <Form.Item
                                name="selectSubject"
                                label="Subject List"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select subject list" },
                                ]}
                            >
                                <Select placeholder="Semester list" style={{ width: "100%" }} onChange={(e) => getMarkConfigListBySubject(e)}>
                                    {subjectByGroupConfigIdArray ? (
                                            subjectByGroupConfigIdArray.map((type, idx) => (
                                            <Option key={type.subjectId} value={type.subjectId}>
                                                {type.subjectName}
                                            </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching Subject</Option>
                                        )}
                                </Select>
                            </Form.Item>
                            <div className="pull-right">
                                <Button type="primary" className=" mt-0" htmlType="submit" icon={<SearchOutlined />} >
                                    Search
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
                <br />
                <Row style={{ display: examMarkConfigList == null || examMarkConfigList == "" ? 'none' : 'block' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24}} xl={{ span: 24}}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns:configTableColumn,
                                rowKey:"defaultId",
                                dataSource: examMarkConfigList,
                                filterData:examMarkConfigList,
                                pagination: false,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />
                    </Col>
                </Row>
                <br />
                <Skeleton loading={loading} paragraph={{rows:10}} />
                <Row style={{ display: examMarkConfigList !== null && markInputTableData?.length > 0 ? 'block' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24}} xl={{ span: 24}}>
                        <TableView
                            antTableProps={{
                                rowSelection:studentRowSelection,
                                showHeader: true,
                                columns:markInputTableColumn,
                                rowKey:"markinputId",
                                dataSource: markInputTableData,
                                filterData:markInputTableData,
                                pagination: false,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />
                    </Col>
                    <br />
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24}} xl={{ span: 24}}>
                        <div className="pull-right">
                            <Button danger  onClick={(e) => saveMarkDelete(e)} icon={<SaveOutlined />} >Delete</Button>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    )

}