import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Skeleton, Space, Tooltip, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import NoImage from './no.png'
import $ from 'jquery';
import jsPDF from "jspdf";

import ReactExport from "react-export-excel";
import { pdfDataL, ppowerdbypdf, pdatepdf, lpowerdbypdf } from '../../../utils/pdf';
import { Typography } from 'antd';
require('jspdf-autotable');

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

export default function StaffList(props) {

    const { Title } = Typography;
    const staffBasicInfoListReport = useStoreState((state) => state.staff.staffBasicInfoListReport);
    const fetchstaffBasicInfoListReport = useStoreActions((state) => state.staff.fetchstaffBasicInfoListReport);
    const loading = useStoreState((state) =>  state.staff.loading);

    useEffect(() => {
        fetchstaffBasicInfoListReport();
    }, [])




    const columns = [

        {
            title: 'Serial',
            dataIndex: 'staffSerial',
            key: 'staffSerial',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: ' Teachers Name',
            dataIndex: 'staffName',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'ID',
            dataIndex: 'customStaffId',
            key: 'customStaffId',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Designation',
            dataIndex: 'designationName',
            key: 'designationName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Mobile No.',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Father's Name ",
            dataIndex: 'fatherName',
            key: 'fatherName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Mother's Name",
            dataIndex: 'motherName',
            key: 'motherName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Religion',
            dataIndex: 'religion',
            key: 'religion',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Blood Group',
            dataIndex: 'bloodGroup',
            key: 'bloodGroup',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Image',
            key: 'imageName',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <div style={{ textAlign: "center" }}>
                    <img src={record.imageName == '' ? NoImage : ("data:image/png;base64," + record.imageName)} alt="Staff Image" style={{ height: 50, width: 50 }} />

                </div>
            ),
        },
    ];

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");

        var details = `Staff List`;
        pdfDataL(doc, "");
        doc.text(details, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        var col = [
            "Serial",
            "Name",
            "ID",
            "Designation",
            "Mobile No.",
            "Father's Name",
            "Mother's Name",
            "Gender",
            "Religion",
            "Blood Group",
        ].filter(Boolean);

        var rows: any = [];


        staffBasicInfoListReport.forEach(element => {
            var temp: any = [
                element.staffSerial,
                element.staffName,
                element.customStaffId,
                element.designationName,
                element.mobileNumber,
                element.fatherName,
                element.motherName,
                element.gender,
                element.religion,
                element.bloodGroup,
            ];
            rows.push(temp);
        });


        let first = doc.autoTable.previous;
        doc.autoTable(col, rows, {
            startY: 45,
            showHeader: "firstPage",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                guardianMobile: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 250
                }
            },
            addPageContent: pageContent,
        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }


    return (
        <>
        
            <Card title="Teachers & Staff List" >
            {staffBasicInfoListReport === null && <Skeleton loading={loading} paragraph={{rows:10}}/>}
         {staffBasicInfoListReport !==null && 
                <div className="datatable-responsive">
                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: staffBasicInfoListReport,
                            filterData: staffBasicInfoListReport,
                            pagination: true,
                            bordered: true,
                            rowKey: 'staffId'
                        }}
                        mobileBreakPoint={768}
                    />
                </div>
            }
                <Space size="small" style={{ float: "right", marginTop:12 }}>

                <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Staff List`}
                            >
                                <ExcelSheet data={staffBasicInfoListReport} name="Staff List">
                                    <ExcelColumn label="Staff ID" value="customStaffId" />
                                    <ExcelColumn label="Staff Name" value="staffName" />
                                    <ExcelColumn label="Gender" value="gender" />
                                    <ExcelColumn label="Religion" value="religion" />
                                    <ExcelColumn label="Date of Birth" value="birthDate" />
                                    <ExcelColumn label="Email" value="email" />
                                    <ExcelColumn label="Mobile" value="mobileNumber" />
                                    <ExcelColumn label="Alternative Mobile" value="alternativeMobile" />
                                    <ExcelColumn label="Blood Group" value="bloodGroup" />
                                    <ExcelColumn label="Designation" value="designationName" />
                                    <ExcelColumn label="Father Name" value="fatherName" />
                                    <ExcelColumn label="Mother Name" value="motherName" />

                                    <ExcelColumn label="Employeement Status" value="employeementStatus" />
                                    <ExcelColumn label="Employment Date" value="employmentDate" />
                                    <ExcelColumn label="National ID" value="nationalId" />
                                    <ExcelColumn label="Passport No" value="passportNo" />
                                    <ExcelColumn label="Subject Specialist" value="subjectSpecialist" />
                                    <ExcelColumn label="Mobile Banking Info" value="mobileBankingInfo" />

                                    <ExcelColumn label="Address Details" value="staffAddress" />
                                    
                                    <ExcelColumn label="Staff Image" value="imageName" />
                                    <ExcelColumn label="Signature Image" value="signatureName" />
                                    <ExcelColumn label="Present Address" value="presentAddress" />
                                    <ExcelColumn label="Present Thana" value="presentThanaName" />
                                    <ExcelColumn label="Present District" value="presentDistrictName" />
                                    <ExcelColumn label="Permanent Address" value="permanentAddress" />
                                    <ExcelColumn label="Permanent Thana" value="permanentThanaName" />
                                    <ExcelColumn label="Permanent District" value="permanentDistrictName" />
                                   
                                    <ExcelColumn label="Promotion Date" value="promotionDate" />
                                    <ExcelColumn label="Promotion Status" value="promotionStatus" />
                                    <ExcelColumn label="Joining Post" value="joiningPost" />
                                    
                                    <ExcelColumn label="Last Promotion Date" value="lastPromotionDate" />
                                    <ExcelColumn label="Last Promoted Post" value="lastPromotedPost" />
                                    <ExcelColumn label="Main Job Responsibility" value="mainJobResponsibility" />
                                   
                                    
                                </ExcelSheet>
                            </ExcelFile>


                    <Button type="primary" icon={<DownloadOutlined />} onClick={() => exportPdf()} >PDF Download</Button>
                </Space>
            </Card>
        </>
    )
}

