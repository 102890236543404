import { DeleteOutlined, EditOutlined, EyeOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Descriptions, Form, Input, Modal, notification, Popconfirm, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import moment from 'moment';

export default function UpayPgwProblemView() {
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();

    const ofpsTransactionProblemView = useStoreState((state) => state.customerSupport.upayPgwTransactionProblemList);
    const ofpsTransactionProblemCheck = useStoreState((state) => state.customerSupport.upayPgwTransactionProblemCheckView);
    const isofps = useStoreState((state) => state.customerSupport.isofps);
    const fetchofpsTransactionProblemView = useStoreActions((state) => state.customerSupport.fetchUpayPgwTransactionProblemList);
    const fetchofpsTransactionProblemCheck = useStoreActions((state) => state.customerSupport.fetchUpayPgwTransactionProblemCheckView);
    const solveOfpsTransactionProblem = useStoreActions((state) => state.customerSupport.solveUpayPgwTransactionProblem);
    const [txnId, setTxnId] = useState<any>(null);
    const [upayInvoiceId, setUpayInvoiceId] = useState<any>(null);

    const handleSolve = () => {

        let postData = {
            "upayInvoiceId": upayInvoiceId,
        };

        solveOfpsTransactionProblem(postData);
        setIsModalVisible(false);
        setTimeout(() => {
            fetchofpsTransactionProblemView(data);
        }, 2000);
    }


    const [data, setData] = useState<any>({});
    const formSubmit = (value) => {

        value.trnDate = moment(value?.trnDate).format("YYYY-MM-DD")
        fetchofpsTransactionProblemView(value);
        setData(value);
    }

    useEffect(() => {
        if (isofps === true) {
            setIsModalVisible(true)
        }
    }, [isofps])

    const columns = [

        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true
        },
        
        {
            title: 'Log Date',
            dataIndex: 'logDate',
            key: 'logDate',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Trx ID',
            dataIndex: 'trxId',
            key: 'trxId',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'TXN ID',
            dataIndex: 'txnId',
            key: 'txnId',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Upay InvoiceId',
            dataIndex: 'upayInvoiceId',
            key: 'upayInvoiceId',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Session Id',
            dataIndex: 'sessionId',
            key: 'sessionId',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Request Staus',
            dataIndex: 'requestStaus',
            key: 'requestStaus',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Check',
            key: 'id',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Check Problem">
                        <Button type='primary' icon={<EyeOutlined />} onClick={() => {
                            notification.info({ message: "Please Wait", description: "Loading...", duration: 1 });
                            fetchofpsTransactionProblemCheck(record.txnId);
                            setTxnId(record.txnId);
                            setUpayInvoiceId(record.upayInvoiceId);

                        }}
                        />
                    </Tooltip>

                </Space>
            ),
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <>
            <Card title="OFPS View (Upay-Pgw)" >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="customStudentId"
                                label="Student Id"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input student id" },
                                ]}
                            >

                                <Input placeholder="Student Id" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="instituteId"
                                label="Institute Id"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input institute id" },
                                ]}
                            >

                                <Input placeholder="Institute Id" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="trnDate"
                                label="Tran. Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select tran date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    </Row>
                </Form>

                {ofpsTransactionProblemView?.length > 0 &&
                    <div className="datatable-responsive">
                        <Table
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: ofpsTransactionProblemView,
                                filterData: ofpsTransactionProblemView,
                                pagination: false,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                }
            </Card>

            <Modal
                title="OFPS (Upay PGW)"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                centered
                footer={[
                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                        <Button key="1" type="primary" style={{ background: "#800080", borderColor: "yellow" }}>
                           
                        </Button>
                        <Button key="3" type="primary" onClick={() => handleSolve()}>
                            Solve
                        </Button>
                    </div>

                ]}
            >
                <Descriptions
                    // title="User Info"
                    bordered
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                >
                    <Descriptions.Item label="TXN ID">{ofpsTransactionProblemCheck?.txnId}</Descriptions.Item>
                    <Descriptions.Item label="TRX ID">{ofpsTransactionProblemCheck?.trxId}</Descriptions.Item>
                    <Descriptions.Item label="InvoiceId">{ofpsTransactionProblemCheck?.invoiceId}</Descriptions.Item>
                    <Descriptions.Item label="SessionId">{ofpsTransactionProblemCheck?.sessionId}</Descriptions.Item>
                    <Descriptions.Item label="Status">{ofpsTransactionProblemCheck?.status}</Descriptions.Item>
                    <Descriptions.Item label="Amount">{ofpsTransactionProblemCheck?.amount}</Descriptions.Item>
                    <Descriptions.Item label="Merchant Name">{ofpsTransactionProblemCheck?.merchantName}</Descriptions.Item>
                    <Descriptions.Item label="Customer Wallet">{ofpsTransactionProblemCheck?.customerWallet}</Descriptions.Item>
                    <Descriptions.Item label="Date">{ofpsTransactionProblemCheck?.date}</Descriptions.Item>
                </Descriptions>

            </Modal>
        </>
    )
}
