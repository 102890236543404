import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Radio, DatePicker, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import SessionYear from '../Students/settings/SessionYear.page';
import { SelectAcademicYear } from '../../select/SelectAcademicYear';
import { DownloadOutlined, EditOutlined, PlusOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import testimonialsOne from '../tesimonial/tcSVG/Transfer_certificate_1.svg';
import testimonialsTwo from '../tesimonial/tcSVG/Transfer_certificate_2.svg';
import testimonialsThree from '../tesimonial/tcSVG/Transfer_certificate_3.svg';
import testimonialsFour from '../tesimonial/tcSVG/Transfer_certificate_4.svg';
import testimonialsFive from '../tesimonial/tcSVG/Transfer_certificate_5.png';
import moment from 'moment';
export default function TetimonialAddDownload() {
    const [searchForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const fetchTransferCertificateList = useStoreActions((state) => state.testimonials.fetchTransferCertificateList);
    const transferCertificateList = useStoreState((state) => state.testimonials.transferCertificateList);
    const updateTransferCertificate = useStoreActions((state) => state.testimonials.updateTransferCertificate)
    const fetchTransferCertificatePdf = useStoreActions((state) => state.testimonials.fetchTransferCertificatePdf);
    const [transferCertificateId, setTRansferCertificateId] = useState<any>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
    const [transferCertificateTemplateId, setTransferCertificateTemplateId] = useState<any>();
    const [academicYear, setAcademicYear] = useState<any>();
    const loading = useStoreState((state) =>  state.testimonials.loading);

    const searchStudentForm = (value) => {
        fetchTransferCertificateList(value.academic_year);
        setAcademicYear(value.academic_year);
    }

    const downloadTetimonials = (id) => {
        
        let postData:any = {
            "templateNo" : transferCertificateTemplateId,
            "tcId" : transferCertificateId
        }
        fetchTransferCertificatePdf(postData);
    }

    const updateSubmitForm = (value) => {
        let updateData:any = {
            postData: {
                "leftDate": moment(value?.leftDate, 'dd/mm/yyyy'),
                "tcReason": value.tc_reason,
                "tcId": transferCertificateId,
            },
            academicYear : academicYear
        }
        
        updateTransferCertificate(updateData);
        setIsModalVisible(false);
    }

    const column = [
        { title: 'Serial', dataIndex: 'tcSerial', key: 'tcSerial', showOnResponse: true, showOnDesktop: true  },
        { title: 'Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true},
        { title: 'Class', dataIndex: 'className', key: 'className', showOnResponse: true, showOnDesktop: true},
        { title: 'Shift', dataIndex: 'shiftName', key: 'shiftName', showOnResponse: true, showOnDesktop: true},
        { title: 'Section', dataIndex: 'sectionName', key: 'sectionName', showOnResponse: true, showOnDesktop: true},
        { title: 'TC Reason', dataIndex: 'tcReason', key: 'tcReason', showOnResponse: true, showOnDesktop: true},
        { title: 'TC Issue Date', dataIndex: 'tcIssueDate', key: 'tcIssueDate', showOnResponse: true, showOnDesktop: true},
        { title: 'Left Date', dataIndex: 'leftDate', key: 'leftDate', showOnResponse: true, showOnDesktop: true},
        { title: 'Action', dataIndex: 'tcId', key: 'tcId', showOnResponse: true, showOnDesktop: true,
          render: (text, record:any, index) => (
            <Space size="middle">
                <Tooltip title="Edit">
                    <Button type='primary' icon={<EditOutlined />} onClick={() => {
                        setIsModalVisible(true);
                        setTRansferCertificateId(record.tcId);
                        updateForm.setFieldsValue({
                            tc_reason:record.tcReason,
                            leftDate:moment(record.leftDate, 'dd/mm/yyyy')
                        });
                    }} />
                </Tooltip>
                <Button type='primary' icon={<DownloadOutlined />} onClick={() => {
                    setIsDownloadModalVisible(true);
                    setTRansferCertificateId(record.tcId);
                }}/>

            </Space>
          ),
        },
        
    ];

    
    return (
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                    <Form
                        layout="vertical"
                        id="sessionInfo"
                        onFinish={searchStudentForm}
                        form={searchForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset:6 }} xl={{ span: 10, offset:6 }}>
                                <Form.Item
                                    name="academic_year"
                                    label="Academic Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select academic year" },
                                    ]}
                                >
                                    <SelectAcademicYear />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Button type="primary" htmlType="submit" icon={<SearchOutlined />} style={{ float: "right" }}>
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            < Skeleton loading={loading} paragraph={{rows:10}} />
            <Row className={transferCertificateList.length > 0 ? '' : 'd-none'}>
                {!loading &&
                <Col span={24}>
                    <TableView
                        antTableProps={{
                            showHeader: true,
                            columns:column,
                            rowKey:"testimonialId",
                            dataSource: transferCertificateList,
                            filterData: transferCertificateList,
                            pagination: true,
                            bordered: true
                        }}
                        mobileBreakPoint={768}
                    />
                </Col>
                }
            </Row>
            <Modal
                title="Update"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={updateSubmitForm}
                    form={updateForm}
                >
                    <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                                name="tc_reason"
                                label="Tc Reason"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write Tc Reason" },
                                ]}
                            >
                                <Input placeholder="Tc Reason" />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                            name="leftDate"
                            label="Left Date"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select late date" },
                            ]}
                        >
                            <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                title="Select Testimonial"
                visible={isDownloadModalVisible}
                onOk={downloadTetimonials}
                onCancel={() => setIsDownloadModalVisible(false)}
                width={1000}
                className="testimonial-modal-wrapper"
                okText="Download"
            >
                <Radio.Group name="radiogroup" defaultValue={1} style={{ width: "100%"}}>
                    <Row>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }} className="text-center">
                            <Radio onChange={(e) => setTransferCertificateTemplateId(1)}>
                                <img src={testimonialsOne} alt="testimonials-1" />    
                                <p>Template 1 (Vertical)</p>
                            </Radio>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }} className="text-center">
                            <Radio value={2} onChange={(e) => setTransferCertificateTemplateId(e.target.value)}>
                                <img src={testimonialsTwo} alt="testimonials-2" />    
                                <p>Template 2 (Vertical)</p>
                            </Radio>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }} className="text-center">
                            <Radio value={3} onChange={(e) => setTransferCertificateTemplateId(e.target.value)}>
                                <img src={testimonialsThree} alt="testimonials-3" />    
                                <p>Template 3 (Horizental)</p>
                            </Radio>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }} className="text-center">
                            <Radio value={4} onChange={(e) => setTransferCertificateTemplateId(e.target.value)}>
                                <img src={testimonialsFour} alt="testimonials-1" />    
                                <p>Template Two (Horizontal)</p>
                            </Radio>
                        </Col>                        
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }} className="text-center">
                            <Radio value={5} onChange={(e) => setTransferCertificateTemplateId(e.target.value)}>
                                <img src={testimonialsFive} alt="testimonials-1" />    
                                <p>Template Three</p>
                            </Radio>
                        </Col>
                    </Row>
                </Radio.Group>
            </Modal>
        </>
    )
}