import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Space } from 'antd'
import { DeleteOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
export default function Invoice(props) {

    const feeInvoiceList = useStoreState((state) => state.saccount.feeInvoiceList);

    const fetchfeeInvoiceList = useStoreActions((state) => state.saccount.fetchfeeInvoiceList);
    const deletefeeInvoiceList = useStoreActions((state) => state.saccount.deletefeeInvoiceList);

    const [deleteForm] = Form.useForm();

    const deleteFormSubmit = (value) => {
        fetchfeeInvoiceList(value.invoiceId);
    };
    const columns = [
        {
            title: 'Fee Head',
            dataIndex: 'feeHeadName',
            key: 'feeHeadName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Fee Sub Head',
            dataIndex: 'feeSubHeadName',
            key: 'feeSubHeadName',
            showOnResponse: true,
            showOnDesktop: true,

        },
        {
            title: 'Payable',
            dataIndex: 'payableAmount',
            key: 'payableAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Paid',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Due',
            dataIndex: 'dueAmount',
            key: 'dueAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },
    ]



    return (
        <>
            <Card title="Invoice Delete" >
                <Form
                    layout="vertical"
                    onFinish={deleteFormSubmit}
                    id="basic-info"
                    form={deleteForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="invoiceId"
                                label="Invoice ID"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input invoice id" },
                                ]}
                            >
                                <Input
                                    placeholder="Invoice ID"
                                />

                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>
                <br />
                {feeInvoiceList != null &&
                    <>
                        <Row gutter={8}>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <span style={{ fontWeight: "bold" }}>Student ID</span>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                {feeInvoiceList.customStudentId}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <span style={{ fontWeight: "bold" }}>Roll</span>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                {feeInvoiceList.studentRoll}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <span style={{ fontWeight: "bold" }}>Name</span>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                {feeInvoiceList.studentName}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <span style={{ fontWeight: "bold" }}>Section</span>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                {feeInvoiceList.sectionName}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <span style={{ fontWeight: "bold" }}>Generated Date</span>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                {feeInvoiceList.generatedDate}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <span style={{ fontWeight: "bold" }}>Payment Date</span>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                {feeInvoiceList.paymentDate}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <span style={{ fontWeight: "bold" }}>Payable Amount</span>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                {feeInvoiceList.payableAmount}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <span style={{ fontWeight: "bold" }}>Due Amount</span>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                {feeInvoiceList.dueAmount}
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <span style={{ fontWeight: "bold" }}>Paid Amount</span>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                {feeInvoiceList.paidAmount}
                            </Col>


                        </Row>
                        <br />

                        <div className="table-responsive">
                            <Table
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: feeInvoiceList?.detailsList,
                                    filterData: feeInvoiceList?.detailsList,
                                    pagination: true,
                                    bordered: true,
                                    rowKey: "studentId",
                                }}
                                mobileBreakPoint={768}

                            />
                        </div>
                        <Button danger icon={<DeleteOutlined />} onClick={() => deletefeeInvoiceList(feeInvoiceList?.masterId)} style={{ float: "right" }}>Delete Invoice</Button>
                    </>
                }

            </Card>
        </>
    )
}
