
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton, Tabs, Avatar } from 'antd'
import { DeleteOutlined, DownloadOutlined, EditOutlined, EyeOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import TableView from '../../../../contents/AntTableResponsive';
import moment from 'moment';
import { SelectClassConfig } from '../../../select/SelectClassConfig';

const { TabPane } = Tabs;
const { Option } = Select;


export default function StudentProfileUpdate() {

    const [sectionWiseReportForm] = Form.useForm();
    const fetchSectionsWiseReport = useStoreActions((state) => state.student.fetchSectionsWiseReport4);
    const sectionWiseListReport = useStoreState((state) => state.student.sectionWiseListReport4);
    const loading = useStoreState((state) => state.student.loading);

    const formSubmit = (value) => {
        let postData: any = {
            academicYear: value.yearId,
            classconfigid: value.sectionId
        }
        fetchSectionsWiseReport(postData);
    }

    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        { title: 'Group', dataIndex: 'groupName', key: 'groupName', showOnResponse: true, showOnDesktop: true },
        { title: 'Father', dataIndex: 'fatherName', key: 'fatherName', showOnResponse: true, showOnDesktop: true },
        { title: 'Mother', dataIndex: 'motherName', key: 'motherName', showOnResponse: true, showOnDesktop: true },
        { title: 'Gender', dataIndex: 'studentGender', key: 'studentGender', showOnResponse: true, showOnDesktop: true },
        { title: 'Religion', dataIndex: 'studentReligion', key: 'studentReligion', showOnResponse: true, showOnDesktop: true },
        { title: 'Mobile No.', dataIndex: 'guardianMobile', key: 'guardianMobile', showOnResponse: true, showOnDesktop: true },
        {
            title: 'Action', dataIndex: 'fatherName', key: 'fatherName', fixed: 'right', showOnResponse: true, showOnDesktop: true, render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Button type='primary' icon={<EditOutlined />} onClick={() => {
                        setIsModalVisible(true);
                        setModalHeader(record.studentName);
                        fetchstudentAllInfo({
                            customStudentId: record?.customStudentId,
                            instituteId: instituteInfo?.instiltuteId,
                        })
                    }} />
                </Space>
            ),
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalHeader, setModalHeader] = useState<any>();
    const fetchstudentAllInfo = useStoreActions((state) => state.masterSettings.fetchstudentAllInfo);
    const studentAllInfo = useStoreState(state => state.masterSettings.studentAllInfo)
    const instituteInfo = useStoreState(state => state.auth.instituteInfo)

    ///
    const [formBasic] = Form.useForm();
    const [formGuardian] = Form.useForm();
    const [formAddress] = Form.useForm();
    const [updateBasic, setUpdateBasic] = useState<boolean>(false);
    //const [date, setDate] = useState<any>((studentAllInfo?.studentDOB !== null || studentAllInfo?.studentDOB !== undefined || studentAllInfo?.studentDOB !== '') ? studentAllInfo?.studentDOB : null)
    const [studentImageFileName, setStudentImageFileName] = useState<any>(null);
    const [studentImageFileContent, setStudentImageFileContent] = useState<any>(null);
    const [fatherPhotoName, setfatherPhotoName] = useState<any>(null);
    const [fatherPhotoFileContent, setfatherPhotoFileContent] = useState<any>(null);
    const [motherPhotoName, setmotherPhotoName] = useState<any>(null);
    const [motherPhotoFileContent, setmotherPhotoFileContent] = useState<any>(null);

    const updateStudentProfileBasicInfo = useStoreActions(state => state.common.updateStudentProfileBasicInfo);
    const updateStudentGuardianInfo = useStoreActions(state => state.common.updateStudentGuardianInfo);

    const imageUpload = (e: any) => {
        e.preventDefault();
        const reader: any = new FileReader();
        const file = e.target.files[0];
        console.log(file)
        if (reader !== undefined && file !== undefined) {
            reader.onloadend = () => {
                setStudentImageFileName(file.name)
                setStudentImageFileContent(reader.result.split(',')[1])
            }
            reader.readAsDataURL(file);
        }
    };
    const imageUploadFather = (e: any) => {
        e.preventDefault();
        const reader: any = new FileReader();
        const file = e.target.files[0];
        console.log(file)
        if (reader !== undefined && file !== undefined) {
            reader.onloadend = () => {
                setfatherPhotoName(file.name)
                setfatherPhotoFileContent(reader.result.split(',')[1])
            }
            reader.readAsDataURL(file);
        }
    };
    const imageUploadMother = (e: any) => {
        e.preventDefault();
        const reader: any = new FileReader();
        const file = e.target.files[0];
        console.log(file)
        if (reader !== undefined && file !== undefined) {
            reader.onloadend = () => {
                setmotherPhotoName(file.name)
                setmotherPhotoFileContent(reader.result.split(',')[1])
            }
            reader.readAsDataURL(file);
        }
    };

    const onFinishBasic = (values: any) => {
        console.log(values)
        // console.log( moment(values?.studentDOB?._d).format("YYYY-MM-DD") );

        let payload = {
            "birthCertificateNo": values.birthCertificateNo,
            "bloodGroup": values.bloodGroup,
            "instituteId": studentAllInfo?.instituteId,
            // "mailingAddress": values.mailingAddress,
            "studentDOB": moment(values?.studentDOB?._d).format("YYYY-MM-DD"),
            "studentGender": values.studentGender,
            "studentId": studentAllInfo?.studentId,
            "customStudentId": studentAllInfo?.customStudentId,
            "studentName": values.studentName,
            "studentReligion": values.studentReligion,
            "studentImageFileContent": studentImageFileContent !== null ? studentImageFileContent : studentAllInfo?.studentImageFileContent !== null ? studentAllInfo?.imageName : 'R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
            "studentImageFileName": studentImageFileName !== null ? studentImageFileName : 'Test.jpg',
        }
        updateStudentProfileBasicInfo(payload)
        //setUpdateBasic(false)
        setStudentImageFileName(null);
        setStudentImageFileContent(null);
        setTimeout(() => {
            sectionWiseReportForm.submit()
        }, 1000);
    };

    const [updateGuardian, setUpdateGuardian] = useState<boolean>(false);
    const onFinishGuardian = (values: any) => {
        values.fatherPhotoName = fatherPhotoName !== null ? fatherPhotoName : 'Father.jpg';
        values.motherPhotoName = motherPhotoName !== null ? motherPhotoName : 'Father.jpg';
        values.fatherPhotoFileContent = fatherPhotoFileContent !== null ? fatherPhotoFileContent : studentAllInfo?.fatherPhotoFileContent !== null ? studentAllInfo?.fatherPhoto : 'R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
        values.motherPhotoFileContent = motherPhotoFileContent !== null ? motherPhotoFileContent : studentAllInfo?.motherPhotoFileContent !== null ? studentAllInfo?.motherPhoto : 'R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
        values.studentId = studentAllInfo?.studentId;
        values.instituteId = studentAllInfo?.instituteId;
        values.customStudentId = studentAllInfo?.customStudentId;
        
        values.fatherPhotoSave = fatherPhotoFileContent !== null ? true : false;
        values.motherPhotoSave = motherPhotoFileContent !== null ? true : false;
        
        updateStudentGuardianInfo(values);
       // setUpdateGuardian(false);
        setfatherPhotoFileContent(null);
        setfatherPhotoName(null);
        setTimeout(() => {
            sectionWiseReportForm.submit()
        }, 1000);
    }



    return (
        <>
            <Card title="Section Wise Student List">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={sectionWiseReportForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="yearId"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="sectionId"
                                        label="Select Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: sectionWiseListReport.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: studentListColumn,
                                rowKey: "identificationId",
                                dataSource: sectionWiseListReport,
                                filterData: sectionWiseListReport,
                                pagination: true,
                                bordered: true,
                            }}
                            mobileBreakPoint={768}
                        />
                        {/* <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} onClick={() => deleteStudent()} className="float-right">Delete</Button> */}
                    </Col>

                </Row>

            </Card>
            <Modal
                title={modalHeader}
                visible={isModalVisible}
                // onOk={() => setIsModalVisible(false)}
                // okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => {
                    setIsModalVisible(false);
                    setUpdateBasic(false);
                    setUpdateGuardian(false)
                }}
                okButtonProps={{ style: { display: 'none' } }}
                cancelText="Close"
                centered
                maskClosable={false}
                width="70%"
                destroyOnClose={true}
            >

                <Row>
                    <Col span={24}>


                        <Tabs tabPosition="left">
                            <TabPane tab="Basic Information" key="1">
                                <Row>
                                    <Col span={24}>
                                        <div style={{ textAlign: "right", marginBottom: 10 }}>
                                            <Button type='primary' onClick={() => {
                                                setUpdateBasic(true);
                                                formBasic.setFieldsValue({
                                                    studentName: studentAllInfo?.studentName,
                                                    studentGender: studentAllInfo?.studentGender,
                                                    studentReligion: studentAllInfo?.studentReligion,
                                                    studentDOB: studentAllInfo?.studentDOB !== null ? moment(studentAllInfo?.studentDOB, 'YYYY/MM/DD') : null,
                                                    bloodGroup: studentAllInfo?.bloodGroup,
                                                    birthCertificateNo: studentAllInfo?.birthCertificateNo,

                                                })
                                            }} icon={<EditOutlined />}>Edit</Button>
                                        </div>

                                    </Col>
                                    <Col span={24}>
                                        <Form
                                            name="basic"
                                            labelCol={{ span: 10 }}
                                            wrapperCol={{ span: 14 }}
                                            autoComplete="off"
                                            onFinish={onFinishBasic}
                                            form={formBasic}
                                        >
                                            <Form.Item
                                                label="Name"
                                                name="studentName"
                                                labelAlign="left"
                                                rules={[{ required: updateBasic ? true : false, message: 'Please input your name' }]}
                                            >
                                                {updateBasic ? <Input style={{ height: 40 }} /> : <span>{studentAllInfo?.studentName}</span>}

                                            </Form.Item>

                                            <Form.Item
                                                label="Gender"
                                                name="studentGender"
                                                labelAlign="left"
                                                rules={[{ required: updateBasic ? true : false, message: 'Please select gender' }]}
                                            >
                                                {updateBasic ? <Select
                                                    placeholder="Select Gender"
                                                    className="selectBasic"
                                                >
                                                    <Option value="Male">Male</Option>
                                                    <Option value="Female">Female</Option>
                                                </Select> : <span>{studentAllInfo?.studentGender}</span>}
                                            </Form.Item>

                                            <Form.Item
                                                label="Religion"
                                                name="studentReligion"
                                                labelAlign="left"
                                                rules={[{ required: updateBasic ? true : false, message: 'Please select religion' }]}
                                            >
                                                {updateBasic ? <Select
                                                    placeholder="Select Religion"
                                                    className="selectBasic"
                                                >
                                                    <Option value="Islam">Islam</Option>
                                                    <Option value="Hinduism">Hinduism</Option>
                                                    <Option value="Christian">Christian</Option>
                                                    <Option value="Buddist">Buddist</Option>
                                                    <Option value="Other">Other</Option>
                                                </Select> : <span>{studentAllInfo?.studentReligion}</span>}
                                            </Form.Item>

                                            <Form.Item
                                                label="Birthday"
                                                name="studentDOB"
                                                labelAlign="left"
                                                rules={[{ required: updateBasic ? true : false, message: 'Please select date' }]}
                                            >
                                                {updateBasic ? <DatePicker style={{ width: "100%", height: 40 }} value={studentAllInfo?.studentDOB ? moment(studentAllInfo?.studentDOB, 'YYYY/MM/DD') : moment((new Date()), "DD/MM/YYYY")} /> : <span>{studentAllInfo?.studentDOB}</span>}
                                            </Form.Item>

                                            <Form.Item
                                                label="Blood Group"
                                                name="bloodGroup"
                                                labelAlign="left"
                                                rules={[{ required: updateBasic ? true : false, message: 'Please select blood group' }]}
                                            >
                                                {updateBasic ? <Select
                                                    placeholder="Select Blood Group"
                                                    className="selectBasic"
                                                >
                                                    <Option value="A+">A+</Option>
                                                    <Option value="A-">A-</Option>
                                                    <Option value="AB+">AB+</Option>
                                                    <Option value="AB-">AB-</Option>
                                                    <Option value="B+">B+</Option>
                                                    <Option value="B-">B-</Option>
                                                    <Option value="O+">O+</Option>
                                                    <Option value="O-">O-</Option>

                                                </Select> : <span>{studentAllInfo?.bloodGroup}</span>}
                                            </Form.Item>
                                            <Form.Item
                                                label="Birth Certificate No"
                                                name="birthCertificateNo"
                                                labelAlign="left"


                                            >
                                                {updateBasic ? <Input placeholder='Birth Certificate No' style={{ height: 40 }} /> : <span>{studentAllInfo?.birthCertificateNo}</span>}
                                            </Form.Item>



                                            <div>
                                                <Row gutter={8}>
                                                    <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                                                        <strong>Student Photo: </strong>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={14} xl={14}>

                                                        {updateBasic ? <Input style={{ marginLeft: 0 }} type={'file'} accept=".png, .jpg, .jpeg" onChange={(e) => { imageUpload(e) }} /> : <Avatar
                                                            src={'data:image/png;base64,' + studentAllInfo?.imageName}
                                                            size={'large'}
                                                            style={{ marginLeft: 0 }}
                                                            className="pointer topUserImage"
                                                        />}

                                                    </Col>
                                                </Row>
                                            </div>
                                            {updateBasic &&
                                                <Form.Item style={{ float: "right", marginTop: 20, marginBottom: -10 }}>
                                                    <Space size={"middle"}>

                                                        <Button type="primary" htmlType="submit">
                                                            Update
                                                        </Button>
                                                    </Space>
                                                </Form.Item>
                                            }
                                        </Form>
                                    </Col>
                                </Row>

                            </TabPane>

                            <TabPane tab="Guardian Information" key="2">
                                <Row>
                                    <Col span={24}>
                                        <div style={{ textAlign: "right", marginBottom: 10 }}>
                                            <Button type='primary' onClick={() => {
                                                setUpdateGuardian(true);
                                                formGuardian.setFieldsValue({
                                                    fatherName: studentAllInfo?.fatherName,
                                                    fatherOccupation: studentAllInfo?.fatherOccupation,
                                                    fatherDesignation: studentAllInfo?.fatherDesignation,
                                                    fatherWorkingPlace: studentAllInfo?.fatherWorkingPlace,
                                                    fatherNid: studentAllInfo?.fatherNid,
                                                    motherName: studentAllInfo?.motherName,
                                                    motherOccupation: studentAllInfo?.motherOccupation,
                                                    motherDesignation: studentAllInfo?.motherDesignation,
                                                    motherWorkingPlace: studentAllInfo?.motherWorkingPlace,
                                                    motherNid: studentAllInfo?.motherNid,
                                                    guardianMobile: studentAllInfo?.guardianMobile,

                                                })
                                            }} icon={<EditOutlined />}>Edit</Button>
                                        </div>

                                    </Col>
                                    <Col span={24}>
                                        <Form
                                            name="guardianInfo"
                                            labelCol={{ span: 10 }}
                                            wrapperCol={{ span: 14 }}
                                            // initialValues={{ remember: true }}
                                            onFinish={onFinishGuardian}
                                            form={formGuardian}
                                            // onFinishFailed={onFinishFailed}
                                            autoComplete="off"
                                        >
                                            <Form.Item
                                                label="Father's Name"
                                                name="fatherName"
                                                labelAlign="left"
                                                rules={[{ required: updateGuardian ? true : false, message: "Please input father's name" }]}
                                            >
                                                {updateGuardian ? <Input placeholder="Father's Name" style={{ height: 40 }} /> : <span>{studentAllInfo?.fatherName}</span>}
                                            </Form.Item>

                                            <Form.Item
                                                label="Father's Profession"
                                                name="fatherOccupation"
                                                labelAlign="left"
                                                rules={[{ required: updateGuardian ? true : false, message: "Please select father occupation" }]}
                                            >
                                                {updateGuardian ? <Select
                                                    placeholder="Select Profession"
                                                    className="selectBasic"
                                                >
                                                    <Option value="Business">Business</Option>
                                                    <Option value="Service">Service</Option>
                                                    <Option value="Private Service">Private Service</Option>
                                                    <Option value="Govt. Service">Govt. Service</Option>
                                                    <Option value="Teacher">Teacher</Option>
                                                    <Option value="Banker">Banker</Option>
                                                    <Option value="Doctor">Doctor</Option>
                                                    <Option value="Engineer">Engineer</Option>
                                                    <Option value="Lawyer">Lawyer</Option>
                                                    <Option value="Journalist">Journalist</Option>
                                                    <Option value="Farmer">Farmer</Option>
                                                    <Option value="Driver">Driver</Option>
                                                    <Option value="Phycian">Phycian</Option>
                                                    <Option value="Student">Student</Option>
                                                    <Option value="Army">Army</Option>
                                                    <Option value="Police Officer">Police Officer</Option>
                                                    <Option value="Navy">Navy</Option>
                                                    <Option value="Airforce">Airforce</Option>
                                                    <Option value="Retired Person">Retired Person</Option>
                                                    <Option value="NRB">NRB</Option>
                                                    <Option value="BGB">BGB</Option>
                                                    <Option value="N/A">N/A</Option>
                                                    <Option value="NSI">NSI</Option>
                                                </Select> : <span>{studentAllInfo?.fatherOccupation}</span>}
                                            </Form.Item>

                                            <Form.Item
                                                label="Father's Designation"
                                                name="fatherDesignation"
                                                labelAlign="left"
                                                rules={[{ required: updateGuardian ? true : false, message: "Please input father's designation " }]}
                                            >
                                                {updateGuardian ? <Input placeholder="Father's Designation " style={{ height: 40 }} /> : <span>{studentAllInfo?.fatherDesignation}</span>}
                                            </Form.Item>

                                            <Form.Item
                                                label="Father's Workplace"
                                                name="fatherWorkingPlace"
                                                labelAlign="left"
                                                rules={[{ required: updateGuardian ? true : false, message: "Please input father's working place " }]}
                                            >
                                                {updateGuardian ? <Input placeholder="Father's Working Place " style={{ height: 40 }} /> : <span>{studentAllInfo?.fatherWorkingPlace}</span>}
                                            </Form.Item>

                                            <Form.Item
                                                label="Father's NID"
                                                name="fatherNid"
                                                labelAlign="left"
                                                rules={[{ required: updateGuardian ? true : false, message: "Please input father's nid" }]}
                                            >
                                                {updateGuardian ? <Input placeholder="Father's NID " style={{ height: 40 }} /> : <span>{studentAllInfo?.fatherNid}</span>}
                                            </Form.Item>

                                            <div>
                                                <Row gutter={8}>
                                                    <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                                                        <span>Father's Photo: </span>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                                                        {updateGuardian ? <Input type={'file'} accept=".png, .jpg, .jpeg" onChange={(e) => { imageUploadFather(e) }} /> : <Avatar
                                                            src={studentAllInfo?.fatherPhotoLink}
                                                            size={'large'}
                                                            style={{ marginRight: 5 }}
                                                            className="pointer topUserImage"
                                                        />}
                                                    </Col>
                                                </Row>
                                                <br />
                                            </div>

                                            <Form.Item
                                                label="Mother's Name"
                                                name="motherName"
                                                labelAlign="left"
                                                rules={[{ required: updateGuardian ? true : false, message: "Please input mother's name" }]}
                                            >
                                                {updateGuardian ? <Input placeholder="Mother's Name" style={{ height: 40 }} /> : <span>{studentAllInfo?.motherName}</span>}
                                            </Form.Item>

                                            <Form.Item
                                                label="Mother's Profession"
                                                name="motherOccupation"
                                                labelAlign="left"
                                                rules={[{ required: updateGuardian ? true : false, message: "Please select mother's occupation" }]}
                                            >
                                                {updateGuardian ? <Select
                                                    placeholder="Select Profession"
                                                    className="selectBasic"
                                                >
                                                    <Option value="House Wife">House Wife</Option>
                                                    <Option value="Business">Business</Option>
                                                    <Option value="Service">Service</Option>
                                                    <Option value="Private Service">Private Service</Option>
                                                    <Option value="Govt. Service">Govt. Service</Option>
                                                    <Option value="Teacher">Teacher</Option>
                                                    <Option value="Banker">Banker</Option>
                                                    <Option value="Doctor">Doctor</Option>
                                                    <Option value="Engineer">Engineer</Option>
                                                    <Option value="Lawyer">Lawyer</Option>
                                                    <Option value="Journalist">Journalist</Option>
                                                    <Option value="Farmer">Farmer</Option>
                                                    <Option value="Driver">Driver</Option>
                                                    <Option value="Phycian">Phycian</Option>
                                                    <Option value="Student">Student</Option>
                                                    <Option value="Army">Army</Option>
                                                    <Option value="Police Officer">Police Officer</Option>
                                                    <Option value="Navy">Navy</Option>
                                                    <Option value="Airforce">Airforce</Option>
                                                    <Option value="Retired Person">Retired Person</Option>
                                                    <Option value="NRB">NRB</Option>
                                                    <Option value="BGB">BGB</Option>
                                                    <Option value="N/A">N/A</Option>
                                                    <Option value="NSI">NSI</Option>
                                                </Select> : <span>{studentAllInfo?.motherOccupation}</span>}
                                            </Form.Item>

                                            <Form.Item
                                                label="Mother's Designation"
                                                name="motherDesignation"
                                                labelAlign="left"
                                                rules={[{ required: updateGuardian ? true : false, message: "Please input mother's designation " }]}
                                            >
                                                {updateGuardian ? <Input placeholder="Mother's Designation " style={{ height: 40 }} /> : <span>{studentAllInfo?.motherDesignation}</span>}
                                            </Form.Item>

                                            <Form.Item
                                                label="Mother's Workplace"
                                                name="motherWorkingPlace"
                                                labelAlign="left"
                                                rules={[{ required: updateGuardian ? true : false, message: "Please input mother's working place " }]}
                                            >
                                                {updateGuardian ? <Input placeholder="Mother's Working Place " style={{ height: 40 }} /> : <span>{studentAllInfo?.motherWorkingPlace}</span>}
                                            </Form.Item>

                                            <Form.Item
                                                label="Mother's NID"
                                                name="motherNid"
                                                labelAlign="left"
                                                rules={[{ required: updateGuardian ? true : false, message: "Please input mother's nid" }]}
                                            >
                                                {updateGuardian ? <Input placeholder="Mother's NID " style={{ height: 40 }} /> : <span>{studentAllInfo?.motherNid}</span>}
                                            </Form.Item>

                                            <div>
                                                <Row gutter={8}>
                                                    <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                                                        <span>Mother's Photo: </span>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                                                        {updateGuardian ? <Input type={'file'} accept=".png, .jpg, .jpeg" onChange={(e) => { imageUploadMother(e) }} /> : <Avatar
                                                            src={studentAllInfo?.motherPhotoLink}
                                                            size={'large'}
                                                            style={{ marginRight: 5 }}
                                                            className="pointer topUserImage"
                                                        />}
                                                    </Col>
                                                </Row>
                                                <br />
                                            </div>

                                            <Form.Item
                                                label="Guardian's Number"
                                                name="guardianMobile"
                                                labelAlign="left"
                                                rules={[{ required: updateGuardian ? true : false, message: "Please input guardian's number" }]}
                                            >
                                                {updateGuardian ? <Input placeholder="Guardian's Mobile mNumber" style={{ height: 40 }} /> : <span>{studentAllInfo?.guardianMobile}</span>}
                                            </Form.Item>
                                            {updateGuardian &&
                                                <Form.Item style={{ float: "right", marginTop: 20, marginBottom: -10 }}>
                                                    <Space size={"middle"}>
                                                        {/* <Button type="default" onClick={() => setUpdateGuardian(false)}>Close</Button> */}
                                                        <Button type="primary" htmlType="submit">
                                                            Update
                                                        </Button>
                                                    </Space>
                                                </Form.Item>
                                            }
                                        </Form>
                                    </Col>
                                </Row>
                            </TabPane>
                            {/* <TabPane tab="Address Information" key="3">
                                <Row>
                                    <Col span={24}>
                                        <Form
                                            name="address"
                                            labelCol={{ span: 10 }}
                                            wrapperCol={{ span: 14 }}
                                            autoComplete="off"
                                        >
                                            <Form.Item
                                                label="Mailing Address"
                                                name="mailingAddress"
                                                labelAlign="left"
                                            >
                                                <span>{studentAllInfo?.mailingAddress}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Present Village"
                                                name="presentVillege"
                                                labelAlign="left"
                                            >
                                                <span>{studentAllInfo?.presentVillege}</span>
                                            </Form.Item>
                                            <Form.Item
                                                label="Present Post Office"
                                                name="presentPostOffice"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.presentPostOffice}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Present District"
                                                name="presentPostOffice"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.presentDistrictName}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Present Thana"
                                                name="presentThanaId"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.presentThanaName}</span>
                                            </Form.Item>
                                            <Form.Item
                                                label="Permenant Village"
                                                name="permanentVillege"
                                                labelAlign="left"
                                            >
                                                <span>{studentAllInfo?.permanentVillege}</span>
                                            </Form.Item>
                                            <Form.Item
                                                label="Permenant Post Office"
                                                name="permanentPostOffice"
                                                labelAlign="left"
                                            >
                                                <span>{studentAllInfo?.permanentPostOffice}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Permenant District"
                                                name="presentPostOffice"
                                                labelAlign="left"
                                            >
                                                <span>{studentAllInfo?.permanentDistrictName}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Permenant Thana"
                                                name="permanentThanaId"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.permanentThanaName}</span>
                                            </Form.Item>


                                        </Form>
                                    </Col>
                                </Row>
                            </TabPane> */}
                        </Tabs>

                    </Col>
                </Row>

            </Modal>
        </>
    )
}
