import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../../store/hooks/easyPeasy';
import { Table, Tag} from 'antd';
import TableView from '../../../../../contents/AntTableResponsive';
import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../../utils/pdf';
import { FilePdfOutlined } from '@ant-design/icons';
import { SelectClassConfig } from '../../../../select/SelectClassConfig';
import $ from 'jquery';
import { SelectAcademicYear } from '../../../../select/SelectAcademicYear';
require('jspdf-autotable');

export default function AssignedList() {
    const [searchStudentForm] = Form.useForm();
    const fetchForthSubjectAssignedList = useStoreActions((state) => state.exam.fetchForthSubjectAssignedList);
    const forthSubjectAssignedList = useStoreState((state) => state.exam.forthSubjectAssignedList); 
    const [studentForthSubjectAssignedList, setStudentForthSubjectAssignedList] = useState<any>([]);
    const [studentSelected, setStudentSelected] = useState([]);
    const [studentSelectedValue, setstudentSelectedValue] = useState<any>([]);
    const deleteForthSubjectAssigned = useStoreActions((state) => state.exam.deleteForthSubjectAssigned);
    const [selectSection, setSelectSection] = useState<any>("");
    const loading = useStoreState((state) =>  state.exam.loading);
    
    const searchStudentList = (value) => {

        setSelectSection(value.classconfigid);

        let postData: any = {
            classConfigurationId: value?.classconfigid,
            academicYear : value?.academicYear,
        }

        setStudentForthSubjectAssignedList(forthSubjectAssignedList);
        fetchForthSubjectAssignedList(postData);
    }

    function exportPdf() {

        var doc = new jsPDF("p", "mm", "a4");

        var details = `Exam Fourth Subject Assigned List of ${$(".sectionSelect").text()}`;
        pdfDataL(doc, "");
        doc.text(details, 105, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);            
        };


        var col = [
            "ID",
            "Name",
            "Roll",
            "Group",
            "Subject Name",
            "Subject Status",
        ].filter(Boolean);

        var rows:any = [];


        studentForthSubjectAssignedList?.forEach(element => {
            var temp:any = [ 
                element.customStudentId,
                element.studentName, 
                element.roll,
                element.groupName,
                element.subjectName,
                element.subjectStatus,
            ];
            rows.push(temp);
        });
       

            let first = doc.autoTable.previous;
            doc.autoTable(col, rows, {
                startY: 45,
                showHeader: "firstPage",
                theme: 'grid',
                headerStyles: {
                    // fillColor: [105, 105, 105],
                    // textColor: [255, 255, 255],
                    // fontSize: 10
                    lineWidth: .01,
                    lineColor: [224, 224, 224]
                },
                styles: {
                    overflow: 'linebreak',
                },
                columnStyles: {
                    0: { overflow: 'visible', columnWidth:25 }
                },

              
            });

  
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    const assignTableColmn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true  },
        { title: 'Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true},
        { title: 'Roll', dataIndex: 'roll', key: 'roll', showOnResponse: true, showOnDesktop: true},
        { title: 'Group', dataIndex: 'groupName', key: 'groupName', showOnResponse: true, showOnDesktop: true},
        { title: 'Subject Name', dataIndex: 'subjectName', key: 'subjectName', showOnResponse: true, showOnDesktop: true},
        { title: 'Subject Status', dataIndex: 'subjectStatus', key: 'subjectStatus', showOnResponse: true, showOnDesktop: true},
    ]

    const onSelectChangeStudentSelect = (selectedRowKeys, value) => {
        setStudentSelected(selectedRowKeys);
        setstudentSelectedValue(value);
    };

    const studentRowSelection = {
        forthSubjectAssignedList,
        onChange: onSelectChangeStudentSelect,
    };

    const deleteStudent = () => {
        let postData:any = {
            studentSelected: studentSelected,
            section:selectSection
        }
        deleteForthSubjectAssigned(postData);
    }

    useEffect(() => {
        setStudentForthSubjectAssignedList(forthSubjectAssignedList);
    }, [forthSubjectAssignedList])

    return(
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                    <Form
                        layout="vertical"
                        id="sessionYearInfo"
                        onFinish={searchStudentList}
                        form={searchStudentForm}
                    >
                    <Row>
                    
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="academicYear"
                                label="Academic Year"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select academicYear" },
                                ]}
                                >
                                <SelectAcademicYear />
                                </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="classconfigid"
                                label="Section"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select section" },
                                ]}
                                >
                                    <SelectClassConfig />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }}>

                                <Button type="primary" id="searchButton" htmlType="submit" icon={<SearchOutlined />} >
                                    Search
                                </Button>

                            </Col>
                        </Row>
                    </Form>
                </Col>
                <br />
                <Skeleton loading={loading} paragraph={{rows:10}} />
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}} lg={{ span: 24}} xl={{ span: 24}} style={{ display: studentForthSubjectAssignedList.length > 0 ? "block" : "none" }}>
                    <TableView
                        antTableProps={{
                            rowSelection:studentRowSelection,
                            showHeader: true,
                            columns:assignTableColmn,
                            rowKey:"fourthSubjectAssignId",
                            dataSource: studentForthSubjectAssignedList,
                            filterData: studentForthSubjectAssignedList,
                            pagination: false,
                            bordered: true
                        }}
                        mobileBreakPoint={768}
                    />
                </Col>
                <br />
                {studentForthSubjectAssignedList.length > 0 ? 
                <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 24}} lg={{ span: 24 }} xl={{ span: 24 }}>
                    <br />
                    <Space size={"large"} style={{ float: "right" }}>
                    <Button type="primary" onClick={exportPdf}  icon={<FilePdfOutlined />}  >Download PDF</Button>
                    <Button danger icon={<DeleteOutlined />}  onClick={() => deleteStudent()} >
                        Delete
                    </Button>
                    </Space>
                </Col>
                : ''}
            </Row>
        </>
    )
}

