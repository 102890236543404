import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import SessionYear from '../Students/settings/SessionYear.page';
import { SelectAcademicYear } from '../../select/SelectAcademicYear';
import { EditOutlined } from '@ant-design/icons';

const {Option} = Select;

export default function InstituteUpdate() {

    const [instituteInfoUpdate] = Form.useForm();
    const updateinstitutePhoto =  useStoreActions((state) => state.auth.updateinstitutePhoto);
    const fetchInstiuteInfoList = useStoreActions((state) => state.masterSettings.fetchInstiuteInfoList);
    const instiuteInfoList =  useStoreState((state) =>  state.masterSettings.instiuteInfoList);
    const [academicyearData, setAcademicyearData ] = useState<any>();
    const updateinstituteInfo = useStoreActions((state) =>  state.auth.updateinstituteInfo);


    useEffect(() => {
        fetchInstiuteInfoList();
    }, []);

    useEffect(() => {
        instituteInfoUpdate.setFieldsValue({
            instituteName: instiuteInfoList.instituteName,
            instituteAddress: instiuteInfoList.address,
            adminName: instiuteInfoList.adminName,
            contactNumber: instiuteInfoList.contactNumber,
            instituteEmail: instiuteInfoList.instituteEmail,
            academicYear: instiuteInfoList.academicYear,
            eiinNo: instiuteInfoList.eiinNo,
            onlinePaymentStatus: instiuteInfoList.onlinePaymentStatus,
            feeSubheadCheckStatus: instiuteInfoList.feeSubheadCheckStatus,
            allFeeCheckStatus: instiuteInfoList.allFeeCheckStatus,
            feeAutoSms: instiuteInfoList.feeAutoSms,
            onlineProfileUpdateStatus: instiuteInfoList.onlineProfileUpdateStatus,
        });
        setAcademicyearData(instiuteInfoList.academicYear);
    }, [instiuteInfoList])

    const instituteUpdate = (value) => {
        let postData:any = {
            academicYear: value.academicYear,
            address: value.instituteAddress,
            adminName: value.adminName,
            contactNumber: value.contactNumber,
            eiinNo: value.eiinNo,
            onlinePaymentStatus: value.onlinePaymentStatus,
            feeSubheadCheckStatus: value.feeSubheadCheckStatus,
            instituteEmail: value.instituteEmail,
            instituteName: value.instituteName,
            feeAutoSms:value.feeAutoSms,
            allFeeCheckStatus: value.allFeeCheckStatus,
            onlineProfileUpdateStatus: value.onlineProfileUpdateStatus,
        }
        updateinstituteInfo(postData);
    }

    const onchangeFile = (val) => {
        updateinstitutePhoto(val.target.files[0]);        
    }

    return(
        <>
            <Card title="Institute Info Update">
                <Form
                    layout="vertical"
                    id="instructionForm"
                    onFinish={instituteUpdate}
                    form={instituteInfoUpdate}
                >
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="instituteName"
                                label="Institute Name"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter institute name" },
                                ]}
                            >
                                <Input placeholder="Institute name" defaultValue={instiuteInfoList?.instituteName} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="instituteAddress"
                                label="Institute Address"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter institute address" },
                                ]}
                            >
                                <Input placeholder="Institute address" defaultValue={ instiuteInfoList?.instituteAddress} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="adminName"
                                label="Admin Name"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter admin name" },
                                ]}
                            >
                                <Input placeholder="Admin name" defaultValue={ instiuteInfoList?.adminName} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="contactNumber"
                                label="Contact Number"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter contact number" },
                                ]}
                            >
                                <Input placeholder="Contact number" defaultValue={ instiuteInfoList?.contactNumber} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="instituteEmail"
                                label="Email"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter email" },
                                ]}
                            >
                                <Input placeholder="Email address" defaultValue={ instiuteInfoList?.instituteEmail} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="academicYear"
                                label="Academic Year"
                                className="title-Text"
                                initialValue={instiuteInfoList?.academicYear}
                                rules={[
                                    { required: true, message: "Select academic year" },
                                ]}
                            >
                                <SelectAcademicYear selected={academicyearData}  onChange={(e) => setAcademicyearData(e)} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="eiinNo"
                                label="EIIN No."
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Write eiin number" },
                                ]}
                            >
                                <Input placeholder="EIIN number"  defaultValue={ instiuteInfoList?.eiinNo} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="onlinePaymentStatus"
                                label="Online Payment Status"
                                className="title-Text"
                            >
                               <Select placeholder="Online Payment Status">
                                <Option value={1}>On</Option>
                                <Option value={0}>Off</Option>
                               </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="feeSubheadCheckStatus"
                                label="Online Fee Subhead Check Status"
                                className="title-Text"
                            >
                               <Select placeholder="Fee Subhead Status">
                                <Option value={1}>On</Option>
                                <Option value={0}>Off</Option>
                               </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="allFeeCheckStatus"
                                label="Online All Fee Check Status"
                                className="title-Text"
                            >
                               <Select placeholder="All Fee Check Status">
                                <Option value={1}>On</Option>
                                <Option value={0}>Off</Option>
                               </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="feeAutoSms"
                                label="Fee Auto Sms"
                                className="title-Text"
                            >
                               <Select placeholder="Fee Auto Sms Status">
                                <Option value={1}>On</Option>
                                <Option value={0}>Off</Option>
                               </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}> 
                        <Form.Item
                                name="onlineProfileUpdateStatus"
                                label="Online Profile Update Status"
                                className="title-Text"
                            >
                               <Select placeholder="Online Profile Update Status">
                                <Option value={1}>On</Option>
                                <Option value={0}>Off</Option>
                               </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="photoUpload"
                                label="Logo Upload"
                                className="title-Text"
                            >
                                <Input type="file" value={''} onChange={(e) => onchangeFile(e)} />
                            </Form.Item>
                        </Col>                       

                        
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}> 
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                            <Button type="primary" htmlType="submit" icon={<EditOutlined />} style={{ float: 'right' }} >
                                Update
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}