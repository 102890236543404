import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { Button, Card, Col,Form, Row, Space,DatePicker, Skeleton } from 'antd'
import {DownloadOutlined, SearchOutlined,FileExcelOutlined } from '@ant-design/icons';

import TableView from '../../../contents/AntTableResponsive';
import { pdfDataL,lpowerdbypdf, ldatepdf } from '../../utils/pdf';
import moment from 'moment';
import jsPDF from "jspdf";
import ReactExport from "react-export-excel";


const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

export default function SmsPurchaseLog() {

    const [searchForm] = Form.useForm();
    const fetchSmsPurchaseLog = useStoreActions((state) => state.sms.fetchSmsPurchaseLog);
    const smsPurchaseLog = useStoreState((state) => state.sms.smsPurchaseLog);

    const searchSubmitForm = (value) => {
        let postdata:any = {
            fromDate : moment(value.fromDate._d).format("YYYY-MM-DD"),
            toDate : moment(value.toDate._d).format("YYYY-MM-DD"),
        }
        
        fetchSmsPurchaseLog(postdata);
    }

    let columns = [
        {title: 'Transaction Date', dataIndex: 'transactionDate', key: 'transactionDate', showOnResponse: true, showOnDesktop: true},
        {title: 'Transaction Time', dataIndex: 'transactionTime', key: 'transactionTime', showOnResponse: true, showOnDesktop: true},
        {title: 'Card Type', dataIndex: 'cardType', key: 'cardType', showOnResponse: true, showOnDesktop: true},
        {title: 'Transaction Id', dataIndex: 'bankTransactionId', key: 'bankTransactionId', showOnResponse: true, showOnDesktop: true},
        {title: 'Trn. Amount', dataIndex: 'totalAmount', key: 'totalAmount', showOnResponse: true, showOnDesktop: true},
        {title: 'Purchased Sms', dataIndex: 'totalSms', key: 'totalSms', showOnResponse: true, showOnDesktop: true},
        {title: 'Trn Status', dataIndex: 'trnStatus', key: 'trnStatus', showOnResponse: true, showOnDesktop: true},
        
    ]


    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");

        var details = `SMS Purchase Log`;
        pdfDataL(doc, "");
        doc.text(details, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);            
        };


        var col = [
            "Transaction Date",
            "Transaction Time",
            "Card Type",
            "Transaction ID",
            "Trn. Amount",
            "Purchased Sms",
            "Trn Status",
           
        ].filter(Boolean);

        var rows:any = [];


        smsPurchaseLog.forEach(element => {
            var temp:any = [ 
                element.transactionDate, 
                element.transactionTime, 
                element.cardType,
                element.bankTransactionId,
                element.totalAmount,
                element.totalSms,
                element.trnStatus
               
            ];
            rows.push(temp);
        });
       

            let first = doc.autoTable.previous;
            doc.autoTable(col, rows, {
                startY: 45,
                showHeader: "firstPage",
                theme: 'grid',
                styles: {
                    overflow: 'linebreak',
                },
                headerStyles: {
                    // fillColor: [105, 105, 105],
                    // textColor: [255, 255, 255],
                    // fontSize: 10
                    lineWidth: .01,
                    lineColor: [224, 224, 224]
                },
                columnStyles: {
                    guardianMobile: {
                        halign: "left",
                        fontSize: 8,
                        columnWidth: 250
                    }
                },
                addPageContent: pageContent,
            });

  
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }


    const loading = useStoreState((state) =>  state.sms.loading);
    return(
        <>
            <Card title="SMS Purchase Log">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                        <Form
                            layout="vertical"
                            id="sessionInfo"
                            onFinish={searchSubmitForm}
                            form={searchForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                    <Form.Item
                                        name="fromDate"
                                        label="From Date"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select from date" },
                                        ]}
                                    >
                                        <DatePicker placeholder="from date" style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                    <Form.Item
                                        name="toDate"
                                        label="To Date"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select to date" },
                                        ]}
                                    >
                                        <DatePicker placeholder="to date" style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{rows:10}} />
                <Row className="m-t-mo-30">
                    <Col span="24">
                        <div className="datatable-responsive-demo">
                            {smsPurchaseLog?.length > 0 &&
                                <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: smsPurchaseLog,
                                    filterData: smsPurchaseLog,
                                    pagination: true,
                                    bordered: true,
                                    rowKey:"id",
                                }}
                                mobileBreakPoint={768}
                            />
                            }
                        </div>
                    </Col>

                    <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>
                        <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`SMS Purchase Log Summary`}>
                                <ExcelSheet data={smsPurchaseLog} name="SMS Purchase Log Summary">
                                    <ExcelColumn label="Transaction Date" value="transactionDate" />
                                    <ExcelColumn label="Transaction Time" value="transactionTime" />
                                    <ExcelColumn label="Card Type" value="cardType" />
                                    <ExcelColumn label="Transaction ID" value="bankTransactionId" />
                                    <ExcelColumn label="Transaction Amount" value="totalAmount" />
                                    <ExcelColumn label="Purchase SMS" value="totalSms" />
                                    <ExcelColumn label="Tansaction Status" value="trnStatus" />
                                </ExcelSheet>
                            </ExcelFile>
                            <Button type="primary" icon={<DownloadOutlined />} onClick={() => exportPdf()} >PDF Download</Button>
                        </Space>
                    </Col>
                </Row>
            </Card>
        </>
    )
}
