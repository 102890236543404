import React, { useEffect } from 'react'
import { Card, Tabs } from 'antd';
import ExamRoutineCreate from './ExamRoutineCreate';
import ExamRoutineList from './ExamRoutineList';

const { TabPane } = Tabs;

export default function ExamRoutineWrapper(props) {

    return (
        <>
            <Card title="Exam Routine" >
                <Tabs defaultActiveKey="1" size={"large"}>
                    <TabPane tab="Create" key="1">
                        <ExamRoutineCreate />
                    </TabPane>
                    <TabPane tab="List" key="2">
                        <ExamRoutineList />
                    </TabPane>
                </Tabs>
            </Card>
        </>
    )

}