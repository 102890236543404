import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { defaultSignatureListUrl, deleteDefaultSignatureUrl, deleteMasterSettingUserUrl, fetchInstituteInfoUrl, masterSettingsUserList, saveDefaultSignatureUrl, saveMasterSettingUser, signatureImageUpdate, staffListUrl, updateInstituteInfoUrl, updateMasterSettingUserUrl, updatePhoto } from '../../../http/masterSettings/masterSettings';
import { smsSearchReportUrl, searchClassStudentIthAcademicYear, sectionDueListUrl, sendDueListSms, sendInstiuteSms, sendStaffSms, sendStudentSms, smsBlanceurl, smsTemplate, staffListForSendSms, staffSmsCount, templateSaveUrl, templateUpdateUrl } from '../../../http/sms/sms';
require("dotenv").config();

export interface MasterSettings {
    updateinstitutePhoto: Thunk<MasterSettings, any>;
    instiuteInfoList: any;
    setInstiuteInfoList: Action<MasterSettings, any>;
    fetchInstiuteInfoList: Thunk<MasterSettings>;
    updateinstituteInfo: Thunk<MasterSettings, any>;

    defaultSignatureList: any;
    setDefaultSignatureList: Action<MasterSettings, any>;
    fetchDefaultSignatureList: Thunk<MasterSettings>;
    saveDefaultSignature: Thunk<MasterSettings, any>;
    deleteDefaultSignature: Thunk<MasterSettings, any>;
    updateSignatureDefaultPhoto: Thunk<MasterSettings, any>;

    fetchMasterSettingUserList: Thunk<MasterSettings>;
    setMasterSettingUserList: Action<MasterSettings, any>;
    masterSettingUserList: any;

    fetchMasterSettingStaffList: Thunk<MasterSettings>;
    setMasterSettingStaffList: Action<MasterSettings, any>;
    masterSettingStaffList: any;
    saveMaseterSettingUser: Thunk<MasterSettings, any>;
    deleteMasterSettingUser: Thunk<MasterSettings, any>;
    updateMaseterSettingUser: Thunk<MasterSettings, any>;

    fetchMasterSettingPayabledList: Thunk<MasterSettings>;
    setMasterSettingPayabledList: Action<MasterSettings, any>;
    masterSettingPayabledList: any;

    fetchMasterSettingPaidList: Thunk<MasterSettings>;
    setMasterSettingPaidList: Action<MasterSettings, any>;
    masterSettingPaidList: any;

    fetchstudentAllInfo: Thunk<MasterSettings, any>;
    setstudentAllInfo: Action<MasterSettings, any>;
    studentAllInfo: any;

    loading: boolean;
    setLoading: Action<MasterSettings, boolean>;

}

export const masterSettingsStore: MasterSettings = {

    instiuteInfoList: [],
    defaultSignatureList: [],
    masterSettingUserList: [],
    masterSettingStaffList: [],
    masterSettingPayabledList: [],
    masterSettingPaidList: [],
    studentAllInfo: null,

    loading: false,
    setLoading: action((state, payload) => {
        state.loading = payload;
    }),

    updateinstitutePhoto: thunk(async (actions, payload) => {
        var data = new FormData()
        data.append('file', payload)
        const response = await updatePhoto(data);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    fetchInstiuteInfoList: thunk(async (actions, payload) => {
        const response = await fetchInstituteInfoUrl();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setInstiuteInfoList(body?.item);
        } else {
            notification.error({ message: "Something went wrong" })
        }
    }),
    setInstiuteInfoList: action((state, payload) => {
        state.instiuteInfoList = payload;
    }),
    updateinstituteInfo: thunk(async (actions, payload) => {
        const response = await updateInstituteInfoUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }
            actions.fetchInstiuteInfoList();
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    fetchDefaultSignatureList: thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await defaultSignatureListUrl();
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setDefaultSignatureList(body?.item);
        } else {
            notification.error({ message: "Something went wrong" })
            actions.setLoading(false);
        }
    }),
    setDefaultSignatureList: action((state, payload) => {
        state.defaultSignatureList = payload;
    }),
    saveDefaultSignature: thunk(async (actions, payload) => {
        const response = await saveDefaultSignatureUrl(payload?.payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
               // notification.success({ message: body.message })
                // actions.fetchDefaultSignatureList();
                let updateImage = {
                    signatureId : body?.item,
                    signatureData : payload?.image
                };
                actions.updateSignatureDefaultPhoto(updateImage)
            } else {
                notification.error({ message: body.message })
            }
            actions.fetchDefaultSignatureList();
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    deleteDefaultSignature: thunk(async (actions, payload) => {
        const response = await deleteDefaultSignatureUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchDefaultSignatureList();
            } else {
                notification.error({ message: body.message })
            }
            actions.fetchDefaultSignatureList();
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    updateSignatureDefaultPhoto: thunk(async (actions, payload) => {
        var data = new FormData()
        data.append('file', payload.signatureData)
        const response = await signatureImageUpdate(data, payload.signatureId);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchDefaultSignatureList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    fetchMasterSettingStaffList: thunk(async (actions, payload) => {
        const response = await staffListUrl();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setMasterSettingStaffList(body?.item);
        } else {
            notification.error({ message: "Something went wrong" })
        }
    }),
    setMasterSettingStaffList: action((state, payload) => {
        state.masterSettingStaffList = payload;
    }),

    fetchMasterSettingUserList: thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await masterSettingsUserList();
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setMasterSettingUserList(body?.item);
        } else {
            notification.error({ message: "Something went wrong" })
            actions.setLoading(false);
        }
    }),
    setMasterSettingUserList: action((state, payload) => {
        state.masterSettingUserList = payload;
    }),
    saveMaseterSettingUser: thunk(async (actions, payload) => {
        const response = await saveMasterSettingUser(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }
            actions.fetchMasterSettingUserList();
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    deleteMasterSettingUser: thunk(async (actions, payload) => {
        const response = await deleteMasterSettingUserUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }
            actions.fetchMasterSettingUserList();
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    updateMaseterSettingUser: thunk(async (actions, payload) => {
        const response = await updateMasterSettingUserUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }
            actions.fetchMasterSettingUserList();
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    fetchMasterSettingPayabledList: thunk(async (actions, payload) => {
        actions.setLoading(true);
        // const BASE_URL_SUITE = process.env.REACT_APP_COMMUNITY_BASE_URL
        // fetch(BASE_URL_SUITE + '/public/product/bill-generator/unpaid/list?clientProvideId=' + payload + '&productCustomId=101')
        //     .then(response => response.json())
        //     .then(body =>
        //         actions.setMasterSettingPayabledList(body?.item)
        //     );
        actions.setLoading(false);
    }),

    setMasterSettingPayabledList: action((state, payload) => {
        state.masterSettingPayabledList = payload;
    }),

    fetchMasterSettingPaidList: thunk(async (actions, payload: any) => {
        actions.setLoading(true);
        const BASE_URL_SUITE = process.env.REACT_APP_COMMUNITY_BASE_URL
        fetch(BASE_URL_SUITE + '/public/product/bill-generator/paid/list?clientProvideId=' + payload.clientProvideId + '&productCustomId=101&year=' + payload.year)
            .then(response => response.json())
            .then(body => {
                if (body?.item?.length > 0) {
                    actions.setMasterSettingPaidList(body?.item)
                } else {
                    actions.setMasterSettingPaidList(body?.item);
                    notification.error({ message: "No data found" })
                }

            }
            );
        actions.setLoading(false);
    }),

    setMasterSettingPaidList: action((state, payload) => {
        state.masterSettingPaidList = payload;
    }),

    fetchstudentAllInfo: thunk(async (actions, payload: any) => {
        const BASE_URL_PUBLIC = process.env.REACT_APP_API_ROOT
        fetch(BASE_URL_PUBLIC + '/public/student-portal/login?customStudentId=' + payload.customStudentId + '&instituteId=' + payload.instituteId)
            .then(response => response.json())
            .then(body => {
                if (body?.messageType === 1) {
                    actions.setstudentAllInfo(body?.item)
                    //actions.setLoading(false);
                } else {
                    actions.setstudentAllInfo(null);
                    notification.error({ message: "No data found" })
                    //actions.setLoading(false);
                }

            }
            );
        
    }),

    setstudentAllInfo: action((state, payload) => {
        state.studentAllInfo = payload;
    }),
}