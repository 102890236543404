import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import StaffUpdateSingleBasic from './StaffUpdateSingleBasic';
import StaffUpdateSingleEducation from './StaffUpdateSingleEducation';
import StaffDocumentUpload from './StaffDocumentUpload';

export default function StaffUpdateSingle (){
    const { TabPane } = Tabs;

    return(
        <>
            <Card title="Staff Information Update">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Basic" key="1">
                            <StaffUpdateSingleBasic />
                        </TabPane>
                        <TabPane tab="Education" key="2">
                            <StaffUpdateSingleEducation />
                        </TabPane>

                        <TabPane tab="Upload" key="3">
                            <StaffDocumentUpload />
                        </TabPane>
                    </Tabs>
                </Card>
        </>
    )
}