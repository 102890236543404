
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton, DatePicker } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';
import TableView from '../../../../contents/AntTableResponsive';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { FilePdfOutlined } from '@ant-design/icons';
import ReactExport from "react-export-excel";
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectFeeHeadMultiple } from '../../../select/SelectFeeHeadMultiple';
import moment from 'moment';


export default function FeeHeadCollectionStudentDateToDateAll() {

    const [studentWiseReportFormDateToDate] = Form.useForm();
    //const fetchshowStudentFeeHeadPaymentViewDateToDate = useStoreActions((state) => state.saccount.fetchshowStudentFeeHeadPaymentViewDateToDate);
    const fetchdateToDateAllClassFeeHeadCollection = useStoreActions((state) => state.saccount.fetchdateToDateAllClassFeeHeadCollection);
    const dateToDateAllClassFeeHeadCollection = useStoreState((state) =>  state.saccount.dateToDateAllClassFeeHeadCollection);
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState<any>([])
    const formSubmit = (value) => {
        setLoading(true);
        let postData:any = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
        }
        fetchdateToDateAllClassFeeHeadCollection(postData);
        
    }
    useEffect(() => {
        setTableData(dateToDateAllClassFeeHeadCollection?.classList);
        setLoading(false);
    }, [dateToDateAllClassFeeHeadCollection])


    const studentListColumn = [
        { title: 'Class', dataIndex: 'className', key: 'className', showOnResponse: true, showOnDesktop: true  },
        dateToDateAllClassFeeHeadCollection?.feeHead1!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead1, dataIndex: 'feeHeadAmount1', key: 'feeHeadAmount1', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead2!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead2, dataIndex: 'feeHeadAmount2', key: 'feeHeadAmount2', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead3!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead3, dataIndex: 'feeHeadAmount3', key: 'feeHeadAmount3', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead4!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead4, dataIndex: 'feeHeadAmount4', key: 'feeHeadAmount4', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead5!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead5, dataIndex: 'feeHeadAmount5', key: 'feeHeadAmount5', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead6!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead6, dataIndex: 'feeHeadAmount6', key: 'feeHeadAmount6', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead7!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead7, dataIndex: 'feeHeadAmount7', key: 'feeHeadAmount7', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead8!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead8, dataIndex: 'feeHeadAmount8', key: 'feeHeadAmount8', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead9!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead9, dataIndex: 'feeHeadAmount9', key: 'feeHeadAmount9', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead10!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead10, dataIndex: 'feeHeadAmount10', key: 'feeHeadAmount10', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead11!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead11, dataIndex: 'feeHeadAmount11', key: 'feeHeadAmount11', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead12!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead12, dataIndex: 'feeHeadAmount12', key: 'feeHeadAmount12', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead13!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead13, dataIndex: 'feeHeadAmount13', key: 'feeHeadAmount13', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead14!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead14, dataIndex: 'feeHeadAmount14', key: 'feeHeadAmount14', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead15!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead15, dataIndex: 'feeHeadAmount15', key: 'feeHeadAmount15', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead16!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead16, dataIndex: 'feeHeadAmount16', key: 'feeHeadAmount16', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead17!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead17, dataIndex: 'feeHeadAmount17', key: 'feeHeadAmount17', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead18!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead18, dataIndex: 'feeHeadAmount18', key: 'feeHeadAmount18', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead19!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead19, dataIndex: 'feeHeadAmount19', key: 'feeHeadAmount19', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead20!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead20, dataIndex: 'feeHeadAmount20', key: 'feeHeadAmount20', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead21!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead21, dataIndex: 'feeHeadAmount21', key: 'feeHeadAmount21', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead22!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead22, dataIndex: 'feeHeadAmount22', key: 'feeHeadAmount22', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead23!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead23, dataIndex: 'feeHeadAmount23', key: 'feeHeadAmount23', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead24!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead24, dataIndex: 'feeHeadAmount24', key: 'feeHeadAmount24', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead25!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead25, dataIndex: 'feeHeadAmount25', key: 'feeHeadAmount25', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead26!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead26, dataIndex: 'feeHeadAmount26', key: 'feeHeadAmount26', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead27!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead27, dataIndex: 'feeHeadAmount27', key: 'feeHeadAmount27', showOnResponse: true, showOnDesktop: true },

        dateToDateAllClassFeeHeadCollection?.feeHead28!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead28, dataIndex: 'feeHeadAmount28', key: 'feeHeadAmount28', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead29!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead29, dataIndex: 'feeHeadAmount29', key: 'feeHeadAmount29', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead30!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead30, dataIndex: 'feeHeadAmount30', key: 'feeHeadAmount30', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead31!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead31, dataIndex: 'feeHeadAmount31', key: 'feeHeadAmount31', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead32!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead32, dataIndex: 'feeHeadAmount32', key: 'feeHeadAmount32', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead33!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead33, dataIndex: 'feeHeadAmount33', key: 'feeHeadAmount33', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead34!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead34, dataIndex: 'feeHeadAmount34', key: 'feeHeadAmount34', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead35!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead35, dataIndex: 'feeHeadAmount35', key: 'feeHeadAmount35', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead36!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead36, dataIndex: 'feeHeadAmount36', key: 'feeHeadAmount36', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead37!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead37, dataIndex: 'feeHeadAmount37', key: 'feeHeadAmount37', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead38!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead38, dataIndex: 'feeHeadAmount38', key: 'feeHeadAmount38', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead39!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead39, dataIndex: 'feeHeadAmount39', key: 'feeHeadAmount39', showOnResponse: true, showOnDesktop: true },
        dateToDateAllClassFeeHeadCollection?.feeHead40!==''&&  { title: dateToDateAllClassFeeHeadCollection?.feeHead40, dataIndex: 'feeHeadAmount40', key: 'feeHeadAmount40', showOnResponse: true, showOnDesktop: true },
       



        { title: 'Total', dataIndex: 'total', key: 'total', showOnResponse: true, showOnDesktop: true  },
    ];

    return(
        <>
            <>
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={studentWiseReportFormDateToDate}
                        >
                            
                            <Row gutter={8}>
                                    <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>


                                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                        <Form.Item
                                            name="fromDate"
                                            label="From Date"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please enter from date" },
                                            ]}
                                        >

                                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                        <Form.Item
                                            name="toDate"
                                            label="To Date"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please enter to date" },
                                            ]}
                                        >

                                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                                        <Space size="small" >
                                            <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                                Search
                                            </Button>
                                        </Space>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }}>
                    <Row style={{ display: dateToDateAllClassFeeHeadCollection !== null ? '' : 'none' }}>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}}>
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns:studentListColumn,
                                    rowKey:"className",
                                    dataSource: tableData,
                                    filterData: tableData,
                                    pagination: true,
                                    bordered: true,                           
                                    exportable : true,                           
                                }}
                                mobileBreakPoint={768}
                            />
                            <br />
                            {/* <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} onClick={() => deleteStudent()} className="float-right">Delete</Button> */}
                        </Col>
                        <Col span={24}>
                            <Space size="small" style={{ float: "right" }}>
                            {/* <ExcelFile
                                    element={<Button type="primary" icon={<FileExcelOutlined />} >
                                        Download Excel
                                    </Button>}
                                    filename={`Student Fee Head Collection of Class ${$(".classSelect").text()} and Academic Year ${studentWiseReportFormDateToDate.getFieldValue("yearId")}`}
                                >
                                    <ExcelSheet data={tableData} name="Student Fee Head Collection">
                                        <ExcelColumn label="Student ID" value="customStudentId" />
                                        <ExcelColumn label="Student Name" value="studentName" />
                                        <ExcelColumn label="Roll" value="studentRoll" />
                                        {dateToDateAllClassFeeHeadCollection?.feeHeadNames?.feeHeadName1!==''&&<ExcelColumn label={dateToDateAllClassFeeHeadCollection?.feeHeadNames?.feeHeadName1} value="feeHead1" />}
                                        {dateToDateAllClassFeeHeadCollection?.feeHeadNames?.feeHeadName2!==''&&<ExcelColumn label={dateToDateAllClassFeeHeadCollection?.feeHeadNames?.feeHeadName2} value="feeHead2" />}

                                        <ExcelColumn label="Total" value="studentRoll" />
                                    </ExcelSheet>
                                </ExcelFile> */}
                                {/* <Button type="primary" icon={<DownloadOutlined />} onClick={() => exportPdf()} >PDF Download</Button> */}
                            </Space>
                        </Col>
                    </Row>
                </Skeleton>

            </>
        </>
    )
}
