import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Skeleton, Space, Tooltip, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import NoImage from './no.png'
import { SelectReligion } from '../../../select/SelectReligion';
import { SelectDesignation } from '../../../select/SelectDesignation';
import { SelectBloodGroup } from '../../../select/SelectBloodGroup';
import { SelectGender } from '../../../select/SelectGender';
import { Typography } from 'antd';
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import { SelectSubjectByClassNGroup } from '../../../select/SelectSubjectByClassNGroup';
import { SelectThana } from '../../../select/SelectThana';
import { SelectDistrict } from '../../../select/SelectDistrict';
import { SelectDistrict2 } from '../../../select/SelectDistrict2';
import { SelectThana2 } from '../../../select/SelectThana2';
const { Option } = Select;


export default function StaffUpdateSingleBasic(props) {

    const [updateForm] = Form.useForm();
    const { Title } = Typography;
    const staffBasicInfoList = useStoreState((state) => state.staff.staffBasicInfoList);
    const staffBasicSingleViewByStaff = useStoreState((state) => state.staff.staffBasicSingleViewByStaff);
    const fetchstaffBasicSingleViewByStaff = useStoreActions((state) => state.staff.fetchstaffBasicSingleViewByStaff);
    const instituteInfo = useStoreState(state => state.auth.instituteInfo)
    const fetchstaffBasicInfoList = useStoreActions((state) => state.staff.fetchstaffBasicInfoList);
    const updateSingleStaffBasicInfo = useStoreActions((state) => state.staff.updateSingleStaffBasicInfo);
    const updateSingleStaffPhoto = useStoreActions((state) => state.staff.updateSingleStaffPhoto);
    const loading = useStoreState((state) => state.staff.loading);

    useEffect(() => {
        fetchstaffBasicSingleViewByStaff(instituteInfo?.staffId);
    }, [])

    useEffect(() => {
        setstaffId(staffBasicSingleViewByStaff?.staffId);
        setgender(staffBasicSingleViewByStaff?.gender);
        setreligion(staffBasicSingleViewByStaff?.religion);
        setdesignationId(staffBasicSingleViewByStaff?.designationId);
        setbloodGroup(staffBasicSingleViewByStaff?.bloodGroup);
        thanaListFetch(staffBasicSingleViewByStaff?.presentDistrictId);
        setTimeout(() => {
            setThana(staffBasicSingleViewByStaff?.presentThanaId);
            setThana2(staffBasicSingleViewByStaff?.permanentThanaId);
            setDistrict(staffBasicSingleViewByStaff?.presentDistrictId);
            setDistrict2(staffBasicSingleViewByStaff?.permanentDistrictId);
            updateForm.setFieldsValue({
                permanentThanaId: staffBasicSingleViewByStaff?.permanentThanaId,
                presentThanaId: staffBasicSingleViewByStaff?.presentThanaId,
            });

        }, 1000);
        updateForm.setFieldsValue({
            staffName: staffBasicSingleViewByStaff?.staffName,
            staffStatus: staffBasicSingleViewByStaff?.staffStatus === 1 ? true : false,
            customStaffId: staffBasicSingleViewByStaff?.customStaffId,
            fatherName: staffBasicSingleViewByStaff?.fatherName,
            motherName: staffBasicSingleViewByStaff?.motherName,
            mobileNumber: staffBasicSingleViewByStaff?.mobileNumber,
            alternativeMobile: staffBasicSingleViewByStaff?.alternativeMobile,
            email: staffBasicSingleViewByStaff?.email,
            religion: staffBasicSingleViewByStaff?.religion,
            gender: staffBasicSingleViewByStaff?.gender,
            passportNo: staffBasicSingleViewByStaff?.passportNo,
            nationalId: staffBasicSingleViewByStaff?.nationalId,
            employeementStatus: staffBasicSingleViewByStaff?.employeementStatus,
            promotionStatus: staffBasicSingleViewByStaff?.promotionStatus,
            mobileBankingInfo: staffBasicSingleViewByStaff?.mobileBankingInfo,
            subjectSpecialist: staffBasicSingleViewByStaff?.subjectSpecialist,
            bloodGroup: staffBasicSingleViewByStaff?.bloodGroup,
            presentAddress: staffBasicSingleViewByStaff?.presentAddress,
            permanentAddress: staffBasicSingleViewByStaff?.permanentAddress,
            designationId: staffBasicSingleViewByStaff?.designationId,
            staffSerial: staffBasicSingleViewByStaff?.staffSerial,
            birthDate: staffBasicSingleViewByStaff?.birthDate == null ? null : moment(staffBasicSingleViewByStaff?.birthDate, 'YYYY-MM-DD'),
            employmentDate: staffBasicSingleViewByStaff?.employmentDate == null ? null : moment(staffBasicSingleViewByStaff?.employmentDate, 'YYYY-MM-DD'),
            promotionDate: staffBasicSingleViewByStaff?.promotionDate == null ? null : moment(staffBasicSingleViewByStaff?.promotionDate, 'YYYY-MM-DD'),
            lastPromotionDate: staffBasicSingleViewByStaff?.lastPromotionDate == null ? null : moment(staffBasicSingleViewByStaff?.lastPromotionDate, 'YYYY-MM-DD'),
            lastPromotedPost: staffBasicSingleViewByStaff?.lastPromotedPost,
            joiningPost: staffBasicSingleViewByStaff?.joiningPost,
            mobileBankingName: staffBasicSingleViewByStaff?.mobileBankingName,
            mainJobResponsibility: staffBasicSingleViewByStaff?.mainJobResponsibility,
        });
    }, [staffBasicSingleViewByStaff])



    const updateFomrSubmit = (value) => {
        // console.log(value.staffStatus)
        value.staffId = staffId;
        value.birthDate = value.birthDate === null ? null : moment(value?.birthDate).format("YYYY-MM-DD");
        value.employmentDate = value.employmentDate === null ? null : moment(value?.employmentDate).format("YYYY-MM-DD");
        value.promotionDate = value.promotionDate === null ? null : moment(value?.promotionDate).format("YYYY-MM-DD");
        value.staffStatus = value.staffStatus === true ? 1 : 0;
        value.presentThanaId = thana;
        value.permanentThanaId = thana2;
        
        updateSingleStaffBasicInfo(value)
        //updateDesignation(postdata);
   
    }

    const [staffId, setstaffId] = useState<any>();
    const [gender, setgender] = useState<any>();
    const [religion, setreligion] = useState<any>();
    const [designationId, setdesignationId] = useState<any>();
    const [bloodGroup, setbloodGroup] = useState<any>();

    const uploadImage = (val, id) => {
        let image_as_files = val.target.files[0];
        let data = {
            file: image_as_files,
            staffId: id
        }
        updateSingleStaffPhoto(data)
    }

    const columns = [


        {
            title: 'Image',
            key: 'imageName',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <div style={{ textAlign: "center" }}>
                    <img src={record.imageName == '' ? NoImage : ("data:image/png;base64," + record.imageName)} alt="Staff Image" style={{ height: 50, width: 50 }} />
                    <br />
                    <input type="file" name="my_file" id="my_file" accept="image/*" onChange={(e) => uploadImage(e, record.staffId)} />
                </div>
            ),
        },
    ];



    const [disttrict, setDistrict] = useState<any>();
    const [thana, setThana] = useState<any>();
    const [disttrict2, setDistrict2] = useState<any>();
    const [thana2, setThana2] = useState<any>();
    const thanaListFetch = useStoreActions((state) => state.common.thanaListFetch);
    const thanaListFetch2 = useStoreActions((state) => state.common.thanaListFetch2);

    const districsIdStore = (val) => {
        setDistrict(val);
    }
    const districsIdStore2 = (val) => {
        setDistrict2(val);
    }

    return (
        <>
            {/* {staffBasicInfoList === null && <Skeleton loading={loading} paragraph={{ rows: 10 }} />}
            {staffBasicInfoList !== null &&
                <Card title="Staff Information Update" >
                    <div className="datatable-responsive">
                        <Table
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: staffBasicInfoList,
                                filterData: staffBasicInfoList,
                                pagination: true,
                                bordered: true,
                                rowKey: 'staffId'
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>

                </Card>
            } */}
            {/* <Modal
                title="Update Staff Info"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => { setIsModalVisible(false); updateForm.resetFields() }}
                cancelText="Close"
                okText="Update"
                centered
                width={"60%"}
            > */}
            < >
                <Form
                    layout="vertical"

                    onFinish={updateFomrSubmit}
                    id="update"
                    form={updateForm}
                >
                    <Row gutter={8}>
                        
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="customStaffId"
                                label="ID:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input staff id" },
                                ]}
                            >
                                <Input placeholder='Staff Id' readOnly/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="staffName"
                                label="Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input staff name" },
                                ]}
                            >
                                <Input placeholder='Staff Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="designationId"
                                label="Designation:"
                                className="title-Text"

                                rules={[
                                    { required: true, message: "Please select designation" },
                                ]}
                            >
                                <SelectDesignation selected={designationId} onChange={(e) => setdesignationId(e)} />
                            </Form.Item>
                        </Col>

                        <Form.Item 
                                hidden
                                name="staffStatus">
                                    
                            </Form.Item>



                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="fatherName"
                                label="Father's Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input father name" },
                                ]}
                            >
                                <Input placeholder='Father Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="motherName"
                                label="Mother's Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input mother name" },
                                ]}
                            >
                                <Input placeholder='Mother name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="religion"
                                label="Religion:"
                                className="title-Text"

                                rules={[
                                    { required: true, message: "Please select religion" },
                                ]}
                            >
                                <SelectReligion selected={religion} onChange={(e) => setreligion(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="bloodGroup"
                                label="Blood Group:"
                                className="title-Text"

                                rules={[
                                    { required: true, message: "Please select blood group" },
                                ]}
                            >
                                <SelectBloodGroup selected={bloodGroup} onChange={(e) => setbloodGroup(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="gender"
                                label="Gender:"
                                className="title-Text"

                                rules={[
                                    { required: true, message: "Please select gender" },
                                ]}
                            >
                                <SelectGender selected={gender} onChange={(e) => setgender(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="birthDate"
                                label="Date of Birth:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select birth date" },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="mobileNumber"
                                label="Mobile No:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input mobile no." },
                                ]}
                            >
                                <Input placeholder='Mobile Number' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="alternativeMobile"
                                label="Alternative Mobile:"
                                className="title-Text"

                            >
                                <Input placeholder='Alternative Mobile' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="email"
                                label="Email:"
                                className="title-Text"

                            >
                                <Input placeholder='Email' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="nationalId"
                                label="National ID:"
                                className="title-Text"

                            >
                                <Input placeholder='National ID' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="passportNo"
                                label="Passport No:"
                                className="title-Text"

                            >
                                <Input placeholder='Passport No' />
                            </Form.Item>
                        </Col>
                        {/* <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="employmentDate"
                                label="Employment Date:"
                                className="title-Text"

                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col> */}
                        {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="employeementStatus"
                                label="Employment Staus:"
                                className="title-Text"

                                style={{ width: "100%" }}
                            >
                                <Select placeholder="Employment Status" allowClear style={{ width: "100%" }}>
                                    <Option value="Permanent">Permanent</Option>
                                    <Option value="Contractual">Contractual</Option>
                                    <Option value={"Part Time"}>Part Time</Option>
                                </Select>

                            </Form.Item>
                        </Col> */}
                        {/* <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="promotionDate"
                                label="Promotion Date:"
                                className="title-Text"

                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col> */}
                        {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="promotionStatus"
                                label="Promotion Staus:"
                                className="title-Text"

                                style={{ width: "100%" }}
                            >
                                <Select placeholder="Promotion Status" allowClear style={{ width: "100%" }}>
                                    <Option value="Promoted">Promoted</Option>
                                    <Option value="Not Promoted">Not Promoted</Option>
                                </Select>

                            </Form.Item>
                        </Col> */}
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="mobileBankingInfo"
                                label="Mobile Banking Number:"
                                className="title-Text"

                            >
                                <Input placeholder='Mobile Banking Number' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="mobileBankingName"
                                label="Mobile Banking Name"
                                className="title-Text"

                            >
                                <Input placeholder='Mobile Banking Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="staffSerial"
                                label="Serial:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input serial" },
                                ]}
                            >
                                <Input readOnly placeholder='Staff Serial' />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row> <Col xs={24} sm={24} md={24} lg={24} xl={24}><u><strong>Job Info</strong></u></Col> </Row>
                
                <Row>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    
                            <Form.Item
                                name="employmentDate"
                                label="Joining Date:"
                                className="title-Text"

                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="joiningPost"
                                label="Joining Post"
                                className="title-Text"

                            >
                            <Input placeholder='Joining Post' />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="employeementStatus"
                                label="Employment Staus:"
                                className="title-Text"

                                style={{ width: "100%" }}
                            >
                                <Select placeholder="Employment Status" allowClear style={{ width: "100%" }}>
                                    <Option value="Permanent">Permanent</Option>
                                    <Option value="Fixed Salary">Fixed Salary</Option>
                                    <Option value="Temporary">Temporary</Option>
                                </Select>

                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="promotionStatus"
                                label="Got Promotion:"
                                className="title-Text"

                                style={{ width: "100%" }}
                            >
                                <Select placeholder="Got Promotion" allowClear style={{ width: "100%" }}>
                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                                </Select>

                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    
                    <Form.Item
                        name="lastPromotionDate"
                        label="Last Promotion Date:"
                        className="title-Text"

                    >
                        <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                    </Form.Item>
                </Col>


                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="lastPromotedPost"
                                label="Last Promoted Post"
                                className="title-Text"

                            >
                            <Input placeholder='Last Promoted Post' />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="subjectSpecialist"
                                label="Subject Specialist:"
                                className="title-Text"

                            >
                                <Input placeholder='Subject' />
                            </Form.Item>
                        </Col>

                        
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="mainJobResponsibility"
                                label="Main Job Responsibility"
                                className="title-Text"

                            >
                            <Input placeholder='Main Job Responsibility' />
                            </Form.Item>
                        </Col>
                    </Row>





                    <Row>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <u><strong>Presenet Address</strong></u>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="presentAddress"
                                        label="Present Address:"
                                        className="title-Text"

                                    >
                                        <Input placeholder='Present Address' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="presentDistrictId"
                                        label="Present District"
                                        className="title-Text"

                                    >
                                        <SelectDistrict selected={disttrict} onChange={e => { districsIdStore(e); setThana(null) }} />

                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="presentThanaId"
                                        label="Present Thana"
                                        className="title-Text"

                                    >
                                        <SelectThana selected={thana} onChange={e => setThana(e)} />

                                    </Form.Item>
                                </Col>

                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <u><strong>Permanent Address</strong></u>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="permanentAddress"
                                        label="Permanent Address:"
                                        className="title-Text"

                                    >
                                        <Input placeholder='Permanent Address' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="permanentDistrictId"
                                        label="Permanent District"
                                        className="title-Text"
                                    >
                                        <SelectDistrict2 selected={disttrict2} onChange={e => { districsIdStore2(e); setThana2(null) }} />

                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="permanentThanaId"
                                        label="Permanent Thana"
                                        className="title-Text"
                                    >
                                        <SelectThana2 selected={thana2} onChange={e => setThana2(e)} />

                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                            Save
                        </Button>
                    </div>
                </Form>
            </>
            {/* 
            </Modal> */}
        </>
    )
}
