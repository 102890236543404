import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag } from 'antd';
import TableView from '../../../../contents/AntTableResponsive';
import { DeleteOutlined, DownloadOutlined, EditOutlined, FileExcelOutlined, FilePdfOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { v4 as uuidv4 } from "uuid";
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { Excel } from 'antd-table-saveas-excel';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
require('jspdf-autotable');

export default function ExamReportSubjectGrading() {
    const { Option } = Select;
    const [examReportSectionWiseResultForm] = Form.useForm();

    const fetchExamListByclassConfigIdSectionWiseExam = useStoreActions((state) => state.exam.fetchExamListByclassConfigIdSectionWiseExam);
    const examListByclassConfigIdSectionWiseExam = useStoreState((state) => state.exam.examListByclassConfigIdSectionWiseExam);

    const fetchsubjectGradingSummary = useStoreActions((state) => state.exam.fetchsubjectGradingSummary);
    const subjectGradingSummary = useStoreState((state) => state.exam.subjectGradingSummary);

    const unassignedSubjectMarkListColumn = [
        { title: 'Subject', dataIndex: 'subjectName', key: "subjectName", showOnResponse: true, showOnDesktop: true },
        { title: 'Total', dataIndex: 'total', key: "total", showOnResponse: true, showOnDesktop: true },
        { title: 'A+', dataIndex: 'aplus', key: "aplus", showOnResponse: true, showOnDesktop: true },
        { title: 'A', dataIndex: 'a', key: "a", showOnResponse: true, showOnDesktop: true },
        { title: 'A-', dataIndex: 'aminus', key: "aminus", showOnResponse: true, showOnDesktop: true },
        { title: 'B', dataIndex: 'b', key: "b", showOnResponse: true, showOnDesktop: true },
        { title: 'C', dataIndex: 'c', key: "c", showOnResponse: true, showOnDesktop: true },
        { title: 'D', dataIndex: 'd', key: "d", showOnResponse: true, showOnDesktop: true },
        { title: 'F', dataIndex: 'f', key: "f", showOnResponse: true, showOnDesktop: true }
    ];
    const loading = useStoreState((state) => state.exam.loading);

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");
        var details = `${$(".sectionSelect").text()}-${$(".examSelectx").text()}  Subject Wise Grading Summary`;
        var detailsx = `Subject Wise Grading Summary`;
        pdfDataL(doc, "");
        doc.text(detailsx, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        var col = [
            "Subject",
            "Total",
            "A+",
            "A",
            "A-",
            "B",
            "C",
            "D",
            "F"
        ].filter(Boolean);

        var rows: any = [];


        subjectGradingSummary.forEach(element => {
            var temp: any = [
                element.subjectName,
                element.total,
                element.aplus,
                element.a,
                element.aminus,
                element.b,
                element.c,
                element.d,
                element.f
            ];
            rows.push(temp);
        });


        let first = doc.autoTable.previous;
        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
            { title: "", dataKey: "b3" },
            { title: "", dataKey: "b4" },
            { title: "", dataKey: "b5" },
            { title: "", dataKey: "b6" },
        ], [
            {
                b1: "Academic Year",
                b2: $(".yearSelect").text(),
                b3: 'Section',
                b4: $(".sectionSelect").text(),
                b5: 'Exam',
                b6: $(".examSelectx").text(),
            }
        ], {
            startY: 45,
            showHeader: "never",
            theme: 'grid',

            // addPageContent: pageContent,
        });
        doc.autoTable(col, rows, {
            startY: doc.autoTable.previous.finalY + 5,
            showHeader: "firstPage",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                overflow: 'linebreak',
                lineColor: [224, 224, 224]
            },
            styles: {
                overflow: 'linebreak',
            },
            columnStyles: {
                ledgerName: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 75
                },
                debit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                },
                credit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                }
            },


        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    const onChangeClassList = (e) => {
        fetchExamListByclassConfigIdSectionWiseExam(e);
    }

    const examReportSearch = (value) => {
        let postData: any = {
            examConfigId: value.selectExam,
            classConfigId: value.sectionSection,
            academicYear: value.sectionYear
        }
        fetchsubjectGradingSummary(postData);
    }

    return (
        <>
            <Card title="Subject Wise Grading Summary">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={examReportSearch}
                            form={examReportSectionWiseResultForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="sectionYear"
                                        label="Select Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="sectionSection"
                                        label="Select Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig onChange={(e) => onChangeClassList(e)} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="selectExam"
                                        label="Select Exam"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select exam" },
                                        ]}
                                    >
                                        <Select placeholder="Exam List" style={{ width: "100%" }} className="examSelectx">
                                            {examListByclassConfigIdSectionWiseExam ? (
                                                examListByclassConfigIdSectionWiseExam.map((type, idx) => (
                                                    <Option key={type.examConfigId} value={type.examConfigId}>
                                                        {type.examName}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching exam</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: subjectGradingSummary.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: unassignedSubjectMarkListColumn,
                                rowKey: "subjectSerial",
                                dataSource: subjectGradingSummary,
                                filterData: subjectGradingSummary,
                                pagination: true,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                        <Space size={'large'} className="float-right">
                            < Button
                                type='primary'
         
                                icon={<FileExcelOutlined />}
                                onClick={() => {
                                    const excel: any = new Excel();
                                    excel
                                        .addSheet(`Sheet1`)
                                        .addColumns(unassignedSubjectMarkListColumn)
                                        .addDataSource(subjectGradingSummary)
                                        .saveAs(`${$(".sectionSelect").text()}-${$(".examSelectx").text()} Subject Wise Grading Summary.xlsx`);
                                }}
                            >
                                Download Excel
                            </ Button >
                            <Button type="primary" htmlType="submit" icon={<FilePdfOutlined />} onClick={() => exportPdf()} >Download PDF</Button>
                        </Space>
                    </Col>
                </Row>
            </Card>
        </>
    )

}