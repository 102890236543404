import { useState } from 'react';
import { Button, Card, Col,Form, Row,Space} from 'antd'
import { DownCircleOutlined} from '@ant-design/icons';
import { useStoreActions } from '../../../../store/hooks/easyPeasy';
import { SelectSanmasikSubjectbyClassConfig } from '../../../select/SelectSanmasikSubjectbyClassConfig';
import { SelectAcademicYear2 } from '../../../select/SelectAcademicYear2';
import { SelectClassConfig2 } from '../../../select/SelectClassConfig2';


export default function SanmasikTranscript(props) {

    const fetchsanmasikSubjectListbyClassConfig = useStoreActions((state) => state.customerSupport.fetchsanmasikSubjectListbyClassConfig);
    const sanmasikTranscriptDownload = useStoreActions((state) =>  state.exam.sanmasikTranscript);
    const [sanmasikTranscriptForm] = Form.useForm();


    const sanmasikTranscriptDownloadFunction = (value) => {
        let payload:any = {
            classConfigurationId: sectionId,
            academicYear: value.academicYear,
            sanmasikSubjectId: subjectId,
        }

        sanmasikTranscriptDownload(payload)
    }

    const [sectionId, setsectionId] = useState<any>(null);
    const [subjectId, setsubjectId] = useState<any>(null);
    const onChangeFetchSubject = (val) => {
        setsectionId(val);
        fetchsanmasikSubjectListbyClassConfig(val);
    }
    const onChangeFetchbySubject = (val) => {
        setsubjectId(val);
    }


    return (
        <Card title="Download Sanmasik Transcript">

<Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 16, offset: 6 }} xl={{ span: 16, offset: 4 }}>


<Col span={4}></Col>

<Col span={12}>
                    <Form
                        layout="vertical"
                        id="sanmasikClass"
                        onFinish={sanmasikTranscriptDownloadFunction}
                        form={sanmasikTranscriptForm}
                    >
                       
                       
                                <Form.Item
                                    name="academicYear"
                                    label="Academic Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select year" },
                                    ]}
                                >
                                    <SelectAcademicYear2 />
                                </Form.Item>
                             
                                <Form.Item
                                    name="classConfigurationId"
                                    label="Section"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select section" },
                                    ]}
                                >
                                    <SelectClassConfig2 onChange={e => onChangeFetchSubject(e)} />
                                </Form.Item>
                            
                            
                                <Form.Item
                                    name="subjectId"
                                    label="Subject"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select competency name" },
                                    ]}
                                >
                                    <SelectSanmasikSubjectbyClassConfig onChange={e => onChangeFetchbySubject(e)} />
                                </Form.Item>
                           

                            
                              
                                    <Button type="primary" htmlType="submit" icon={<DownCircleOutlined />} style={{float:"right"}} >
                                    Download Transcript
                                    </Button>
                               
                                
                           
                       
                    </Form> 
                    </Col>
                    <Col span={4}></Col>                  
                </Col>
            </Row>

        </Card>
    )
}