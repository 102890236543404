import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { DeleteOutlined, EditOutlined, FilePdfOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectClass } from '../../../select/SelectClass';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
require('jspdf-autotable');

export default function MarkProblemDelete(props) {
    const { Option } = Select;
    const [searchMarkConfigForm] = Form.useForm();
    const fetchGroupListForMarkConfigSearch = useStoreActions((state) => state.exam.fetchGroupListForMarkConfigSearch);
    const subjectGroupListMarkConfigSearch = useStoreState((state) => state.exam.subjectGroupListMarkConfigSearch);
    const fetchExamListForMarkConfigSearch = useStoreActions((state) => state.exam.fetchExamListForMarkConfigSearch);
    const examListForMarkConfigSearch = useStoreState((state) => state.exam.examListForMarkConfigSearch);
    const [groupListForMarkConfig, setGroupListForMarkConfig] = useState([]);
    const [examListForMarkConfig, setExamListForMarkConfig] = useState([]);
    const loading = useStoreState((state) => state.exam.loading);

    const viewExamMarkProblem = useStoreState((state) => state.exam.viewExamMarkProblem);
    const fetchviewExamMarkProblem = useStoreActions((state) => state.exam.fetchviewExamMarkProblem);


    const cols = [
        {
            title: 'Student Id',
            name: 'customStudentId',
            dataIndex: 'customStudentId',
            key: 'defaultId',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Student Name',
            name: 'studentName',
            dataIndex: 'studentName',
            key: 'defaultId',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Roll',
            name: 'studentRoll',
            dataIndex: 'studentRoll',
            key: 'defaultId',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Subject',
            name: 'subjectName',
            dataIndex: 'subjectName',
            key: 'defaultId',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Total Score',
            name: 'totalScore',
            dataIndex: 'totalScore',
            key: 'defaultId',
            showOnResponse: true,
            showOnDesktop: true
        },

    ];

    const [serData, setSerData] = useState<any>(null);

    const searchMarkConfigList = (e) => {
        let postData: any = {
            "academicYear": e?.academicYear,
            "classId": e?.classId,
            "examId": e?.examId,
            "groupId": e?.groupId
        };
        setSerData(postData);
        fetchviewExamMarkProblem(postData);
    }



    const onChangeClassListForMarkConfig = (val) => {
        fetchGroupListForMarkConfigSearch(val);
        fetchExamListForMarkConfigSearch(val)
        setExamListForMarkConfig(examListForMarkConfigSearch);
        setGroupListForMarkConfig(subjectGroupListMarkConfigSearch);
    }

    useEffect(() => {
        setGroupListForMarkConfig(subjectGroupListMarkConfigSearch);
        setExamListForMarkConfig(examListForMarkConfigSearch);
    }, [examListForMarkConfigSearch, subjectGroupListMarkConfigSearch])

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const fetchExamMarkDelete2 = useStoreActions((state) => state.exam.fetchExamMarkDelete2);

    const onDel = () => {
        if (selectedRowKeys.length === 0) {
            notification.error({
                message: 'Error',
                description: 'Please select student',
            });
            return;
        }
        let finalData: any = {
            deleteIds: {
                markInputIds: selectedValue?.map(item => item?.markinputId)
            },
            viewExamMarkProblem: serData
        }
        fetchExamMarkDelete2(finalData);

    }


    return (
        <>


            <Card className="p-0" title="Mark Problem Search" bordered={false}>

                <Form
                    layout="vertical"
                    id="markConfigListTable"
                    onFinish={searchMarkConfigList}
                    form={searchMarkConfigForm}
                >
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24, offset: 1 }} xl={{ span: 24, offset: 1 }}>
                            <Row>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="classId"
                                        label="Class Name"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Select class list" },
                                        ]}
                                    >
                                        <SelectClass onChange={(e) => { onChangeClassListForMarkConfig(e); searchMarkConfigForm.setFieldsValue({ classGroup: null, examGroup: null }) }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="groupId"
                                        label="Group"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Select group list" },
                                        ]}
                                    >
                                        <Select placeholder="Group List" style={{ width: "100%" }} >
                                            {groupListForMarkConfig ? (
                                                groupListForMarkConfig.map((type: any, idx) => (
                                                    <Option key={type.groupId} value={type.groupId}>
                                                        {type.groupName}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Group</Option>
                                            )}
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="examId"
                                        label="Exam"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Select Exam" },
                                        ]}
                                    >
                                        <Select placeholder="Group Exam" style={{ width: "100%" }}>
                                            {examListForMarkConfig ? (
                                                examListForMarkConfig.map((type: any, idx) => (
                                                    <Option key={type.examId} value={type.examId}>
                                                        {type.examName}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Exam</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >Search</Button>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    <br />
                </Form>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />

                {viewExamMarkProblem?.length > 0 &&
                    <>
                        <div >
                            {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                            <Table
                                antTableProps={{
                                    showHeader: true,
                                    columns: cols,
                                    dataSource: viewExamMarkProblem,
                                    filterData: viewExamMarkProblem,
                                    pagination: false,
                                    bordered: true,
                                    rowKey: "markinputId",
                                    rowSelection: rowSelection,
                                    style: { maxHeight: 500 }
                                }}
                                mobileBreakPoint={768}
                            />
                        </div>
                        <br />
                        <Popconfirm
                            title="Are you sure to delete this?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() =>
                                onDel()
                            }
                        >
                            <Tooltip title="Delete">
                                <Button danger icon={<DeleteOutlined />} style={{ float: 'right' }}> Delete</Button>
                            </Tooltip>
                        </Popconfirm>

                    </>
                }

            </Card>

        </>
    )
}