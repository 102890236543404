
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import TableView from '../../../../contents/AntTableResponsive';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectFeeHead } from '../../../select/SelectFeeHead';
import { Excel } from 'antd-table-saveas-excel';
import $ from 'jquery';
require('jspdf-autotable');



export default function FeeSubHeadCollectionDetails() {

    const [reportForm] = Form.useForm();
    //const fetchshowStudentFeeHeadPaymentView = useStoreActions((state) => state.saccount.fetchshowStudentFeeHeadPaymentView);
    const fetchstudentFeeSubHeadDetails = useStoreActions((state) => state.saccount.fetchstudentFeeSubHeadDetails);
    const studentFeeSubHeadDetails = useStoreState((state) => state.saccount.studentFeeSubHeadDetails);
    const loading = useStoreState((state) => state.student.loading);

    const [tableData, setTableData] = useState<any>([])
    const formSubmit = (value) => {
        let postData: any = {
            academicYear: value.yearId,
            classConfigId: value.classConfigId,
            feeHeadId: value.feeHeadId,
        }
        fetchstudentFeeSubHeadDetails(postData);
    }
    useEffect(() => {
        setTableData(studentFeeSubHeadDetails?.studentList)
    }, [studentFeeSubHeadDetails])


    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        { title: 'Total Amount', dataIndex: 'totalAmount', key: 'totalAmount', showOnResponse: true, showOnDesktop: true },
        ...(studentFeeSubHeadDetails?.feeSubHeadName1 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName1, dataIndex: 'feeSubHeadAmount1', key: 'feeSubHeadAmount1', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName2 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName2, dataIndex: 'feeSubHeadAmount2', key: 'feeSubHeadAmount2', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName3 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName3, dataIndex: 'feeSubHeadAmount3', key: 'feeSubHeadAmount3', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName4 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName4, dataIndex: 'feeSubHeadAmount4', key: 'feeSubHeadAmount4', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName5 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName5, dataIndex: 'feeSubHeadAmount5', key: 'feeSubHeadAmount5', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName6 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName6, dataIndex: 'feeSubHeadAmount6', key: 'feeSubHeadAmount6', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName7 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName7, dataIndex: 'feeSubHeadAmount7', key: 'feeSubHeadAmount7', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName8 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName8, dataIndex: 'feeSubHeadAmount8', key: 'feeSubHeadAmount8', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName9 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName9, dataIndex: 'feeSubHeadAmount9', key: 'feeSubHeadAmount9', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName10 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName10, dataIndex: 'feeSubHeadAmount10', key: 'feeSubHeadAmount10', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName11 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName11, dataIndex: 'feeSubHeadAmount11', key: 'feeSubHeadAmount11', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName12 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName12, dataIndex: 'feeSubHeadAmount12', key: 'feeSubHeadAmount12', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName13 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName13, dataIndex: 'feeSubHeadAmount13', key: 'feeSubHeadAmount13', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName14 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName14, dataIndex: 'feeSubHeadAmount14', key: 'feeSubHeadAmount14', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName15 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName15, dataIndex: 'feeSubHeadAmount15', key: 'feeSubHeadAmount15', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName16 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName16, dataIndex: 'feeSubHeadAmount16', key: 'feeSubHeadAmount16', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName17 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName17, dataIndex: 'feeSubHeadAmount17', key: 'feeSubHeadAmount17', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName18 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName18, dataIndex: 'feeSubHeadAmount18', key: 'feeSubHeadAmount18', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName19 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName19, dataIndex: 'feeSubHeadAmount19', key: 'feeSubHeadAmount19', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName20 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName20, dataIndex: 'feeSubHeadAmount20', key: 'feeSubHeadAmount20', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName21 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName21, dataIndex: 'feeSubHeadAmount21', key: 'feeSubHeadAmount21', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName22 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName22, dataIndex: 'feeSubHeadAmount22', key: 'feeSubHeadAmount22', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName23 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName23, dataIndex: 'feeSubHeadAmount23', key: 'feeSubHeadAmount23', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName24 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName24, dataIndex: 'feeSubHeadAmount24', key: 'feeSubHeadAmount24', showOnResponse: true, showOnDesktop: true }] : [],
        ...(studentFeeSubHeadDetails?.feeSubHeadName25 !== '') ? [{ title: studentFeeSubHeadDetails?.feeSubHeadName25, dataIndex: 'feeSubHeadAmount25', key: 'feeSubHeadAmount25', showOnResponse: true, showOnDesktop: true }] : [],
    ];


    return (
        <Card title="Subhead Collection">
            <>
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 3 }} xl={{ span: 20, offset: 3 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={reportForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="yearId"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="classConfigId"
                                        label="Select Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select Section" },
                                        ]}
                                    >
                                        <SelectClassConfig />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="feeHeadId"
                                        label="Select Feehead"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select feehead" },
                                        ]}
                                    >
                                        <SelectFeeHead />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: studentFeeSubHeadDetails !== null ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: studentListColumn,
                                rowKey: "customStudentId",
                                dataSource: tableData,
                                filterData: tableData,
                                pagination: true,
                                bordered: true,
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                        {/* <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} onClick={() => deleteStudent()} className="float-right">Delete</Button> */}
                    </Col>
                    <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>
                            < Button
                                type='primary'
                                icon={<FileExcelOutlined />}
                                onClick={() => {
                                    const excel: any = new Excel();
                                    excel
                                        .addSheet(`Sheet1`)
                                        .addColumns(studentListColumn)
                                        .addDataSource(tableData)
                                        .saveAs(`${$(".yearSelect").text()}-${$(".sectionSelect").text()} -${$(".feeSelect").text()} Subhead Collection.xlsx`);
                                }}
                            >
                                Download Excel
                            </ Button >
                        </Space>
                    </Col>
                </Row>

            </>
        </Card>
    )
}
