import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { EditOutlined, FileExcelOutlined, SearchOutlined, SwitcherOutlined } from '@ant-design/icons';
import { Column } from '@ant-design/plots';
export default function GraphicalView() {


    const { Option } = Select;

    const fetchbillCollection = useStoreActions((state) => state.customerSupport.fetchbillCollection);
    const fetchmonthWiseSpg = useStoreActions((state) => state.customerSupport.fetchmonthWiseSpg);
    const fetchserviceCharge = useStoreActions((state) => state.customerSupport.fetchserviceCharge);
    const loading = useStoreState((state) => state.customerSupport.loading);
    const billCollection = useStoreState((state) => state.customerSupport.billCollection);
    const monthWiseSpg = useStoreState((state) => state.customerSupport.monthWiseSpg);
    const serviceCharge = useStoreState((state) => state.customerSupport.serviceCharge);

    let year = new Date().getFullYear();

    // useEffect(() => {
    //     fetchInstiuteListcore();
    // }, []);

    const [search, setSearch] = useState<boolean>(false);
    const submitFormForInstituteList = (value) => {
        fetchbillCollection(value.year);
        fetchmonthWiseSpg(value.year);
        fetchserviceCharge(value.year);
        setTimeout(() => {
            setSearch(true);
        }, 1000);
    }





    return (
        <>
            <Card title="Graphical View">

                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 2 }} xl={{ span: 20, offset: 2 }}>
                        <Form
                            layout="vertical"
                            id="submitForm"
                            onFinish={submitFormForInstituteList}
                        >
                            <Row>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>

                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="year"
                                        label="Year:"
                                        className="title-Text"
                                        initialValue={year}
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                        style={{ width: "100%" }}
                                    >
                                        <Select className='yearSelectx' placeholder="Select Year" allowClear style={{ width: "100%" }}>
                                            <Option value={year - 2}>{year - 2}</Option>
                                            <Option value={year - 1}>{year - 1}</Option>
                                            <Option value={year}>{year}</Option>
                                            <Option value={year + 1}>{year + 1}</Option>
                                            <Option value={year + 2}>{year + 2}</Option>
                                        </Select>

                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                {search === true &&
                    <>
                        <Row gutter={0}>
                            <Col span={8} >
                                <Card title="Bill Collection">
                                    {billCollection !== null ?
                                        <Column
                                            data={billCollection}
                                            height={370}
                                            xField="monthName"
                                            yField="collectedAmount"
                                            color='blue'
                                        /> : <div>
                                            <strong style={{ color: "red", fontSize: 16 }}> No Data Found</strong>
                                        </div>
                                    }
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="SPG Live">
                                    {monthWiseSpg !== null ?
                                        <Column
                                            data={monthWiseSpg}
                                            height={370}
                                            xField="monthName"
                                            yField="totalSpg"
                                            color='blue'
                                        /> : <div>
                                            <strong style={{ color: "red", fontSize: 16 }}> No Data Found</strong>
                                        </div>
                                    }
                                </Card>
                            </Col>                        <Col span={8}>
                                <Card title="Service Charge">
                                    {serviceCharge !== null ?
                                        <Column
                                            data={serviceCharge}
                                            height={370}
                                            xField="monthName"
                                            yField="amount"
                                            color='blue'
                                        /> : <div>
                                            <strong style={{ color: "red", fontSize: 16 }}> No Data Found</strong>
                                        </div>
                                    }
                                </Card>
                            </Col>
                        </Row>

                    </>
                }
            </Card>

        </>
    )
}