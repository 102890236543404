import { Button, Card, Col, Form, Input, Modal, Row, Select, Skeleton, Space, Table } from 'antd';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { EditOutlined, FileExcelOutlined, FilePdfOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../utils/pdf';
import Text from 'antd/lib/typography/Text';
import { Excel } from 'antd-table-saveas-excel';

export default function BillingUpdate() {

    const fetchinstituteBillingList = useStoreActions((state) => state.customerSupport.fetchinstituteBillingList2);
    const instituteBillingList = useStoreState((state) => state.customerSupport.instituteBillingList2)
    const loading = useStoreState((state) => state.customerSupport.loading);
    const [myform] = Form.useForm();
    const { Option } = Select;

    const sectionWiseAdmissionReportFormSubmit = (value) => {
        let postData: any = {
            "instituteStatus": value.instituteStatus === 3 ? null : value.instituteStatus,
            "billStatus": value.billStatus === 3 ? null : value.billStatus,
            "billingMonth": value.billingMonth === 'xx' ? '' : value.billingMonth,
            "billingYear": value.billingYear,
            "instituteOwner": "SDL"
        }
        fetchinstituteBillingList(postData);
    }
    const column = [
        { title: 'Institute ID', dataIndex: 'instituteId', key: "instituteId", showOnResponse: true, showOnDesktop: true },
        { title: 'Institute Name', dataIndex: 'instituteName', key: "instituteName", showOnResponse: true, showOnDesktop: true },
        { title: 'Bill Type', dataIndex: 'billType', key: "billType", showOnResponse: true, showOnDesktop: true },
        { title: 'Bill Status', dataIndex: 'billStatusString', key: "billStatusString", showOnResponse: true, showOnDesktop: true },
        { title: 'Actual Bill', dataIndex: 'actualBillAmount', key: "actualBillAmount", showOnResponse: true, showOnDesktop: true },
        { title: 'Payable Amount', dataIndex: 'payableAmount', key: "payableAmount", showOnResponse: true, showOnDesktop: true },
        { title: 'Company Amount', dataIndex: 'companyAmount', key: "companyAmount", showOnResponse: true, showOnDesktop: true },
        { title: 'Partner Name', dataIndex: 'partnerName', key: "partnerName", showOnResponse: true, showOnDesktop: true },
        { title: 'Discount', dataIndex: 'discountAmount', key: "discountAmount", showOnResponse: true, showOnDesktop: true },
        { title: 'Commission', dataIndex: 'commission', key: "commission", showOnResponse: true, showOnDesktop: true },

    ];
    let year = new Date().getFullYear();

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");
        var detailsc = `Institute Billing List`;
        pdfDataL(doc, "");
        doc.text(detailsc, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        var col = [
            "Institute ID",
            "Institute Name",
            "Bill Type",
            "Bill Status",
            "Actual Bill",
            "Payable Amount",
            "Company Amount",
            "Partner Name",
            "Discount",
            "Commission",
        ].filter(Boolean);

        var rows: any = [];


        instituteBillingList.forEach(element => {
            var temp: any = [
                element.instituteId,
                element.instituteName,
                element.billType,
                element.billStatusString,
                element.actualBillAmount,
                element.payableAmount,
                element.companyAmount,
                element.partnerName,
                element.discountAmount,
                element.commission,
            ];
            rows.push(temp);
        });


        let first = doc.autoTable.previous;
        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
            { title: "", dataKey: "b3" },
            { title: "", dataKey: "b4" },
            { title: "", dataKey: "b5" },
            { title: "", dataKey: "b6" },

        ], [
            {
                b1: "Billing Year",
                b2: $(".yearSelectx").text(),
                b3: 'Billing Month',
                b4: $(".billMonthx").text(),
                b5: 'Bill Status',
                b6: $(".billStatusx").text(),

            }
        ], {
            startY: 45,
            showHeader: "never",
            theme: 'grid',

            // addPageContent: pageContent,
        });
        doc.autoTable(col, rows, {
            startY: doc.autoTable.previous.finalY + 5,
            showHeader: "firstPage",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                overflow: 'linebreak',
                lineColor: [224, 224, 224]
            },
            styles: {
                overflow: 'linebreak',
            },
            columnStyles: {
                ledgerName: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 75
                },
                debit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                },
                credit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                }
            },


        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(detailsc + ".pdf");

    }



    return (
        <>
            <Card title="Institute Billing List">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 2 }} xl={{ span: 20, offset: 2 }}>
                        <Form
                            layout="vertical"
                            id="instructionForm"
                            onFinish={sectionWiseAdmissionReportFormSubmit}
                            form={myform}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="billingYear"
                                        label="Year:"
                                        className="title-Text"
                                        initialValue={year}
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                        style={{ width: "100%" }}
                                    >
                                        <Select className='yearSelectx' placeholder="Select Year" allowClear style={{ width: "100%" }}>
                                            <Option value={year - 1}>{year - 1}</Option>
                                            <Option value={year}>{year}</Option>
                                            <Option value={year + 1}>{year + 1}</Option>
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="billingMonth"
                                        label="Month:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select month" },
                                        ]}
                                    >
                                        <Select className='billMonthx' placeholder="Select Month" allowClear style={{ width: "100%" }}>
                                            <Option value="xx">Not Applicable</Option>
                                            <Option value="January-June">January-June</Option>
                                            <Option value="July-December">July-December</Option>
                                            <Option value="January">January</Option>
                                            <Option value="February">February</Option>
                                            <Option value="March">March</Option>
                                            <Option value="April">April</Option>
                                            <Option value="May">May</Option>
                                            <Option value="June">June</Option>
                                            <Option value="July">July</Option>
                                            <Option value="August">August</Option>
                                            <Option value="September">September</Option>
                                            <Option value="October">October</Option>
                                            <Option value="November">November</Option>
                                            <Option value="December">December</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="instituteStatus"
                                        label="Institute Staus:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select Institute Staus" },
                                        ]}
                                        style={{ width: "100%" }}
                                    >
                                        <Select className='billStatusx' placeholder="Institute Status" allowClear style={{ width: "100%" }}>
                                            <Option value={1}>Active</Option>
                                            <Option value={0}>Inactive</Option>
                                            <Option value={3}>All</Option>
                                        </Select>

                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="billStatus"
                                        label="Bill Staus:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select bill Staus" },
                                        ]}
                                        style={{ width: "100%" }}
                                    >
                                        <Select className='billStatusx' placeholder="Bill Status" allowClear style={{ width: "100%" }}>
                                            <Option value={1}>Paid</Option>
                                            <Option value={0}>Unpaid</Option>
                                            <Option value={3}>All</Option>
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <br />
                {instituteBillingList !== null &&
                    <>
                        <Row className="m-t-mo-30">
                            <Col span="24">
                                <div className="datatable-responsive-demo">
                                    <TableView
                                        antTableProps={{
                                            showHeader: true,
                                            columns: column,
                                            dataSource: instituteBillingList,
                                            filterData: instituteBillingList,
                                            pagination: true,
                                            bordered: true,
                                            rowKey: "billId",
                                            summary: function () {
                                                let actualBillAmountx = 0;
                                                let payableAmountx = 0;
                                                let companyAmountx = 0;
                                                let discountAmountx = 0;
                                                let commissionx = 0;

                                                instituteBillingList?.forEach(({ actualBillAmount, payableAmount, companyAmount, discountAmount, commission }) => {
                                                    actualBillAmountx += actualBillAmount;
                                                    payableAmountx += payableAmount;
                                                    companyAmountx += companyAmount;
                                                    discountAmountx += discountAmount;
                                                    commissionx += commission;
                                                });

                                                return (
                                                    <>
                                                        <Table.Summary.Row>
                                                            <Table.Summary.Cell index={0}><Text type="danger" strong>Total</Text> </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={1}>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={2}>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={3}>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={4}>
                                                                <Text type="danger" strong>{actualBillAmountx.toFixed(2)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={5}>
                                                                <Text type="danger" strong>{payableAmountx.toFixed(2)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={6}>
                                                                <Text type="danger" strong>{companyAmountx.toFixed(2)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={7}>

                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={8}>
                                                                <Text type="danger" strong>{discountAmountx.toFixed(2)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={9}>
                                                                <Text type="danger" strong>{commissionx.toFixed(2)}</Text>
                                                            </Table.Summary.Cell>
                                                        </Table.Summary.Row>

                                                    </>
                                                );
                                            }
                                        }}
                                        mobileBreakPoint={768}
                                    />
                                </div>

                            </Col>

                        </Row>
                        <Space size={'large'} className="float-right">
                            < Button
                                type='primary'
                                icon={<FileExcelOutlined />}
                                onClick={() => {
                                    const excel: any = new Excel();
                                    excel
                                        .addSheet(`Sheet1`)
                                        .addColumns(column)
                                        .addDataSource(instituteBillingList)
                                        .saveAs(`${$(".yearSelectx").text()}-${$(".billMonthx").text()}-${$(".billStatusx").text()} Institute Billing List.xlsx`);
                                }}
                            >
                                Download Excel
                            </ Button >
                            <Button type="primary" htmlType="submit" icon={<FilePdfOutlined />} onClick={() => exportPdf()} >Download PDF</Button>
                        </Space>
                    </>
                }
            </Card>


        </>
    )
}