import { Button, Card, Col, DatePicker, Form, Row, Skeleton, Space, Table } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, lpowerdbypdf, ldatepdf, pdfGenerate } from '../../../utils/pdf';
import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import TableView from "../../../../contents/AntTableResponsive";
import ReactExport from "react-export-excel";
import { SelectLedgerDFPSMulti } from '../../../select/SelectLedgerDFPSMulti';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import Text from 'antd/lib/typography/Text';
import { SelectFeeHead } from '../../../select/SelectFeeHead';
import { Excel } from 'antd-table-saveas-excel';
import { SelectFeeSubHeadMultiple } from '../../../select/SelectFeeSubHeadMultiple';
require('jspdf-autotable');



let fd = '';
let td = '';
let totalfeeDue = '';
let totalfineDue = '';
let totaltotalDue = '';

let ttotalDue = 0;
let ffeeDue = 0;
let ffineDue = 0;
export default function SectionDueSpecificDue(props) {
    const specificDue = useStoreState((state) => state.saccount.specificDue);
    const fetchspecificDue = useStoreActions((state) => state.saccount.fetchspecificDue);
    const loading = useStoreState((state) => state.saccount.loading);
    const itemListFetch = useStoreActions((state) => state.saccount.fetchfeeSubHeadConfigurationList);
    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        let payload = {
            "academicYear": value?.academicYear,
            "classConfigId": value?.classConfigId,
            "feeHeadId": value?.feeHeadId,
            "feeSubHeadIds": value?.feeSubHeadIds
          }
         fetchspecificDue(payload);
    };

    const columns = [

        {
            title: 'Id',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },        
        {
            title: 'Student Group',
            dataIndex: 'studentGroup',
            key: 'studentGroup',
            showOnResponse: true,
            showOnDesktop: true,
        },        
        {
            title: 'Student Category',
            dataIndex: 'studentCategory',
            key: 'studentCategory',
            showOnResponse: true,
            showOnDesktop: true,
        },        
        {
            title: 'Fee Head',
            dataIndex: 'feeHead',
            key: 'feeHead',
            showOnResponse: true,
            showOnDesktop: true,
        },        
        {
            title: 'Fee Sub Head',
            dataIndex: 'feeSubhead',
            key: 'feeSubhead',
            showOnResponse: true,
            showOnDesktop: true,
        },        
        {
            title: 'Fee Due',
            dataIndex: 'feeDue',
            key: 'feeDue',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Fine Due',
            dataIndex: 'fineDue',
            key: 'fineDue',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Total Due',
            dataIndex: 'totalDue',
            key: 'totalDue',
            showOnResponse: true,
            showOnDesktop: true,
        }

    ];

    function exportPdf() {
        let secName = $('.classConfigId .ant-select-selection-item').text();
        let acYear = $('.academicYear .ant-select-selection-item').text();
        var details = `Specific Fee Sub Head Section Due List ${secName} and Academic Year ${acYear}`;
        totalfeeDue = specificDue.reduce(function (acc, obj) { return acc + obj?.feeDue }, 0);
        totalfineDue = specificDue.reduce(function (acc, obj) { return acc + obj?.fineDue }, 0);
        totaltotalDue = specificDue.reduce(function (acc, obj) { return acc + obj?.totalDue }, 0);
        var doc = new jsPDF("l", "mm", "a4");


        pdfDataL(doc, details);

        var col = ["Student ID", "Name", "Roll", "Student Group", "Student Category", "Fee Head", "Fee Sub Head", "Fee Due", "Fine Due", "Total Due"];

        var rows: any = [];

        var temp: any = []


        specificDue.forEach(element => {
            var temp = [element.customStudentId, element.studentName, element.studentRoll,element.studentGroup,element.studentCategory,element.feeHead,element.feeSubhead,element.feeDue,element.fineDue,
            element.totalDue];
            rows.push(temp);
        });

        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };

        doc.autoTable(col, rows, {
            columnStyles: {
                0: { overflow: 'linebreak' },
                1: { overflow: 'linebreak' },
                2: { overflow: 'linebreak' },
                4: { overflow: 'linebreak' },
                5: { overflow: 'linebreak' },
                6: { overflow: 'linebreak' },
                7: { overflow: 'linebreak' },
                8: { overflow: 'linebreak' },
                9: { overflow: 'linebreak' },
                3: { overflow: 'linebreak' },
            },
            headerStyles: {
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },

            theme: "grid",
            startY: 45,
            addPageContent: pageContent
        });

        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
            { title: "", dataKey: "b3" },
            { title: "", dataKey: "b4" },
            { title: "", dataKey: "b5" },
            { title: "", dataKey: "b6" },
        ], [
            {
                b1: "Total Fee Due:",
                b2: totalfeeDue,
                b3: "Total Fine Due:",
                b4: totalfineDue,                
                b5: "Total Due:",
                b6: totaltotalDue,
            }
        ], {
            startY: doc.autoTable.previous.finalY + 5,
            showHeader: "never",
            theme: 'grid',
            styles: { fillColor: [255, 255, 255], lineColor: 255, },
            columnStyles: {
                b1: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 40,
                    fontStyle: 'bold'
                },
                b2: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 40
                },               
                 b3: {
                    halign: "right",
                    fontStyle: 'bold',
                    fontSize: 8,
                    columnWidth: 40
                },                 
                b4: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 40
                },
                b5: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 40,
                    fontStyle: 'bold'
                },                 
                b6: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 40
                },

            },

            // addPageContent: pageContent,
        });



        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }

        doc.save(details + ".pdf");

    }

    const [sh, setsh]= useState<any>([]);

   

    return (
        <>
            <>
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={1} xl={1}> </Col>



                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="academicYear"
                                label="Academic Year:"
                                className="academicYear"
                                rules={[
                                    { required: true, message: "Please select year" },
                                ]}
                            >

                                <SelectAcademicYear />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="classConfigId"
                                label="Section"
                                className="classConfigId"
                                rules={[
                                    { required: true, message: "Please select section" },
                                ]}
                            >

                                <SelectClassConfig />
                            </Form.Item>
                        </Col>                        
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="feeHeadId"
                                label="Fee Head"
                                className="feeHeadId"
                                rules={[
                                    { required: true, message: "Please select fee" },
                                ]}
                            >

                                <SelectFeeHead  onChange={e=>{itemListFetch(e);setsh([]);reportForm.setFieldsValue({feeSubHeadIds:null})}} />
                            </Form.Item>
                        </Col>                        
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="feeSubHeadIds"
                                label="Fee Subhead"
                                className="feeSubHeadIds"
                                rules={[
                                    { required: true, message: "Please select fee subhead" },
                                ]}
                            >

                                <SelectFeeSubHeadMultiple selected={sh} onChange={e=>setsh(e)}/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    </Row>
                </Form>
                {specificDue === null && < Skeleton loading={loading} paragraph={{ rows: 10 }} />}
                {specificDue?.length > 0 &&
                    <div >
                        <TableView 
                            antTableProps={{
                                showHeader: true,
                                columns: columns,
                                dataSource: specificDue,
                                filterData: specificDue,
                                pagination: false,
                                bordered: true,
                                style:{maxHeight:500},
                                rowKey: "identificationId",
                                summary: function () {
                                    ttotalDue = 0;
                                    ffeeDue = 0;
                                    ffineDue = 0;

                                    specificDue?.forEach(({ totalDue, feeDue, fineDue }) => {
                                        ttotalDue += totalDue;
                                        ffeeDue += feeDue;
                                        ffineDue += fineDue;
                                    });

                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell index={0}><Text type="danger" strong>Total</Text> </Table.Summary.Cell>
                                                <Table.Summary.Cell index={1}>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={2}>
                                                </Table.Summary.Cell>                                                
                                                <Table.Summary.Cell index={3}>
                                                </Table.Summary.Cell>                              
                                                <Table.Summary.Cell index={4}>
                                                </Table.Summary.Cell>                                                
                                                <Table.Summary.Cell index={5}>
                                                </Table.Summary.Cell>                                                
                                                <Table.Summary.Cell index={6}>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={7}>
                                                    <Text type="danger" strong>{ffeeDue}</Text>
                                                </Table.Summary.Cell>                                                
                                                <Table.Summary.Cell index={8}>
                                                    <Text type="danger" strong>{ffineDue}</Text>
                                                </Table.Summary.Cell>                                                
                                                <Table.Summary.Cell index={9}>
                                                    <Text type="danger" strong>{ttotalDue}</Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>

                                        </>
                                    );
                                }
                            }}
                            mobileBreakPoint={768}

                        />
                        <Space style={{ float: 'right' }} size="middle">
                        < Button
                                type='primary'
                                icon={<FileExcelOutlined />}
                                onClick={() => {
                                    const excel: any = new Excel();
                                    excel
                                        .addSheet(`Sheet1`)
                                        .addColumns(columns)
                                        .addDataSource(specificDue)
                                        .saveAs(`Specific Fee SubHead Section Due Lis ${$('.classConfigId .ant-select-selection-item').text()} and Academic Year ${$('.academicYear .ant-select-selection-item').text()}.xlsx`);
                                }}
                            >
                                Download Excel
                            </ Button >
                            <Button type="primary" icon={<FilePdfOutlined />} onClick={exportPdf} > Download PDF </Button>
                        </Space>
                    </div>
                }
            </>
        </>
    )
}
