
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Skeleton, Table, Upload } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SaveOutlined, SearchOutlined, SettingOutlined, UploadOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import xlsxParser from 'xlsx-parse-json';
import $ from 'jquery';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { Excel } from 'antd-table-saveas-excel';
import { SelectFeeSubHeadByFeeHeadSingle } from '../../../select/SelectFeeSubHeadByFeeHeadSingle';
import { SelectFeeHead } from '../../../select/SelectFeeHead';


export default function IndividualFeeAssignList() {

    const [form2] = Form.useForm();
    const fetchfeeSubHeadConfigurationList = useStoreActions((state) => state.saccount.fetchfeeSubHeadConfigurationList);
    const studentIndividualFeeAssignListForSave = useStoreState((state) => state.saccount.studentIndividualFeeAssignListForSave);
    const fetchstudentIndividualFeeAssignListForSave = useStoreActions((state) => state.saccount.fetchstudentIndividualFeeAssignListForSave);
    const saveStudentIndividualFeeAssign = useStoreActions((state) => state.saccount.saveStudentIndividualFeeAssign);
    const loading = useStoreState((state) => state.student.loading);

    const [data, setData] = useState<any>('');

    const [feeHeadId, setFeeHeadId] = useState<any>(null);
    const [feeSubHeadId, setFeeSubHeadId] = useState<any>(null);

    const formSubmit = (value) => {
        setData(value);
        fetchstudentIndividualFeeAssignListForSave(value);
    }

    const [tableData, setTableData] = useState<any>([]);

    useEffect(() => {
        setTableData(studentIndividualFeeAssignListForSave)
    }, [studentIndividualFeeAssignListForSave])


    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        {
            title: "Fee Amount",
            render: (text: any, record: any, index) => (
                <InputNumber
                    min={0}
                    onChange={onchangeValue("amount", record, record?.index)}
                    value={record.amount}
                    placeholder="Amount"
                    style={{ width: 100 }}
                ></InputNumber>
            ),
        },
        Table.SELECTION_COLUMN,
    ];
    const studentListColumn2 = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        { title: 'Fee Amount', dataIndex: 'amount', key: 'studentRoll', amount: true, showOnDesktop: true },
    ];

    const onchangeValue: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...tableData];
            newData[index][key] = e;
            setTableData(newData);
        };

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onProcess = () => {
        if (selectedValue.length === 0) {
            notification.error({ message: "Please select student" });
            return
        }
        let studentList = selectedValue.map(item => {
            return {
                feeAmount: item.amount,
                identificationId: item.identificationId,
            }
        });

        let payload = {
            "feeHeadId": feeHeadId,
            "feeSubHeadId": feeSubHeadId,
            "studentInfos": studentList
        };

        setselectedRowKeys([]);
        setselectedValue([])

        saveStudentIndividualFeeAssign(payload)
        // setIsModalVisible(true);
        setTimeout(() => {
            fetchstudentIndividualFeeAssignListForSave(data);
        }, 500);
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const submitData = (value) => {
        saveStudentIndividualFeeAssign(value);
        setIsModalVisible(false);
        form.resetFields()
        setselectedRowKeys([]);;
        setTableData([]);
        setTimeout(() => {
            setTableData(studentIndividualFeeAssignListForSave)
        }, 200);
    }

    const [fileList, setFileList] = useState<any>([]);

    const onChangeFile = ({ fileList: newFileList }) => {
        newFileList[0].status = "done"
        xlsxParser
            .onFileSelection(newFileList[0]?.originFileObj, { showNullProperties: true })
            .then(data => {

                if (data?.['Sheet1'] === undefined) {
                    notification.error({ message: "Wrong Excel File" });
                    return;
                }

                if (data?.['Sheet1']?.length === 0) {
                    notification.error({ message: "Emplty Excel file" });
                    return;
                }
                let val = data?.['Sheet1']?.[0];


                let temp = data?.['Sheet1']?.map(function (item, index) {
                    return {
                        customStudentId: item['Student ID'],
                        studentName: item['Student Name'],
                        studentRoll: item["Roll"],
                        amount: item["Fee Amount"],
                    }
                })

                temp.forEach(item => {
                    for (val in item) item[val] = (item[val] === null || item[val] === undefined) ? 0 : item[val]
                });

                for (let i in studentIndividualFeeAssignListForSave) {
                    for (let j in temp) {
                          if (studentIndividualFeeAssignListForSave[i].customStudentId===temp[j].customStudentId){
                            temp[j].identificationId=studentIndividualFeeAssignListForSave[i].identificationId
                      }
                    }
                  };
                setTableData(temp);
   

            });
    };

    return (
        <>

            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24, offset: 1 }} xl={{ span: 24, offset: 1 }}>
                    <Form
                        layout="vertical"
                        id="classConfigInfo"
                        onFinish={formSubmit}
                        form={form2}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="academicYear"
                                    label="Academic Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select year" },
                                    ]}
                                >
                                    <SelectAcademicYear />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="classConfigId"
                                    label="Select Section"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select section" },
                                    ]}
                                >
                                    <SelectClassConfig />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="feeHeadId"
                                    label="Fee Head"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select fee" },
                                    ]}
                                >
                                    <SelectFeeHead onChange={e => { fetchfeeSubHeadConfigurationList(e); setFeeHeadId(e); form2.setFieldsValue({ feeSubHeadId: null }) }} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="feeSubHeadId"
                                    label="Fee Sub Head"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select fee" },
                                    ]}
                                >
                                    <SelectFeeSubHeadByFeeHeadSingle onChange={e => { setFeeSubHeadId(e) }} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                    </Form>
                </Col>
            </Row>
            {< Skeleton loading={loading} paragraph={{ rows: 10 }} />}
            {tableData?.length > 0 &&
                <Row >
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>

                        <div style={{ maxHeight: 600, overflow: "auto" }}>
                            <div style={{display:"flex", marginBottom:10}}>
                                < Button
                                    type='primary'
                                    icon={<FileExcelOutlined />}
                                    onClick={() => {
                                        const excel: any = new Excel();
                                        excel
                                            .addSheet(`Sheet1`)
                                            .addColumns(studentListColumn2)
                                            .addDataSource(tableData)
                                            .saveAs(`Individual fee assign ${$(".yearSelect").text()}-${$(".sectionSelect").text()}-${$(".feeSelect").text()}-${$(".feesselect").text()}.xlsx`);
                                    }}
                                >
                                    Download Excel
                                </ Button >
                                <Upload
                            listType="text"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            fileList={fileList}
                            onChange={onChangeFile}
                            style={{ maxWidth: 220 }}
                        >
                            {fileList.length < 1 && <><Button style={{marginLeft:10,maxHeight:32}} icon={<UploadOutlined />}>Upload Excel File</Button></>}
                        </Upload>
                            </div>
                            <Table
                                columns={studentListColumn}
                                rowSelection={rowSelection}
                                dataSource={tableData}
                                rowKey={'identificationId'}
                                pagination={false}
                            />
                        </div>

                        {/* <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} onClick={() => deleteStudent()} className="float-right">Delete</Button> */}
                    </Col>
                    <br />
                    <Col span={24}>
                        <Space size="small" style={{ float: "right", marginTop: 15 }}>

                            <Button type="primary" icon={<SaveOutlined />} onClick={() => onProcess()} >Save</Button>
                        </Space>
                    </Col>
                </Row>
            }
            <Modal
                title="Fee Discard"
                visible={isModalVisible}
                // onOk={handleOk}
                // okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                centered
            >
                <Form
                    layout="vertical"
                    onFinish={submitData}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24}>
                            <Form.Item
                                name="feeSubHeadIds"
                                label="Fee Sub Head"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select fee sub head" },
                                ]}
                            >

                                <SelectFeeSubHeadByFeeHeadSingle />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                            Save
                        </Button>
                    </div>
                </Form>

            </Modal>

        </>
    )
}
