
import { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd';
import TableView from '../../../contents/AntTableResponsive';
import { SelectDistrict2 } from '../../select/SelectDistrict2';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { ROUTES } from '../../../contents/routes';

export default function PartnerInfo() {

    const fetchPartnerInfoList = useStoreActions((state) => state.customerSupport.fetchPartnerList);
    const partnerList = useStoreState((state) => state.customerSupport.partnerList);
    const updatePartnerInfo = useStoreActions((state) => state.customerSupport.updatePartnerinformation);
    const deletePartnerInfo = useStoreActions((state) => state.customerSupport.deletePartnerInformation);
    
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [updateform] = Form.useForm();
    const [partnerId, setPartnerId] = useState<any>(null);
    const [districtId, setDistrictId] = useState<any>(null);

    const updateInfoForm = (value) => {
        let payload = {
            "partnerId": partnerId,
            "partnerName": value.partnerName,
            "commssionPercent": value.commssionPercent,
            "districtId": value.districtId,
            "email": value.email,
            "address": value.address,
            "mobileNo": value.mobileNo
        }
        updatePartnerInfo(payload);
        updateform.resetFields();
        setIsModalVisible(false);
    
    };

    const partnerInfoDelete = () =>{
        let payload = {
            "partnerId": partnerId,
            
        }
        deletePartnerInfo(payload);
        setIsDeleteModalVisible(false);
    };

    useEffect(() => {
        fetchPartnerInfoList();
    }, []);

    const columns = [

        { title: 'Partner Name', dataIndex: 'partnerName', key: "partnerName", showOnResponse: true, showOnDesktop: true },
        { title: 'Mobile No.', dataIndex: 'mobileNo', key: "mobileNo", showOnResponse: true, showOnDesktop: true },
        { title: 'Email', dataIndex: 'email', key: "email", showOnResponse: true, showOnDesktop: true },
        { title: 'District', dataIndex: 'districtName', key: "districtName", showOnResponse: true, showOnDesktop: true },
        { title: 'Address', dataIndex: 'address', key: "address", showOnResponse: true, showOnDesktop: true },
        { title: 'Commission', dataIndex: 'commssionPercent', key: "commssionPercent", showOnResponse: true, showOnDesktop: true },
        {
            title: 'Action', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true, render: (text, record: any, index) =>
                <>
                    <Space size="middle">
                        <Tooltip title="Edit">
                            <Button type='primary' icon={<EditOutlined />} onClick={() => {
                                setIsModalVisible(true);
                                setPartnerId(record?.partnerId);
                                setDistrictId(record?.districtId);
                                updateform.setFieldsValue({
                                    partnerName: record.partnerName,
                                    mobileNo: record.mobileNo,
                                    email: record.email,
                                    address: record.address,
                                    districtId: record.districtId,
                                    commssionPercent: record.commssionPercent,
      
                                });
                            }}
                            />
                        </Tooltip>
                    </Space>
                </>
        },

        {
            title: 'Action', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true, render: (text, record: any, index) =>
                <>
                    <Space size="middle">
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} onClick={() => {
                                setIsDeleteModalVisible(true);
                                setPartnerId(record?.partnerId);
                                
                            }}
                            />
                        </Tooltip>
                    </Space>
                </>
        }
    ]

    return (
        <>
            <Card title="Partner List"  extra={ <strong><Link to={ROUTES.PARTNER_INFO_SAVE} className="nav-text" >Add Partner</Link></strong>  }>
                <Row className="m-t-mo-30">
                    <Col span={24}>
                        <div className="datatable-responsive-demo">
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: partnerList,
                                    filterData: partnerList,
                                    pagination: true,
                                    bordered: true,
                                    rowKey: "partnerId",
                                }}
                                mobileBreakPoint={768}
                            />
                        </div>
                    </Col>
                </Row>
            </Card>


            <Modal
                title="Update Partner Info"
                visible={isModalVisible}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"
                    onFinish={updateInfoForm}
                    id="update"
                    form={updateform}
                >
                    <Form.Item
                        name="partnerName"
                        label="Partner Name"
                        className="title-Text"
                        rules={[
                          { required: true, message: "Partner Name is required." },
                      ]}
        
                    >
                        <Input placeholder='Partner Name' />

                    </Form.Item>                    
                    
                    <Form.Item
                        name="mobileNo"
                        label="Mobile No."
                        className="title-Text"
                        rules={[
                            { required: true, message: "Mobile No. is required." },
                        ]}
                    >
                        <Input placeholder="Mobile No" />

                    </Form.Item>
                    
                    <Form.Item
                        name="email"
                        label="Email "
                        className="title-Text"
                        rules={[
                            { required: true, message: "Email is required." },
                        ]}
                    >
                        <Input placeholder="Email" />

                    </Form.Item>

                    <Form.Item
                        name="districtId"
                        label="District"
                        className="title-Text"
                        rules={[
                            { required: true, message: "District is required." },
                        ]}
                    >
                    <SelectDistrict2 selected={districtId} onChange={(e)=>setDistrictId(e)}/>

                    </Form.Item>
                    
                    <Form.Item
                        name="address"
                        label="Address"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Address is required." },
                        ]}
                    >
                    <Input.TextArea placeholder="Address" />

                    </Form.Item>

                    <Form.Item
                        name="commssionPercent"
                        label="Commssion %"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Commssion is required." },
                        ]}
                    >
                    <Input.TextArea placeholder="Commssion" />

                    </Form.Item>

                </Form>

            </Modal>


            <Modal
                title="Delete Partner Info"
                visible={isDeleteModalVisible}
                okButtonProps={{ form: 'delete', htmlType: 'submit' }}
                onCancel={() => setIsDeleteModalVisible(false)}
                cancelText="No"
                okText="Yes"
                centered
            >
                

                <Form
                    layout="vertical"
                    onFinish={partnerInfoDelete}
                    id="delete"
                >

                    Are You Sure To Delete The Partner ?

                </Form>

            </Modal>
        </>
    )
}
