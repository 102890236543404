import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../select/SelectAcademicYear';
import TableView from '../../../contents/AntTableResponsive';

import $ from 'jquery';
import jsPDF from "jspdf";

import { FilePdfOutlined } from '@ant-design/icons';
import ReactExport from "react-export-excel";
require('jspdf-autotable');
const { Option } = Select;

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

export default function OnlineAdmissionReportPaidUnpaid() {


    const [paidUnpaidReportForm] = Form.useForm();
    const fetchOnlineAdmissionReportPaidUnpaid = useStoreActions((state) => state.onlineAdmission.fetchOnlineAdmissionReportPaidUnpaid);
    const onlineAdmissionReportPaidUnpaidList = useStoreState((state) =>  state.onlineAdmission.onlineAdmissionReportPaidUnpaidList);
    const loading = useStoreState((state) =>  state.student.loading);

    const formSubmit = (value) => {
        let postData:any = {
            academicYear: value.academicYear,
            paymentStatus: value.paymentStatus
        }
        fetchOnlineAdmissionReportPaidUnpaid(postData);
    }

    const studentListColumn = [
        { title: 'Registration Id', dataIndex: 'registrationId', key: 'registrationId', showOnResponse: true, showOnDesktop: true  },
        { title: 'Applicant Name', dataIndex: 'applicantName', key: 'applicantName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Class Name', dataIndex: 'className', key: 'className', showOnResponse: true, showOnDesktop: true  },
        { title: 'Group Name', dataIndex: 'groupName', key: 'groupName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Roll', dataIndex: 'roll', key: 'roll', showOnResponse: true, showOnDesktop: true  },
        { title: 'Gender', dataIndex: 'gender', key: 'gender', showOnResponse: true, showOnDesktop: true  },
        { title: 'Blood Group', dataIndex: 'bloodGroup', key: 'bloodGroup', showOnResponse: true, showOnDesktop: true  },
        { title: 'Date of Birth', dataIndex: 'dateOfBirthString', key: 'dateOfBirthString', showOnResponse: true, showOnDesktop: true  },
        { title: 'Father Name', dataIndex: 'fatherName', key: 'fatherName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Guardian Mobile', dataIndex: 'localGuardianMobile', key: 'localGuardianMobile', showOnResponse: true, showOnDesktop: true  },
        { title: 'Present Address', dataIndex: 'presentAddress', key: 'presentAddress', showOnResponse: true, showOnDesktop: true  },
        { title: 'Present District', dataIndex: 'presentDistrict', key: 'presentDistrict', showOnResponse: true, showOnDesktop: true  },
        { title: 'Admission Charge', dataIndex: 'admissionCharge', key: 'admissionCharge', showOnResponse: true, showOnDesktop: true  },
        { title: 'Payment Status', dataIndex: 'paymentStatus', key: 'paymentStatus', showOnResponse: true, showOnDesktop: true  },
        { title: 'Payment Date', dataIndex: 'paymentDate', key: 'paymentDate', showOnResponse: true, showOnDesktop: true  },
        {
            title: 'Photo',
            dataIndex: 'applicantId',
            key: 'applicantId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => {
                let imgDataLink = record.studentImageLink;
                return (
                    <div style={{ display: "flex" }}>
                        <img src={imgDataLink} height='35' width='30' />
                    </div>
                )
               
            },
        },

    ];

  return (
    <>
    <Card title="Online Admission All-Paid, All-Unpaid List">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="paidUnpaidReport"
                            onFinish={formSubmit}
                            form={paidUnpaidReportForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]} 
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="paymentStatus"
                                        label="Payment Status"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select Payment Status" },
                                        ]} 
                                    >
                                    <Select placeholder='Payment Status'  style={{ width: "100%" }} >
                                        <Option value="1">Paid</Option>
                                        <Option value="0">Unpaid</Option>
                                   </Select>

                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>

                <Row style={{ display: onlineAdmissionReportPaidUnpaidList.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns:studentListColumn,
                                rowKey:"applicantId",
                                dataSource: onlineAdmissionReportPaidUnpaidList,
                                filterData: onlineAdmissionReportPaidUnpaidList,
                                pagination: true,
                                bordered: true,                           
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                    </Col>

                    <Col span={24}>
                        
                        <Space size="small" style={{ float: "right" }}>
                        
                        <ExcelFile element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Online Applicant List`}>
                                
                                <ExcelSheet data={onlineAdmissionReportPaidUnpaidList} name="Class Wise Report">
                                    <ExcelColumn label="Registration ID" value="registrationId" />
                                    <ExcelColumn label="Applicant Name" value="applicantName" />
                                    <ExcelColumn label="Class Name" value="className" />
                                    <ExcelColumn label="Group Name" value="groupName" />
                                    <ExcelColumn label="Roll" value="roll" />
                                    <ExcelColumn label="Gender " value="gender" />
                                    <ExcelColumn label="Blood Group " value="bloodGroup" />
                                    <ExcelColumn label="Version " value="medium" />
                                    <ExcelColumn label="Date of Birth" value="dateOfBirthString" />
                                    <ExcelColumn label="Age" value="age" />
                                    <ExcelColumn label="Father Name" value="fatherName" />
                                    <ExcelColumn label="Mother Name" value="motherName" />
                                    <ExcelColumn label="Father Designation" value="fatherDesignation" />
                                    <ExcelColumn label="Mother Designation" value="motherDesignation" />
                                    <ExcelColumn label="Guardian Mobile" value="localGuardianMobile" />
                                    <ExcelColumn label="Father Work Place" value="fatherWorkPlace" />
                                    <ExcelColumn label="Mother Work Place" value="motherWorkPlace" />
                                    <ExcelColumn label="Present Address" value="presentAddress" />
                                    <ExcelColumn label="Present District" value="presentDistrict" />
                                    <ExcelColumn label="Admission Charge" value="admissionCharge" />
                                    <ExcelColumn label="Payment Status" value="paymentStatus" />
                                    <ExcelColumn label="Payment Date" value="paymentDate" />
                                    <ExcelColumn label="Quota" value="quota" />
                                </ExcelSheet>

                            </ExcelFile>
                            
                        </Space>
                    </Col>
                    
                </Row>

            </Card>
    
    </>
  )
}
