import { post, get, del,postFile} from "../http";

export const fetchdesignationList = () => get("/initial-setup/designation/list");
export const createDesignation = (payload) => post("/initial-setup/designation/create", payload);
export const updateDesignation = (payload) => post("/initial-setup/designation/update", payload);
export const deleteDesignation = (payload) => del("/initial-setup/designation/delete?id="+payload);
export const saveSingleStaffRegistration = (payload) => post("/staff/single/registration", payload);
export const doMultipleStaffRegistration = (payload) => post("/staff/multiple/registration", payload);
export const updateSingleStaffBasicInfo = (payload) => post("/staff/basic/update", payload);
export const fetchstaffBasicInfoList = () => get("/staff/basic/info/list/for/update");
export const fetchstaffBasicSingleViewByStaff = (payload) => get("/staff/report/basic/view/by/staff-id?staffId="+payload);
export const fetchstaffBasicInfoListReport = () => get("/staff/report/basic/info/list");
export const updateSingleStaffPhoto = (payload, staffId) => postFile(`/staff/photo/update?staffId=${staffId}`, payload);
export const updateSingleStaffSignature = (payload, staffId) => postFile(`/staff/signature/update?staffId=${staffId}`, payload);
export const fetchclassTeacherAssignList = () => get("/class-teacher/assign/list");
export const deleteclassTeacherAssignList = (payload) => del("/class-teacher/assign/delete?assignId="+payload);
export const saveclassTeacherAssignList = (payload) => post(`/class-teacher/assign/save?staffId=${payload.staffId}&classConfigId=${payload.classConfigId}`);

export const fetchteacherSubjectAssignList = () => get("/teacher/subject/assign/list");
export const deleteteacherSubjectAssignList = (payload) => del("/teacher/subject/assign/delete?teacherSubjectAssignId="+payload);
export const saveteacherSubjectAssignList = (payload) => post("/teacher/subject/assign/save", payload);


export const fetchIdCardTemplate = () => get("/staff/id-card/template/view");
export const idCardSaveTemplate = (payload) => post("/staff/id-card/template/save", payload);

export const takeAttendance = (payload) => post("/staff/attendance/input", payload);
export const fetchstaffAtttendanceList = (payload) => get("/staff/attendance/list/for/update?attendanceDate="+payload);
export const updateAttendance = (payload) => post("/staff/attendance/update", payload);


export const fetchsingleStuffView = (customStaffId) => get("/staff/report/single/view?customStaffId=" + customStaffId);
export const fetchstaffLeaveList = (staffId) => get("/hr/leave/single/list?staffId=" + staffId);
export const savestaffLeave = (payload) => post("/hr/leave/save", payload);
export const deleteStaffLeaveInfo = (payload) => del('/hr/leave/delete?leaveId='+payload);


export const fetchtimeConfigurationList = () => get("/attendance/time/configuration/list" );
export const saveTimeConfiguration = (payload) => post("/attendance/time/configuration/input", payload);

export const fetchenabledStuff = () => get("/staff/machine-map/enabled/list");

export const saveSingleIdmapping = (payload) => post("/staff/machine-map/save", payload);
export const saveBatchIdmapping = (payload) => post("/staff/machine-map/batch/save", payload);

export const fetchstaffTimeConfigReport = () => get("/attendance/time/configuration/list/report");
export const fetchstaffDateWiseAttReport = (attendanceDate) => get("/staff/attendance/report/date-wise?attendanceDate=" + attendanceDate);
export const staffTxtFile = (payload, date) => postFile(`/staff/attendance/read/txt-file?date=${date}`, payload);
export const staffTxtFileSave = (payload) => post("/staff/attendance/machine-data/input", payload);
export const updateStaffAttendanceComments = (payload) => post("/staff/attendance/update/comments", payload);
export const fetchstaffMonthWiseAttReport = (payload) => get(`/staff/attendance/report/single/staff/details?month=${payload.month}&staffId=${payload.staffId}&year=${payload.year}`);
export const fetchattendanceDetailsAllStaff = (payload) => get(`/staff/attendance/report/all/staff/details?month=${payload.month}&year=${payload.year}`);

export const fetchweeklyHolidayList = () => get("/holyday/weekly/list");
export const createHolidayList = (payload) => get("/holyday/weekly/save?dayName="+payload?.dayName);
export const deleteHolidayList = (payload) => del('/holyday/weekly/delete?holyDayId='+payload);

export const fetchgovtHolidayList = (payload) => get("/holyday/govt/list/by-year?year="+payload?.year);
export const creategovtHolidayList = (payload) => post("/holyday/govt/save", payload);
export const deletegovtHolidayList = (payload) => del("/holyday/govt/delete?holyDayId="+payload);

export const savStaffEducationInfo = (payload) => post('/staff/education/save', payload);
export const fetchStaffEducationList = (payload) => get('/staff/education/list?staffId='+payload);
export const deleteStaffEducationInfo = (payload) => del('/staff/education/delete?educationId='+payload);
export const updateStaffEducationInfo = (payload) => post('/staff/education/update', payload);