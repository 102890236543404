import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, InputNumber, Popconfirm, Row, Space, Tooltip, Modal, Skeleton } from "antd";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { useStoreActions, useStoreState } from "../../../store/hooks/easyPeasy";
import TableView from "../../../contents/AntTableResponsive";

export default function InventoryCategory() {

  const createInventoryCategory = useStoreActions((state) => state.inventory.createInventoryCategory);
  const fetchInventoryCategoryList = useStoreActions((state) => state.inventory.fetchInventoryCategoryList);
  const inventoryCategoryList = useStoreState((state) => state.inventory.inventoryCategoryList);
  const updateInventoryCategory = useStoreActions((state) => state.inventory.updateInventoryCategory);
  const deleteInventoryCategory = useStoreActions((state) => state.inventory.deleteInventoryCategory);
  const [inventoryCategoryForm] = Form.useForm();
  const [inventoryCategoryUpdateForm] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [categoryId, setCategoryId] = useState<any>();
  const loading = useStoreState((state) => state.inventory.loading);

  const inventoryCategoryCreateForm = (value) => {
    let postData = {
      categoryName: value.categoryName,
      serial: value.serial,
      note: value.note,
    };

    createInventoryCategory(postData);

  };

  const updateInventoryCategoryInfo = (value) => {
    let postData = {
      categoryId: categoryId,
      categoryName: value.categoryName,
      serial: value.serial,
      note: value.note,
    };
    updateInventoryCategory(postData);
    setIsModalVisible(loading);

  };

  useEffect(() => {
    fetchInventoryCategoryList();

  }, [])


  const columns = [
    {
      title: 'Serial',
      dataIndex: 'serial',
      key: 'serial',
      showOnResponse: true,
      showOnDesktop: true
    },
    {
      title: 'Category Name',
      dataIndex: 'categoryName',
      key: 'categoryName',
      showOnResponse: true,
      showOnDesktop: true
    },    
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      showOnResponse: true,
      showOnDesktop: true
    },

    {
      title: 'Action',
      key: 'categoryId',
      showOnResponse: true,
      showOnDesktop: true,
      render: (text: any, record: any, index) => (
        <Space size="middle">

          <Tooltip title="Edit">
            <Button type='primary' icon={<EditOutlined />} onClick={() => {
              setIsModalVisible(true);
              setCategoryId(record.categroyId);
              inventoryCategoryUpdateForm.setFieldsValue({
                categoryName: record.categoryName,
                serial: record.serial,
                note: record.note,
              });
            }}
            />
          </Tooltip>
          <Popconfirm
            title="Are you sure to delete this?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteInventoryCategory(record?.categroyId)}
          >
            <Tooltip title="Delete">
              <Button danger icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>

        </Space>
      ),
    },


  ];

  return (
    <>
      <Card title="Inventory Category Info">
        <Row>
          <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 3 }} xl={{ span: 20, offset: 3 }}>
            <Form
              layout="vertical"
              id="classInfo"
              onFinish={inventoryCategoryCreateForm}
              form={inventoryCategoryForm}
            >
              <Row>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="categoryName"
                    label="Category Name"
                    className="title-Text"
                    rules={[
                      {
                        required: true,
                        message: "Please write inventory category name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter inventory category name" />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="note"
                    label="Note"
                    className="title-Text"
                    rules={[
                      { required: true, message: "Please write note" },
                    ]}
                  >
                    <Input placeholder="Enter note" />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="serial"
                    label="Serial Number"
                    className="title-Text"
                    rules={[
                      { required: true, message: "Please write serial number" },
                    ]}
                  >
                    <InputNumber placeholder="Enter serial number" />
                  </Form.Item>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Space size="small">
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<SaveOutlined />}
                    >
                      Save
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Skeleton loading={loading} paragraph={{ rows: 10 }} />

        <Row className="m-t-mo-30">
          <Col span="24">
            <div className="datatable-responsive-demo">
              <TableView
                antTableProps={{
                  showHeader: true,
                  columns,
                  dataSource: inventoryCategoryList,
                  filterData: inventoryCategoryList,
                  pagination: true,
                  bordered: true,
                  rowKey: "id",

                }}
                mobileBreakPoint={768}
              />
            </div>
          </Col>
        </Row>

      </Card>

      <Modal
        title="Category Edit"
        visible={isModalVisible}
        okButtonProps={{ form: 'update', htmlType: 'submit' }}
        onCancel={() => setIsModalVisible(false)}
        cancelText="Close"
        okText="Update"
        centered
      >
        <Form
          layout="vertical"
          onFinish={updateInventoryCategoryInfo}
          id="update"
          form={inventoryCategoryUpdateForm}
        >

          <Form.Item
            name="categoryName"
            label="Category Name : "
            className="title-Text"
            rules={[
              { required: true, message: "Please input Category Name" },
            ]}
          >
            <Input placeholder='Item Name' />
          </Form.Item>
          <Form.Item
            name="note"
            label="Note"
            className="title-Text"
            rules={[
              { required: true, message: "Please write note" },
            ]}
          >
            <Input placeholder="Enter note" />
          </Form.Item>

          <Form.Item
            name="serial"
            label="Serial : "
            className="title-Text"
            rules={[
              { required: true, message: "Please Input Item Unity" },
            ]}
          >
            <InputNumber placeholder='Serial' />
          </Form.Item>

        </Form>

      </Modal>

    </>
  );
}
