import React, { useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker } from 'antd';
import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import moment from 'moment';
import Table from "../../../../contents/AntTableResponsive";
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import TextArea from 'antd/lib/input/TextArea';


export default function LeaveInput() {

    const { Option } = Select;
    const [searchStudent] = Form.useForm();
    const [attendanceDetailsStudent] = Form.useForm();
    const [leaveApplicationForm] = Form.useForm();
    const fetchStudentGeneralInfo = useStoreActions((state) =>  state.student.fetchStudentGeneralInfo);
    const studentGeneralInfo = useStoreState((state) => state.student.studentGeneralInfo);
    const saveLeaveInput = useStoreActions((state) => state.student.saveLeaveInput);

    const fetchStudentSingleLeaveList = useStoreActions((state) =>  state.student.fetchStudentSingleLeaveList);
    const studentSingleLeaveList = useStoreState((state) =>  state.student.singleStudentLeaveList);

    const deleteStudentSingleLeave = useStoreActions((state) =>  state.student.deleteStudentLeaveInfo);

    const dateFormat = 'YYYY-MM-DD';
    const [identificationid, setIdentificationid] = useState<any>();

    const searchStudentDetails = (value) => {
        fetchStudentGeneralInfo(value.studentId);   
    }

    useEffect(() => {
        attendanceDetailsStudent.setFieldsValue({
            customStudentId: studentGeneralInfo.customStudentId,
            studentName: studentGeneralInfo.studentName,
            classId: studentGeneralInfo.classConfigName,
            rollNumber: studentGeneralInfo.studentRoll
        });
        setIdentificationid(studentGeneralInfo.identificationId);

    }, [studentGeneralInfo])


    const submitLeaveApplication = (value) => {
        let postdata:any = {
            fromDate: moment(value.formDate).format(dateFormat),
            identificationId: identificationid,
            leaveFor: value.leaveFor,
            leaveReason: value.leaveReson,
            toDate: moment(value.toDate).format(dateFormat)
        }
        saveLeaveInput(postdata);
    };

    const deleteLeaveApplication = (leaveId) => {
        let postdata:any = {
            identificationId: identificationid,
            leaveId: leaveId,
        }
        deleteStudentSingleLeave(postdata);
    };

    const columns = [

        {
            title: 'Leave Date',
            dataIndex: 'leaveDate',
            key: 'leaveDate',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Leave Reason',
            dataIndex: 'leaveReason',
            key: 'leaveReason',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Leave For',
            dataIndex: 'leaveFor',
            key: 'leaveFor',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Action',
            key: 'leaveId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteLeaveApplication(record?.leaveId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        },
    ];
  
    return (
        <>
            <Card title="Student Leave Input">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                        <Form
                            layout="vertical"
                            id="studentDetails"
                            onFinish={searchStudentDetails}
                            form={searchStudent}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>

                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="studentId"
                                        label="Student ID"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write student ID" },
                                        ]}
                                    >
                                        <Input placeholder='student id' />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>

                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>

                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form
                                layout="vertical"
                                form={leaveApplicationForm}
                                onFinish={submitLeaveApplication}
                                
                            >
                                <Row>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <Form.Item
                                            name="formDate"
                                            label="From Date"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select from date" },
                                            ]}
                                        >
                                            <DatePicker format={dateFormat} style={{ width: "100%" }}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <Form.Item
                                            name="toDate"
                                            label="To Date"
                                            className="title-Text"
                                        >
                                            <DatePicker format={dateFormat} style={{ width: "100%" }}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <Form.Item
                                            name="leaveFor"
                                            label="Leave For"
                                            className="title-Text"
                                        >
                                            <TextArea rows={2}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <Form.Item
                                            name="leaveReson"
                                            label="Leave Reson"
                                            className="title-Text"
                                        >
                                            <Select placeholder="leave reson" style={{ width: "100%" }}>
                                                <Option value="Accidental Issue">Accidental Issue</Option>
                                                <Option value="Doctor Appointment">Doctor Appointment</Option>
                                                <Option value="Death Of Family Member">Death Of Family Member</Option>
                                                <Option value="Family Issue">Family Issue</Option>
                                                <Option value="Natural Disaster">Natural Disaster</Option>
                                                <Option value="Personal Issue">Personal Issue</Option>
                                                <Option value="Political">Political</Option>
                                                <Option value="Sick">Sick</Option>
                                                <Option value="Sports">Sports</Option>
                                                <Option value="Strike">Strike</Option>
                                                <Option value="Traffic Jam">Traffic Jam</Option>
                                            </Select>
                                            
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                        <Button type="primary" className='mt-0' style={{ float: "right" }} htmlType="submit" icon={<SaveOutlined />} >Save</Button>
                                    </Col>
                                </Row>
                            </Form>
                            <br />
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Card title="Student Details" className='box-shadow-none'>
                            <Form
                                layout="vertical"
                                form={attendanceDetailsStudent}
                                onFinish={submitLeaveApplication}
                            >
                                <Row>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <Form.Item
                                            name="customStudentId"
                                            label="Student ID"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please write student id" },
                                            ]}
                                        >
                                            <Input placeholder='student id' disabled/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <Form.Item
                                            name="studentName"
                                            label="Student Name"
                                            className="title-Text"
                                        >
                                            <Input placeholder='student name' disabled/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <Form.Item
                                            name="classId"
                                            label="Class ID"
                                            className="title-Text"
                                        >
                                            <Input placeholder='class' disabled/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <Form.Item
                                            name="rollNumber"
                                            label="Roll"
                                            className="title-Text"
                                        >
                                            <Input placeholder='student roll' disabled/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Row>


                 <Row>

                 <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                
                 </Col>

                 <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                 
                 {studentSingleLeaveList!== null &&studentSingleLeaveList?.length>0 &&
                <div className="datatable-responsive">
                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: studentSingleLeaveList,
                            filterData: studentSingleLeaveList,
                            pagination: true,
                            bordered: true
                        }}
                        mobileBreakPoint={768}
                    />
                </div>

                }

                </Col>

                </Row>


            </Card>
        </>
    )
}