import React, { useCallback, useEffect, useState,  } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker, Skeleton } from 'antd';
import { EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import moment from 'moment';
import TableResponsive from "../../../../contents/AntTableResponsive";
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectAcademicYear4 } from '../../../select/SelectAcademicYear4';



import { useTable, usePagination, useFilters } from "react-table";

const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData // This is a custom function that we supplied to our table instance
}) => {
    // We need to keep and update the state of the cell normally
    // console.log(id)
    const [value, setValue] = React.useState(initialValue);

    const onChange = (e) => {
        setValue(e.target.value);
    };  
    
    const onChecked = (e) => {
        setValue(e);
    };  
    const onChangeDate = (e) => {
        setValue(moment(e._d).format("YYYY-MM-DD"));
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    if (id === 'selected') {
        return <label className="ant-checkbox-wrapper">
            <input
               className='ant-checkbox'
                type="checkbox"
                checked={value}
                onChange={() => onChecked(!value)}
                onBlur={onBlur}
            />
        </label>
    }

    if (id === 'studentReligion') {
        return <div className="select-box">
            <select
                id="select-box1"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                className='tableDropDown'
                style={{width:150}}
            >
                <option value="Islam">Islam</option>
                <option value="Hinduism">Hinduism</option>
                <option value="Christian">Christian</option>
                <option value="Buddist">Buddist</option>
                <option value="Other">Other</option>
            </select>
        </div>
    }
    if (id === 'studentGender') {
        return <div className="select-box">
            <select
                id="select-box1"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                className='tableDropDown'
                style={{width:120}}
            >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
            </select>
        </div>
    }
    if (id === 'bloodGroup') {
        return <div className="select-box">
            <select
                id="select-box1"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                className='tableDropDown'
                style={{width:100}}
            >
                <option value="A+(ve)">A+(ve)</option>
                <option value="A-(ve)">A-(ve)</option>
                <option value="AB+(ve)">AB+(ve)</option>
                <option value="AB-(ve)">AB-(ve)</option>
                <option value="B+(ve)">B+(ve)</option>
                <option value="B-(ve)">B-(ve)</option>
                <option value="O+(ve)">O+(ve)</option>
                <option value="O-(ve)">O-(ve)</option>
            </select>
        </div>
    }

    if (id === 'studentDOB') {
        if (value !== '' ) return <DatePicker onBlur={onBlur} onChange={onChangeDate} allowClear={false} value={(moment(value?.split('-').join('/')))} style={{ width: 150, border: "1px solid #03D665" }} />
        if (value === null ) return <DatePicker onBlur={onBlur} value={null} onChange={onChangeDate} allowClear={false} style={{ width: 150, border: "1px solid #03D665" }} />
        else return <DatePicker onBlur={onBlur} allowClear={false} value={null} onChange={onChangeDate} style={{ width: 150, border: "1px solid #03D665" }} />
 
    }

    else return <input className="ant-input" style={{ border: "1px solid #03D665" }} value={value} onChange={onChange} onBlur={onBlur} />;
};

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input
            className="ant-input"
            style={{ border: "1px solid #F0F0F0" }}
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Search ${count} records...`}
        />
    )
}





// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    Filter: DefaultColumnFilter,
    Cell: EditableCell,

};

// Be sure to pass our updateMyData and the skipPageReset option
function Table({ columns, data, updateMyData, skipPageReset,  }) {
    // For this example, we're using pagination to illustrate how to stop
    // the current page from resetting when our data changes
    // Otherwise, nothing is different here.
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,

        state: { pageIndex, pageSize, selectedRowIds }
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageSize: 10 },
            autoResetFilters: false,
            // use the skipPageReset option to disable page resetting temporarily
            autoResetPage: !skipPageReset,
            // updateMyData isn't part of the API, but
            // anything we put into these options will
            // automatically be available on the instance.
            // That way we can call this function from our
            // cell renderer!
            updateMyData
        },
        useFilters, // useFilters!
        usePagination,
 

    );

    return (
        <>
            <div className='ant-table-content' style={{ width: "100%" }}>
                <div style={{ overflow: "auto" }}>
                    <table {...getTableProps()} className='shebaTable' style={{ width: "100%" }}>
                        <thead className='ant-table-thead'>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th className='ant-table-cell' {...column.getHeaderProps({ style: { minWidth: column.minWidth, width: column.width } })} >{column.render("Header")}
                                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                                        </th>

                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody className='ant-table-tbody' {...getTableBodyProps()}>
                            {page.length > 0 && page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr className={`ant-table-row ant-table-row-level-${i}`} {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td className='ant-table-cell' {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <br />
                <div className="pagination" style={{ float: 'right' }}>
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} style={{ height: 40 }}>
                        {"<<"}
                    </button>{" "}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage} style={{ height: 40 }}>
                        {"<"}
                    </button>{" "}
                    <button onClick={() => nextPage()} disabled={!canNextPage} style={{ height: 40 }}>
                        {">"}
                    </button>{" "}
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} style={{ height: 40 }}>
                        {">>"}
                    </button>{" "}
                    <span>
                        Page{" "}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{" "}
                    </span>
                    <span>
                        | Go to page:{" "}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(page);
                            }}
                            className="ant-input"
                            style={{ width: "100px", height: 40, border: "1px solid #03D665" }}
                        />
                    </span>{" "}
                    <select
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                        style={{ height: 40, border: "1px solid #03D665" }}
                    >
                        {[10, 20, 30, 40, 50, 100, 200, 500, 1000].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </>
    );
}


export default function BasicUpdate() {

    const { Option } = Select;
    const [searchStudentForm] = Form.useForm();
    const fetchStudentListByClassConfigId = useStoreActions((state) => state.student.fetchStudentListByClassConfigId);
    const studentListByClassConfigId = useStoreState((state) => state.student.studentListByClassConfigId);
    const check = useStoreState((state) => state.student.check);
    const updateStudentBasicInfo = useStoreActions((state) => state.student.updateStudentBasicInfo);
    const [section, setSection] = useState<any>();
    const [tableRowStore, setTableRowStore] = useState<any>([]);
    const loading = useStoreState((state) => state.student.loading);

    const searchStudentList = (value) => {
        setTableRowStore([])
        fetchStudentListByClassConfigId(value);
        setSection(value)
    }
    const updateStudentInfo = (value) => {
        setTimeout(() => {
            let checkSelectedRow = tableRowStore?.filter(item=>item?.selected===true);
            if (checkSelectedRow?.length > 0) {
                let updateValueArray: any = [];
                checkSelectedRow?.map((item: any) => {
                    let updateData = {
                        studentId: item?.studentId,
                        customStudentId: item?.customStudentId,
                        bloodGroup: item?.bloodGroup,
                        fatherName: item?.fatherName,
                        studentGender: item?.studentGender,
                        motherName: item?.motherName,
                        registrationNo: item?.registrationNo,
                        studentReligion: item?.studentReligion,
                        guardianMobile: item?.guardianMobile,
                        studentDOB: item?.studentDOB,
                        studentName: item?.studentName,
                        studentRoll: item?.studentRoll,
                        identificationId: item?.identificationId,
                        studentSession: item?.studentSession,
                    }
                   
                    updateValueArray.push(updateData);
                });
                updateStudentBasicInfo({ data: updateValueArray, id: section });
            } else {
                notification.error({ message: 'Select the table row first' });
            }
        }, 100);
   
    }

    useEffect(() => {
        setTableRowStore(studentListByClassConfigId);
    }, [studentListByClassConfigId]);


    const columnsx = React.useMemo(
        () => [
            {
                Header: "Select",
                accessor: "selected",
                disableFilters: true,
                maxWidth: 30,
                minWidth: 30,
                width: 30,
            },            
            {
                Header: "Student Id",
                accessor: "customStudentId",
                maxWidth: 400,
                minWidth: 140,
                width: 200,
            },
            {
                Header: "Student Name",
                accessor: "studentName",
                maxWidth: 400,
                minWidth: 250,
                width: 250,
            },
            {
                Header: "Roll",
                accessor: "studentRoll",
                maxWidth: 400,
                minWidth: 140,
                width: 200,
            },
            {
                Header: "Registration No",
                accessor: "registrationNo",
                disableFilters: true,
                maxWidth: 400,
                minWidth: 140,
                width: 200,
            },
            {
                Header: "Session",
                accessor: "studentSession",
                disableFilters: true,
                maxWidth: 400,
                minWidth: 140,
                width: 200,
            },
            {
                Header: "Father Name",
                accessor: "fatherName",
                disableFilters: true,
                maxWidth: 400,
                minWidth: 250,
                width: 250,
            },
            {
                Header: "Mother Name",
                accessor: "motherName",
                disableFilters: true,
                maxWidth: 400,
                minWidth: 250,
                width: 250,
            },
            {
                Header: "Religion",
                accessor: "studentReligion",
                disableFilters: true,
                maxWidth: 400,
                minWidth: 140,
                width: 200,
            },
            {
                Header: "Gender",
                accessor: "studentGender",
                disableFilters: true,
                maxWidth: 400,
                minWidth: 140,
                width: 200,
            },
            {
                Header: "Blood Group",
                accessor: "bloodGroup",
                disableFilters: true,
                maxWidth: 400,
                minWidth: 140,
                width: 200,
            },
            {
                Header: "Date of Birth",
                accessor: "studentDOB",
                disableFilters: true,
                maxWidth: 400,
                minWidth: 150,
                width: 200,
            },
            {
                Header: "Mobile",
                accessor: "guardianMobile",
                disableFilters: true,
                maxWidth: 400,
                minWidth: 140,
                width: 200,
            },
            // {
            //     Header: "Grade",
            //     accessor: "letterGrade",
            //     disableFilters:true,
            //     Cell: function Cell(cell) {
            //         return <span>{cell.value}</span>;
            //     }
            // },
        ],
        [studentListByClassConfigId]
    );


    const [skipPageReset, setSkipPageReset] = React.useState(false);

    // We need to keep the table from resetting the pageIndex when we
    // Update data. So we can keep track of that flag with a ref.

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setTableRowStore((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value
                    };
                }
                return row;
            })
        );
    };





    return (
        <>
            <Card title="Student Basic Update">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={searchStudentList}
                            form={searchStudentForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear4 />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="classconfigid"
                                        label="Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig onChange={e => setSection(e)} style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>

                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>

                {studentListByClassConfigId === null && <Skeleton loading={loading} paragraph={{ rows: 10 }} />}
                {studentListByClassConfigId !== null &&
                    <>

                        <div className="datatable-responsive-demo" style={{ width: "100%", overflow: "hidden" }}>
                            {/* <Table bordered={true} dataSource={studentListByClassConfigId} columns={columns} className="p-datatable-responsive-demo" /> */}



                            {/* <TableResponsive
                                antTableProps={{
                                    showHeader: true,
                                    rowSelection: rowSelection,
                                    columns,
                                    dataSource: tableRowStore,
                                    filterData: tableRowStore,
                                    loading: check,
                                    pagination: { defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10', '15', '20', '25'] },
                                    bordered: true,
                                    rowKey: "studentId",
                                }}
                                mobileBreakPoint={768}
                            /> */}
                            <Table
                                columns={columnsx}
                                data={tableRowStore}
                                updateMyData={updateMyData}
                                skipPageReset={skipPageReset}
   
                            />
                        </div>

                        <Row >
                            <Col span="24">
                                <Button type="primary" icon={<EditOutlined />} style={{ float: 'right', marginTop:10 }} onClick={(e) => updateStudentInfo(e)}>
                                    Update
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            </Card>
        </>
    )
}