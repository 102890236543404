import { DeleteOutlined, EditOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Skeleton, Space, Table, Tooltip, message, notification } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import { SelectInventoryCategory } from '../../select/SelectInventoryCategory';
import { SelectInventoryItem } from '../../select/SelectInventoryItem';
import { SelectLedgerCashEqui } from '../../select/SelectLedgerCashEqui';
import TableView from '../../../contents/AntTableResponsive';



export default function InventoryItemRecordOut(props) {

    const [form] = Form.useForm();
    const fetchInventoryItemListByCategory = useStoreActions((state) => state.inventory.fetchInventoryItemListByCategory);
    const saveOutRecord = useStoreActions((state) => state.inventory.inventoryItemRecordOut);
    const fetchItemRecordOutList = useStoreActions((state) => state.inventory.fetchInventoryItemRecordOutList);
    const itemRecordOutList = useStoreState((state) => state.inventory.inventoryItemRecordOutList);
    const deleteItemRecordOut = useStoreActions((state) => state.inventory.deleteInventoryItemRecordOut);
    const loading = useStoreState((state) => state.inventory.loading);
    const [fromDate, setFromDate] = useState<any>(null);
    const [toDate, setToDate] = useState<any>(null);

    const recordOutventoryItem = (value) => {
        let payload = {
            itemId: value?.itemId,
            quantity: value?.quantity,
            "fromDate" :moment(fromDate).format("YYYY-MM-DD"),
            "toDate" :moment(toDate).format("YYYY-MM-DD"),
        }
        saveOutRecord(payload);
    }

    function deleteRecord(value) {
        
        let payload = {
            "recordId": value,
            "fromDate" :moment(fromDate).format("YYYY-MM-DD"),
            "toDate" :moment(toDate).format("YYYY-MM-DD"),
        }

        deleteItemRecordOut(payload);
    }


    const searchtemRecordOutLIst = () =>{

        if(fromDate==null){
            notification.error({message:"From Date cannot be empty"})
            return;
        }   
        
        if(toDate==null){
            notification.error({message:"To Date cannot be empty"})
            return;
        }

        let payload= {
            "fromDate":moment(fromDate).format("YYYY-MM-DD"),
            "toDate":moment(toDate).format("YYYY-MM-DD")
          }

          fetchItemRecordOutList(payload);   
  
    }

    const columns = [
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            key: 'itemName',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Item Unity',
            dataIndex: 'itemUnity',
            key: 'itemUnity',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Record Date',
            dataIndex: 'recordDate',
            key: 'recordDate',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Action",
            key: 'recordId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Delete">
                        <Popconfirm
                            title="Are you sure to delete this?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteRecord(record?.recordId)}
                        >
                            <Button danger icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ]

    

    return (
        <>
        <Card title="Inventory Record Out">
            <Row gutter={8}>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <Form
                        layout="vertical"
                        onFinish={recordOutventoryItem}
                        id="basic-info"
                        form={form}
                    >

                        <Row gutter={8}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item
                                    name="catgeory"
                                    label="Catgeory"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select category" },
                                    ]}
                                >
                                    <SelectInventoryCategory onChange={e => fetchInventoryItemListByCategory(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item
                                    name="itemId"
                                    label="Item"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select item" },
                                    ]}
                                >

                                    <SelectInventoryItem />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item
                                    name="quantity"
                                    label="Quantity"
                                    className="title-Text"
                                    initialValue={0}
                                    rules={[
                                        { required: true, message: "Please input quantity" },
                                    ]}
                                >
                                    <InputNumber min={0}  placeholder='Quantity' />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Button type="primary" htmlType="submit"  icon={<PlusCircleOutlined />}>
                                    Save Out
                                </Button>
                            </Col>
                        </Row>

                    </Form>
                </Col>
                
                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} >
                        <div className="ant-col ant-form-item-label">
                                <label className="ant-form-item" style={{ marginBottom: -3 }}>From Date</label></div>
                            <DatePicker style={{ width: '100%' }} value={fromDate} onChange={e=>setFromDate(e)} placeholder="Select Date" format={"DD/MM/YYYY"} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} >
                            <div className="ant-col ant-form-item-label">
                                <label className="ant-form-item" style={{ marginBottom: -3 }}>To Date</label></div>
                            <DatePicker style={{ width: '100%' }} value={toDate} onChange={e=>setToDate(e)} placeholder="Select Date" format={"DD/MM/YYYY"} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginTop: 10 }} >
                            
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginTop: 40, textAlign: 'right' }} >
                            <Button
                                type="primary"
                                onClick={searchtemRecordOutLIst}
                                icon={<SaveOutlined />}
                            >
                                Search Record
                            </Button>
                        </Col>
                    </Row>

                    <Row className="m-t-mo-30">
                    <br />  <br /> 
                    </Row>

                    
                    <Skeleton loading={loading} paragraph={{ rows: 10 }} />

                        <Row className="m-t-mo-30">
                            <Col span="24">
                                <div className="datatable-responsive-demo">
                                    <TableView
                                        antTableProps={{
                                            showHeader: true,
                                            columns,
                                            dataSource: itemRecordOutList,
                                            filterData: itemRecordOutList,
                                            pagination: true,
                                            bordered: true,
                                            rowKey: "recordId",
                                        }}
                                        mobileBreakPoint={768}
                                    />
                                </div>
                            </Col>
                        </Row>

                </Col>
             </Row>

            </Card>

        </>
    )
}
