import { FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Row, Skeleton, Space } from 'antd';
import React from 'react'
import TableView from '../../../../contents/AntTableResponsive';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';
import { Excel } from 'antd-table-saveas-excel';

export default function StudentGivenWaiverReport() {
  const studentList = useStoreState((state) => state.saccount.classStudentWaiverSummary);
  const fetchStudentList = useStoreActions((state) => state.saccount.fetchClassStudentWaiverSummary);
  const loading = useStoreState((state) =>  state.saccount.loading);

  const searchStudent = (value) => {

    let postData:any = {
      academicYear : value.academicYear,
      classId : value.classId
    }

    fetchStudentList(postData)

  };

  const columns = [

    {
      title: 'Student ID',
      dataIndex: 'customStudentID',
      key: 'customStudentID',
      showOnResponse: true,
      showOnDesktop: true,
    },
    {
      title: 'Student Name',
      dataIndex: 'studentName',
      key: 'studentName',
      showOnResponse: true,
      showOnDesktop: true,
    },

    {
      title: 'Class Name',
      dataIndex: 'className',
      key: 'className',
      showOnResponse: true,
      showOnDesktop: true,
    },

    {
      title: 'Student Roll',
      dataIndex: 'studentRoll',
      key: 'studentRoll',
      showOnResponse: true,
      showOnDesktop: true,
    },
    {
      title: 'Fee Head',
      dataIndex: 'feeHeadName',
      key: 'feeHeadName',
      showOnResponse: true,
      showOnDesktop: true,
    },
    {
      title: 'Waiver Name',
      dataIndex: 'waiverName',
      key: 'waiverName',
      showOnResponse: true,
      showOnDesktop: true,
    },

    {
      title: 'Waiver Amount',
      dataIndex: 'waiverAmount',
      key: 'waiverAmount',
      showOnResponse: true,
      showOnDesktop: true,
    },
  ];
  
  return (
    <>
      <Card title="Student Waiver Given Summary">
        <Form
          layout="vertical"
          onFinish={searchStudent}
          id="search-student-waiver"
        >
          <Row gutter={8}>
            <Col xs={24} sm={24} md={24} lg={5} xl={5}> </Col>

            <Col xs={24} sm={24} md={24} lg={5} xl={5}>
              <Form.Item
                name="academicYear"
                label="Academic Year"
                className="title-Text"
                rules={[
                  { required: true, message: "Please select academic year" },
                ]}
              >

                <SelectAcademicYear />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={5} xl={5}>
              <Form.Item
                name="classId"
                label="Class"
                className="title-Text"
                rules={[
                  { required: true, message: "Please select class" },
                ]}
              >

                <SelectClass />
              </Form.Item>
            </Col>


            <Col xs={24} sm={24} md={24} lg={2} xl={2}>
              <Space size="small" >
                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                  Search
                </Button>
              </Space>
            </Col>

          </Row>
        </Form>

        {studentList === null && < Skeleton loading={loading} paragraph={{ rows: 10 }} />}
        {studentList?.length > 0 &&

          <>
            <Row className="m-t-mo-30">
              <Col span={24}>

                <TableView
                  antTableProps={{
                    showHeader: true,
                    columns,
                    dataSource: studentList,
                    filterData: studentList,
                    pagination: true,
                    bordered: true,
                    rowKey: "customStudentId",
                  }}
                  mobileBreakPoint={768}
                />

              </Col>

            </Row>

            <div style={{
              float: "right"
            }}>
              <br />
              < Button
                type='primary'

                icon={<FileExcelOutlined />}
                onClick={() => {
                  const excel: any = new Excel();
                  excel
                    .addSheet(`Sheet1`)
                    .addColumns(columns)
                    .addDataSource(studentList)
                    .saveAs(`StudentWaiverList.xlsx`);
                }}
              >
                Download Excel
              </ Button >
            </div>

          </>

        }

      </Card>

    </>
  )
}
