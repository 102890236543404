import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Table } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { DeleteOutlined, DownCircleFilled, DownloadOutlined, EditOutlined, FileExcelOutlined, FilePdfOutlined, PrinterOutlined, SaveOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import $ from 'jquery';
// import { data } from './extra'
import ReactToPrint from 'react-to-print';
import { Excel } from 'antd-table-saveas-excel';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';

const { Column, ColumnGroup } = Table;
// const pageStyle = `
//   @page {
//     size: 200mm 200mm;
//   }

//   @media all {
//     .pagebreak {
//       display: none;
//     }
//   }

//   @media print {
//     .pagebreak {
//       page-break-before: always;
//     }
//   }
// `;

// const ComponentToPrint = React.forwardRef((props: any, ref: any) => {
//     const { tableData, numberSubject, scName, subName } = props;

//     return (
//         <div className='print-source' ref={ref} >
//             <Table dataSource={tableData} pagination={false} style={{ overflow: 'hidden' }}>
//                 <Column title="Student Name" dataIndex="studentName" key="studentName" />
//                 <Column title="Roll" dataIndex="studentRoll" key="studentRoll" />
//                 {/* {rendergroup()} */}
//                 {numberSubject.map((item, idx) =>
//                     <ColumnGroup title={subName[idx]} key={idx}>
//                         <Column title={scName[0]} dataIndex={`sbj${idx + 1}Sc1ObtainedMark`} key={`sbj${idx + 1}Sc1ObtainedMark`} />
//                         <Column title={scName[1]} dataIndex={`sbj${idx + 1}Sc2ObtainedMark`} key={`sbj${idx + 1}Sc2ObtainedMark`} />
//                         <Column title={scName[2]} dataIndex={`sbj${idx + 1}Sc3ObtainedMark`} key={`sbj${idx + 1}Sc3ObtainedMark`} />
//                         <Column title={scName[3]} dataIndex={`sbj${idx + 1}Sc4ObtainedMark`} key={`sbj${idx + 1}Sc4ObtainedMark`} />
//                         <Column title={'Total'} dataIndex={`sbj${idx + 1}ObtainedMark`} key={`sbj${idx + 1}ObtainedMark`} />
//                         <Column title={'LG'} dataIndex={`sbj${idx + 1}lg`} key={`sbj${idx + 1}lg`} />
//                         <Column title={'GP'} dataIndex={`sbj${idx + 1}gp`} key={`sbj${idx + 1}gp`} />
//                     </ColumnGroup>
//                 )}

//             </Table>

//         </div>
//     );
// });

export default function TabulationSheet(props) {

    const [tableData, setTableData] = useState<any>([]);
    const [numberSubject, setnumberSubject] = useState<any>([]);
    const [scName, setscName] = useState<any>([0, 1, 2, 3,4,5]);
    const [columns, setColumns] = useState<any>([]);
    const [subName, setsubName] = useState<any>([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]);





    const [createForm] = Form.useForm();
    const { Option } = Select;

    const fetchSemesterListByClassConfigIdForGeneralTabulationSheet = useStoreActions((state) => state.exam.fetchSemesterListByClassConfigIdForGeneralTabulationSheet);
    const fetchtabulationData = useStoreActions((state) => state.exam.fetchtabulationData);
    const semesterListByClassConfigIdForGeneralTabulationSheet = useStoreState((state) => state.exam.semesterListByClassConfigIdForGeneralTabulationSheet);
    const tabulationData = useStoreState((state) => state.exam.tabulationData);
    //const downloadTabulationSheet = useStoreActions((state) => state.exam.downloadTabulationSheet)
    const onChangeClassList = (e) => {
        fetchSemesterListByClassConfigIdForGeneralTabulationSheet(e);
    }

    const tabulationSheetDownload1 = (value) => {
        let payload: any = {
            examConfigId: value.examConfig,
            classConfigId: value.section,
            academicYear: value.academicYear,
            // typeNo: value.typeNo
        }
        fetchtabulationData(payload)
    }



    useEffect(() => {
        setTableData(tabulationData?.studentList);
        setnumberSubject(Array.from(Array(tabulationData?.numberOfSubject).keys()));
        setscName([tabulationData?.sc1, tabulationData?.sc2, tabulationData?.sc3, tabulationData?.sc4,tabulationData?.sc5,tabulationData?.sc6])
        setsubName([
            tabulationData?.sbj1Name,
            tabulationData?.sbj2Name,
            tabulationData?.sbj3Name,
            tabulationData?.sbj4Name,
            tabulationData?.sbj5Name,
            tabulationData?.sbj6Name,
            tabulationData?.sbj7Name,
            tabulationData?.sbj8Name,
            tabulationData?.sbj9Name,
            tabulationData?.sbj10Name,
            tabulationData?.sbj11Name,
            tabulationData?.sbj12Name,
            tabulationData?.sbj13Name,
            tabulationData?.sbj14Name,
            tabulationData?.sbj15Name,
            tabulationData?.sbj16Name
        ]);


    }, [tabulationData]);

    useEffect(() => {

        let m: any = numberSubject?.map((item, idx) => {
            return {
                title: subName[idx],
                dataIndex: subName[idx],
                key: subName[idx],
                children: [
                    ... (scName[0]!==undefined) ? [{
                        title: scName[0],
                        dataIndex: `sbj${idx + 1}Sc1ObtainedMark`,
                        key: `sbj${idx + 1}Sc1ObtainedMark`,
                    }]:[],
                    ... (scName[1]!==undefined) ? [{
                        title: scName[1],
                        dataIndex: `sbj${idx + 1}Sc2ObtainedMark`,
                        key: `sbj${idx + 1}Sc2ObtainedMark`,
                    }]:[],
                    ... (scName[2]!==undefined) ? [{
                        title: scName[2],
                        dataIndex: `sbj${idx + 1}Sc3ObtainedMark`,
                        key: `sbj${idx + 1}Sc3ObtainedMark`,
                    }]:[],
                    ... (scName[3]!==undefined) ? [{
                        title: scName[3],
                        dataIndex: `sbj${idx + 1}Sc4ObtainedMark`,
                        key: `sbj${idx + 1}Sc4ObtainedMark`,
                    }]:[],                    
                    ... (scName[4]!==undefined) ? [{
                        title: scName[4],
                        dataIndex: `sbj${idx + 1}Sc5ObtainedMark`,
                        key: `sbj${idx + 1}Sc5ObtainedMark`,
                    }]:[],                 
                    ... (scName[5]!==undefined) ? [{
                        title: scName[5],
                        dataIndex: `sbj${idx + 1}Sc6ObtainedMark`,
                        key: `sbj${idx + 1}Sc6ObtainedMark`,
                    }]:[],
                    {
                        title: 'Total',
                        dataIndex: `sbj${idx + 1}ObtainedMark`,
                        key: `sbj${idx + 1}ObtainedMark`,
                    },
                    {
                        title: 'LG',
                        dataIndex: `sbj${idx + 1}lg`,
                        key: `sbj${idx + 1}lg`,
                    },
                    {
                        title: 'GP',
                        dataIndex: `sbj${idx + 1}gp`,
                        key: `sbj${idx + 1}gp`,
                    },
                ],
            }
        })
        let newArray: any = []
        if (m[0]?.dataIndex === 'studentName' === false) {
            let firstCol = [
                {
                    title: 'Student Name',
                    dataIndex: 'studentName',
                    key: 'studentName',
                },
                {
                    title: 'Roll',
                    dataIndex: 'studentRoll',
                    key: 'studentRoll',
                },
                {
                    title: 'Total Marks',
                    dataIndex: 'obtainedMarks',
                    key: 'obtainedMarks',
                },

                {
                    title: 'GPA',
                    dataIndex: 'finalGpa',
                    key: 'finalGpa',
                },

                {
                    title: 'Letter Grade',
                    dataIndex: 'finalLetterGrade',
                    key: 'finalLetterGrade',
                },
            ];
            newArray = firstCol.concat(m)
        } else {
            newArray = m
        }
        setColumns(newArray)

    }, [numberSubject])

    return (
        <>
            <>
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 20, offset: 2 }}>
                        <Form
                            layout="vertical"
                            id="sessionInfo"
                            onFinish={tabulationSheetDownload1}
                            form={createForm}
                        >
                            <Row>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Select Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select academicYear" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="section"
                                        label="Select Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig onChange={(e) => {createForm.setFieldsValue({examConfig:null}); onChangeClassList(e)}} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="examConfig"
                                        label="Select Exam"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select exam" },
                                        ]}
                                    >
                                        <Select placeholder="Semester list" style={{ width: "100%" }} className="examSelect">
                                            {semesterListByClassConfigIdForGeneralTabulationSheet ? (
                                                semesterListByClassConfigIdForGeneralTabulationSheet.map((type, idx) => (
                                                    <Option key={type.examConfigId} value={type.examConfigId}>
                                                        {type.examName}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Subject</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                {tableData?.length > 0 &&
                    <>
                        <Table dataSource={tableData} columns={columns}  bordered/>
                        <Space size={'large'} style={{ float: "right" }}>
                            < Button
                                type='primary'
                                style={{
                                    marginBottom: 20,
                                    marginRight: 20,
                                }}
                                icon={<FileExcelOutlined />}
                                onClick={() => {
                                    const excel: any = new Excel();
                                    excel
                                        .addSheet(`Sheet1`)
                                        .addColumns(columns)
                                        .addDataSource(tableData)
                                        .saveAs(`Tabulation Sheet of ${$(".sectionSelect").text()}__${$(".examSelect").text()}.xlsx`);
                                }}
                            >
                                Download Excel
                            </ Button >
                        </Space>
                    </>
                }

                {/* <div>
                    <ReactToPrint
                        trigger={() => <Button type='primary' icon={<PrinterOutlined />}>Print</Button>}
                        content={() => componentRef.current}
                        pageStyle={pageStyle}
                    />
                    <ComponentToPrint ref={componentRef} tableData={tableData} numberSubject={numberSubject} scName={scName} subName={subName} />
                </div> */}
            </>
        </>
    )
}