import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import TableView from '../../../contents/AntTableResponsive';
import { SaveOutlined, SearchOutlined } from '@ant-design/icons';

export default function StaffTimeConfig() {

    const fetchstaffBasicInfoListReport = useStoreActions((state) => state.staff.fetchstaffBasicInfoListReport);
    const staffBasicInfoListReport = useStoreState((state) => state.staff.staffBasicInfoListReport);

    const saveTimeConfiguration = useStoreActions((state) => state.staff.saveTimeConfiguration);
    const fetchtimeConfigurationList = useStoreActions((state) => state.staff.fetchtimeConfigurationList);
    const timeConfigurationList = useStoreState((state) => state.staff.timeConfigurationList);
    const loading = useStoreState((state) =>  state.staff.loading);

    useEffect(() => {
        fetchstaffBasicInfoListReport();
        fetchtimeConfigurationList();
    }, []);



    const columns = [

        {
            title: 'ID',
            dataIndex: 'customStaffId',
            key: 'customStaffId',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Name',
            dataIndex: 'staffName',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true
        }
    ];

    const [staff] = Form.useForm();

    const staffSubmit = (value) => {
        if (selectedRowKeys.length === 0) {
            message.error("Please select a staff");
            return;
        }
        value.staffIds = selectedValue?.map(item => item.staffId);
        saveTimeConfiguration(value);
        staff.resetFields();
        setselectedRowKeys([]);
        setselectedValue([]);
    }

    const columns2 = [

        {
            title: 'ID',
            dataIndex: 'customStaffId',
            key: 'customStaffId',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Teacher Name',
            dataIndex: 'staffName',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Day',
            dataIndex: 'dayName',
            key: 'dayName',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'In Time',
            dataIndex: 'inTime',
            key: 'inTime',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Delay Time',
            dataIndex: 'delayTime',
            key: 'delayTime',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Out Time',
            dataIndex: 'outTime',
            key: 'outTime',
            showOnResponse: true,
            showOnDesktop: true
        },

    ];

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <>
            <Card title="Staff Attendance Time Configuration">
            
                <Row >
                {/* {timeConfigurationList.length>0 && <Skeleton loading= {loading} paragraph={{rows:10}}/> } */}
           
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className="datatable-responsive-demo">
                            <Card title='Teachers & Staff List' className='box-shadow-none'>
                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: staffBasicInfoListReport,
                                        filterData: staffBasicInfoListReport,
                                        pagination: false,
                                        bordered: true,
                                        rowKey: "staffId",
                                        rowSelection: rowSelection,
                                    }}
                                    mobileBreakPoint={768}
                                />
                            </Card>
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={12} xl={12} >
                        <Card title='Time and Day' className='box-shadow-none'>
                            <Form
                                layout="vertical"
                                onFinish={staffSubmit}
                                id="basic-info"
                                form={staff}
                            >
                                <Row gutter={8}>
                                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                        <Form.Item
                                            name="inTime"
                                            label="In Time"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select in time" },
                                            ]}
                                        >
                                            <Input placeholder="In Time" type={'time'} />

                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                        <Form.Item
                                            name="outTime"
                                            label="Out Time"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select out time" },
                                            ]}
                                        >
                                            <Input placeholder="Out Time" type={'time'} />

                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                        <Form.Item
                                            name="delayTime"
                                            label="Delay Time"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select delay time" },
                                            ]}
                                        >
                                            <Input placeholder="Delay Time" type={'time'} />

                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                        <Form.Item
                                            name="dayNames"
                                            label="Select Day(s)"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select day(s)" },
                                            ]}
                                        >
                                            <Select placeholder='Select Days' mode='multiple'>
                                                <Select.Option value="Saturday">Saturday</Select.Option>
                                                <Select.Option value="Sunday">Sunday</Select.Option>
                                                <Select.Option value="Monday">Monday</Select.Option>
                                                <Select.Option value="Tuesday">Tuesday</Select.Option>
                                                <Select.Option value="Wednesday">Wednesday</Select.Option>
                                                <Select.Option value="Thursday">Thursday</Select.Option>
                                                <Select.Option value="Friday">Friday</Select.Option>
                                            </Select>

                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                        <Space size="small" >
                                            <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                                                Save
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className='mt-30'>
                    <Skeleton loading= {loading} paragraph={{rows:10}}/> 
                   {!loading &&  
                        <Card title='Time Configuration List' className='box-shadow-none'>
                            <div className="datatable-responsive-demo">
                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns: columns2,
                                        dataSource: timeConfigurationList,
                                        filterData: timeConfigurationList,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "staffId",
                                    }}
                                    mobileBreakPoint={768}
                                />
                            </div>
                        </Card>
                                }
                                </Col>
                </Row>
            </Card>
        </>
    )
}