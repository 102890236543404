
import { Button, Card, Col, Form, Row, Select, Skeleton, Space, } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
//import TableView from '../../../contents/AntTablePagination';
import { Excel } from 'antd-table-saveas-excel';

import { FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';



export default function OFPSInstiuteList() {

    // const fetchofpsinstiuteList = useStoreActions((state) => state.customerSupport.fetchofpsinstiuteList);
    // const fetchofpsinstiuteList2 = useStoreActions((state) => state.customerSupport.fetchofpsinstiuteList2);
    // const ofpsinstiuteList = useStoreState((state) => state.customerSupport.ofpsinstiuteList);
    // const fetchofpsinstiuteListNoPag = useStoreActions((state) => state.customerSupport.fetchofpsinstiuteListNoPag);
    // const ofpsinstiuteListnoPag = useStoreState((state) => state.customerSupport.ofpsinstiuteListnoPag);
    // const ofpsAmountConfigurationUpdate = useStoreActions((state) => state.customerSupport.ofpsConfigurationAmountUpdate);

    const fetchOfpsConfigurationLisForManagement = useStoreActions((state) => state.customerSupport.fetchOfpsConfigurationLisForManagement);
    const ofpsConfigurationLisForManagement = useStoreState((state) => state.customerSupport.ofpsConfigurationLisForManagement);
    const loading = useStoreState((state) => state.customerSupport.loading);
    const { Option } = Select;

    const submitFormForInstituteList = (value) => {
        let postData: any = {
            "instituteStatus": value.instituteStatus
        }
        fetchOfpsConfigurationLisForManagement(postData);
    }


    const columns = [
        { title: 'Institute ID', dataIndex: 'instituteId', key: "instituteId", showOnResponse: true, showOnDesktop: true },
        { title: 'Institute Name', dataIndex: 'instituteName', key: "instituteName", showOnResponse: true, showOnDesktop: true },
        { title: 'Package', dataIndex: 'institutePackage', key: "institutePackage", showOnResponse: true, showOnDesktop: true },
        { title: 'Service Charge', dataIndex: 'serviceCharge', key: "serviceCharge", showOnResponse: true, showOnDesktop: true },
        { title: 'Step', dataIndex: 'step', key: "step", showOnResponse: true, showOnDesktop: true },
        { title: 'Type', dataIndex: 'instituteType', key: "instituteType", showOnResponse: true, showOnDesktop: true },
        { title: 'Address', dataIndex: 'address', key: "address", showOnResponse: true, showOnDesktop: true },
        { title: 'Partner', dataIndex: 'partnerName', key: "partnerName", showOnResponse: true, showOnDesktop: true },
        { title: 'Live Date', dataIndex: 'liveDate', key: "liveDate", showOnResponse: true, showOnDesktop: true },
        { title: 'Status', dataIndex: 'instituteStatus', key: "instituteStatus", showOnResponse: true, showOnDesktop: true },
    ];


    return (
        <>
            <Card title="OFPS Institute List">


                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 2 }} xl={{ span: 20, offset: 2 }}>
                        <Form
                            layout="vertical"
                            id="submitForm"
                            onFinish={submitFormForInstituteList}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>

                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="instituteStatus"
                                        label="Institute Staus:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select Institute Staus" },
                                        ]}
                                        style={{ width: "100%" }}
                                    >
                                        <Select className='billStatusx' placeholder="Institute Status" allowClear style={{ width: "100%" }}>
                                            <Option value={1}>Active</Option>
                                            <Option value={0}>Inactive</Option>
                                            <Option value={3}>All</Option>
                                        </Select>

                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                {ofpsConfigurationLisForManagement?.length > 0 &&
                    <>
                        <Row className="m-t-mo-30">
                            <Col span={24}>

                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: ofpsConfigurationLisForManagement,
                                        filterData: ofpsConfigurationLisForManagement,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "instituteId",
                                    }}
                                    mobileBreakPoint={768}
                                />


                            </Col>

                        </Row>

                        <div style={{
                            float: "right"
                        }}>
                            <br />
                            < Button
                                type='primary'

                                icon={<FileExcelOutlined />}
                                onClick={() => {
                                    const excel: any = new Excel();
                                    excel
                                        .addSheet(`Sheet1`)
                                        .addColumns(columns)
                                        .addDataSource(ofpsConfigurationLisForManagement)
                                        .saveAs(`OFPS Institute List.xlsx`);
                                }}
                            >
                                Download Excel
                            </ Button >
                        </div>
                    </>
                }
            </Card>

        </>
    )
}