import { post, get, del,postFile} from "../http";

export const sectionWiseAdmissionReport = (payload) => get(`/online/appicant/list/for/college/by/paid-status/classconfigid?classConfigId=`+payload.classConfigId+`&paidStatus=`+payload.paidStatus);
export const groupWiseAdmissionReport = (payload) => get(`/online/appicant/list/for/college/by/paid-status/groupid?classConfigId=`+payload.classConfigId+`&paidStatus=`+payload.paidStatus);
export const paymentStatusWiseAdmissionReport = (payload) => get(`/online/appicant/list/for/college/by/paid-status?paidStatus=`+payload);
export const onlineAdmissionReportPaidUnpaid = (payload) => get(`/online/admission/report/all/paid/unpaid/list?academicYear=`+payload.academicYear+`&paymentStatus=`+payload.paymentStatus);
export const onlineAdmissionReportGroupByPaidUnpaid = (payload) => get(`/online/admission/report/group/paid-unpaid/applicant/list?academicYear=`+payload.academicYear+`&groupConfigId=`+payload.groupConfigId+`&paymentStatus=`+payload.paymentStatus);
export const onlineAdmissionGeneralList = (payload) => post("/online/admission/report/applicant/list",payload);
export const updateApplicantInfo = (payload) => post("/online/admission/applicant-info/update",payload);
export const fetchfindSingleApplicant = (payload) => get("/online/admission/single/applicant/info?registrationId="+payload);
