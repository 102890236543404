import { DeleteOutlined, EditOutlined, EyeOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Descriptions, Form, Input, InputNumber, Modal, notification, Popconfirm, Row, Select, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Table from "../../../contents/AntTableResponsive";
import moment from 'moment';

export default function MigrationChange(props) {

    const [form] = Form.useForm();

    const migrationChange = useStoreState((state) => state.customerSupport.migrationChange);
    const fetchmigrationChange = useStoreActions((state) => state.customerSupport.fetchmigrationChange);
    const fetchmigrationSave = useStoreActions((state) => state.customerSupport.fetchmigrationSave);

    const [data, setData] = useState<any>({});
    const formSubmit = (value) => {
        fetchmigrationChange(value);
        setData(value);
    }

    const onChangeStatus = (value, id) => {
        let paydata: any = {
            identificationId: id,
            migrationStatus: value
        };
        let final = {
            payload: paydata,
            fetch: data
        };
        fetchmigrationSave(final);
    }

    const columns = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: "customStudentId", showOnResponse: true, showOnDesktop: true },
        { title: 'Name', dataIndex: 'studentName', key: "studentName", showOnResponse: true, showOnDesktop: true },
        { title: 'Academic Year', dataIndex: 'academicYear', key: "academicYear", showOnResponse: true, showOnDesktop: true },
        {title: 'Status',dataIndex: 'studentStatus', key: 'customStudentId',showOnResponse: true, showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <>
                    <span>{record.studentStatus == 1 ? 'Enable' : 'Disable'}</span>
                </>
            )
        },
        { title: 'Section', dataIndex: 'classConfigName', key: "classConfigName", showOnResponse: true, showOnDesktop: true },
        { title: 'Group', dataIndex: 'groupName', key: "groupName", showOnResponse: true, showOnDesktop: true },
        { title: 'Student Category', dataIndex: 'studentCategoryName', key: "studentCategoryName", showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: "studentRoll", showOnResponse: true, showOnDesktop: true },
        {
            title: 'Migration Status',
            key: 'migrationStatus',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                  <Select style={{width:"100%"}} placeholder="Migartion Status" value={record?.migrationStatus} onChange={e=>onChangeStatus(e, record?.identificationId)} >
                    <Select.Option value={true} >True</Select.Option>
                    <Select.Option value={false} >False</Select.Option>
                  </Select>
                </div>
            )
        },

    ];


    return (
        <>
            <Card title="Migartion Change" >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="customStudentId"
                                label="Student Id"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input student id" },
                                ]}
                            >

                                <Input placeholder="Student Id" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="instituteId"
                                label="Institute Id"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input institute id" },
                                ]}
                            >

                                <Input placeholder="Institute Id" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    </Row>
                </Form>

                {migrationChange?.length > 0 &&
                    <div className="datatable-responsive">
                        {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                        <Table
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: migrationChange,
                                filterData: migrationChange,
                                pagination: false,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                }
            </Card>

        </>
    )
}
