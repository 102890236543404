import React, { useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker, Radio } from 'antd';
import idCardTemplate1 from "../../../../../assets/images/staff-id-card-1.png";
import idCardTemplate2 from "../../../../../assets/images/staff-id-card-2.png";
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';


export default function StaffIdCardTemplate() {
    const fetchIdCardTemplate = useStoreActions((state) => state.staff.fetchIdCardTemplate);
    const idCardTemplateList = useStoreState((state) => state.staff.idCardTemplateList);
    const idCardSaveTemplate = useStoreActions((state) => state.staff.idCardSaveTemplate);
    const [templateDefaultValue, setTemplateDefaultValue] = useState<any>();
    useEffect(() => {
        fetchIdCardTemplate();
    }, []);

    useEffect(() => {
        setTemplateDefaultValue(idCardTemplateList?.templateId);
    }, [idCardTemplateList])

    const templateSaveMethod = (val) => {
        setTemplateDefaultValue(val);
        var postData: any = {
            templateId: val,
            barcodeValue: "Barcode value not found",
        }
        idCardSaveTemplate(postData);
    }

    const downloadIDCard = () => {
        let url=localStorage.getItem("url")+"/jasper/staff/id-card/download?access_token="+encodeURIComponent(localStorage.getItem("tok") as any);;
        window.open(url, "_blank");
    }

    return (
        <>

            {templateDefaultValue !== '' ?
                <Card title="HR ID Card Template">
                    <Row>
                        <Col span={24}>
                            <Radio.Group size="large" style={{ width: "100%" }} className='cardTemplateWrapper' onChange={(e) => templateSaveMethod(e.target.value)}>
                                <Row>

                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4 }}>
                                        <Radio.Button value="201" className={templateDefaultValue == 201 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplate1} alt="" /></Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4 }}>
                                        <Radio.Button value="202" className={templateDefaultValue == 202 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplate2} alt="" /></Radio.Button>
                                    </Col>

                                </Row>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Space size={'middle'} style={{ float: "right" }}>
                        <Button type='primary' onClick={downloadIDCard}>Download ID Card</Button>
                    </Space>
                </Card>
                : ''}
        </>
    )
}