import React, { useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker, Skeleton } from 'antd';
import { EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { Typography } from 'antd';

export default function SingleSearchStudent() {

    const [searchStudentForm] = Form.useForm();
    const fetchSingleStudentInfo = useStoreActions((state) => state.student.fetchSingleStudentInfo);
    const singleStudentInfo = useStoreState((state) => state.student.singleStudentInfo);
    const { Title } = Typography;
    const loading = useStoreState((state) =>  state.student.loading);
    const searchStudentList = (value) => {
        console.log('value.studentId', value.studentId);
        
        fetchSingleStudentInfo(value.studentId);
    }
    const columns = [
        {
            title: 'ID',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Academic Year',
            dataIndex: 'academicYear',
            key: 'academicYear',
            showOnResponse: true,
            showOnDesktop: true,

        },
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,

        },

        {
            title: 'Section',
            dataIndex: 'classConfigName',
            key: 'classConfigName',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Group',
            dataIndex: 'groupName',
            key: 'groupName',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Student Category',
            dataIndex: 'studentCategoryName',
            key: 'studentCategoryName',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Status',
            dataIndex: 'studentStatus',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <>
                    <span>{record.studentStatus == 1 ? 'Enable' : 'Disable'}</span>
                </>
            )
        },
        {
            title: 'Migrartion Status',
            dataIndex: 'migrationStatus',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <>
                    <span>{record.migrationStatus == true ? 'Enable' : 'Disable'}</span>
                </>
            )
        }
    ];

    const [section, setSection] = useState<any>();

    return (
        <>
            <Card title="Student Info View">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={searchStudentList}
                            form={searchStudentForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>

                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                    <Form.Item
                                        name="studentId"
                                        label="Student ID"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write student id" },
                                        ]}
                                    >
                                        <Input placeholder='Student id' />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>

                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                <Skeleton loading={loading} paragraph={{ rows: 4 }} />
                    <Col span={24}>
                        <div className="datatable-responsive-demo" style={{ width: "100%", overflow: "auto", display: singleStudentInfo.length > 0  ? "" : "none" }}>
                            <Table
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: singleStudentInfo,
                                    filterData:singleStudentInfo,
                                    pagination: true,
                                    bordered: true,
                                    rowKey: "customStudentId",
                                }}
                                mobileBreakPoint={768}

                            />
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    )
}