import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton, DatePicker } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TableView from '../../../../contents/AntTableResponsive';
import { DeleteOutlined, DownloadOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';
import moment from 'moment';


export default function ResultPublicationList() {
    const { Option } = Select;
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();

    const fetchresultPublishDateList = useStoreActions((state) => state.exam.fetchresultPublishDateList);
    const updateResultPublishDate = useStoreActions((state) => state.exam.updateResultPublishDate);
    const deleteResultPublishDate = useStoreActions((state) => state.exam.deleteResultPublishDate);
    const resultPublishDateList = useStoreState((state) => state.exam.resultPublishDateList);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [search, setsearch] = useState<any>('');
    const [classId, setclassId] = useState<any>(null);
    const [resultPublishDateId, setresultPublishDateId] = useState<any>(null);

    const coloumns = [
        { title: 'Academic Year', dataIndex: 'academicYear', key: "academicYear", showOnResponse: true, showOnDesktop: true },
        { title: 'Class Name', dataIndex: 'className', key: "className", showOnResponse: true, showOnDesktop: true },
        { title: 'Exam Name', dataIndex: 'examName', key: "examName", showOnResponse: true, showOnDesktop: true },
        { title: 'Mark Edit Status', dataIndex: 'markEditStatusString', key: "markEditStatusString", showOnResponse: true, showOnDesktop: true },
        { title: 'Mark View Status', dataIndex: 'markViewStatusString', key: "markViewStatusString", markViewStatusString: true, showOnDesktop: true },
        { title: 'Result Publish Date', dataIndex: 'resultPublishDate', key: "resultPublishDate", showOnResponse: true, showOnDesktop: true },
        { title: 'Result Publish Message', dataIndex: 'resultPublishMessage', key: "resultPublishMessage", showOnResponse: true, showOnDesktop: true },
        { title: 'Working Day Start', dataIndex: 'workingDayStart', key: "workingDayStart", showOnResponse: true, showOnDesktop: true },
        { title: 'Working Day End', dataIndex: 'workingDayEnd', key: "workingDayEnd", showOnResponse: true, showOnDesktop: true },
        {
            title: 'Action', dataIndex: 'gradeRange', key: 'serial', showOnResponse: true, showOnDesktop: true,
            render: (text, record, index) => (
                <Space size="middle">
                    <Button type="primary" onClick={(e) => {
                        setresultPublishDateId(record.resultPublishDateId);
                        setIsModalVisible(true);
                        setclassId(record.classId)
                        updateForm.setFieldsValue({
                            academicYear: record.academicYear,
                            className: record.className,
                            examName: record.examName,
                            markEditStatus: record.markEditStatus,
                            markViewStatus: record.markViewStatus,
                            resultPublishStatus: record.resultPublishStatus,
                            resultPublishDate: moment(record.resultPublishDate),
                            resultPublishMessage: record.resultPublishMessage,
                            workingDayStart: moment(record.workingDayStart),
                            workingDayEnd: moment(record.workingDayEnd),
                        });
                    }} icon={<EditOutlined />} />
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteResultPublishDate({ data: record.resultPublishDateId, search: search })}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    const loading = useStoreState((state) => state.exam.loading);


    const submitForm = (value) => {
        // console.log('value', value);
        setsearch(value);
        fetchresultPublishDateList(value);
    }

    const updateSubmitForm = (value) => {
        let data = {
            "markEditStatus": value.markEditStatus,
            "classId": search.classId,
            "markViewStatus": value.markViewStatus,
            "resultPublishDate": moment(value?.resultPublishDate).format("YYYY-MM-DD"),
            "resultPublishDateId": resultPublishDateId,
            "resultPublishMessage": value.resultPublishMessage,
            "resultPublishStatus": value.resultPublishStatus,
            "workingDayEnd": moment(value?.workingDayEnd).format("YYYY-MM-DD"),
            "workingDayStart": moment(value?.workingDayStart).format("YYYY-MM-DD")
        }
        updateResultPublishDate({ data: data, search: search });
        setIsModalVisible(false);
        setclassId(null)
        // console.log('value', value);
    }




    return (
        <>
            <>
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 18, offset: 5 }} xl={{ span: 18, offset: 5 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={submitForm}
                            form={form}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Select Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="classId"
                                        label="Select Class"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select class" },
                                        ]}
                                    >
                                        <SelectClass />
                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: resultPublishDateList.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: coloumns,
                                rowKey: "resultPublishDateId",
                                dataSource: resultPublishDateList,
                                filterData: resultPublishDateList,
                                pagination: true,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                    </Col>
                </Row>
                <Modal
                    title="Mark Configuration"
                    visible={isModalVisible}
                    //onOk={grandFinalMarkConfigureation}
                    onCancel={() => setIsModalVisible(false)}
                    cancelText="Close"
                    okText="Update"
                    centered
                    footer={null}
                >
                    <Form
                        layout="vertical"
                        id="update"
                        onFinish={updateSubmitForm}
                        form={updateForm}
                    >
                        <Row>

                            <Col xs={{ span: 24 }}>
                                <Form.Item
                                    name="academicYear"
                                    label="Year"
                                    className="title-Text"

                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <Form.Item
                                    name="className"
                                    label="Class"
                                    className="title-Text"

                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <Form.Item
                                    name="examName"
                                    label="Exam"
                                    className="title-Text"

                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }}>
                                <Form.Item
                                    name="markEditStatus"
                                    label="Mark Edit Status"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select edit status" },
                                    ]}
                                >
                                    <Select placeholder="Mark Edit Status" style={{ width: "100%" }}>
                                        <Option value={1}>Yes</Option>
                                        <Option value={0}>No</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <Form.Item
                                    name="markViewStatus"
                                    label="Mark View Status"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select view status" },
                                    ]}
                                >
                                    <Select placeholder="Mark View Status" style={{ width: "100%" }}>
                                        <Option value={1}>Yes</Option>
                                        <Option value={0}>No</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <Form.Item
                                    name="resultPublishDate"
                                    label="Result Publish Date"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select date" },
                                    ]}
                                >

                                    <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <Form.Item
                                    name="resultPublishMessage"
                                    label="Result Publish Message"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please input result publish message" },
                                    ]}
                                >

                                    <Input placeholder="Result Publish Message" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <Form.Item
                                    name="resultPublishStatus"
                                    label="Result Publish Status"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select result publish status" },
                                    ]}
                                >
                                    <Select placeholder="Result Publish Status" style={{ width: "100%" }}>
                                        <Option value={1}>Yes</Option>
                                        <Option value={0}>No</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <Form.Item
                                    name="workingDayStart"
                                    label="Working Day Start"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select date" },
                                    ]}
                                >

                                    <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <Form.Item
                                    name="workingDayEnd"
                                    label="Working Day End"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select date" },
                                    ]}
                                >

                                    <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }}>
                                <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                    Save
                                </Button>
                            </Col>

                        </Row>
                    </Form>

                </Modal>
            </>
        </>
    )

}