import { FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Row, Skeleton, Space, Table } from 'antd';
import React, { useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import moment from 'moment';
import TableView from '../../../contents/AntTableResponsive';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../utils/pdf';
import { Excel } from 'antd-table-saveas-excel';
import Text from 'antd/lib/typography/Text';
export default function InstituteDateToDateBillReportManagement() {

    const fetchInstituteDateToDateBillCollectionManagementReportList = useStoreActions((state) => state.customerSupport.fetchInstituteDateToDateBillCollectionManagementReportList);
    const instituteDateToDateBillCollectionManagementReportList = useStoreState((state) => state.customerSupport.instituteDateToDateBillCollectionManagementReportList);
    const loading = useStoreState((state) => state.customerSupport.loading);
    const [post, setPost] = useState<any>(null);

    const callApiFunction = (value) => {
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
        }
        setPost(postData)
        fetchInstituteDateToDateBillCollectionManagementReportList(postData);

    };

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");
        var detailsc = `Date To Date Bill Collection`;
        pdfDataL(doc, "");
        doc.text(detailsc, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };
        var col = [
            "Institute ID",
            "Institute Name",
            "Partner Name",
            "Billing Year",
            "Billing Month",
            "Payable Amount",
            "Paid Amount",
            "Partner Commission",
            "Company Amount",
        ].filter(Boolean);

        var rows: any = [];


        instituteDateToDateBillCollectionManagementReportList.forEach(element => {
            var temp: any = [
                element.instituteId,
                element.instituteName,
                element.partnerName,
                element.billingYear,
                element.billingMonth,
                element.payableAmount,
                element.paidAmont,
                element.partnerCommission,
                element.companyAmount,
            ];
            rows.push(temp);
        });


        let first = doc.autoTable.previous;
        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
            { title: "", dataKey: "b3" },
            { title: "", dataKey: "b4" },

        ], [
            {
                b1: "From Date",
                b2: post.fromDate,
                b3: 'To Date',
                b4: post.toDate,


            }
        ], {
            startY: 45,
            showHeader: "never",
            theme: 'grid',

            // addPageContent: pageContent,
        });
        doc.autoTable(col, rows, {
            startY: doc.autoTable.previous.finalY + 5,
            showHeader: "firstPage",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                overflow: 'linebreak',
                lineColor: [224, 224, 224]
            },
            styles: {
                overflow: 'linebreak',
            },
            columnStyles: {
                ledgerName: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 75
                },
                debit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                },
                credit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                }
            },


        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(detailsc + ".pdf");

    }


    const coloumns = [

        { title: 'Institute Id', dataIndex: 'instituteId', key: "instituteId", showOnResponse: true, showOnDesktop: true },
        { title: 'Institute Name', dataIndex: 'instituteName', key: "instituteName", showOnResponse: true, showOnDesktop: true },
        { title: 'Partner Name', dataIndex: 'partnerName', key: "partnerName", showOnResponse: true, showOnDesktop: true },
        { title: 'Billing Year', dataIndex: 'billingYear', key: "billingYear", showOnResponse: true, showOnDesktop: true },
        { title: 'Billing Month', dataIndex: 'billingMonth', key: "billingMonth", showOnResponse: true, showOnDesktop: true },
        { title: 'Payable Amount', dataIndex: 'payableAmount', key: "payableAmount", showOnResponse: true, showOnDesktop: true },
        { title: 'Paid Amount', dataIndex: 'paidAmont', key: "paidAmont", showOnResponse: true, showOnDesktop: true },
        { title: 'Partner Commission', dataIndex: 'partnerCommission', key: "partnerCommission", showOnResponse: true, showOnDesktop: true },
        { title: 'Company Amount', dataIndex: 'companyAmount', key: "companyAmount", showOnResponse: true, showOnDesktop: true },
    ]

    return (
        <>

            <Card title="Date To Date Bill Collection">

                <Form
                    layout="vertical"
                    onFinish={callApiFunction}
                    id="searchReport"
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>


                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    </Row>
                </Form>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                {instituteDateToDateBillCollectionManagementReportList?.length > 0 &&
                    <>
                        <Row className="m-t-mo-30">
                            <Col span={24}>
                                <div className="datatable-responsive-demo">
                                    <TableView
                                        antTableProps={{
                                            showHeader: true,
                                            columns: coloumns,
                                            dataSource: instituteDateToDateBillCollectionManagementReportList,
                                            filterData: instituteDateToDateBillCollectionManagementReportList,
                                            pagination: true,
                                            bordered: true,
                                            rowKey: "instituteId",
                                            summary: function () {
                                                let payableAmountx = 0;
                                                let paidAmontx = 0;
                                                let partnerCommissionx = 0;
                                                let companyAmountx = 0;
                                                
                                                instituteDateToDateBillCollectionManagementReportList?.forEach(({ payableAmount, paidAmont, partnerCommission, companyAmount }) => {
                                                    payableAmountx += payableAmount;
                                                    paidAmontx += paidAmont;
                                                    partnerCommissionx += partnerCommission;
                                                    companyAmountx += companyAmount;
                                                });

                                                return (
                                                    <>
                                                        <Table.Summary.Row>
                                                            <Table.Summary.Cell index={0}><Text type="danger" strong>Total</Text> </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={1}>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={2}>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={3}>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={4}>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={5}>
                                                                <Text type="danger" strong>{payableAmountx.toFixed(2)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={6}>
                                                                <Text type="danger" strong>{paidAmontx.toFixed(2)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={7}>
                                                                <Text type="danger" strong>{partnerCommissionx.toFixed(2)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={8}>
                                                                <Text type="danger" strong>{companyAmountx.toFixed(2)}</Text>
                                                            </Table.Summary.Cell>

                                                        </Table.Summary.Row>

                                                    </>
                                                );
                                            }
                                        }}
                                        mobileBreakPoint={768}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Space size={'large'} className="float-right">
                            < Button
                                type='primary'
                                icon={<FileExcelOutlined />}
                                onClick={() => {
                                    const excel: any = new Excel();
                                    excel
                                        .addSheet(`Sheet1`)
                                        .addColumns(coloumns)
                                        .addDataSource(instituteDateToDateBillCollectionManagementReportList)
                                        .saveAs(`${post.fromDate}-${post.toDate} Date To Date Bill Collection.xlsx`);
                                }}
                            >
                                Download Excel
                            </ Button >
                            <Button type="primary" htmlType="submit" icon={<FilePdfOutlined />} onClick={() => exportPdf()} >Download PDF</Button>
                        </Space>
                    </>
                }

            </Card>


        </>
    )
}
