import { Button, Card, Col, DatePicker, Form, Row, Skeleton, Space, Table } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import TableResponsive from "../../../../contents/AntTableResponsive";
import ReactExport from "react-export-excel";
import Text from 'antd/lib/typography/Text';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, lpowerdbypdf, ldatepdf, pdfGenerate } from '../../../utils/pdf';
require('jspdf-autotable');
const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;


let fd = '';
let td = '';

let totalAmount = 0;

export default function UpayPgwStatement(props) {
    const upayStatement = useStoreState((state) => state.saccount.upayPgwStatement);
    const fetchUpayStatement = useStoreActions((state) => state.saccount.fetchUpayPgwStatement);
    const loading = useStoreState((state) => state.saccount.loading);



    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
        }
        fetchUpayStatement(postData);
        fd = moment(value?.fromDate).format("YYYY-MM-DD");
        td = moment(value?.toDate).format("YYYY-MM-DD");
    };

    const columns = [

        {
            title: 'Student ID',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Trx ID',
            dataIndex: 'trxId',
            key: 'trxId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Fee Invoice ID',
            dataIndex: 'feeInvoiceId',
            key: 'feeInvoiceId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Upay Invoice ID',
            dataIndex: 'upayInvoiceId',
            key: 'upayInvoiceId',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Trn. Date',
            dataIndex: 'trnDate',
            key: 'trnDate',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Trn. Amount',
            dataIndex: 'amount',
            key: 'amount',
            showOnResponse: true,
            showOnDesktop: true,
        },
      
        {
            title: 'Status',
            dataIndex: 'requestStaus',
            key: 'requestStaus',
            showOnResponse: true,
            showOnDesktop: true,
        },
    ];


    function exportPdf() {
        var details = `Upay PGW Statement from ${fd} to ${td}`;

        var doc = new jsPDF("l", "mm", "a4");


        pdfDataL(doc, details);

        var col = ["Student ID", "Student Name", "Fee Invoice ID", "TRX ID", "Trn. Date", "Trn. Amount", "Status"];

        var rows: any = [];

        var temp: any = []


        upayStatement.forEach(element => {
            var temp = [element.customStudentId, element.studentName, element.feeInvoiceId, element.trxId,
            element.trnDate, element.amount, element.requestStaus];
            rows.push(temp);
        });

        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };

        doc.autoTable(col, rows, {
            headerStyles: {
                lineWidth: .01,
                lineColor: [224, 224, 224],
                overflow: 'linebreak'
            },
            columnStyles: {
                0: { overflow: 'linebreak', columnWidth: 30, halign: "left" },
                1: { overflow: 'linebreak', columnWidth: 65, halign: "left" },
                2: { overflow: 'linebreak', columnWidth: 30, halign: "left" },
                3: { overflow: 'linebreak', columnWidth: 60, halign: "left" },
                4: { overflow: 'linebreak', columnWidth: 25, halign: "left" },
                5: { overflow: 'linebreak', columnWidth: 25, halign: "right" },
                6: { overflow: 'linebreak', columnWidth: 25, halign: "left" },
            },
            theme: "grid",
            startY: 45,
            addPageContent: pageContent
        });
        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
            { title: "", dataKey: "b3" },
            { title: "", dataKey: "b4" },
            { title: "", dataKey: "b5" },
            { title: "", dataKey: "b6" },
            { title: "", dataKey: "b7" },

        ], [
            {
                b1: "",
                b2: "",
                b3: "",
                b4: "",
                b5: "Total",
                b6: totalAmount,
                b7: "",
               
            }
        ], {
            startY: doc.autoTable.previous.finalY + 0,
            showHeader: "never",
            theme: 'grid',

            columnStyles: {
                b1: {
                    halign: "left",
                    columnWidth: 30
                },
                b2: {
                    halign: "left",
                    columnWidth: 65
                },
                b3: {
                    halign: "left",
                    columnWidth: 30
                },
                b4: {
                    halign: "left",
                    columnWidth: 60
                },
                b5: {
                    halign: "left",
                    columnWidth: 25
                },
                b6: {
                    halign: "right",
                    columnWidth: 25
                },
                b7: {
                    halign: "right",
                    columnWidth: 25
                },
      

            },

            // addPageContent: pageContent,
        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }

        doc.save(details + ".pdf");

    }


    return (
        <>
            <Card title="Upay Statement">
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>


                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    </Row>
                </Form>

                {< Skeleton loading={loading} paragraph={{ rows: 10 }} />}
               
                {upayStatement?.length > 0 && !loading &&
                    <>
                        <TableResponsive
                            antTableProps={{
                                showHeader: true,
                                columns: columns,
                                dataSource: upayStatement,
                                filterData: upayStatement,
                                pagination: true,
                                bordered: true,
                                rowKey: "requestLogId",
                                summary: function () {
                                    totalAmount = 0;
                                    totalAmount = 0;

                                    upayStatement?.forEach(({ amount }) => {
                                        totalAmount += amount;
                                    });

                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                
                                                <Table.Summary.Cell index={0}> 
                                                </Table.Summary.Cell>

                                                <Table.Summary.Cell index={1}>
                                                </Table.Summary.Cell>

                                                <Table.Summary.Cell index={2}>
                                                </Table.Summary.Cell>

                                                <Table.Summary.Cell index={3}>
                                                </Table.Summary.Cell>

                                                <Table.Summary.Cell index={4}>
                                                </Table.Summary.Cell>
                                                
                                                <Table.Summary.Cell index={5}>
                                                <div style={{ textAlign: "right" }}>
                                                        <Text type="danger" strong>Total</Text>
                                                    </div>
                                                </Table.Summary.Cell>

                                                <Table.Summary.Cell index={6}>
                                                <Text type="danger" strong>{totalAmount}</Text>
                                                </Table.Summary.Cell>

                                                <Table.Summary.Cell index={7}>
                                                </Table.Summary.Cell>

                                            </Table.Summary.Row>

                                        </>
                                    );
                                }
                            }}
                            mobileBreakPoint={768}

                        />
                        <Space style={{ float: 'right' }} size="middle">

                            <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Upay statment from ${fd} to ${td}`}
                            >
                                <ExcelSheet data={upayStatement} name="Upay Statement">
                                    <ExcelColumn label="Student ID " value="customStudentId" />
                                    <ExcelColumn label="Student Name " value="studentName" />
                                    <ExcelColumn label="Trx ID" value="trxId" />
                                    <ExcelColumn label="Fee Invoice ID " value="feeInvoiceId" />
                                    <ExcelColumn label="Upay Invoice ID" value="upayInvoiceId" />
                                    <ExcelColumn label="Trn. Date" value="trnDate" />
                                    <ExcelColumn label="Trn. Amount" value="amount" />
                                    <ExcelColumn label="Status" value="requestStaus" />
                                </ExcelSheet>
                            </ExcelFile>
                            <Button type="primary" icon={<FilePdfOutlined />} onClick={exportPdf} > Download PDF </Button>
                        </Space>
                    </>
                }
            </Card>
        </>
    )
}
