
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, DatePicker, Skeleton, Table } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';
import TableView from '../../../../contents/AntTableResponsive';
import TableResponsive from "../../../../contents/AntTableResponsive";
import Text from 'antd/lib/typography/Text';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, ldatepdf, lpowerdbypdf } from '../../../utils/pdf';
import { FilePdfOutlined } from '@ant-design/icons';
import ReactExport from "react-export-excel";
import { SelectSection } from '../../../select/SelectSection';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectPeriod } from '../../../select/SelectPeriod';
import moment from 'moment';
require('jspdf-autotable');


export default function SectionWiseDailySummary() {

    const [sectionWiseReportForm] = Form.useForm();
    const fetchStudentDailySectionWiseSummaryReport = useStoreActions((state) => state.student.fetchStudentDailySectionWiseSummaryReport);
    const studentDailySectionWiseSummaryReport = useStoreState((state) =>  state.student.studentDailySectionWiseSummaryReport);
    const fetchStudentAttendanceAllPeriod = useStoreActions((state) => state.student.fetchStudentAttendanceAllPeriod)
    const dateFormat = 'YYYY-MM-DD';
    const [attendanceDate,setAttenDanceDate] = useState('');

    let totalAttendanceTaken = 0;
    let totalPresent = 0;
    let totalAbsent=0;
    
    const loading = useStoreState((state) =>  state.student.loading);
    const formSubmit = (value) => {
        let postData:any = {
            date: moment(value.date).format(dateFormat),
            periodId: value.period
        }
        fetchStudentDailySectionWiseSummaryReport(postData);

        setAttenDanceDate(moment(value?.date).format("YYYY-MM-DD"));
    }

    useEffect(() => {
        fetchStudentAttendanceAllPeriod()
    }, [])

    const studentListColumn = [
        { title: 'Class Name', dataIndex: 'className', key: 'className', showOnResponse: true, showOnDesktop: true  },
        { title: 'Shift Name', dataIndex: 'shiftName', key: 'shiftName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Section Name', dataIndex: 'sectionName', key: 'sectionName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Total Attendance', dataIndex: 'attendanceTaken', key: 'attendanceTaken', showOnResponse: true, showOnDesktop: true  },
        { title: 'Present', dataIndex: 'present', key: 'present', showOnResponse: true, showOnDesktop: true  },
        { title: 'Absent', dataIndex: 'absent', key: 'absent', showOnResponse: true, showOnDesktop: true  },
    ];

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");

        var details = `Section Wise Daily Summary of `+attendanceDate;
        pdfDataL(doc, "");
        doc.text(details, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);            
        };


        var col = [
            "Class Name",
            "Shift Name",
            "Section Name",
            "Total Attendance",
            "Present",
            "Absent",
        ].filter(Boolean);

        var rows:any = [];
        
        studentDailySectionWiseSummaryReport.forEach(element => {
            var temp:any = [ 
                element.className, 
                element.shiftName, 
                element.sectionName,
                element.attendanceTaken,
                element.present,
                element.absent,
            ];
            rows.push(temp);
        });

        var temp2:any =[
            "",
            "",
            "Total",
            totalAttendanceTaken+"",
            totalPresent+"",
            totalAbsent+""
        ]
        rows.push(temp2);
       

            let first = doc.autoTable.previous;
            doc.autoTable(col, rows, {
                startY: 45,
                showHeader: "firstPage",
                theme: 'grid',
                headerStyles: {
                    // fillColor: [105, 105, 105],
                    // textColor: [255, 255, 255],
                    // fontSize: 10
                    lineWidth: .01,
                    lineColor: [224, 224, 224]
                },
                columnStyles: {
                    guardianMobile: {
                        halign: "left",
                        fontSize: 8,
                        columnWidth: 250
                    }
                },
                addPageContent: pageContent,
            });

  
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    return(
        <>
            <Card title="Section Wise Daily Summary">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={sectionWiseReportForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="date"
                                        label="Select Date"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select date" },
                                        ]}
                                    >
                                        <DatePicker style={{ width: "100%" }}  format={dateFormat}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="period"
                                        label="Select Period"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select period" },
                                        ]}
                                    >
                                        <SelectPeriod />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{rows:15}}/>
                <Row style={{ display: studentDailySectionWiseSummaryReport.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}}>
                        <TableResponsive
                            antTableProps={{
                                showHeader: true,
                                columns:studentListColumn,
                                rowKey:"identificationId",
                                dataSource: studentDailySectionWiseSummaryReport,
                                filterData: studentDailySectionWiseSummaryReport,
                                pagination: true,
                                bordered: true,
                                
                                summary: function () {
                                    totalAttendanceTaken = 0;
                                    totalPresent = 0;
                                    totalAbsent=0;

                                    studentDailySectionWiseSummaryReport?.forEach(({ attendanceTaken, present, absent }) => {
                                        totalAttendanceTaken += attendanceTaken;
                                        totalPresent += present;
                                        totalAbsent += absent;
                                    });

                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                
                                                <Table.Summary.Cell index={0}> 
                                                </Table.Summary.Cell>
                                                
                                                <Table.Summary.Cell index={1}>
                                                </Table.Summary.Cell>

                                                <Table.Summary.Cell index={2}>
                                                </Table.Summary.Cell>

                                                <Table.Summary.Cell index={3}>
                                                <Text type="danger" strong>{totalAttendanceTaken}</Text>
                                                </Table.Summary.Cell>

                                                <Table.Summary.Cell index={4}>
                                                <Text type="danger" strong>{totalPresent}</Text>
                                                </Table.Summary.Cell>

                                                <Table.Summary.Cell index={5}>
                                                <Text type="danger" strong>{totalAbsent}</Text>
                                                </Table.Summary.Cell>

                                            </Table.Summary.Row>

                                        </>
                                    );
                                }

                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                    </Col>
                    <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>
                            <Button type="primary" icon={<DownloadOutlined />} onClick={() => exportPdf()} >PDF Download</Button>
                        </Space>
                    </Col>
                </Row>

            </Card>
        </>
    )
}
