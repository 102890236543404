import { Button, Card, Col, DatePicker, Form, message, Row, Select, Skeleton, Space } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, lpowerdbypdf, ldatepdf, pdfGenerate } from '../../../utils/pdf';
import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined, SearchOutlined, SendOutlined } from '@ant-design/icons';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import TableResponsive from "../../../../contents/AntTableResponsive";
import Table from "../../../../contents/AntTableResponsive";
import ReactExport from "react-export-excel";
import Text from 'antd/lib/typography/Text';
import TextArea from 'antd/lib/input/TextArea';

require('jspdf-autotable');

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;


let fd = '';
let td = '';
let totalsum = '';
export default function RegistrationSms(props) {
    const sectionPaidReport = useStoreState((state) => state.saccount.sectionPaidReport);
    const fetchsectionPaidReport = useStoreActions((state) => state.saccount.fetchsectionPaidReport);
    const sendStudentSms = useStoreActions((state) => state.sms.sendRegistrationSms);
    const loading = useStoreState((state) =>  state.saccount.loading);
    const [reportForm] = Form.useForm();
    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    let secName = $('.classConfigId .ant-select-selection-item').text();

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const reportFormSubmit = (value) => {
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
            classConfigId: value.classConfigId
        }
        fd = moment(value?.fromDate).format("YYYY-MM-DD");
        td = moment(value?.toDate).format("YYYY-MM-DD");
        fetchsectionPaidReport(postData);
    };

    const sendSms = () => {
        if (selectedValue.length === 0) {
            message.error("Please select atleast one student");
            return
        } 
        let postData = selectedValue.map((item) => item.identificationId);
        sendStudentSms(postData);
        setselectedRowKeys([]); setselectedValue([]);
    }

    const columns = [

        {
            title: 'Fee Invoice Id',
            dataIndex: 'feeInvoiceId',
            key: 'feeInvoiceId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Id',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Class',
            dataIndex: 'className',
            key: 'className',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Section',
            dataIndex: 'sectionName',
            key: 'sectionName',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Fee Heads',
            dataIndex: 'feeHeads',
            key: 'feeHeads',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Fee Sub Heads',
            dataIndex: 'feeSubHeads',
            key: 'feeSubHeads',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Payment Date',
            dataIndex: 'paymentDate',
            key: 'paymentDate',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Paid Amount',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Registration Sms',
            dataIndex: 'registrationSms',
            key: 'registrationSms',
            showOnResponse: true,
            showOnDesktop: true,
        },
       

    ];

    return (
        <>
            <Card title="Send Registration SMS">
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>

                            <Form.Item
                                name="classConfigId"
                                label="Section:"
                                className="classConfigId"
                                rules={[
                                    { required: true, message: "Please select section" },
                                ]}
                            >
                                <SelectClassConfig />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    </Row>
                </Form>
                {sectionPaidReport ===null && < Skeleton loading={loading} paragraph={{rows:10}} />}
                {sectionPaidReport?.length > 0 &&
                    <>
                        <Table
                             antTableProps={{
                                showHeader: true,
                                rowSelection: rowSelection,
                                columns: columns,
                                dataSource: sectionPaidReport,
                                filterData: sectionPaidReport,
                                pagination: false,
                                bordered: true,
                                rowKey: "masterId",
                            }}
                            mobileBreakPoint={768}

                        />

                        <br />

                        <Space style={{ float: 'right' }} size="middle">
                            <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Section Paid Info of ${secName} from ${fd} to ${td}`}
                            >
                                <ExcelSheet data={sectionPaidReport} name="Section Paid Info">
                                    <ExcelColumn label="Invoice Id" value="feeInvoiceId" />
                                    <ExcelColumn label="Student Id" value="customStudentId" />
                                    <ExcelColumn label="Student Name" value="studentName" />
                                    <ExcelColumn label="Class" value="className" />
                                    <ExcelColumn label="Section" value="sectionName" />
                                    <ExcelColumn label="Roll" value="studentRoll" />
                                    <ExcelColumn label="Fee Head" value="feeHeads" />
                                    <ExcelColumn label="Fee Sub Heads" value="feeSubHeads" />
                                    <ExcelColumn label="Payment Date" value="paymentDate" />
                                    <ExcelColumn label="Ledger Name" value="ledgerName" />
                                    <ExcelColumn label="Paid Amount" value="paidAmount" />
                                    <ExcelColumn label="Registration Sms" value="registrationSms" />

                                </ExcelSheet>
                            </ExcelFile>

                                <Button type="primary" htmlType="submit" onClick={sendSms} className="mt-0 pull-right" icon={<SendOutlined />} >
                                 Send Registration SMS
                                </Button>

                        </Space>
                   
                        </>

                }

            </Card>
        </>
    )
}
