import { Button, Card, Col, Form, Row, Select, Space } from 'antd';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import ReactExport from "react-export-excel";


const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

export default function OnlineAdmissionPaymentStatusWise() {

    const fetchPaymentStatusWiseOnlineAdmissionList = useStoreActions((state) => state.onlineAdmission.fetchPaymentStatusWiseOnlineAdmissionList);
    const paymentStatusWiseOnlineAdmissionList = useStoreState((state) =>  state.onlineAdmission.paymentStatusWiseOnlineAdmissionList)
    const [paymentStatusWiseAdmissionReportForm] = Form.useForm();
    const { Option } = Select;
    
    const paymentStatusAdmissionReportFormSubmit = (value) => {
        fetchPaymentStatusWiseOnlineAdmissionList(value.status);
    }
    const column = [
        { title: 'Applicant ID', dataIndex: 'applicantId', key: 'applicantId', showOnResponse: true, showOnDesktop: true  },
        { title: 'Applicant Name', dataIndex: 'applicantName', key: 'applicantName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Gender', dataIndex: 'gender', key: 'gender', showOnResponse: true, showOnDesktop: true  },
        { title: 'Religion', dataIndex: 'religion', key: 'religion', showOnResponse: true, showOnDesktop: true  },
        { title: 'Birthday', dataIndex: 'dateOfBirth', key: 'dateOfBirth', showOnResponse: true, showOnDesktop: true  },
        { title: 'Quota', dataIndex: 'quota', key: 'quota', showOnResponse: true, showOnDesktop: true  },
        { title: 'Birth Certifica No', dataIndex: 'birthCertificatNo', key: 'birthCertificatNo', showOnResponse: true, showOnDesktop: true  },
        { title: 'Nationality', dataIndex: 'nationality', key: 'nationality', showOnResponse: true, showOnDesktop: true  },
        { title: 'Blood Group', dataIndex: 'bloodGroup', key: 'bloodGroup', showOnResponse: true, showOnDesktop: true  },
        { title: 'Autism', dataIndex: 'autism', key: 'autism', showOnResponse: true, showOnDesktop: true  },
        { title: 'Father Name', dataIndex: 'fatherName', key: 'fatherName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Father Occupation', dataIndex: 'fatherOccupation', key: 'fatherOccupation', showOnResponse: true, showOnDesktop: true  },
        { title: 'Father Income', dataIndex: 'fatherIncome', key: 'fatherIncome', showOnResponse: true, showOnDesktop: true  },
        { title: 'Father NID', dataIndex: 'fatherNid', key: 'fatherNid', showOnResponse: true, showOnDesktop: true  },
        { title: 'Father Mobile', dataIndex: 'fatherMobile', key: 'fatherMobile', showOnResponse: true, showOnDesktop: true  },
        { title: 'Mother', dataIndex: 'motherName', key: 'motherName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Mother Occupation', dataIndex: 'motherOccupation', key: 'motherOccupation', showOnResponse: true, showOnDesktop: true  },
        { title: 'Mother Income', dataIndex: 'motherIncome', key: 'motherIncome', showOnResponse: true, showOnDesktop: true  },
        { title: 'Mother NID', dataIndex: 'motherNid', key: 'motherNid', showOnResponse: true, showOnDesktop: true  },
        { title: 'Mother Mobile', dataIndex: 'motherMobile', key: 'motherMobile', showOnResponse: true, showOnDesktop: true  },
        { title: 'Local Guardian Name', dataIndex: 'localGuardianName', key: 'localGuardianName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Local Guardian Mobile', dataIndex: 'localGuardianMobile', key: 'localGuardianMobile', showOnResponse: true, showOnDesktop: true  },
        { title: 'Application Date', dataIndex: 'applicationDate', key: 'applicationDate', showOnResponse: true, showOnDesktop: true  },
    ];
    return(
        <>
            <Card title="Section Wise Online Admission Report">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="instructionForm"
                            onFinish={paymentStatusAdmissionReportFormSubmit}
                            form={paymentStatusWiseAdmissionReportForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 4 }}>

                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                                    <Form.Item
                                            name="status"
                                            label="Select Status"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select admission status" },
                                            ]} 
                                        >
                                        <Select placeholder="Select status">
                                            <Option value={1}>Paid</Option>
                                            <Option value={0}>UnpPaid</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>

                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <br />
                {paymentStatusWiseOnlineAdmissionList?.length > 0 &&
                    <Row className="m-t-mo-30">
                        <Col span="24">
                            <div className="datatable-responsive-demo">
                                    <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns:column,
                                        dataSource: paymentStatusWiseOnlineAdmissionList,
                                        filterData: paymentStatusWiseOnlineAdmissionList,
                                        pagination: true,
                                        bordered: true,
                                        rowKey:"id",
                                    }}
                                    mobileBreakPoint={768}
                                />
                            </div>
                        </Col>
                        <Col span={24}>
                            <Space size="small" style={{ float: "right" }}>
                            <ExcelFile
                                    element={<Button type="primary" icon={<FileExcelOutlined />} >
                                        Download Excel
                                    </Button>}
                                    filename={`Payment Status Wise Online Admission Report`}
                                >
                                    <ExcelSheet data={paymentStatusWiseOnlineAdmissionList} name="Payment Status Wise Online Admission Report">
                                        <ExcelColumn label="Applicant ID" value="applicantId" />
                                        <ExcelColumn label="Applicant Name" value="applicantName" />
                                        <ExcelColumn label="Gender" value="gender" />
                                        <ExcelColumn label="Religion" value="religion" />
                                        <ExcelColumn label="Birthday" value="dateOfBirth" />
                                        <ExcelColumn label="Quota" value="quota" />
                                        <ExcelColumn label="Birth Certifica No" value="birthCertificatNo" />
                                        <ExcelColumn label="Nationality" value="nationality" />
                                        <ExcelColumn label="Blood Group" value="bloodGroup" />
                                        <ExcelColumn label="Autism" value="autism" />
                                        <ExcelColumn label="Father Name" value="fatherName" />
                                        <ExcelColumn label="Father Occupation" value="fatherOccupation" />
                                        <ExcelColumn label="Father Income" value="fatherIncome" />
                                        <ExcelColumn label="Father NID" value="fatherNid" />
                                        <ExcelColumn label="Father Mobile" value="fatherMobile" />
                                        <ExcelColumn label="Mother" value="motherName" />
                                        <ExcelColumn label="Mother Occupation" value="motherOccupation" />
                                        <ExcelColumn label="Mother Income" value="motherIncome" />
                                        <ExcelColumn label="Mother NID" value="motherNid" />
                                        <ExcelColumn label="Mother Mobile" value="motherMobile" />
                                        <ExcelColumn label="Local Guardian Name" value="localGuardianName" />
                                        <ExcelColumn label="Local Guardian Mobile" value="localGuardianMobile" />
                                        <ExcelColumn label="Application Date" value="applicationDate" />
                                    </ExcelSheet>
                                </ExcelFile>
                            </Space>
                        </Col>
                    </Row>
                }
            </Card>
        </>
    )
}