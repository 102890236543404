import { Avatar, Button, Card, Col, DatePicker, Form, Row, Skeleton, Space, Table } from 'antd'
import moment from 'moment';
import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { FilePdfOutlined, PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import ReactToPrint from 'react-to-print';
import $ from 'jquery';
declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        // extends React's HTMLAttributes
        name?: string;
    }
}

const ComponentToPrint = React.forwardRef((props: any, ref: any) => {
    const { userWiseTran, info, fromDate, toDate } = props;
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        let temp = _.orderBy(userWiseTran, 'userName', 'desc');
        setData(temp);
    }, [userWiseTran])


    let sameKey;
    const columns = [
        {
            title: "User",
            dataIndex: "userName",
            key: "userName",
            render: (value, row, index) => {

                const obj: any = {
                    children: value,
                    props: {}
                };
                if (!(sameKey !== value)) {
                    obj.props.rowSpan = 0;
                    return obj;
                }
                const count = data.filter(item => item.userName === value).length;
                sameKey = value;
                obj.props.rowSpan = count;
                return obj;
            }
        },
        {
            title: "Details",
            children: [
                {
                    title: "Ledger Name",
                    dataIndex: "ledgerName",
                    key: 1
                },
                {
                    title: "Debit",
                    dataIndex: "debit",
                    key: 2
                },
                {
                    title: "Credit",
                    dataIndex: "credit",
                    key: 3
                }
            ]
        }
    ];
    return (
        <div className='print-source' ref={ref} >
            <div style={{ display: "flex", justifyContent: "space-between", padding:20 }}>
                <div>
                    <Avatar
                        size={60}
                        src={"data:image/png;base64," + info?.imageName}
                        style={{ margin: "auo" }}
                    />
                </div>
                <div style={{ textAlign: "center" }}>
                    <h2 style={{ marginBottom: -5 }}>{info?.instituteName}</h2>
                    <h3>{info?.address}</h3>
                </div>
                <div></div>
            </div>
            <hr style={{marginTop:-10}} />
            <div style={{ textAlign: "center", fontWeight: "bold", marginBottom: 5 }}>User Wise Transaction Overview from {fromDate} to {toDate}</div>
            {userWiseTran?.length > 0 &&
                <div style={{ pointerEvents: "none", padding: "20px" }}>

                    <Table
                        columns={columns}
                        dataSource={data?.map((d, i) => ({ key: i, ...d }))}
                        pagination={false}
                        bordered={true}
                    />

                </div>
            }
        </div>
    );
});

export default function UserWiseTransaction(props) {

    const userWiseTran = useStoreState((state) => state.ledger.userWiseTran);
    const fetchuserWiseTran = useStoreActions((state) => state.ledger.fetchuserWiseTran);
    const loading = useStoreState((state) => state.ledger.loading);
    const instituteInfo = useStoreState((state) => state.auth.instituteInfo);
    const [reportForm] = Form.useForm();
    const [fromDate, setfromDate] = useState<any>(null);
    const [toDate, settoDate] = useState<any>(null);

    const reportFormSubmit = (value) => {
        setfromDate(moment(value?.fromDate).format("YYYY-MM-DD"));
        settoDate(moment(value?.toDate).format("YYYY-MM-DD"));
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD")
        }

        fetchuserWiseTran(postData);
    };

    const [data, setData] = useState<any>([]);

    useEffect(() => {
        let temp = _.orderBy(userWiseTran, 'userName', 'desc');
        setData(temp);
    }, [userWiseTran])


    let sameKey;
    const columns = [
        {
            title: "User",
            dataIndex: "userName",
            key: "userName",
            render: (value, row, index) => {

                const obj: any = {
                    children: value,
                    props: {}
                };
                if (!(sameKey !== value)) {
                    obj.props.rowSpan = 0;
                    return obj;
                }
                const count = data.filter(item => item.userName === value).length;
                sameKey = value;
                obj.props.rowSpan = count;
                return obj;
            }
        },
        {
            title: "Details",
            children: [
                {
                    title: "Ledger Name",
                    dataIndex: "ledgerName",
                    key: 1
                },
                {
                    title: "Debit",
                    dataIndex: "debit",
                    key: 2
                },
                {
                    title: "Credit",
                    dataIndex: "credit",
                    key: 3
                }
            ]
        }
    ];

    const componentRef: any = useRef();

    return (
        <>
            <Card title="User Wise Transaction Overview" >
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>
                < Skeleton loading={loading} paragraph={{ rows: 10 }} />
                {userWiseTran?.length > 0 &&
                    <>
                        <div style={{ pointerEvents: "none" }}>

                            <Table
                                columns={columns}
                                dataSource={data?.map((d, i) => ({ key: i, ...d }))}
                                pagination={false}
                                bordered={true}
                            />

                        </div>
                        <br />
                        <Space size="small" style={{ float: "right" }}>
                            <ReactToPrint
                                trigger={() => <Button type='primary' icon={<PrinterOutlined />}>Print</Button>}
                                content={() => componentRef.current}
                            />
                            <ComponentToPrint ref={componentRef} userWiseTran={userWiseTran} info={instituteInfo} fromDate={fromDate} toDate={toDate} />


                        </Space>

                    </>
                }
            </Card>
        </>
    )
}
