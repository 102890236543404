import React, { useCallback, useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton, Upload } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag } from 'antd';
import TableView from '../../../../contents/AntTableResponsive';
import { Excel } from 'antd-table-saveas-excel';
import { SelectClassConfigTeacher } from '../../../select/SelectClassConfigTeacher';
import { SelectGroupbySection } from '../../../select/SelectGroupbySection';
import { DownCircleOutlined, FileExcelOutlined, SaveOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import xlsxParser from 'xlsx-parse-json';
import $ from 'jquery';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';

export default function MarkInputFormExcelSubject() {

    const { Option } = Select;
    const [markInputForm] = Form.useForm();
    const fetchSemesterListByClassConfigId = useStoreActions((state) => state.exam.fetchSemesterListByClassConfigId);
    const semesterListByClassConfigIdArray = useStoreState((state) => state.exam.semesterListByClassConfigIdArray);
    const fetchlistGroupbyClassConfig = useStoreActions((state) => state.student.fetchlistGroupbyClassConfig);
    const fetchsubjectListTeacher = useStoreActions((state) => state.exam.fetchsubjectListTeacher);
    const subjectListTeacher = useStoreState((state) => state.exam.subjectListTeacher);
    const fetchExamMarkCofigureListByExamConfigId = useStoreActions((state) => state.exam.fetchExamMarkCofigureListByExamConfigId2);
    const examMarkCofigureListByExamConfigId = useStoreState((state) => state.exam.examMarkCofigureListByExamConfigId2);
    const fetchExamMarkInputStudentList = useStoreActions((state) => state.exam.fetchExamMarkInputStudentList2);
    const examMarkInputStudentList = useStoreState<any>((state) => state.exam.examMarkInputStudentList2);
    const examMarkInputSave = useStoreActions((state) => state.exam.examMarkInputSave);
    const fetchMarkBlankSheetPdf = useStoreActions((state) => state.exam.fetchMarkBlankSheetPdf);
    const [classConfigId, setClassConfigId] = useState<any>("");
    const [examConfigId, setExamConfigId] = useState<any>("");
    const [examMarkConfigList, setExamMarkConfigList] = useState<any>();
    const [markInputTableData, setMarkInputTableData] = useState<any>();

    const [singleGroupConfigId, setSingleGroupConfigId] = useState<any>();

    const [markInputTableHeaderVisibility, setmarkInputTableHeaderVisibility] = useState<any>();

    const [markSaveObject, setMarkSaveObject] = useState<any>();
    const loading = useStoreState((state) => state.exam.loading);
    const loading1 = useStoreState((state) => state.exam.loading);

    useEffect(() => {
        setMarkInputTableData(examMarkInputStudentList.studentList);
        setmarkInputTableHeaderVisibility(examMarkInputStudentList.shortCodeVisibilty);
    }, [examMarkInputStudentList]);

    const onChangeClassList = (e) => {
        fetchSemesterListByClassConfigId(e);
        fetchlistGroupbyClassConfig(e);
        setClassConfigId(e)
        setMarkInputTableData([]);
        setExamMarkConfigList([]);
    }

    const searchSubjectByGroupId = (e) => {

        let payload: any = {
            classConfigId: classConfigId,
            groupId: e.groupId
        }
        setSingleGroupConfigId(e.groupId);
        fetchsubjectListTeacher(payload);
    }

    const configTableColumn = [
        { title: 'Short Code Title', dataIndex: 'shortCodeName', key: 'shortCodeName', showOnResponse: true, showOnDesktop: true },
        { title: 'Total', dataIndex: 'totalMark', key: 'totalMark', showOnResponse: true, showOnDesktop: true },
        { title: 'Pass Mark', dataIndex: 'passMark', key: 'passMark', showOnResponse: true, showOnDesktop: true },
        { title: 'Acceptance', dataIndex: 'acceptance', key: 'acceptance', showOnResponse: true, showOnDesktop: true },
    ]



    const getMarkConfigListBySubject = (e) => {
        let payload: any = {
            examConfigId: examConfigId,
            subjectId: e
        }
        fetchExamMarkCofigureListByExamConfigId(payload);
    }

    useEffect(() => {
        setExamMarkConfigList(examMarkCofigureListByExamConfigId == null ? null : examMarkCofigureListByExamConfigId);
    }, [examMarkCofigureListByExamConfigId])


    const searchMarkInputSearch = (value) => {
        let payload: any = {
            classConfigId: value.sectionList,
            examConfigId: value.semesterYear,
            groupId: value?.classGroup?.groupId,
            subjectId: value.selectSubject,
            academicYear: value.academicYear,
        }

        fetchExamMarkInputStudentList(payload);

        let saveObject = {
            classConfigId: value.sectionList,
            examConfigId: value.semesterYear,
            groupId: value.classGroup.groupId,
            subjectId: value.selectSubject,
            type: "insert",
            academicYear: value.academicYear,
        }

        setMarkSaveObject(saveObject);
        setMarkInputTableData([]);
    }

    const markInputTableColumn = [
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true, className: "none" },
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        ...(examMarkInputStudentList?.shortCodeVisibilty?.shortCode1Name !== '') ? [{ title: examMarkInputStudentList?.shortCodeVisibilty?.shortCode1Name + '(SC1)', dataIndex: 'dump', key: 'shortCode1Name', showOnResponse: true, showOnDesktop: true }] : [],
        ...(examMarkInputStudentList?.shortCodeVisibilty?.shortCode2Name !== '') ? [{ title: examMarkInputStudentList?.shortCodeVisibilty?.shortCode2Name + '(SC2)', dataIndex: 'dump', key: 'shortCode2Name', showOnResponse: true, showOnDesktop: true }] : [],
        ...(examMarkInputStudentList?.shortCodeVisibilty?.shortCode3Name !== '') ? [{ title: examMarkInputStudentList?.shortCodeVisibilty?.shortCode3Name + '(SC3)', dataIndex: 'dump', key: 'shortCode3Name', showOnResponse: true, showOnDesktop: true }] : [],
        ...(examMarkInputStudentList?.shortCodeVisibilty?.shortCode4Name !== '') ? [{ title: examMarkInputStudentList?.shortCodeVisibilty?.shortCode4Name + '(SC4)', dataIndex: 'dump', key: 'shortCode4Name', showOnResponse: true, showOnDesktop: true }] : [],
        ...(examMarkInputStudentList?.shortCodeVisibilty?.shortCode5Name !== '') ? [{ title: examMarkInputStudentList?.shortCodeVisibilty?.shortCode5Name + '(SC5)', dataIndex: 'dump', key: 'shortCode5Name', showOnResponse: true, showOnDesktop: true }] : [],
        ...(examMarkInputStudentList?.shortCodeVisibilty?.shortCode6Name !== '') ? [{ title: examMarkInputStudentList?.shortCodeVisibilty?.shortCode6Name + '(SC6)', dataIndex: 'dump', key: 'shortCode6Name', showOnResponse: true, showOnDesktop: true }] : [],

    ];



    const downloadPdfBlankSheet = () => {
        if (classConfigId == '' || examConfigId == '') {
            notification.error({ message: "Please select section and exam" })
        } else {
            let postData: any = {
                classConfigId: classConfigId,
                examConfigId: examConfigId
            }
            fetchMarkBlankSheetPdf(postData);
        }
    };

    const [groupId, setGroupId] = useState<any>(null);

    const [fileList, setFileList] = useState<any>([]);

    const [tableData, setTableData] = useState<any>([])

    const [noError,setnoError]= useState<boolean>(true)

    const onChangeFile = ({ fileList: newFileList }) => {
        newFileList[0].status = "done"
        xlsxParser
            .onFileSelection(newFileList[0]?.originFileObj, { showNullProperties: true })
            .then(data => {

                if (data?.['Sheet1'] === undefined) {
                    notification.error({ message: "Wrong Excel File" });
                    return;
                }

                if (data?.['Sheet1']?.length === 0) {
                    notification.error({ message: "Emplty Excel file" });
                    return;
                }
                let val = data?.['Sheet1']?.[0];

                let shortCode1 = Object.keys(val).find(function (q) { return /SC1/gi.test(q) });
                let shortCode2 = Object.keys(val).find(function (q) { return /SC2/gi.test(q) });
                let shortCode3 = Object.keys(val).find(function (q) { return /SC3/gi.test(q) });
                let shortCode4 = Object.keys(val).find(function (q) { return /SC4/gi.test(q) });
                let shortCode5 = Object.keys(val).find(function (q) { return /SC5/gi.test(q) });
                let shortCode6 = Object.keys(val).find(function (q) { return /SC6/gi.test(q) });


                let temp = data?.['Sheet1']?.map(function (item, index) {
                    return {
                        studentName: item['Student Name'],
                        customStudentId: item['Student ID'],
                        studentRoll: item["Roll"],
                        //identificationId: examMarkInputStudentList?.studentList[index]?.identificationId,
                        shortCode1: item[`${shortCode1}`],
                        shortCode2: item[`${shortCode2}`],
                        shortCode3: item[`${shortCode3}`],
                        shortCode4: item[`${shortCode4}`],
                        shortCode5: item[`${shortCode5}`],
                        shortCode6: item[`${shortCode6}`],
                    }
                })

                temp.forEach(item => {
                    for (val in item) item[val] = (item[val] === null || item[val] === undefined) ? 0 : item[val]
                });

                for (let i in examMarkInputStudentList?.studentList) {
                    for (let j in temp) {
                          if (examMarkInputStudentList?.studentList[i].customStudentId===temp[j].customStudentId){
                            temp[j].identificationId=examMarkInputStudentList?.studentList[i].identificationId
                      }
                    }
                  };
                setTableData(temp);
                setTimeout(() => {
                    var  invalidmark= document.getElementsByClassName('invalidmark');
                    if (invalidmark?.length>0){
                        setnoError(false)
                    } else {
                        setnoError(true)
                    }
                }, 1500);


            });
    };


    const finalCol = [
      {
        title: "Student Name",
        dataIndex: "studentName",
        key: "studentName",
        showOnResponse: true,
        showOnDesktop: true,
        className: "none",
      },
      {
        title: "Student ID",
        dataIndex: "customStudentId",
        key: "customStudentId",
        showOnResponse: true,
        showOnDesktop: true,
      },
      {
        title: "Roll",
        dataIndex: "studentRoll",
        key: "studentRoll",
        showOnResponse: true,
        showOnDesktop: true,
      },
      ...(examMarkInputStudentList?.shortCodeVisibilty?.shortCode1Name !== ""
        ? [
            {
              title: examMarkInputStudentList?.shortCodeVisibilty?.shortCode1Name,
              dataIndex: "shortCode1",
              key: "shortCode1",
              showOnResponse: true,
              showOnDesktop: true,
              render: (text: any, record: any, index) => (
                <>
                <span>{record?.shortCode1}</span>
                {record?.shortCode1>(examMarkConfigList.find(item=>item?.shortCodeName==examMarkInputStudentList?.shortCodeVisibilty?.shortCode1Name)?.totalMark)?
                <p className='invalidmark'>Invalid Mark</p>:''}
                </>
            ),
            },
          ]
        : []),
      ...(examMarkInputStudentList?.shortCodeVisibilty?.shortCode2Name !== ""
        ? [
            {
              title: examMarkInputStudentList?.shortCodeVisibilty?.shortCode2Name,
              dataIndex: "shortCode2",
              key: "shortCode2",
              showOnResponse: true,
              showOnDesktop: true,
              render: (text: any, record: any, index) => (
                <>
                <span>{record?.shortCode2}</span>
                {record?.shortCode2>(examMarkConfigList.find(item=>item?.shortCodeName==examMarkInputStudentList?.shortCodeVisibilty?.shortCode2Name)?.totalMark)?
                <p className='invalidmark'>Invalid Mark</p>:''}
                </>
            ),
            },
          ]
        : []),
      ...(examMarkInputStudentList?.shortCodeVisibilty?.shortCode3Name !== ""
        ? [
            {
              title: examMarkInputStudentList?.shortCodeVisibilty?.shortCode3Name,
              dataIndex: "shortCode3",
              key: "shortCode3",
              showOnResponse: true,
              showOnDesktop: true,
              render: (text: any, record: any, index) => (
                <>
                <span>{record?.shortCode3}</span>
                {record?.shortCode3>(examMarkConfigList.find(item=>item?.shortCodeName==examMarkInputStudentList?.shortCodeVisibilty?.shortCode3Name)?.totalMark)?
                <p className='invalidmark'>Invalid Mark</p>:''}
                </>
            ),
            },
          ]
        : []),
      ...(examMarkInputStudentList?.shortCodeVisibilty?.shortCode4Name !== ""
        ? [
            {
              title: examMarkInputStudentList?.shortCodeVisibilty?.shortCode4Name,
              dataIndex: "shortCode4",
              key: "shortCode4",
              showOnResponse: true,
              showOnDesktop: true,
              render: (text: any, record: any, index) => (
                <>
                <span>{record?.shortCode4}</span>
                {record?.shortCode4>(examMarkConfigList.find(item=>item?.shortCodeName==examMarkInputStudentList?.shortCodeVisibilty?.shortCode4Name)?.totalMark)?
                <p className='invalidmark'>Invalid Mark</p>:''}
                </>
            ),
            },
          ]
        : []),
      ...(examMarkInputStudentList?.shortCodeVisibilty?.shortCode5Name !== ""
        ? [
            {
              title: examMarkInputStudentList?.shortCodeVisibilty?.shortCode5Name,
              dataIndex: "shortCode5",
              key: "shortCode5",
              showOnResponse: true,
              showOnDesktop: true,
              render: (text: any, record: any, index) => (
                <>
                <span>{record?.shortCode5}</span>
                {record?.shortCode5>(examMarkConfigList.find(item=>item?.shortCodeName==examMarkInputStudentList?.shortCodeVisibilty?.shortCode5Name)?.totalMark)?
                <p className='invalidmark'>Invalid Mark</p>:''}
                </>
            ),
            },
          ]
        : []),
      ...(examMarkInputStudentList?.shortCodeVisibilty?.shortCode6Name !== ""
        ? [
            {
              title: examMarkInputStudentList?.shortCodeVisibilty?.shortCode6Name,
              dataIndex: "shortCode6",
              key: "shortCode6",
              showOnResponse: true,
              showOnDesktop: true,
              render: (text: any, record: any, index) => (
                <>
                <span>{record?.shortCode6}</span>
                {record?.shortCode6>(examMarkConfigList.find(item=>item?.shortCodeName==examMarkInputStudentList?.shortCodeVisibilty?.shortCode6Name)?.totalMark)?
                <p className='invalidmark'>Invalid Mark</p>:''}
                </>
            ),
            },
          ]
        : []),

    ];

    const saveMarkInputValue = (e) => {
        let postdata = {
            examConfigurationId: markSaveObject.examConfigId,
            groupId: markSaveObject.groupId,
            studentMarkInfos: tableData,
            subjectId: markSaveObject.subjectId,
            type: "insert",
            academicYear: markSaveObject.academicYear,
        }
        // console.log(postdata);
        examMarkInputSave(postdata);
        setMarkInputTableData([]);
        setExamMarkConfigList([]);
        setTableData([]);
        setnoError(true)
    }

    return (
        <>
            <>
                <Form
                    layout="vertical"
                    id="sessionInfo"
                    onFinish={searchMarkInputSearch}
                    form={markInputForm}
                >
                    <Row>


                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item
                                name="academicYear"
                                label="academicYear"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select academic Year" },
                                ]}
                            >
                                <SelectAcademicYear />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item
                                name="sectionList"
                                label="Section List"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select section" },
                                ]}
                            >
                                <SelectClassConfigTeacher onChange={(e) => { markInputForm.setFieldsValue({ semesterYear: null, selectSubject: null }); setGroupId(null); onChangeClassList(e) }} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item
                                name="semesterYear"
                                label="Exam List"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select semester" },
                                ]}
                            >
                                <Select className='exmSelect' placeholder="Semester list" style={{ width: "100%" }} onChange={(e) => setExamConfigId(e)}>
                                    {semesterListByClassConfigIdArray ? (
                                        semesterListByClassConfigIdArray.map((type, idx) => (
                                            <Option key={type.examConfigId} value={type.examConfigId}>
                                                {type.examName}
                                            </Option>
                                        ))
                                    ) : (
                                        <Option value="fetching">Fetching semester</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item
                                name="classGroup"
                                className="title-Text"
                                label="Group"
                                rules={[
                                    { required: true, message: "Select group list" },
                                ]}
                            >
                                <SelectGroupbySection selected={groupId} onChange={(e) => { setGroupId(e?.groupId); searchSubjectByGroupId(e) }} />

                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item
                                name="selectSubject"
                                label="Subject List"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select subject list" },
                                ]}
                            >
                                <Select className='subSelect' placeholder="Semester list" style={{ width: "100%" }} onChange={(e) => getMarkConfigListBySubject(e)}>
                                    {subjectListTeacher ? (
                                        subjectListTeacher.map((type, idx) => (
                                            <Option key={type.subjectId} value={type.subjectId}>
                                                {type.subjectName}
                                            </Option>
                                        ))
                                    ) : (
                                        <Option value="fetching">Fetching Subject</Option>
                                    )}
                                </Select>
                            </Form.Item>
                            <div className="pull-right">
                                {/* <Button type="primary" className="mt-0" style={{ marginRight: "15px" }} onClick={() => downloadPdfBlankSheet()} icon={<DownCircleOutlined />} >
                                    Download Blank Sheet
                                </Button> */}
                                <Button type="primary" id='markInputSubmit' className=" mt-0" htmlType="submit" icon={<SearchOutlined />} >
                                    Search
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
                <br />
                <Skeleton loading={loading1} paragraph={{ rows: 10 }} />
                <Row style={{ display: examMarkConfigList == null || examMarkConfigList == "" ? 'none' : 'block' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: configTableColumn,
                                rowKey: "defaultId",
                                dataSource: examMarkConfigList,
                                filterData: examMarkConfigList,
                                pagination: false,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />
                    </Col>
                    <br />
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} style={{ display: examMarkConfigList !== null && markInputTableData?.length > 0 ? 'block' : 'none' }}>
                        < Button
                            type='primary'
                            style={{
                                marginBottom: 20,
                                marginRight: 20,
                            }}
                            icon={<FileExcelOutlined />}
                            onClick={() => {
                                const excel: any = new Excel();
                                excel
                                    .addSheet(`Sheet1`)
                                    .addColumns(markInputTableColumn)
                                    .addDataSource(markInputTableData)
                                    .saveAs(`${$(".sectionSelect").text()}_${$(".exmSelect").text()}_${$(".classSelectBySection").text()}_${$(".subSelect").text()}.xlsx`);
                            }}
                        >
                            Download Excel
                        </ Button >
                        <Upload
                            listType="text"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            fileList={fileList}
                            onChange={onChangeFile}
                            style={{ maxWidth: 220 }}
                        >
                            {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload Excel File</Button></>}
                        </Upload>
                    </Col>

                </Row>

                <Skeleton loading={loading} paragraph={{ rows: 10 }} />

                <Row style={{ display: examMarkConfigList !== null && tableData?.length > 0 ? 'block' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                      {!noError&&<p style={{color:"red", fontWeight:"bold"}}>
                        There are some invalid marks, please fix your excel file and upload again.
                        </p>}
                       <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: finalCol,
                                rowKey: "defaultId",
                                dataSource: tableData,
                                filterData: tableData,
                                pagination: false,
                                bordered: true,
                                style:{maxHeight:600}
                            }}
                            mobileBreakPoint={768}
                        />
                      
                    </Col>
                    <br />
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                        <Button type="primary" disabled={!noError?true:false} className="mt-0 pull-right" onClick={(e) => saveMarkInputValue(e)} icon={<SaveOutlined />} >Save</Button>
                    </Col>
                </Row>

            </>
        </>
    )
}