import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TableView from '../../../../contents/AntTableResponsive';
import { DeleteOutlined, EditOutlined, FilePdfOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectClass } from '../../../select/SelectClass';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
require('jspdf-autotable');

export default function MarkConfigPage(props) {
    const { Option } = Select;
    const [searchExamAndMarkForm] = Form.useForm();
    const [searchMarkConfigForm] = Form.useForm();
    const fetchGroupByStudentClassList = useStoreActions((state) => state.exam.fetchGroupByStudentClassList);
    const subjectGroupListByStudentClass = useStoreState((state) => state.exam.subjectGroupListByStudentClass);
    const fetchGroupListForMarkConfigSearch = useStoreActions((state) => state.exam.fetchGroupListForMarkConfigSearch);
    const subjectGroupListMarkConfigSearch = useStoreState((state) => state.exam.subjectGroupListMarkConfigSearch);
    const fetchSubjectListByClassAndGroupId = useStoreActions((state) => state.exam.fetchSubjectListByClassAndGroupId);
    const fetchExamListByClassAndClassId5 = useStoreActions((state) => state.exam.fetchExamListByClassAndClassId5);
    const examListByClassAndClassId5 = useStoreState((state) => state.exam.examListByClassAndClassId5);
    const fetchExamListForMarkConfigSearch = useStoreActions((state) => state.exam.fetchExamListForMarkConfigSearch);
    const examListForMarkConfigSearch = useStoreState((state) => state.exam.examListForMarkConfigSearch);
    const subjectListByClassAndGroupId = useStoreState((state) => state.exam.subjectListByClassAndGroupId);
    const fetchExamShortcodeListByClassId = useStoreActions((state) => state.exam.fetchExamShortcodeListByClassId);
    const examConfigurationListByClassId = useStoreState((state) => state.exam.examConfigurationListByClassId);
    const markConfigureSave = useStoreActions((state) => state.exam.markConfigureSave);
    const fetchMarkConfigureList = useStoreActions((state) => state.exam.fetchMarkConfigureList);
    const deleteexamSubjectConfigurationId = useStoreActions((state) => state.exam.deleteexamSubjectConfigurationId);
    const markConfigureList = useStoreState((state) => state.exam.markConfigureList);
    const [markTableRow, setMarkTableRow] = useState<any>([]);
    const [groupListForMarkConfig, setGroupListForMarkConfig] = useState([]);
    const [examListForMarkConfig, setExamListForMarkConfig] = useState([]);
    const [chooseSubjectValue, setChooseSubjectValue] = useState<any>([]);
    const [chooseExamValue, setChooseExamValue] = useState<any>([]);
    const loading = useStoreState((state) => state.exam.loading);


    const onChangeClassList = (val) => {
        fetchGroupByStudentClassList(val);
    }

    useEffect(() => {
        setMarkTableRow(examConfigurationListByClassId);
    }, [examConfigurationListByClassId])

    const searchExamAndMark = (value) => {
        let postData: any = {
            classId: value.classList,
            groupId: value.classGroup,
        }
        fetchSubjectListByClassAndGroupId(postData);
        fetchExamListByClassAndClassId5(value.classList);
        fetchExamShortcodeListByClassId(value.classList);
    }
    const chooseSubject = (e) => {
        if (e.target.checked) {
            let val = e.target.value;
            chooseSubjectValue.push(val);
        } else {
            let val = e.target.value;
            const index = chooseSubjectValue.indexOf(val)
            chooseSubjectValue.splice(index, 1);
        }
    }

    const chooseExam = (e) => {

        if (e.target.checked) {
            let val = e.target.value;
            chooseExamValue.push(val);
        } else {
            let val = e.target.value;
            const index = chooseExamValue.indexOf(val)
            chooseExamValue.splice(index, 1);
        }

    }


    const totalMarkChange = (text, record, val, index) => {
        markTableRow.map((item: any, index) => {
            if (item.defaultId == record.defaultId) {
                item.totalMark = val;
            }
        });
    }

    const passMarkChange = (text, record, val, index) => {
        markTableRow.map((item: any, index) => {
            if (item.defaultId == record.defaultId) {
                item.passMark = val;
            }
        });
    }

    const acceptanceChange = (text, record, val, index) => {
        markTableRow.map((item: any, index) => {
            if (item.defaultId == record.defaultId) {
                item.acceptance = val;
            }
        });
    }

    const deleteShortCode = (e) => {
        let temp = markTableRow.filter((item: any) => item.defaultId !== e);
        setMarkTableRow(temp);
        notification.success({ message: "Deleted Successfully" });
    }

    const examMarkColumn = [
        {
            title: 'Short Code Title',
            name: 'shortCodeTitle',
            dataIndex: 'shortCodeTitle',
            key: 'defaultId',
            showOnResponse: true,
            showOnDesktop: true
        }, {
            title: 'Total Mark',
            name: 'totalMark',
            dataIndex: 'totalMark',
            key: 'defaultId',
            render: (text, record, index) => (
                <InputNumber placeholder="Total Mark" defaultValue={record.totalMark} name="totalMark" className="totalMark" onChange={(e) => totalMarkChange(text, record, e, index)} />
            ),
            showOnResponse: true,
            showOnDesktop: true
        }, {
            title: 'Pass Mark',
            name: 'passMark',
            dataIndex: 'passMark',
            key: 'defaultId',
            render: (text, record, index) => (
                <InputNumber placeholder="Pass Mark" defaultValue={record.passMark} name="passMark" className="passMark" onChange={(e) => passMarkChange(text, record, e, index)} />
            ),
            showOnResponse: true,
            showOnDesktop: true
        }, {
            title: 'Acceptance',
            name: 'acceptance',
            dataIndex: 'acceptance',
            key: 'defaultId',
            render: (text, record, index) => (
                <InputNumber placeholder="Acceptance" defaultValue={record.acceptance} name="acceptance" className="acceptance" onChange={(e) => acceptanceChange(text, record, e, index)} />
            ),
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Delete',
            name: 'delete',
            dataIndex: 'delete',
            key: 'defaultId',
            render: (text, record, index) => (
                <Tooltip title="Delete">
                    <Button danger icon={<DeleteOutlined />} onClick={() => deleteShortCode(record?.defaultId)} />
                </Tooltip>
            ),
            showOnResponse: true,
            showOnDesktop: true
        }
    ];
    const saveMarkConfigue = (e) => {
        if (chooseExamValue.length == 0) {
            notification.error({ message: "Select exam first" });
        } else if (markTableRow.length == 0) {
            notification.error({ message: "Shortcode details does't left empty" });
        } else if (chooseSubjectValue.length == 0) {
            notification.error({ message: "Select subject first" });
        } else {
            let postData: any = []
            markTableRow.map(item => {
                let data: any = {
                    acceptance: item.acceptance,
                    passMark: item.passMark,
                    shortCodeId: item.shortCodeId,
                    totalMark: item.totalMark
                }
                postData.push(data);
            });
            var postdata: any = {
                examConfigurationIds: chooseExamValue,
                shortCodeDetails: postData,
                subjectIds: chooseSubjectValue
            }
            markConfigureSave(postdata);

            chooseSubjectValue.length = 0;
            chooseExamValue.length = 0;


        }
    }
    const [serData, setSerData] = useState<any>(null);
    const searchMarkConfigList = (e) => {
        let postData: any = {
            examConfigurationId: e.examGroup,
            groupId: e.classGroup
        };
        setSerData(postData);
        fetchMarkConfigureList(postData);
    }



    const onChangeClassListForMarkConfig = (val) => {
        fetchGroupListForMarkConfigSearch(val);
        fetchExamListForMarkConfigSearch(val)
        setExamListForMarkConfig(examListForMarkConfigSearch);
        setGroupListForMarkConfig(subjectGroupListMarkConfigSearch);
    }

    useEffect(() => {
        setGroupListForMarkConfig(subjectGroupListMarkConfigSearch);
        setExamListForMarkConfig(examListForMarkConfigSearch);
    }, [examListForMarkConfigSearch, subjectGroupListMarkConfigSearch])




    return (
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                    <Card className="p-0" title="Mark Configuration" bordered={false}>
                        <Row>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <Form
                                    layout="vertical"
                                    id="classConfigInfo"
                                    onFinish={searchExamAndMark}
                                    form={searchExamAndMarkForm}
                                >
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                            <Card className="p-0" bordered={false}>
                                                <Row>
                                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                                        <Form.Item
                                                            name="classList"
                                                            label="Class Name"
                                                            className="title-Text"
                                                            rules={[
                                                                { required: true, message: "Select class list" },
                                                            ]}
                                                        >
                                                            <SelectClass onChange={(e) => onChangeClassList(e)} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                                        <Form.Item
                                                            name="classGroup"
                                                            label="Group"
                                                            className="title-Text"
                                                            rules={[
                                                                { required: true, message: "Select group list" },
                                                            ]}
                                                        >
                                                            <Select placeholder="Group List" style={{ width: "100%" }}>
                                                                {subjectGroupListByStudentClass ? (
                                                                    subjectGroupListByStudentClass.map((type, idx) => (
                                                                        <Option key={type.groupId} value={type.groupId}>
                                                                            {type.groupName}
                                                                        </Option>
                                                                    ))
                                                                ) : (
                                                                    <Option value="fetching">Fetching Group</Option>
                                                                )}
                                                            </Select>

                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} className="float-right mt-0" style={{ float: "right" }}>Search</Button>
                                                    </Col>
                                                </Row>
                                                <br />
                                                <Row>
                                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                                                        <Card title="Subjects" className='box-shadow-none' bordered={false} style={{ display: subjectListByClassAndGroupId.length > 0 ? "block" : "none" }}>
                                                            <Row>
                                                                {subjectListByClassAndGroupId ? (
                                                                    subjectListByClassAndGroupId.map((type, idx) => (
                                                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 12 }}>
                                                                            <Checkbox value={type.subjectId} onChange={(e) => chooseSubject(e)}>{type.subjectName}</Checkbox>
                                                                        </Col>
                                                                    ))
                                                                ) : ''}
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <br />
                                                <Row>
                                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                                                        <Card title="Exams" className='box-shadow-none' bordered={false} style={{ display: subjectListByClassAndGroupId.length > 0 ? "block" : "none" }}>
                                                            <Row>
                                                                {examListByClassAndClassId5 ? (
                                                                    examListByClassAndClassId5.map((type, idx) => (
                                                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 12 }}>
                                                                            <Checkbox value={type.examConfigId} onChange={(e) => chooseExam(e)}>{type.examName}</Checkbox>
                                                                        </Col>
                                                                    ))
                                                                ) : ''}
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <br />
                                                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                                                <Row>
                                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                                                        <Card className="p-0 box-shadow-none" title="Exam Mark" bordered={false} style={{ display: subjectListByClassAndGroupId.length > 0 ? "block" : "none" }}>
                                                            <TableView
                                                                antTableProps={{
                                                                    showHeader: true,
                                                                    columns: examMarkColumn,
                                                                    rowKey: "id",
                                                                    dataSource: markTableRow,
                                                                    filterData: markTableRow,
                                                                    pagination: true,
                                                                    bordered: true
                                                                }}
                                                                mobileBreakPoint={768}
                                                            />
                                                        </Card>
                                                    </Col>
                                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} style={{ display: subjectListByClassAndGroupId.length > 0 ? "block" : "none" }}>
                                                        <Button type="primary" icon={<SaveOutlined />} className="float-right" style={{ float: "right" }} onClick={(e) => saveMarkConfigue(e)} >Save</Button>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>

                                </Form>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <Card className="p-0" bordered={false}>
                                    <Form
                                        layout="vertical"
                                        id="markConfigListTable"
                                        onFinish={searchMarkConfigList}
                                        form={searchMarkConfigForm}
                                    >
                                        <Row>
                                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                                <Row>
                                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                                        <Form.Item
                                                            name="classList"
                                                            label="Class Name"
                                                            className="title-Text"
                                                            rules={[
                                                                { required: true, message: "Select class list" },
                                                            ]}
                                                        >
                                                            <SelectClass onChange={(e) => { onChangeClassListForMarkConfig(e); searchMarkConfigForm.setFieldsValue({ classGroup: null, examGroup: null }) }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                                        <Form.Item
                                                            name="classGroup"
                                                            label="Group"
                                                            className="title-Text"
                                                            rules={[
                                                                { required: true, message: "Select group list" },
                                                            ]}
                                                        >
                                                            <Select placeholder="Group List" style={{ width: "100%" }} >
                                                                {groupListForMarkConfig ? (
                                                                    groupListForMarkConfig.map((type: any, idx) => (
                                                                        <Option key={type.groupId} value={type.groupId}>
                                                                            {type.groupName}
                                                                        </Option>
                                                                    ))
                                                                ) : (
                                                                    <Option value="fetching">Fetching Group</Option>
                                                                )}
                                                            </Select>

                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                                        <Form.Item
                                                            name="examGroup"
                                                            label="Exam"
                                                            className="title-Text"
                                                            rules={[
                                                                { required: true, message: "Select Exam" },
                                                            ]}
                                                        >
                                                            <Select placeholder="Group Exam" style={{ width: "100%" }}>
                                                                {examListForMarkConfig ? (
                                                                    examListForMarkConfig.map((type: any, idx) => (
                                                                        <Option key={type.id} value={type.examConfigId}>
                                                                            {type.examName}
                                                                        </Option>
                                                                    ))
                                                                ) : (
                                                                    <Option value="fetching">Fetching Exam</Option>
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} className="float-right mt-0" style={{ float: "right" }}>Search</Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <br />
                                    </Form>
                                    <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                            <div className="table-responsive">
                                                {markConfigureList.length > 0 ?
                                                    <div>
                                                        <table id="tbl2" className="table table-bordered custom-table" style={{ marginBottom: 0 }}>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: '100px' }}>Action</th>
                                                                    <th style={{ width: '171px' }}>Subject Name</th>
                                                                    <th>Short Code </th>
                                                                    <th>Total Mark</th>
                                                                    <th>Pass Mark</th>
                                                                    <th>Acceptance</th>
                                                                </tr>
                                                            </thead>
                                                            {markConfigureList.map((item, index) => {
                                                                return (

                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ verticalAlign: "middle" }} rowSpan={10} >
                                                                                <Popconfirm
                                                                                    title="Are you sure to delete this?"
                                                                                    okText="Yes"
                                                                                    cancelText="No"
                                                                                    onConfirm={() => deleteexamSubjectConfigurationId({ id: item?.examSubjectConfigId, data: serData })}
                                                                                >
                                                                                    <Tooltip title="Delete">
                                                                                        <Button danger icon={<DeleteOutlined />} />
                                                                                    </Tooltip>
                                                                                </Popconfirm>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ verticalAlign: "middle" }} rowSpan={10} >{item.subjectName}</td>
                                                                        </tr>
                                                                        {item.shortCodeDetails ?
                                                                            item.shortCodeDetails.map(item => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{item?.shortCodeName}</td>
                                                                                        <td>{item?.totalMark}</td>
                                                                                        <td>{item?.passMark}</td>
                                                                                        <td>{item?.acceptance}</td>
                                                                                    </tr>
                                                                                )
                                                                            })

                                                                            : ""}

                                                                    </tbody>

                                                                )
                                                            })}
                                                        </table>
                                                    </div>
                                                    : ''}
                                            </div>
                                        </Col>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} style={{ display: markConfigureList.length > 0 ? 'block' : 'none' }}>
                                            <br />
                                            {/* <Button type="primary" htmlType="submit" icon={<FilePdfOutlined />} className="float-right mt-0" style={{ float:"right" }}>PDF Download</Button> */}
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    )
}