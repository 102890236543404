import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, DatePicker } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { SelectAcademicYear4 } from '../../../select/SelectAcademicYear4';
import { SelectClass } from '../../../select/SelectClass';
import moment from 'moment';
import { SelectGroupByClass } from '../../../select/SelectGroupByClass';
import { SelectSubjectByClassNGroup } from '../../../select/SelectSubjectByClassNGroup';

const { Option } = Select;

export default function ExamRoutineCreate() {

    const [form] = Form.useForm();
    const saveClassRoutine = useStoreActions((state) => state.exam.saveClassRoutine);

    const fetchExamListByClassAndClassId2 = useStoreActions((state) => state.exam.fetchExamListByClassAndClassId2);
    const fetchSubjectConfigurationList = useStoreActions((state) => state.exam.fetchSubjectConfigurationList);
    const fetchGroupList = useStoreActions((state) => state.exam.fetchGroupList);
    const examListByClassAndClassId2 = useStoreState((state) => state.exam.examListByClassAndClassId2);




    const submitForm = (value) => {
        let postData = {
            "academicYear": value.academicYear,
            "classId": value.classId,
            "examId": value.examId,
            "groupIds": [value.groupId],
            "examSession": value.examSession,
            "examDate": moment(value?.examDate).format("YYYY-MM-DD"),
            "examTime": value.examTime,
            "roomNo": value.roomNo,
            "subjectId": value.subjectId,
        };
        saveClassRoutine(postData);
        // form.resetFields();
    };

    const onClassChange = (value) => {
        fetchExamListByClassAndClassId2(value);
        fetchGroupList(value);
    }
    const onGroupChange = (value) => {
        let payload: any = { classId: form.getFieldValue("classId"), groupId: value }
        fetchSubjectConfigurationList(payload);
    }


    return (
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 14, offset: 5 }} xl={{ span: 14, offset: 5 }}>
                    <Form
                        layout="vertical"
                        id="instructionForm"
                        onFinish={submitForm}
                        form={form}
                    >
                        <Row>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="academicYear"
                                    label="Academic Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please enter academic year" },
                                    ]}
                                >
                                    <SelectAcademicYear4 />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="classId"
                                    label="Select Class"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select class" },
                                    ]}
                                >
                                    <SelectClass onChange={(e) => onClassChange(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="groupId"
                                    label="Group"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select group" },
                                    ]}
                                >
                                    <SelectGroupByClass onChange={(e) => onGroupChange(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="examId"
                                    label="Select Exam"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select exam" },
                                    ]}
                                >
                                    <Select placeholder="Exam List" style={{ width: "100%" }}>
                                        {examListByClassAndClassId2 ? (
                                            examListByClassAndClassId2.map((type, idx) => (
                                                <Option key={type.examId} value={type.examId}>
                                                    {type.examName}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching exam</Option>
                                        )}
                                    </Select>

                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="subjectId"
                                    label="Subject"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select subject" },
                                    ]}
                                >
                                    <SelectSubjectByClassNGroup />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="examSession"
                                    label="Exam Session"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select session" },
                                    ]}
                                >
                                    <Select placeholder="Exam Seesion" style={{ width: "100%" }}>
                                        <Option value={"1st Session"}>1st Session</Option>
                                        <Option value={"2nd Session"}>2nd Session</Option>
                                        <Option value={"3rd Session"}>3rd Session</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="examDate"
                                    label="Exam Date"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select date" },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="Exam Date" format={"DD/MM/YYYY"} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="examTime"
                                    label="Exam Time"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please input exam time" },
                                    ]}
                                >
                                    <Input placeholder="Exam Time" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="roomNo"
                                    label="Room No"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please input room no" },
                                    ]}
                                >
                                    <Input placeholder="Room No" />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    )
}