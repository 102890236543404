import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import SessionYear from '../Students/settings/SessionYear.page';
import { SelectAcademicYear } from '../../select/SelectAcademicYear';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { SelectLedgerOFPS } from '../../select/SelectLedgerOFPS';
import { SelectLedgerIncome } from '../../select/SelectLedgerIncome';

const { TextArea } = Input;

const { Option } = Select;

export default function BkashOFPSConfigSave() {

    const [instituteInfoUpdate] = Form.useForm();
    const fetchbkasOfpsTransactionConfigurationView = useStoreActions((state) => state.customerSupport.fetchbkasOfpsTransactionConfigurationView);
    const bkasOfpsTransactionConfigurationView = useStoreState((state) => state.customerSupport.bkasOfpsTransactionConfigurationView);
    const [academicyearData, setAcademicyearData] = useState<any>();
    const saveOfpsTransactionConfiguration = useStoreActions((state) => state.customerSupport.saveOfpsTransactionConfiguration);


    useEffect(() => {
        fetchbkasOfpsTransactionConfigurationView();
    }, []);

    const [debitLedgerId, setdebitLedgerId] = useState<any>(null);
    const [creditLedgerId, setcreditLedgerId] = useState<any>(null);

    useEffect(() => {
        if (bkasOfpsTransactionConfigurationView!==null){
            instituteInfoUpdate.setFieldsValue({
                instituteId: bkasOfpsTransactionConfigurationView?.instituteId,
                merchantNumber: bkasOfpsTransactionConfigurationView?.merchantNumber,
                bkashUsername: bkasOfpsTransactionConfigurationView?.bkashUsername,
                bkashPassword: bkasOfpsTransactionConfigurationView?.bkashPassword,
                appKey: bkasOfpsTransactionConfigurationView?.appKey,
                appSecret: bkasOfpsTransactionConfigurationView?.appSecret,
                debitLedgerId: bkasOfpsTransactionConfigurationView?.debitLedgerId,
                creditLedgerId: bkasOfpsTransactionConfigurationView?.creditLedgerId,
                // eiinNo: bkasOfpsTransactionConfigurationView.eiinNo,
                // onlinePaymentStatus: bkasOfpsTransactionConfigurationView.onlinePaymentStatus,
                // feeSubheadCheckStatus: bkasOfpsTransactionConfigurationView.feeSubheadCheckStatus,
            });
            setdebitLedgerId(bkasOfpsTransactionConfigurationView?.debitLedgerId);
            setcreditLedgerId(bkasOfpsTransactionConfigurationView?.creditLedgerId);
        }

    }, [bkasOfpsTransactionConfigurationView])

    const instituteUpdate = (value) => {
        let postData: any = {
            "appKey": value?.appKey,
            "appSecret": value?.appSecret,
            "bkashPassword": value?.bkashPassword,
            "bkashUsername": value?.bkashUsername,
            "creditLedgerLedgerId": creditLedgerId,
            "debitLedgerId": debitLedgerId,
            "instituteId": value?.instituteId,
            "merchantNumber": value?.merchantNumber,
            "serviceCharge": 0,
            "step": 1
        }
        saveOfpsTransactionConfiguration(postData);
    }



    return (
        <>
            <Card title="Bkash OFP Configuration Save">
                <Form
                    layout="vertical"
                    id="instructionForm"
                    onFinish={instituteUpdate}
                    form={instituteInfoUpdate}
                >
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="instituteId"
                                label="Institute Id"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter institute id" },
                                ]}
                            >
                                <Input placeholder="Institute Id" defaultValue={bkasOfpsTransactionConfigurationView?.instituteId} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="merchantNumber"
                                label="Merchant Number"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter merchant number" },
                                ]}
                            >
                                <Input placeholder="Merchant Number" defaultValue={bkasOfpsTransactionConfigurationView?.merchantNumber} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="bkashUsername"
                                label="Bkash Username"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter bkash username" },
                                ]}
                            >
                                <Input placeholder="Bkash Username" defaultValue={bkasOfpsTransactionConfigurationView?.bkashUsername} />
                            </Form.Item>
                        </Col>


                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="bkashPassword"
                                label="Bkash Password"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter bkash password" },
                                ]}
                            >
                                <Input placeholder="Bkash Password" defaultValue={bkasOfpsTransactionConfigurationView?.bkashPassword} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="appKey"
                                label="App Key"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter app key" },
                                ]}
                            >
                                <TextArea placeholder="App Key" defaultValue={bkasOfpsTransactionConfigurationView?.appKey} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="appSecret"
                                label="App Secret"
                                className="title-Text"
                                initialValue={bkasOfpsTransactionConfigurationView?.appSecret}
                                rules={[
                                    { required: true, message: "Please enter app secret" },
                                ]}
                            >
                                <TextArea placeholder="App Secret" defaultValue={bkasOfpsTransactionConfigurationView?.appSecret} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="debitLedgerId"
                                label="Debit Ledger"
                                className="title-Text"
                                initialValue={bkasOfpsTransactionConfigurationView?.debitLedgerId}
                                rules={[
                                    { required: true, message: "Please select debit ledger" },
                                ]}
                            >
                                <SelectLedgerOFPS style={{ width: "100%" }} selected={debitLedgerId} onChange={e => setdebitLedgerId(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="creditLedgerId"
                                label="Credit Ledger"
                                className="title-Text"
                                initialValue={bkasOfpsTransactionConfigurationView?.creditLedgerId}
                                rules={[
                                    { required: true, message: "Please select debit ledger" },
                                ]}
                            >
                                <SelectLedgerIncome style={{ width: "100%" }} selected={creditLedgerId} onChange={e => setcreditLedgerId(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 22 }} xl={{ span: 22 }}> </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined />} style={{ float: 'right', marginTop:-15 }} >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}