import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { defaultSignatureListUrl, deleteDefaultSignatureUrl, deleteMasterSettingUserUrl, fetchInstituteInfoUrl, masterSettingsUserList, saveDefaultSignatureUrl, saveMasterSettingUser, signatureImageUpdate, staffListUrl, updateInstituteInfoUrl, updateMasterSettingUserUrl, updatePhoto } from '../../../http/masterSettings/masterSettings';
import { smsSearchReportUrl, searchClassStudentIthAcademicYear, sectionDueListUrl, sendDueListSms, sendInstiuteSms, sendStaffSms, sendStudentSms, smsBlanceurl, smsTemplate, staffListForSendSms, staffSmsCount, templateSaveUrl, templateUpdateUrl } from '../../../http/sms/sms';
import { addTestimonialsUrl, addTransferCertificateUrl, searchStudentInfo, testimonialsDownloadUrl, testimonialsListUrl, testimonialsUpdateUrl, transferCertificateDownloadUrl, transferCertificateListUrl, transferCertificateUpdateUrl } from '../../../http/testimonials/testimonials';
import FileSaver from 'file-saver';

export interface Testimonials{
    studentInfo: any;
    setStudentInfo: Action<Testimonials, any>;
    fetchStudentInfo: Thunk<Testimonials, any>;
    saveTestimonials: Thunk<Testimonials, any>;

    /******/
    tetimonilasList: any;
    setTetimonilasList: Action<Testimonials, any>;
    fetchTetimonilasList: Thunk<Testimonials, any>;
    updateTestimonials: Thunk<Testimonials, any>;
    fetchTestimonialsPdf: Thunk<Testimonials>;
    /*****/

    studentInfoTransferCertificate: any;
    setStudentInfoTransferCertificate: Action<Testimonials, any>;
    fetchStudentInfoTransferCertificate: Thunk<Testimonials, any>;
    saveTestimonialsTransferCertificate: Thunk<Testimonials, any>;
    /******/
    transferCertificateList: any;
    setTransferCertificateList: Action<Testimonials, any>;
    fetchTransferCertificateList: Thunk<Testimonials, any>;
    updateTransferCertificate: Thunk<Testimonials, any>;
    fetchTransferCertificatePdf: Thunk<Testimonials>;
    loading: boolean;
    setLoading: Action<Testimonials, boolean>;

}

export const testimonialsStore: Testimonials ={
    studentInfo: null,
    tetimonilasList:[],
    studentInfoTransferCertificate:[],
    transferCertificateList:[],
    fetchStudentInfo: thunk(async (actions, payload) => {
        const response = await searchStudentInfo(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body?.messageType===1){
                actions.setStudentInfo(body?.item);
            } else {
                actions.setStudentInfo('');
                notification.warning({message:body?.message})
            }
                       
        } else {
        
        }
    }),

    loading: false,
    setLoading: action((state, payload) => {
        state.loading = payload;
	}),

    setStudentInfo: action((state, payload) => {
        state.studentInfo = payload;
    }),
    saveTestimonials: thunk(async (actions, payload) => {
        const response = await addTestimonialsUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    fetchTetimonilasList: thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await testimonialsListUrl(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setTetimonilasList(body?.item);            
        } else {
            actions.setLoading(false);
        }
    }),
    setTetimonilasList: action((state, payload) => {
        state.tetimonilasList = payload;
    }),
    updateTestimonials: thunk(async (actions, payload) => {
        const response = await testimonialsUpdateUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    fetchTestimonialsPdf:thunk(async (actions, payload) => {
        const response = await testimonialsDownloadUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.blob();
            var blob = new Blob([body], { type: "application/octet-stream" });
            var fileName = "TESTIMONIALS.pdf";
            FileSaver.saveAs(blob, fileName);           
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    ///////***Transfer Certificate****////////

    fetchStudentInfoTransferCertificate: thunk(async (actions, payload) => {
        const response = await searchStudentInfo(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body?.messageType===1){
                actions.setStudentInfoTransferCertificate(body?.item);
            } else {
                actions.setStudentInfoTransferCertificate('');
                notification.warning({message:body?.message})
            }          
        } else {

        }
    }),

    setStudentInfoTransferCertificate: action((state, payload) => {
        state.studentInfoTransferCertificate = payload;
    }),

    saveTestimonialsTransferCertificate: thunk(async (actions, payload) => {
        const response = await addTransferCertificateUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    fetchTransferCertificateList: thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await transferCertificateListUrl(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setTransferCertificateList(body?.item);            
        } else {
            actions.setLoading(false);
        }
    }),

    setTransferCertificateList: action((state, payload) => {
        state.transferCertificateList = payload;
    }),

    updateTransferCertificate: thunk(async (actions, payload) => {
        const response = await transferCertificateUpdateUrl(payload.postData);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchTransferCertificateList(payload.academicYear);
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    fetchTransferCertificatePdf:thunk(async (actions, payload) => {
        const response = await transferCertificateDownloadUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.blob();
            var blob = new Blob([body], { type: "application/octet-stream" });
            var fileName = "TRANSFERCERTIFICATE.pdf";
            FileSaver.saveAs(blob, fileName);           
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
}