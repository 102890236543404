import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TableView from '../../../../contents/AntTableResponsive';
import { DeleteOutlined, EditOutlined, FilePdfOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectClass } from '../../../select/SelectClass';
import { render } from '@testing-library/react';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';

export default function ExamRemarks() {

    const [remarkSearchForm] = Form.useForm();
    const { Option } = Select;
    const { TextArea } = Input;
    const [shortCodeSelections, setShortCodeSelections] = useState([]);
    const [selectedExamGradeValue, setselectedExamGradeValue] = useState<any>([]);

    const fetchRemarksTenplateList = useStoreActions((state) => state.exam.fetchRemarksTenplateList);
    const remarksTemplateListArray = useStoreState((state) => state.exam.remarksTemplateListArray);

    const fetchSemesterListByClassConfigIdRemarks = useStoreActions((state) => state.exam.fetchSemesterListByClassConfigIdRemarks);
    const remarksExamListArray = useStoreState((state) => state.exam.remarksExamListArray);

    const fetchStudentListByExamConfigIdAndClassConfigId = useStoreActions((state) => state.exam.fetchStudentListByExamConfigIdAndClassConfigId);
    const studentListByExamConfigIdAndClassConfigId = useStoreState((state) =>  state.exam.studentListByExamConfigIdAndClassConfigId);

    const saveRemarksState = useStoreActions((state) => state.exam.saveRemarks)

    const [remarksText, setRemarksText] = useState<any>("");
    const [remarksSearchData, setRemarksSearchData] = useState<any>("");
  

    useEffect(() => {
        fetchRemarksTenplateList();
    }, []);

    const onChangeRemarksList = (e) => {
        remarkSearchForm.setFieldsValue({
            "remarks":e  
        })
        setRemarksText(e);
    }

    const onChangeClassList = (e) => {
        fetchSemesterListByClassConfigIdRemarks(e)
    }

    const remarksSearchForm = (value) => {
        let payload:any = {
            examConfigId: value.examList,
            classConfigId: value.sectionList,
            academicYear: value.academicYear
        }
        
        fetchStudentListByExamConfigIdAndClassConfigId(payload);
        setRemarksSearchData(payload);
    }

    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'studentId', key: 'studentId', showOnResponse: true, showOnDesktop: true  },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true  },
        { title: 'Total Marks', dataIndex: 'totalMarks', key: 'totalMarks', showOnResponse: true, showOnDesktop: true  },
        { title: 'Section Position', dataIndex: 'sectionPosition', key: 'sectionPosition', showOnResponse: true, showOnDesktop: true  },
        { title: 'Grade', dataIndex: 'letterGrade', key: 'letterGrade', showOnResponse: true, showOnDesktop: true  },
        { title: 'CGPA', dataIndex: 'gradingPoint', key: 'gradingPoint', showOnResponse: true, showOnDesktop: true  },
        { title: 'Status', dataIndex: 'resultStatus', key: 'resultStatus', showOnResponse: true, showOnDesktop: true  },
        { title: 'Remark', dataIndex: 'remarks', key: 'remarks', showOnResponse: true, showOnDesktop: true  },
    ];

    const onSelectChangeStudent = (selectedRowKeys, value) => {
        setShortCodeSelections(selectedRowKeys);
        setselectedExamGradeValue(value);
    };


    const studentRowSelection = {
        shortCodeSelections,
        onChange: onSelectChangeStudent,
    };

    const onChangeRemarkText = (e) => {
        setRemarksText(e.traget.value)
    }

    const saveRemarks = (e) => {
        let payload:any = {
            saveData : {
                remarks: remarksText,
                resultIds: shortCodeSelections
            },
            searchData: remarksSearchData
        }
        console.log('remarksText', payload);
        saveRemarksState(payload)
    }


    return(
        <>
            <Card title="Remarks to Student">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                        <Form
                            layout="vertical"
                            id="sessionInfo"
                            onFinish={remarksSearchForm}
                            form={remarkSearchForm}
                        >
                            <Row>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12}} xl={{ span: 12}}>
                                    <Form.Item
                                        name="classGroup"
                                        className="title-Text"
                                        label="Remarks List"
                                        rules={[
                                            { required: true, message: "Select remarks list" },
                                        ]}
                                    >
                                        <Select placeholder="Semester list" style={{ width: "100%" }} onChange={(e) => onChangeRemarksList(e)}>
                                            {remarksTemplateListArray ? (
                                                    remarksTemplateListArray.map((type, idx) => (
                                                    <Option key={type.id} value={type.remarks}>
                                                        {type.remarksTitle}
                                                    </Option>
                                                    ))
                                                ) : (
                                                    <Option value="fetching">Fetching remarks list</Option>
                                                )}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12}} xl={{ span: 12}}>
                                    <Form.Item
                                        name="remarks"
                                        label="Remarks"
                                        className="title-Text"
                                    >
                                        <TextArea placeholder="Remarks" value={remarksText} onChange={(e) => onChangeRemarkText(e)} className="placeholderColorChange"/>
                                    </Form.Item>
                                </Col>
                
                            </Row>
                            
                            <Row>

                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12}} xl={{ span: 12}}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select Academic Year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>

                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12}} xl={{ span: 12}}>
                                    <Form.Item
                                        name="sectionList"
                                        label="Section List"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig onChange={(e) => onChangeClassList(e)} />
                                    </Form.Item>
                                </Col>
                                
                            </Row>
                            
                            <Row>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12}} xl={{ span: 12}}>
                                    <Form.Item
                                        name="examList"
                                        className="title-Text"
                                        label="Exam List"
                                        rules={[
                                            { required: true, message: "Select exam list" },
                                        ]}
                                    >
                                        <Select placeholder="Semester list" style={{ width: "100%" }} >
                                            {remarksExamListArray ? (
                                                    remarksExamListArray.map((type, idx) => (
                                                    <Option key={type.examId} value={type.examConfigId}>
                                                        {type.examName}
                                                    </Option>
                                                    ))
                                                ) : (
                                                    <Option value="fetching">Fetching Exam</Option>
                                                )}
                                        </Select>
                                    </Form.Item>
                                </Col>

                               
                            </Row>

                            <Row>

                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24}} xl={{ span: 24}}>
                                    <div className="pull-right">
                                        <Button type="primary" className=" mt-0" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </div>
                                </Col>

                            </Row>

                        </Form>
                    </Col>
                </Row>
                <br />
                {studentListByExamConfigIdAndClassConfigId.length > 0 ? 
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}} lg={{ span: 24}} xl={{ span: 24}}>
                            <TableView
                                antTableProps={{
                                    rowSelection:studentRowSelection,
                                    showHeader: true,
                                    columns:studentListColumn,  
                                    rowKey:"resultId",
                                    dataSource: studentListByExamConfigIdAndClassConfigId,
                                    filterData: studentListByExamConfigIdAndClassConfigId,
                                    pagination: false,
                                    bordered: true
                                }}
                                mobileBreakPoint={768}
                            />                       
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24}} xl={{ span: 24}}>
                            <div className="pull-right">
                                <Button type="primary" className=" mt-0" onClick={(e) => saveRemarks(e)} icon={<SaveOutlined />} >
                                    Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    
                : '' }
            </Card>
        </>
    )
    
}