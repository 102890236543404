
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import TableView from '../../../../contents/AntTableResponsive';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectFeeHead } from '../../../select/SelectFeeHead';
import { Excel } from 'antd-table-saveas-excel';
import $ from 'jquery';
import { SelectFeeSubHeadByFeeHead } from '../../../select/SelectFeeSubHeadByFeeHead';
import { SelectFeeSubHeadByFeeHeadSingle } from '../../../select/SelectFeeSubHeadByFeeHeadSingle';


export default function FeeAmounView() {

    const [reportForm] = Form.useForm();
    //const fetchshowStudentFeeHeadPaymentView = useStoreActions((state) => state.saccount.fetchshowStudentFeeHeadPaymentView);
    const fetchfeeAmountConfigurationListByFeeHeadSubHead = useStoreActions((state) => state.saccount.fetchfeeAmountConfigurationListByFeeHeadSubHead);
    const feeAmountConfigurationListByFeeHeadSubHead = useStoreState((state) => state.saccount.feeAmountConfigurationListByFeeHeadSubHead);
    const loading = useStoreState((state) => state.student.loading);
    const fetchfeeSubHeadConfigurationList = useStoreActions((state) => state.saccount.fetchfeeSubHeadConfigurationList);

    const formSubmit = (value) => {
        let postData: any = {
            feeHeadId: value.feeHeadId,
            feeSubHeadId: value.feeSubHeadId,
        }
        fetchfeeAmountConfigurationListByFeeHeadSubHead(postData);
    }



    const studentListColumn = [
        { title: 'Class', dataIndex: 'className', key: 'className', showOnResponse: true, showOnDesktop: true },
        { title: 'Group', dataIndex: 'groupName', key: 'groupName', showOnResponse: true, showOnDesktop: true },
        { title: 'Category', dataIndex: 'studentCategoryName', key: 'studentCategoryName', showOnResponse: true, showOnDesktop: true },
        { title: 'Fee Head', dataIndex: 'feeHeadName', key: 'feeHeadName', showOnResponse: true, showOnDesktop: true },
        { title: 'Fee Subhead', dataIndex: 'feeSubHeadName', key: 'feeSubHeadName', showOnResponse: true, showOnDesktop: true },
        { title: 'Fee Amount', dataIndex: 'feeAmount', key: 'feeAmount', showOnResponse: true, showOnDesktop: true },
        { title: 'Fine Amount', dataIndex: 'fineAmount', key: 'fineAmount', showOnResponse: true, showOnDesktop: true },
    ];


    const [feeHeadId, setFeeHeadId] = useState<any>(null);
    const [feeSubHeadId, setFeeSubHeadId] = useState<any>(null);
    return (
        <Card title="Fee Amount View">
            <>
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={reportForm}
                        >
                            <Row>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="feeHeadId"
                                        label="Select Feehead"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select feehead" },
                                        ]}
                                    >
                                        <SelectFeeHead onChange={e => { fetchfeeSubHeadConfigurationList(e); setFeeHeadId(e); reportForm.setFieldsValue({ feeSubHeadId: null }) }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="feeSubHeadId"
                                        label="Select Fee SubHead"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select Subhead" },
                                        ]}
                                    >
                                        <SelectFeeSubHeadByFeeHeadSingle onChange={e => { setFeeSubHeadId(e) }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: feeAmountConfigurationListByFeeHeadSubHead !== null ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: studentListColumn,
                                rowKey: "customStudentId",
                                dataSource: feeAmountConfigurationListByFeeHeadSubHead,
                                filterData: feeAmountConfigurationListByFeeHeadSubHead,
                                pagination: true,
                                bordered: true,
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                        {/* <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} onClick={() => deleteStudent()} className="float-right">Delete</Button> */}
                    </Col>
                    <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>
                            < Button
                                type='primary'
                                icon={<FileExcelOutlined />}
                                onClick={() => {
                                    const excel: any = new Excel();
                                    excel
                                        .addSheet(`Sheet1`)
                                        .addColumns(studentListColumn)
                                        .addDataSource(feeAmountConfigurationListByFeeHeadSubHead)
                                        .saveAs(`Fee Subhead View.xlsx`);
                                }}
                            >
                                Download Excel
                            </ Button >
                        </Space>
                    </Col>
                </Row>

            </>
        </Card>
    )
}
