import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Table, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { DeleteOutlined, DownCircleFilled, DownloadOutlined, EditOutlined, FileExcelOutlined, FilePdfOutlined, PrinterOutlined, SaveOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import $ from 'jquery';
// import { data } from './extra'
import ReactToPrint from 'react-to-print';
import { Excel } from 'antd-table-saveas-excel';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';

const { Column, ColumnGroup } = Table;


export default function TabulationSheetExcel(props) {



    const [createForm] = Form.useForm();
    const { Option } = Select;
    const loading = useStoreState((state) => state.exam.loading);
    const fetchSemesterListByClassConfigIdForGeneralTabulationSheet = useStoreActions((state) => state.exam.fetchSemesterListByClassConfigIdForGeneralTabulationSheet);
    const downloadtabulationExcel = useStoreActions((state) => state.exam.downloadtabulationExcel);
    const semesterListByClassConfigIdForGeneralTabulationSheet = useStoreState((state) => state.exam.semesterListByClassConfigIdForGeneralTabulationSheet);
    const tabulationData = useStoreState((state) => state.exam.tabulationData);
    //const downloadTabulationSheet = useStoreActions((state) => state.exam.downloadTabulationSheet)
    const onChangeClassList = (e) => {
        fetchSemesterListByClassConfigIdForGeneralTabulationSheet(e);
    }

    const tabulationSheetDownload1 = (value) => {
        let payload: any = {
            examConfigId: value.examConfig,
            classConfigId: value.section,
            typeNo: value.typeNo,
            academicYear: value.academicYear,

        }
        downloadtabulationExcel(payload)
    }


    return (
        <>
            <>
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 5 }} xl={{ span: 20, offset: 2 }}>
                        <Form
                            layout="vertical"
                            id="sessionInfo"
                            onFinish={tabulationSheetDownload1}
                            form={createForm}
                        >
                            <Row>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Select academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select Academic Year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="section"
                                        label="Select Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig onChange={(e) => {createForm.setFieldsValue({examConfig:null}); onChangeClassList(e)}} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="examConfig"
                                        label="Select Exam"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select exam" },
                                        ]}
                                    >
                                        <Select placeholder="Semester list" style={{ width: "100%" }} className="examSelect">
                                            {semesterListByClassConfigIdForGeneralTabulationSheet ? (
                                                semesterListByClassConfigIdForGeneralTabulationSheet.map((type, idx) => (
                                                    <Option key={type.examConfigId} value={type.examConfigId}>
                                                        {type.examName}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Subject</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="typeNo"
                                        label="Select Type"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select type" },
                                        ]}
                                    >
                                        <Select placeholder="Select Type">
                                            <Option key="1" value="1">Type-1(All)</Option>
                                            <Option key="2" value="2">Type-2(3-Sbj)</Option>
                                            <Option key="3" value="3">Type-3(4-Sbj)</Option>
                                            <Option key="4" value="4">Type-4(5-Sbj)</Option>
                                            <Option key="5" value="5">Type-5(6-Sbj)</Option>
                                            <Option key="6" value="6">Type-6(8-Sbj)</Option>
                                            <Option key="7" value="7">Type-7(10-Sbj)</Option>
                                            <Option key="8" value="8">Type-8(12-Sbj)</Option>
                                            <Option key="9" value="9">Type-9(14-Sbj)</Option>
                                            <Option key="10" value="10">Type-10(10-L)</Option>
                                            <Option key="11" value="11">Type-11(12-L)</Option>
                                            <Option key="12" value="12">Type-12(14-L)</Option>
                                            <Option key="13" value="13">Type-13(16-L)</Option>
                                            <Option key="14" value="14">Type-14(7-Sbj)</Option>
                                            <Option key="15" value="15">Type-15(9-Sbj-2-col)</Option>

                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Download
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                        <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                    </Col>
                </Row>

            </>
        </>
    )
}