import { DeleteOutlined, EditOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Space, Table, Tooltip, message, notification } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import { SelectInventoryCategory } from '../../../select/SelectInventoryCategory';
import { SelectInventoryItem } from '../../../select/SelectInventoryItem';
import { SelectLedgerCashEqui } from '../../../select/SelectLedgerCashEqui';
import { SelectInventorySaler } from '../../../select/SelectInventorySaler';



export default function InventoryPurchaseSave(props) {

    const [form] = Form.useForm();
    const fetchInventoryItemListByCategory = useStoreActions((state) => state.inventory.fetchInventoryItemListByCategory);
    const purchaseItemInventory = useStoreActions((state) => state.inventory.purchaseItemInventory);
    const itemList = useStoreState((state) => state.inventory.inventoryItemListByCategory);
    const [tableData, setTableData] = useState<any>([])

    const addToTable = (value) => {
        let itemName = itemList?.find(item => item?.itemId == value?.itemId)?.itemName
        let save = {
            key: uuidv4(),
            itemName: itemName,
            itemId: value?.itemId,
            unitPrice: unitPrice,
            quantity: quantity,
            total: total,
        }
        setunitPrice(0);
        setquantity(0);
        setTotal(0);
        const newData = [...tableData, save];
        setTableData(newData);
        settotAmount(newData.reduce(function (acc, obj) { return acc + obj.total; }, 0));
        setpayableAmount(newData.reduce(function (acc, obj) { return acc + obj.total; }, 0));
        setdueAmount(newData.reduce(function (acc, obj) { return acc + obj.total; }, 0));
        form.resetFields();
    }

    function deleteItem(key) {
        let temp = tableData.filter((item) => item.key !== key);
        setTableData(temp);
        settotAmount(temp.reduce(function (acc, obj) { return acc + obj.total; }, 0));
        setpayableAmount(temp.reduce(function (acc, obj) { return acc + obj.total; }, 0));
        setdueAmount(temp.reduce(function (acc, obj) { return acc + obj.total; }, 0));
    }

    

    const columns = [
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            key: 'itemName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Unit Price',
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Action",
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Delete">
                        <Popconfirm
                            title="Are you sure to delete this?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteItem(record?.key)}
                        >
                            <Button danger icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ]
    const [total, setTotal] = useState<any>("0");
    const [unitPrice, setunitPrice] = useState<any>(0);
    const [quantity, setquantity] = useState<any>(0);
    const [totAmount, settotAmount] = useState<any>(0);
    const [discount, setdiscount] = useState<any>(0);
    const [payableAmount, setpayableAmount] = useState<any>(0);
    const [paidAmount, setpaidAmount] = useState<any>(0);
    const [dueAmount, setdueAmount] = useState<any>(0);
    const [creditLedgerId, setcreditLedgerId] = useState<any>(null);
    const [salerId, setsalerId] = useState<any>(null);
    const [purchaseDate, setpurchaseDate] = useState<any>(null);

    const onSaveInventory = () =>{

        let details= tableData?.map(item=>{
            return {
                "itemId": item?.itemId,
                "quantity": item?.quantity,
                "unitPrice": item?.unitPrice,
                "totalPrice": item?.total
            }
        })
        if(details?.length===0){
            notification.error({message:"Please add in inventory"})
            return;
        }
        if(creditLedgerId==null){
            notification.error({message:"Please select ledger"})
            return;
        }           
        if(salerId==null){
            notification.error({message:"Please select saler"})
            return;
        }        
        if(purchaseDate==null){
            notification.error({message:"Date cannot be empty"})
            return;
        }        
        if(paidAmount<1){
            notification.error({message:"Invalid paid amount"})
            return;
        }
        let payload= {
            "creditLedgerId": creditLedgerId,
            "salerId": salerId,
            "items": details,
            "discount":discount,
            "paidAmount": paidAmount,
            "purchaseDate":moment(purchaseDate).format("YYYY-MM-DD")
          }
          purchaseItemInventory(payload);
          setTableData([])
          settotAmount(0);
          setdiscount(0);
          setpaidAmount(0);
          setpayableAmount(0);
          setdueAmount(0);
          setcreditLedgerId(null);
          setsalerId(null);
          setpurchaseDate(null);
  
    }

    return (
        <Card title={"Inventory Purchase"}>

        
            <Row gutter={8}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form
                        layout="vertical"
                        onFinish={addToTable}
                        id="basic-info"
                        form={form}
                    >


                        <Row gutter={8}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item
                                    name="catgeory"
                                    label="Catgeory"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select category" },
                                    ]}
                                >

                                    <SelectInventoryCategory onChange={e => fetchInventoryItemListByCategory(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item
                                    name="itemId"
                                    label="Item"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select item" },
                                    ]}
                                >

                                    <SelectInventoryItem />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item
                                    name="unitPrice"
                                    label="Unit Price"
                                    className="title-Text"
                                    initialValue={0}
                                    rules={[
                                        { required: true, message: "Please input unit price" },
                                    ]}
                                >

                                    <InputNumber min={0} value={unitPrice} placeholder='Unit Price' onChange={(e) => {setunitPrice(e); setTotal(quantity*e)}} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item
                                    name="quantity"
                                    label="Quantity"
                                    className="title-Text"
                                    initialValue={0}
                                    rules={[
                                        { required: true, message: "Please input quantity" },
                                    ]}
                                >

                                    <InputNumber min={0} value={quantity} placeholder='Quantity' onChange={(e) => {setquantity(e);setTotal(unitPrice*e)}} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="ant-col ant-form-item-label">
                                <label className="ant-form-item" style={{ marginTop: 0 }}>Total</label></div>
                            <Input value={total} disabled style={{height:40}} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<PlusCircleOutlined />}>
                                    Add
                                </Button>
                            </Col>
                        </Row>

                    </Form>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Table bordered={true} dataSource={tableData} columns={columns} pagination={false} />
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginTop: 10 }}>
                            <div className="ant-col ant-form-item-label">
                                <label className="ant-form-item" style={{ marginBottom: -3 }}>Total Amount</label></div>
                            <Input value={totAmount}  style={{ height: 40 }} disabled />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginTop: 10 }} >
                            <div className="ant-col ant-form-item-label">
                                <label className="ant-form-item" style={{ marginBottom: -3 }}>Discount</label></div>
                            <InputNumber value={discount }   style={{ height: 40 }} onChange={(e)=> {setpayableAmount(totAmount-e); setdueAmount(totAmount-e); setdiscount(e)}}/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginTop: 10 }}>
                            <div className="ant-col ant-form-item-label">
                                <label className="ant-form-item" style={{ marginBottom: -3 }}>Payable Amount</label></div>
                            <Input value={payableAmount} disabled   style={{ height: 40 }}/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginTop: 10 }} >
                            <div className="ant-col ant-form-item-label">
                                <label className="ant-form-item" style={{ marginBottom: -3 }}>Paid Amount</label></div>
                            <InputNumber value={paidAmount}  style={{ height: 40 }} onChange={(e)=> {setdueAmount(payableAmount-e); setpaidAmount(e)}} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginTop: 10 }}>
                            <div className="ant-col ant-form-item-label">
                                <label className="ant-form-item" style={{ marginBottom: -3 }}>Due Amount</label></div>
                            <Input value={dueAmount} disabled   style={{ height: 40 }}/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginTop: 10 }} >
                            <div className="ant-col ant-form-item-label">
                                <label className="ant-form-item" style={{ marginBottom: -3 }}>Purchase Date</label></div>
                            <DatePicker style={{ width: '100%' }} value={purchaseDate} onChange={e=>setpurchaseDate(e)} placeholder="Select Date" format={"DD/MM/YYYY"} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginTop: 10 }} >
                            <div className="ant-col ant-form-item-label">
                                <label className="ant-form-item" style={{ marginBottom: -3 }}>Payment by Ledger</label></div> <br />
                            <SelectLedgerCashEqui style={{ width: "100%" }} selected={creditLedgerId} onChange={e => setcreditLedgerId(e)} />
                        </Col>                        
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginTop: 10 }} >
                            <div className="ant-col ant-form-item-label">
                                <label className="ant-form-item" style={{ marginBottom: -3 }}>Select Saler</label></div> <br />
                            <SelectInventorySaler style={{ width: "100%" }} selected={salerId} onChange={e => setsalerId(e)} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginTop: 40 }} >
                            <Button
                                type="primary"
                                onClick={onSaveInventory}
                                icon={<SaveOutlined />}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>



        </Card>
    )
}
