
import React, { useEffect} from 'react';
import { Button, Card, Col, Row, Space,Skeleton } from 'antd'
import {DownloadOutlined, FileExcelOutlined} from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TableView from '../../../../contents/AntTableResponsive';
import jsPDF from "jspdf";
import { pdfDataL, lpowerdbypdf, ldatepdf } from '../../../utils/pdf';
import ReactExport from "react-export-excel";
import { v4 as uuidv4 } from "uuid";
require('jspdf-autotable');


const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

export default function ClassWiseSummaryReport() {

    const fetchClassWiseSummaryReport = useStoreActions((state) => state.student.fetchClassWiseSummaryReport);
    const classWiseSummaryListReport = useStoreState((state) =>  state.student.classWiseSummaryListReport);
    const loading = useStoreState((state) =>  state.student.loading);

    useEffect(() => {
        fetchClassWiseSummaryReport();
    }, []);

    
    const studentListColumn = [
        { title: 'Class Name', dataIndex: 'className', key: 'className', showOnResponse: true, showOnDesktop: true  },
        { title: 'Total Student', dataIndex: 'totalStudent', key: 'totalStudent', showOnResponse: true, showOnDesktop: true  },
    ];

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");

        var details = `Class Wise Student Summary`;
        pdfDataL(doc, "");
        doc.text(details, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);            
        };


        var col = [
            "Class Name",
            "Total Student"
        ].filter(Boolean);

        var rows:any = [];


        classWiseSummaryListReport.forEach(element => {
            var temp:any = [ 
                element.className, 
                element.totalStudent,
            ];
            rows.push(temp);
        });
       

            let first = doc.autoTable.previous;
            doc.autoTable(col, rows, {
                startY: 45,
                showHeader: "firstPage",
                theme: 'grid',
                styles: {
                    overflow: 'linebreak',
                },
                headerStyles: {
                   
                    lineWidth: .01,
                    lineColor: [224, 224, 224]
                },
                columnStyles: {
                    guardianMobile: {
                        halign: "left",
                        fontSize: 8,
                        columnWidth: 250
                    }
                },
                addPageContent: pageContent,
            });

  
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    return(
        <>
            <Card title="Class Wise Student Summary">
            <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: classWiseSummaryListReport.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns:studentListColumn,
                                rowKey: uuidv4(),
                                dataSource: classWiseSummaryListReport,
                                filterData: classWiseSummaryListReport,
                                pagination: true,
                                bordered: true,                           
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                    </Col>
                    <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>
                        <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Class Wise Student Summary`}
                            >
                                <ExcelSheet data={classWiseSummaryListReport} name="Class Wise Student Summary">
                                    <ExcelColumn label="Class Name" value="className" />
                                    <ExcelColumn label="Total Student" value="totalStudent" />
                                </ExcelSheet>
                            </ExcelFile>
                            <Button type="primary" icon={<DownloadOutlined />} onClick={() => exportPdf()} >PDF Download</Button>
                        </Space>
                    </Col>
                </Row>

            </Card>
        </>
    )
}
