import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag } from 'antd';


export default function SanMasikClass(props) {

    const createSanmasikClass = useStoreActions((state) => state.customerSupport.createSanmasikClass);
    const fetchsanmasikclassList = useStoreActions((state) => state.customerSupport.fetchsanmasikclassList);
    const sanmasikclassList = useStoreState((state) => state.customerSupport.sanmasikclassList);
    const updateSanmasikClass = useStoreActions((state) => state.customerSupport.updateSanmasikClass);
    const deleteSanmasikClass = useStoreActions((state) => state.customerSupport.deleteSanmasikClass);
    const [sanmasikForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const loading = useStoreState((state) => state.customerSupport.loading);

    const sanmasikClassForm = (value) => {
        let postData = {
            className: value.className,
            classCode: value.classCode,
        }
        createSanmasikClass(postData);
        //sanmasikForm.resetFields();
    }


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [updateId, setupdateId] = useState<any>();

    const updateExamForm = (value) => {
        let updatePostData = {
            classId: updateId,
            classCode: value.classCode,
            className: value.className,
        }
        updateSanmasikClass(updatePostData);
        setIsModalVisible(false);
        updateForm.resetFields();
        fetchsanmasikclassList();
    }

    useEffect(() => {
        fetchsanmasikclassList();
    }, [])

    const columns = [
        {
            title: 'Class Name',
            dataIndex: 'className',
            key: 'className',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Class Code',
            dataIndex: 'classCode',
            key: 'classCode',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Action',
            key: 'ledgerId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setupdateId(record.classId);
                            updateForm.setFieldsValue({
                                classCode: record.classCode,
                                className: record.className,
                            });
                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteSanmasikClass(record?.classId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        }
    ];

    return (
        <Card title="Sanmasik Class">
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                    <Form
                        layout="vertical"
                        id="sanmasikClass"
                        onFinish={sanmasikClassForm}
                        form={sanmasikForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                <Form.Item
                                    name="className"
                                    label="Class Name"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write class name" },
                                    ]}
                                >
                                    <Input placeholder="Enter class name" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                <Form.Item
                                    name="classCode"
                                    label="Class Code"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write class code" },
                                    ]}
                                >
                                    <InputNumber placeholder="Enter class code" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                        Save
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Skeleton loading={loading} paragraph={{ rows: 10 }} />
            {sanmasikclassList?.length > 0 &&
                <Row className="m-t-mo-30">
                    <Col span="24">
                        <div className="datatable-responsive-demo">
                            {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}
                            <Table bordered={true} dataSource={sanmasikclassList} columns={columns} className="p-datatable-responsive-demo" />
                        </div>
                    </Col>
                </Row>
            }
            <Modal
                title="Edit"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
                destroyOnClose
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={updateExamForm}
                    form={updateForm}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="className"
                                label="Class Name"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write class name" },
                                ]}
                            >
                                <Input placeholder="Enter class name" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="classCode"
                                label="Class Code"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write class code" },
                                ]}
                            >
                                <InputNumber placeholder="Enter class code" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Card>
    )
}