import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Space, Skeleton, Popconfirm, Tooltip, Modal } from "antd";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { useStoreActions, useStoreState } from "../../../store/hooks/easyPeasy";
import TableView from "../../../contents/AntTableResponsive";
import { SelectInventoryCategory } from "../../select/SelectInventoryCategory";

export default function InventoryItem() {

  const saveInventoryItem = useStoreActions((state) => state.inventory.saveInventoryItem);
  const fetchInventoryItemList = useStoreActions((state) => state.inventory.fetchInventoryItemList);
  const inventoryItemList = useStoreState((state) => state.inventory.inventoryItemList);

  const updateInventoryItem = useStoreActions((state) => state.inventory.updateInventoryItem);
  const deleteInventoryItem = useStoreActions((state) => state.inventory.deleteInventoryItem);

  const [inventoryItemForm] = Form.useForm();
  const [inventoryItemUpdateForm] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [categoryId, setCategoryId] = useState<any>();
  const [itemId, setItemId] = useState<any>();



  const loading = useStoreState((state) => state.inventory.loading);

  const saveInventoryItemInfo = (value) => {
    let postData = {
      categoryId: value.categoryId,
      itemCode: value.itemCode,
      itemName: value.itemName,
      serial: value.serial,
      unity: value.unity,
      salesPrice: value.salesPrice,
    };
    saveInventoryItem(postData);
    //inventoryItemForm.resetFields();
  };

  useEffect(() => {
    fetchInventoryItemList();

  }, []);

  const oncahngeSelectedCategorId = (val) => {
    setCategoryId(val);
  };

  const updateInventoryItemInfo = (value) => {
    let postData = {
      categoryId: value.categoryId,
      itemCode: value.itemCode,
      itemName: value.itemName,
      serial: value.serial,
      unity: value.unity,
      salesPrice: value.salesPrice,
      itemId: itemId,
    };
    updateInventoryItem(postData);
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: 'Serial',
      dataIndex: 'serial',
      key: 'serial',
      showOnResponse: true,
      showOnDesktop: true
    },
    {
      title: 'Item Name',
      dataIndex: 'itemName',
      key: 'itemName',
      showOnResponse: true,
      showOnDesktop: true
    },
    {
      title: 'Category Name',
      dataIndex: 'categoryName',
      key: 'categoryName',
      showOnResponse: true,
      showOnDesktop: true
    },

    {
      title: 'Item Code',
      dataIndex: 'itemCode',
      key: 'itemCode',
      showOnResponse: true,
      showOnDesktop: true
    },

    {
      title: 'Item Unity',
      dataIndex: 'unity',
      key: 'unity',
      showOnResponse: true,
      showOnDesktop: true
    },

    {
      title: 'Sales Price',
      dataIndex: 'salesPrice',
      key: 'salesPrice',
      showOnResponse: true,
      showOnDesktop: true
    },

    {
      title: 'Action',
      key: 'itemId',
      showOnResponse: true,
      showOnDesktop: true,
      render: (text: any, record: any, index) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <Button type='primary' icon={<EditOutlined />} onClick={() => {
              setIsModalVisible(true);
              setCategoryId(record.categoryId);
              setItemId(record.itemId)
              inventoryItemUpdateForm.setFieldsValue({
                itemId: record.itemId,
                itemName: record.itemName,
                serial: record.serial,
                unity: record.unity,
                salesPrice: record.salesPrice,
                categoryId: record.categoryId,
                itemCode: record.itemCode,
              });
            }}
            />
          </Tooltip>
          <Popconfirm
            title="Are you sure to delete this?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteInventoryItem(record?.itemId)}
          >
            <Tooltip title="Delete">
              <Button danger icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>

        </Space>
      ),
    },

  ];

  return (

    <>

      <Card title="Inventory Item">

        <Row>
          <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>
            <Form
              layout="vertical"
              id="classInfo"
              onFinish={saveInventoryItemInfo}
              form={inventoryItemForm}
            >
              <Row>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="serial"
                    label="Serial"
                    className="title-Text"
                    rules={[
                      {
                        required: true,
                        message: "Please enter serial",
                      },
                    ]}
                  >
                    <Input placeholder="Enter serial" />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="itemName"
                    label="Item Name"
                    className="title-Text"
                    rules={[
                      {
                        required: true,
                        message: "Please write inventory Item name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter inventory Item name" />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="categoryId"
                    label="Inventory Category"
                    className="title-Text"
                    rules={[
                      {
                        required: true,
                        message: "Please select category",
                      },
                    ]}
                  >
                    <SelectInventoryCategory />
                  </Form.Item>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="itemCode"
                    label="Item Code"
                    className="title-Text"
                    rules={[
                      { required: true, message: "Please Write Item Code" },
                    ]}
                  >
                    <Input placeholder="Enter Code Number" />
                  </Form.Item>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="unity"
                    label="Item Unity"
                    className="title-Text"
                    rules={[
                      { required: true, message: "Please Write Item Unity" },
                    ]}
                  >
                    <Input placeholder="Enter Unity" />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="salesPrice"
                    label="Sales Price"
                    className="title-Text"
                    rules={[
                      { required: true, message: "Please Write sales price" },
                    ]}
                  >
                    <Input placeholder="Enter Sales Price" />
                  </Form.Item>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Space size="small">
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<SaveOutlined />}
                    >
                      Save
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Skeleton loading={loading} paragraph={{ rows: 10 }} />
        <br />
        <Row className="m-t-mo-30">
          <Col span="24">
            <div className="datatable-responsive-demo">
              {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}
              <TableView
                antTableProps={{
                  showHeader: true,
                  columns,
                  dataSource: inventoryItemList,
                  filterData: inventoryItemList,
                  pagination: true,
                  bordered: true,
                  rowKey: "id",

                }}
                mobileBreakPoint={768}
              />
            </div>
          </Col>
        </Row>
      </Card>


      <Modal
        title="Item Edit"
        visible={isModalVisible}
        okButtonProps={{ form: 'update', htmlType: 'submit' }}
        onCancel={() => setIsModalVisible(false)}
        cancelText="Close"
        okText="Update"
        centered
      >
        <Form
          layout="vertical"
          onFinish={updateInventoryItemInfo}
          id="update"
          form={inventoryItemUpdateForm}
        >
          <Form.Item
            name="serial"
            label="Serial"
            className="title-Text"
            rules={[
              {
                required: true,
                message: "Please enter serial",
              },
            ]}
          >
            <Input placeholder="Enter serial" />
          </Form.Item>
          <Form.Item
            name="itemName"
            label="Item Name : "
            className="title-Text"
            rules={[
              { required: true, message: "Please input Item Name" },
            ]}
          >
            <Input placeholder='Item Name' />
          </Form.Item>

          <Form.Item
            name="categoryId"
            label="Category:"
            className="title-Text"
            initialValue={categoryId}
            rules={[
              { required: true, message: "Please select category" },
            ]}
          >
            <SelectInventoryCategory onChange={(val) => oncahngeSelectedCategorId(val)} selected={categoryId} />
          </Form.Item>
          <Form.Item
            name="unity"
            label="Item Unity : "
            className="title-Text"
            rules={[
              { required: true, message: "Please Input Item Unity" },
            ]}
          >
            <Input placeholder='Item Unity' />
          </Form.Item>


          <Form.Item
            name="itemCode"
            label="Item Code:"
            className="title-Text"
          >
            <Input placeholder='Item Code' />
          </Form.Item>
          <Form.Item
            name="salesPrice"
            label="Sales Price"
            className="title-Text"
            rules={[
              { required: true, message: "Please Write sales price" },
            ]}
          >
            <Input placeholder="Enter Sales Price" />
          </Form.Item>


        </Form>

      </Modal>
    </>
  );
}
