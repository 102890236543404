import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';

import { DeleteOutlined, DownloadOutlined, EditOutlined, FileExcelOutlined, FilePdfOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
require('jspdf-autotable');

export default function ExamReportBlankSheet() {
    const { Option } = Select;
    const [examReportSectionWiseResultForm] = Form.useForm();

    const fetchExamListByclassConfigIdSectionWiseExam = useStoreActions((state) => state.exam.fetchExamListByclassConfigIdSectionWiseExam);
    const examListByclassConfigIdSectionWiseExam = useStoreState((state) => state.exam.examListByclassConfigIdSectionWiseExam);

    const dload = useStoreActions((state) => state.exam.dload);



    const onChangeClassList = (e) => {
        fetchExamListByclassConfigIdSectionWiseExam(e);
    }

    const examReportSearch = (value) => {
        let postData: any = {
            examId: value.examId,
            classConfigId: value.sectionSection,
            academicYear : value.academicYear,
        }
        dload(postData);
    }

    return (
        <>
            <Card title="Attendance Blank Sheet">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 18, offset: 4 }} xl={{ span: 18, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={examReportSearch}
                            form={examReportSectionWiseResultForm}
                        >
                            <Row>

                               <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Select Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select AcademicYear" },
                                        ]}
                                    >
                                        <SelectAcademicYear />

                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="sectionSection"
                                        label="Select Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig onChange={(e) => onChangeClassList(e)} />
                                    </Form.Item>
                                </Col>


                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="examId"
                                        label="Select Exam"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select exam" },
                                        ]}
                                    >
                                        <Select placeholder="Exam List" style={{ width: "100%" }} className="examSelectx">
                                            {examListByclassConfigIdSectionWiseExam ? (
                                                examListByclassConfigIdSectionWiseExam.map((type, idx) => (
                                                    <Option key={type.examId} value={type.examId}>
                                                        {type.examName}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching exam</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Button type="primary" htmlType="submit" icon={<DownloadOutlined />} >
                                        Download
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>

            </Card>
        </>
    )

}