import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';




export default function OFPSBkash(props) {
    const fetchbkasOfpsTransactionConfigurationView = useStoreActions((state) => state.customerSupport.fetchbkasOfpsTransactionConfigurationView);
    const bkasOfpsTransactionConfigurationView = useStoreState((state) => state.customerSupport.bkasOfpsTransactionConfigurationView);

    useEffect(() => {
        fetchbkasOfpsTransactionConfigurationView();
    }, [])


    return (
        <>
            <>
                <Row gutter={8}>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Card title="Configuration View" >
                            <div>
                                Merchant Number: {bkasOfpsTransactionConfigurationView?.merchantNumber} <br /> <br />
                                Debit Ledger Name: {bkasOfpsTransactionConfigurationView?.debitLedgerName} <br /> <br />
                                Credit Ledger Name: {bkasOfpsTransactionConfigurationView?.creditLedgerName}
                            </div>
                        </Card>
                    </Col>
                </Row>


            </>
        </>
    )
}
