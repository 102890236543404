import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, notification, Popconfirm, Row, Space, Tooltip, Select, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { SelectFeeHead } from '../../../select/SelectFeeHead';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import moment from 'moment';
import { SelectAcademicYear2 } from '../../../select/SelectAcademicYear2';

const { Option } = Select;
const year = new Date().getFullYear();

export default function FeeSubHeadTimeConfig(props) {

    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [updateForm] = Form.useForm();


    const feeSubHeadConfigurationList2 = useStoreState((state) => state.saccount.feeSubHeadConfigurationList2);
    const fetchfeeSubHeadConfigurationList2 = useStoreActions((state) => state.saccount.fetchfeeSubHeadConfigurationList2);
    const saveFeeSubHeadTimeConfig = useStoreActions((state) => state.saccount.saveFeeSubHeadTimeConfig);
    const saveFeeSubheadTimeConfiguration = useStoreActions((state) => state.saccount.saveFeeSubheadTimeConfiguration);

    const yearWiseFeeHeadTime = useStoreState((state) => state.saccount.yearWiseFeeHeadTime);
    const fetchyearWiseFeeHeadTime = useStoreActions((state) => state.saccount.fetchyearWiseFeeHeadTime);
    const deleteyearWiseFeeHeadTime = useStoreActions((state) => state.saccount.deleteyearWiseFeeHeadTime);
    const loading = useStoreState((state) => state.saccount.loading);



    const formSubmit = (value) => {
        setsearchData(value);
        fetchfeeSubHeadConfigurationList2(value.feeHeadId)
        // setClassId(null);
    }

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);
    const [searchData, setsearchData] = useState<any>();
    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);

    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const [tableRowStore, setTableRowStore] = useState<any>([]);




    const onchangeValue: any =
        (key, data, index) => (e: any) => {
            const newData: any = [...tableRowStore];
            newData[index][key] = e;
            setTableRowStore(newData);
        };

    const columns = [

        {
            title: 'Fee Head',
            dataIndex: 'feeHeadName',
            key: 'feeHeadName',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Fee Subhead',
            dataIndex: 'feeSubheadName',
            key: 'feeSubheadName',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Payable Month',
            dataIndex: 'payableMonth',
            key: 'payableMonth',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Select placeholder="Select Month" onChange={onchangeValue("payableMonth", record, index)} style={{ width: "100%" }}>
                    <Option value="January">January</Option>
                    <Option value="February">February</Option>
                    <Option value="March">March</Option>
                    <Option value="April">April</Option>
                    <Option value="May">May</Option>
                    <Option value="June">June</Option>
                    <Option value="July">July</Option>
                    <Option value="August">August</Option>
                    <Option value="September">September</Option>
                    <Option value="October">October</Option>
                    <Option value="November">November</Option>
                    <Option value="December">December</Option>
                </Select>
            ),
        },

        {
            title: 'Payable Year',
            dataIndex: 'payableYear',
            key: 'payableYear',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Select placeholder="Select Year" onChange={onchangeValue("payableYear", record, index)} style={{ width: "100%" }}>
                    <Option value={year - 2}>{year - 2}</Option>
                    <Option value={year - 1}>{year - 1}</Option>
                    <Option value={year}>{year}</Option>
                    <Option value={year + 1}>{year + 1}</Option>
                </Select>
            ),
        },

    ];

    const onchangeValueDate: any =
        (key, data) => (e: any) => {
            let payload = {
                fineActiveDate: moment(e).format("YYYY-MM-DD"),
                id: data
            }
            saveFeeSubheadTimeConfiguration(payload);
            setTimeout(() => {
                form2.submit()
            }, 1000);
        }

    const columns2 = [

        {
            title: 'Fee Head',
            dataIndex: 'feeHeadName',
            key: 'feeHeadName',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Fee Subhead',
            dataIndex: 'feeSubHeadName',
            key: 'feeSubHeadName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Payable Year',
            dataIndex: 'payableYear',
            key: 'payableYear',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Payable Month',
            dataIndex: 'payableMonth',
            key: 'payableMonth',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Fine Active Date',
            dataIndex: 'fineActiveDate',
            key: 'fineActiveDate',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (<>
                {record.fineActiveDate !== null ?
                    <DatePicker allowClear={false} value={(moment(record?.fineActiveDate?.split('-').join('/')))} onChange={onchangeValueDate("fineActiveDate", record?.id)} style={{ width: 150 }} />
                    :
                    <DatePicker allowClear={false} value={null} onChange={onchangeValueDate("fineActiveDate", record?.id)} style={{ width: 150 }} />
                }
            </>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'gradeRange',
            key: 'id',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Popconfirm
                    title="Are you sure to delete this?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => {
                        deleteyearWiseFeeHeadTime(record.id);
                        setTimeout(() => {
                            fetchyearWiseFeeHeadTime(yearWise);
                        }, 500);
                    }}
                >
                    <Tooltip title="Delete">
                        <Button danger icon={<DeleteOutlined />} />
                    </Tooltip>
                </Popconfirm>
            ),
        },
    ]

    const [yearWise, setyearWise] = useState<any>();
    const formSubmit2 = (value) => {
        setyearWise(value);
        fetchyearWiseFeeHeadTime(value);
        // form2.resetFields();
    }

    useEffect(() => {
        setTableRowStore(feeSubHeadConfigurationList2);
    }, [feeSubHeadConfigurationList2]);



    const saveFeeSubHead = () => {
        let check = false;
        if (selectedRowKeys.length === 0) {
            notification.error({ message: 'Select the table row first' });
            return;
        }
        let data = selectedValue.map((item, index) => {
            if (item.payableMonth === undefined || item.payableYear === undefined) {
                notification.error({ message: 'Select month or year' });
                check = false;
                return;
            } else {
                check = true;
                return {
                    //feeHeadId: item.feeHeadId,
                    feeSubHeadId: item.feeSubHeadId,
                    payableMonth: item.payableMonth,
                    payableYear: item.payableYear,
                }
            }
        })
        if (!check) {
            return;
        }
        let payload = {
            "academicYear": form.getFieldValue('academicYear'),
            "feeHeadId": form.getFieldValue('feeHeadId'),
            "feeSubHeads": data
        }
        saveFeeSubHeadTimeConfig(payload);
        setselectedRowKeys([]);
        setselectedValue([]);
        setTableRowStore(null);
        fetchfeeSubHeadConfigurationList2(feeHeadid);

    }

    const [feeHeadid, setfeeHeadid] = useState<any>()

    return (
        <>
            <Card title="Fee Subhead Time Configuration" >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>

                        <Card title="Fee Head" >
                            <Form
                                layout="vertical"
                                onFinish={formSubmit}
                                id="basic-info"
                                form={form}
                            >
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                        <Form.Item
                                            name="academicYear"
                                            label="Academic Year"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select year" },
                                            ]}
                                        >
                                            <SelectAcademicYear2 />

                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                        <Form.Item
                                            name="feeHeadId"
                                            label="Fee Head"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select fee head" },
                                            ]}
                                        >
                                            <SelectFeeHead selected={feeHeadid} onChange={e => { setfeeHeadid(e); setselectedRowKeys([]); setselectedValue([]); fetchfeeSubHeadConfigurationList2(e); }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>


                            {feeSubHeadConfigurationList2 === null && < Skeleton loading={loading} paragraph={{ rows: 10 }} />}
                            {tableRowStore !== null &&
                                <>
                                    <Table
                                        antTableProps={{
                                            showHeader: true,
                                            columns,
                                            dataSource: tableRowStore,
                                            filterData: tableRowStore,
                                            pagination: false,
                                            bordered: true,
                                            rowKey: "configId",
                                            rowSelection: rowSelection,
                                        }}
                                        mobileBreakPoint={768}
                                    />
                                    <br />
                                    <Space size={'middle'} style={{ float: "right" }}>
                                        <Button type="primary" onClick={saveFeeSubHead} icon={<SaveOutlined />}>Save</Button>
                                    </Space>
                                </>
                            }


                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Card title="Year Wise Fee Head" >
                            <Form
                                layout="vertical"
                                onFinish={formSubmit2}
                                id="basic-info"
                                form={form2}
                            >
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                                        <Form.Item
                                            name="year"
                                            label="Academic Year"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select year" },
                                            ]}
                                        >
                                            <SelectAcademicYear />

                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                                        <Form.Item
                                            name="feeHeadId"
                                            label="Fee Head"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select fee head" },
                                            ]}
                                        >
                                            <SelectFeeHead />

                                        </Form.Item>

                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                        <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                            Search
                                        </Button>
                                    </Col>
                                </Row>

                            </Form>

                            {yearWiseFeeHeadTime === null && < Skeleton loading={loading} paragraph={{ rows: 10 }} />}
                            {yearWiseFeeHeadTime !== null &&
                                <>
                                    <Table
                                        antTableProps={{
                                            showHeader: true,
                                            columns: columns2,
                                            dataSource: yearWiseFeeHeadTime,
                                            filterData: yearWiseFeeHeadTime,
                                            bordered: true,
                                            rowKey: "id",
                                        }}
                                        mobileBreakPoint={768}
                                    />
                                    {/* <br />
                                    <Space size={'middle'} style={{ float: "right" }}>
                                        <Button type="primary" onClick={saveFeeSubHead} icon={<SaveOutlined />}>Save</Button>
                                    </Space> */}
                                </>
                            }
                        </Card>
                    </Col>
                </Row>

            </Card>


        </>
    )
}
