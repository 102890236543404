import { DeleteOutlined, EditOutlined, EyeOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Descriptions, Form, Input, Modal, notification, Popconfirm, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Table from "../../../contents/AntTableResponsive";
import moment from 'moment';

export default function OnlineAdmissionSpgProblemView() {

  const fetchOnlineAdmissionSpgProblemView = useStoreActions((state) => state.customerSupport.fetchOnlineAdmissionSpgProblemView);
  const onlineAdmissionSpgProblemView = useStoreState((state) => state.customerSupport.onlineAdmissionSpgProblemView);

  const fetchofpsTransactionProblemCheck = useStoreActions((state) => state.customerSupport.fetchofpsTransactionProblemCheck);
  const ofpsTransactionProblemCheck = useStoreState((state) => state.customerSupport.ofpsTransactionProblemCheck);
  const solveOfpsTransactionProblem = useStoreActions((state) => state.customerSupport.solveOnlineAdmissionSpgProblem);

  const problemCheckView = useStoreState((state) => state.customerSupport.ofpsTransactionProblemCheckView);
  const fetchProblemCheckView = useStoreActions((state) => state.customerSupport.fetchOfpsTransactionProblemCheckView);
  const solveNewOfpsTransactionProblem = useStoreActions((state) => state.customerSupport.solveNewOnlineAdmissionSpgProblem);

  const isofps = useStoreState((state) => state.customerSupport.isofps);
  const isofpsNew = useStoreState((state) => state.customerSupport.isofpsNew);

  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [data, setData] = useState<any>({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNewModalVisible, setIsNewModalVisible] = useState(false);
  const [sessionToken, setSessionToken] = useState("");

  const formSubmit = (value) => {

    value.trnDate = moment(value?.trnDate).format("YYYY-MM-DD")
    fetchOnlineAdmissionSpgProblemView(value);
    setData(value);
  }

  const handleSolve = () => {
    let postData = {
        "instituteId": ofpsTransactionProblemCheck?.instituteId,
        "orgiBrCode": ofpsTransactionProblemCheck?.brCode,
        "payMode": ofpsTransactionProblemCheck?.payMode,
        "refTranDateTime": ofpsTransactionProblemCheck?.referenceDate,
        "refTranNo": ofpsTransactionProblemCheck?.refTrnNo,
        "statusCode": ofpsTransactionProblemCheck?.statusCode,
        "tranAmount": ofpsTransactionProblemCheck?.tranAmount,
        "tranNo": ofpsTransactionProblemCheck?.transactionId,
    };

    solveOfpsTransactionProblem(postData);
    setIsModalVisible(false);
    setTimeout(() => {
      fetchOnlineAdmissionSpgProblemView(data);
    }, 2000);
};

const handleSolveNew = () => {
    solveNewOfpsTransactionProblem(sessionToken);
    setIsNewModalVisible(false);
    setTimeout(() => {
      fetchOnlineAdmissionSpgProblemView(data);
    }, 2000);
};

useEffect(() => {
  if (isofps === true) {
      setIsModalVisible(true)
  }
  if (isofpsNew === true) {
    setIsNewModalVisible(true)
  }
}, [isofps,isofpsNew])

  const columns = [

    {
        title: 'Applicant Name',
        dataIndex: 'applicantName',
        key: 'applicantName',
        showOnResponse: true,
        showOnDesktop: true
    },
    {
        title: 'Mobile No',
        dataIndex: 'mobileNo',
        key: 'mobileNo',
        showOnResponse: true,
        showOnDesktop: true
    },
    {
        title: 'Reference Date',
        dataIndex: 'referenceDate',
        key: 'referenceDate',
        showOnResponse: true,
        showOnDesktop: true
    },
    {
        title: 'Fees Payment',
        dataIndex: 'feesPayment',
        key: 'feesPayment',
        showOnResponse: true,
        showOnDesktop: true
    },
    {
        title: 'Service Charge',
        dataIndex: 'serviceCharge',
        key: 'serviceCharge',
        showOnResponse: true,
        showOnDesktop: true
    },
    {
        title: 'Request Id',
        dataIndex: 'requestId',
        key: 'requestId',
        showOnResponse: true,
        showOnDesktop: true
    },
    {
        title: 'Ref No',
        dataIndex: 'refTranNo',
        key: 'refTranNo',
        showOnResponse: true,
        showOnDesktop: true
    },

    {
        title: 'Session Token',
        dataIndex: 'sessionToken',
        key: 'sessionToken',
        showOnResponse: true,
        showOnDesktop: true
    },

    {
        title: 'Status',
        dataIndex: 'requestStatus',
        key: 'requestStatus',
        showOnResponse: true,
        showOnDesktop: true
    },

    {
      title: 'Check (Old)',
      key: 'id',
      showOnResponse: true,
      showOnDesktop: true,
      render: (text: any, record: any, index) => (
          <Space size="middle">
              <Tooltip title="Check Problem">
                  <Button type='primary' icon={<EyeOutlined />} onClick={() => {
                      notification.info({ message: "Please Wait", description: "Loading...", duration: 1 });
                      fetchofpsTransactionProblemCheck(record.refTranNo);
                      

                  }}
                  />
              </Tooltip>

          </Space>
      ),
  },

  {
    title: 'Check (New)',
    key: 'idn',
    showOnResponse: true,
    showOnDesktop: true,
    render: (text: any, record: any, index) => (
        <Space size="middle">
            <Tooltip title="Check Problem">
                <Button type='primary' icon={<EyeOutlined />} onClick={() => {
                    notification.info({ message: "Please Wait", description: "Loading...", duration: 1 });
                    fetchProblemCheckView(record.sessionToken);
                    setSessionToken(record.sessionToken);
                    

                }}
                />
            </Tooltip>

        </Space>
    ),
},

];

  return (
    <>
    
    <Card title="Online Admission Spg Transaction View" >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="registrationId"
                                label="Registration Id"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input registration id" },
                                ]}
                            >

                                <Input placeholder="registrationId" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="instituteId"
                                label="Institute Id"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input institute id" },
                                ]}
                            >

                                <Input placeholder="Institute Id" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="trnDate"
                                label="Tran. Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select tran date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    </Row>
                </Form>

                {onlineAdmissionSpgProblemView?.length > 0 &&
                    <div className="datatable-responsive">
                        <Table
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: onlineAdmissionSpgProblemView,
                                filterData: onlineAdmissionSpgProblemView,
                                pagination: false,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                }
            </Card>


            <Modal
                title="OFPS"
                visible={isModalVisible}
                //onOk={handleSolve}
                //okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                // cancelText="Close"
                // okText="Solve"
                centered
                footer={[
                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                        <Button key="3" type="primary" onClick={() => handleSolve()}>
                            Solve
                        </Button>
                    </div>

                ]}
            >
                <Descriptions
                    // title="User Info"
                    bordered
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                >
                    <Descriptions.Item label="Applicant Name">{ofpsTransactionProblemCheck?.applicantName}</Descriptions.Item>
                    <Descriptions.Item label="Applicant">{ofpsTransactionProblemCheck?.applicantName}</Descriptions.Item>
                    <Descriptions.Item label="Mobile No">{ofpsTransactionProblemCheck?.mobileNo}</Descriptions.Item>
                    <Descriptions.Item label="SP Code">{ofpsTransactionProblemCheck?.spCode}</Descriptions.Item>
                    <Descriptions.Item label="Service Name">{ofpsTransactionProblemCheck?.serviceName}</Descriptions.Item>
                    <Descriptions.Item label="Transaction Id">{ofpsTransactionProblemCheck?.transactionId}</Descriptions.Item>
                    <Descriptions.Item label="Reference Date">{ofpsTransactionProblemCheck?.referenceDate}</Descriptions.Item>
                    <Descriptions.Item label="Transaction Date">{ofpsTransactionProblemCheck?.transactionDate}</Descriptions.Item>
                    <Descriptions.Item label="Ref Trn. No">{ofpsTransactionProblemCheck?.refTrnNo}</Descriptions.Item>
                </Descriptions>

            </Modal>


            <Modal
                title="OFPS"
                visible={isNewModalVisible}
                onCancel={() => setIsNewModalVisible(false)}
                centered
                footer={[
                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                        <Button key="4" type="primary" onClick={() => handleSolveNew()}>
                            Solve
                        </Button>
                    </div>

                ]}
            >
                <Descriptions
                    bordered
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                >
                    <Descriptions.Item label="Applicant Name">{problemCheckView?.applicantName}</Descriptions.Item>
                    <Descriptions.Item label="Applicant Contact">{problemCheckView?.applicantContactNo}</Descriptions.Item>
                    <Descriptions.Item label="Msg">{problemCheckView?.msg}</Descriptions.Item>
                    <Descriptions.Item label="Status">{problemCheckView?.status}</Descriptions.Item>
                    <Descriptions.Item label="Payment Status">{problemCheckView?.paymentStatus}</Descriptions.Item>
                    <Descriptions.Item label="Total Amount">{problemCheckView?.totalAmount}</Descriptions.Item>
                    <Descriptions.Item label="Transaction Id">{problemCheckView?.transactionId}</Descriptions.Item>
                    <Descriptions.Item label="InvoiceNo">{problemCheckView?.invoiceNo}</Descriptions.Item>
                    <Descriptions.Item label="Transaction Date">{problemCheckView?.transactionDate}</Descriptions.Item>
                    <Descriptions.Item label="payMode">{problemCheckView?.payMode}</Descriptions.Item>

                </Descriptions>

            </Modal>
    
    </>
  )
}
