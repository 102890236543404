import { Button, Card, Col, Input, InputNumber, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from "uuid";
import { EditOutlined, FileTextOutlined, MinusCircleTwoTone, PlusOutlined, PrinterOutlined } from '@ant-design/icons';
import ReactToPrint from 'react-to-print';
import Spreadsheet from "react-spreadsheet";
import Text from 'antd/lib/typography/Text';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import SetOptions from "suneditor-react/src/types/SetOptions";
import { ButtonListItem } from "suneditor/src/options"

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    name?: string;
  }
}

const ComponentToPrint = React.forwardRef((props: any, ref: any) => {
  const { data1, data2, data3, data4, number, text } = props;

  return (
    <div className='print-source' ref={ref} >
      <>
      <br />
      <div style={{paddingLeft:30}} dangerouslySetInnerHTML={{ __html: text }} />
        <br />
        {number === 2 &&
          <div className='seatplan'>
            <Spreadsheet data={data1} className='d1x' />
            <Spreadsheet data={data2} className='d1x' />
          </div>
        }
        {number === 3 &&
          <div className='seatplan2'>
            <Spreadsheet data={data1} className='d2x' />
            <Spreadsheet data={data2} className='d2x' />
            <Spreadsheet data={data3} className='d2x' />
          </div>
        }
        {number === 4 &&
          <div className='seatplan3'>
            <Spreadsheet data={data1} className='d3x' />
            <Spreadsheet data={data2} className='d3x' />
            <Spreadsheet data={data3} className='d3x' />
            <Spreadsheet data={data4} className='d3x' />
          </div>
        }
      </>
    </div>
  );
});



export default function SeatPlan() {

  const [number, setNumber] = useState<any>(2);

  const [data1, setData1] = useState<any>([
    [{ value: null }, { value: null },],
  ]);
  const [data2, setData2] = useState<any>([
    [{ value: null }, { value: null },],
  ]);
  const [data3, setData3] = useState<any>([
    [{ value: null }, { value: null },],
  ]);
  const [data4, setData4] = useState<any>([
    [{ value: null }, { value: null },],
  ]);

  const onChangedata1 = (val: any) => {
    let fill = val[val.length - 1][0]?.value;
    if (fill === '' || fill === undefined) {
      let max = [...val];
      max.pop();
      setData1(max);
    } else {
      setData1(val);
    }
  };
  const onChangedata2 = (val: any) => {
    let fill = val[val.length - 1][0]?.value;
    if (fill === '' || fill === undefined) {
      let max = [...val];
      max.pop();
      setData2(max);
    } else {
      setData2(val);
    }
  };
  const onChangedata3 = (val: any) => {
    let fill = val[val.length - 1][0]?.value;
    if (fill === '' || fill === undefined) {
      let max = [...val];
      max.pop();
      setData3(max);
    } else {
      setData3(val);
    }
  };
  const onChangedata4 = (val: any) => {
    let fill = val[val.length - 1][0]?.value;
    if (fill === '' || fill === undefined) {
      let max = [...val];
      max.pop();
      setData4(max);
    } else {
      setData4(val);
    }
  };

  const componentRef: any = useRef();

  const rdata1 = () => {
    let max = [...data1];
    max.pop();
    setData1(max);
  }
  const rdata2 = () => {
    let max = [...data2];
    max.pop();
    setData2(max);
  }

  const rdata3 = () => {
    let max = [...data3];
    max.pop();
    setData3(max);
  }
  const rdata4 = () => {
    let max = [...data4];
    max.pop();
    setData4(max);
  }

  const buttonList: ButtonListItem[] = [
    ['undo', 'redo'],
    ['font', 'fontSize'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['fontColor', 'hiliteColor'], // 'textStyle'
    ['outdent', 'indent'],
    ['align', 'list', 'lineHeight'],
    ['image'],
    ['preview'],
  ]
  const options: SetOptions = {
    buttonList,
    imageWidth: '500px',
    showPathLabel: false,
    height: 'auto',
    // font: ['sans-serif', 'serif'],
    defaultStyle: 'font-family:sans-serif',
  };

  const [text, setText] = useState<any>('');

  return (

    <>
      <Card title="Seat Plan" >
        <Row>
          <Col span={8}></Col>
          <Col span={6}>
            <Text strong>Number of Column: </Text>
            <InputNumber value={number} onChange={(e) => setNumber(e)} min={2} max={4} placeholder='Number of column' />
          </Col>
          <Col span={6}></Col>

          <Col span={24} style={{ marginTop: 20 }}>
            <SunEditor
              setOptions={options}
              onChange={(e: any) => setText(e)}
            />
          </Col>
        </Row>
        <br />
        <Row>
          {number === 2 &&
            <>
              <Col span={12}>
                <Card title={'Column 1'}>
                  <div style={{ display: "flex", padding: 20, marginTop: -30 }}>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => setData1([...data1, [{ value: null }, { value: null },]])}>Add</Button>
                    <Button style={{ marginLeft: 10 }} type='primary' icon={<MinusCircleTwoTone />} onClick={() => rdata1()}>Delete</Button>
                  </div>
                  <Spreadsheet className='seatMain' data={data1} onChange={(e) => onChangedata1(e)} />
                </Card>
              </Col>

              <Col span={12}>
                <Card title={'Column 2'}>
                  <div style={{ display: "flex", padding: 20, marginTop: -30 }}>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => setData2([...data1, [{ value: null }, { value: null },]])}>Add</Button>
                    <Button style={{ marginLeft: 10 }} type='primary' icon={<MinusCircleTwoTone />} onClick={() => rdata2()}>Delete</Button>
                  </div>
                  <Spreadsheet className='seatMain' data={data2} onChange={(e) => onChangedata2(e)} />
                </Card>
              </Col>
            </>
          }
          {number === 3 &&
            <>
              <Col span={8}>
                <Card title={'Column 1'}>
                  <div style={{ display: "flex", padding: 20, marginTop: -30 }}>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => setData1([...data1, [{ value: null }, { value: null },]])}>Add</Button>
                    <Button style={{ marginLeft: 10 }} type='primary' icon={<MinusCircleTwoTone />} onClick={() => rdata1()}>Delete</Button>
                  </div>
                  <Spreadsheet className='seatMain' data={data1} onChange={(e) => onChangedata1(e)} />
                </Card>
              </Col>
              <Col span={8}>
                <Card title={'Column 2'}>
                  <div style={{ display: "flex", padding: 20, marginTop: -30 }}>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => setData2([...data2, [{ value: null }, { value: null },]])}>Add</Button>
                    <Button style={{ marginLeft: 10 }} type='primary' icon={<MinusCircleTwoTone />} onClick={() => rdata2()}>Delete</Button>
                  </div>
                  <Spreadsheet className='seatMain' data={data2} onChange={(e) => onChangedata2(e)} />
                </Card>
              </Col>
              <Col span={8}>
                <Card title={'Column 3'}>
                  <div style={{ display: "flex", padding: 20, marginTop: -30 }}>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => setData3([...data3, [{ value: null }, { value: null },]])}>Add</Button>
                    <Button style={{ marginLeft: 10 }} type='primary' icon={<MinusCircleTwoTone />} onClick={() => rdata3()}>Delete</Button>
                  </div>
                  <Spreadsheet className='seatMain' data={data3} onChange={(e) => onChangedata3(e)} />
                </Card>
              </Col>
            </>
          }
          {number === 4 &&
            <>
              <Col span={6}>
                <Card title={'Column 1'}>
                  <div style={{ display: "flex", padding: 20, marginTop: -30 }}>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => setData1([...data1, [{ value: null }, { value: null },]])}>Add</Button>
                    <Button style={{ marginLeft: 10 }} type='primary' icon={<MinusCircleTwoTone />} onClick={() => rdata1()}>Delete</Button>
                  </div>
                  <Spreadsheet className='seatMain' data={data1} onChange={(e) => onChangedata1(e)} />
                </Card>
              </Col>
              <Col span={6}>
                <Card title={'Column 2'}>
                  <div style={{ display: "flex", padding: 20, marginTop: -30 }}>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => setData2([...data2, [{ value: null }, { value: null },]])}>Add</Button>
                    <Button style={{ marginLeft: 10 }} type='primary' icon={<MinusCircleTwoTone />} onClick={() => rdata2()}>Delete</Button>
                  </div>
                  <Spreadsheet className='seatMain' data={data2} onChange={(e) => onChangedata2(e)} />
                </Card>
              </Col>
              <Col span={6}>
                <Card title={'Column 3'}>
                  <div style={{ display: "flex", padding: 20, marginTop: -30 }}>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => setData3([...data3, [{ value: null }, { value: null },]])}>Add</Button>
                    <Button style={{ marginLeft: 10 }} type='primary' icon={<MinusCircleTwoTone />} onClick={() => rdata3()}>Delete</Button>
                  </div>
                  <Spreadsheet className='seatMain' data={data3} onChange={(e) => onChangedata3(e)} />
                </Card>
              </Col>
              <Col span={6}>
                <Card title={'Column 4'}>
                  <div style={{ display: "flex", padding: 20, marginTop: -30 }}>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => setData4([...data3, [{ value: null }, { value: null },]])}>Add</Button>
                    <Button style={{ marginLeft: 10 }} type='primary' icon={<MinusCircleTwoTone />} onClick={() => rdata4()}>Delete</Button>
                  </div>
                  <Spreadsheet className='seatMain' data={data4} onChange={(e) => onChangedata4(e)} />
                </Card>
              </Col>
            </>
          }
        </Row>
        <br />
        <ReactToPrint
          trigger={() => <Button type='primary' icon={<PrinterOutlined />}>Print</Button>}
          content={() => componentRef.current}
        />
        <ComponentToPrint ref={componentRef} data1={data1} data2={data2} data3={data3} data4={data4}  number={number} text={text} />

      </Card>

    </>
  )
}
