import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';

import { SelectLedgerOFPS } from '../../select/SelectLedgerOFPS';
import { SelectLedgerPayment } from '../../select/SelectLedgerPayment';
import { SelectLedgerRegularIncome } from '../../select/SelectLedgerRegularIncome';



export default function InventoryLedgerConfiguration(props) {

    const [form] = Form.useForm();


    const inventoryLedgerConfiguratioview = useStoreState((state) => state.inventory.inventoryLedgerConfigurationView);
    const fetchInventoryLedgerConfiguration = useStoreActions((state) => state.inventory.fetchInventoryLedgerConfigurationView);
    const saveInventoryLedgerConfiguration = useStoreActions((state) => state.inventory.saveInventoryLedgerConfiguraion);


    useEffect(() => {
      fetchInventoryLedgerConfiguration();
    }, [])

    const formSubmit = (value) => {
        let postData = {
          expenseLedgerId: value.expenseLedgerId,
          incomeLedgerId: value.incomeLedgerId,
        }
        saveInventoryLedgerConfiguration(postData);
    }
    return (
        <>
            <Card title="Inventory Ledger Configuration" >
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                        <Form
                            layout="vertical"
                            onFinish={formSubmit}
                            id="basic-info"
                            form={form}
                        >

                            <Card title="Save" >
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        name="expenseLedgerId"
                                        label="Expense Ledger"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select expense ledger" },
                                        ]}
                                    >

                                        <SelectLedgerPayment style={{ width: 250 }} />
                                    </Form.Item>

                                    <Form.Item
                                        name="incomeLedgerId"
                                        label="Income Ledger"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select income ledger" },
                                        ]}
                                    >

                                        <SelectLedgerRegularIncome style={{ width: 250 }} />
                                    </Form.Item>

                                    <Button type="primary" htmlType="submit" style={{ height: 40, marginLeft: 20 }} icon={<SaveOutlined />}>
                                        Save
                                    </Button>
                                </div>
                            </Card>
                        </Form>
                    </Col>
                    
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Card title="Configuration View" >
                            <div>
                                Inventory Income Ledger : {inventoryLedgerConfiguratioview?.incomeLedger} <br /> <br />
                                Inventory Expense Ledger : {inventoryLedgerConfiguratioview?.expenseLedger}
                            </div>
                        </Card>
                    </Col>
                </Row>


            </Card>
        </>
    )
}
