import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { defaultSignatureListUrl, deleteDefaultSignatureUrl, deleteMasterSettingUserUrl, fetchInstituteInfoUrl, masterSettingsUserList, saveDefaultSignatureUrl, saveMasterSettingUser, signatureImageUpdate, staffListUrl, updateInstituteInfoUrl, updateMasterSettingUserUrl, updatePhoto } from '../../../http/masterSettings/masterSettings';
import { fetchfindSingleApplicant, onlineAdmissionGeneralList, onlineAdmissionReportGroupByPaidUnpaid, onlineAdmissionReportPaidUnpaid, paymentStatusWiseAdmissionReport, sectionWiseAdmissionReport, updateApplicantInfo } from '../../../http/onlineAdmission/onlineAdmission';
import { smsSearchReportUrl, searchClassStudentIthAcademicYear, sectionDueListUrl, sendDueListSms, sendInstiuteSms, sendStaffSms, sendStudentSms, smsBlanceurl, smsTemplate, staffListForSendSms, staffSmsCount, templateSaveUrl, templateUpdateUrl } from '../../../http/sms/sms';
require("dotenv").config();

export interface OnlineAdmission{
    fetchSectionWiseOnlineAdmissionList: Thunk<OnlineAdmission>;
    setSectionWiseOnlineAdmissionList: Action<OnlineAdmission, any>;
    sectionWiseOnlineAdmissionList:any;

    fetchGroupWiseOnlineAdmissionList: Thunk<OnlineAdmission>;
    setGroupWiseOnlineAdmissionList: Action<OnlineAdmission, any>;
    groupWiseOnlineAdmissionList:any;

    fetchPaymentStatusWiseOnlineAdmissionList: Thunk<OnlineAdmission>;
    setPaymentStatusWiseOnlineAdmissionList: Action<OnlineAdmission, any>;
    paymentStatusWiseOnlineAdmissionList:any;



    fetchOnlineAdmissionReportPaidUnpaid: Thunk<OnlineAdmission>;
    setOnlineAdmissionReportPaidUnpaid: Action<OnlineAdmission, any>;
    onlineAdmissionReportPaidUnpaidList:any;

    fetchOnlineAdmissionReportGroupByPaidUnpaid: Thunk<OnlineAdmission>;
    setOnlineAdmissionReportGroupByPaidUnpaid: Action<OnlineAdmission, any>;
    onlineAdmissionReportGroupByPaidUnpaid:any;
    

    fetchOnlineAdmissionGeneralList: Thunk<OnlineAdmission>;
    setOnlineAdmissionGeneralList: Action<OnlineAdmission, any>;
    onlineAdmissionGeneralList:any;    
    
    fetchfindSingleApplicant: Thunk<OnlineAdmission, any>;
    setfindSingleApplicant: Action<OnlineAdmission, any>;
    findSingleApplicant:any;

    updateApplicantInfo: Thunk<OnlineAdmission, any>;

    loading: boolean;
    setLoading: Action<OnlineAdmission, boolean>;
}

export const onlineAdmissionStore: OnlineAdmission ={
    loading: false,
    setLoading: action((state, payload) => {
        state.loading = payload;
    }),
    sectionWiseOnlineAdmissionList:[],
    groupWiseOnlineAdmissionList:[],
    paymentStatusWiseOnlineAdmissionList:[],
    fetchSectionWiseOnlineAdmissionList:thunk(async (actions, payload) => {
        const response = await sectionWiseAdmissionReport(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body?.item.length > 0){
                actions.setSectionWiseOnlineAdmissionList(body.item);
            }else{
                actions.setSectionWiseOnlineAdmissionList([]);
                notification.error({ message: "No Student Found" })
            }
            
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setSectionWiseOnlineAdmissionList: action((state, payload) => {
        state.sectionWiseOnlineAdmissionList = payload;
    }),

    fetchGroupWiseOnlineAdmissionList:thunk(async (actions, payload) => {
        const response = await sectionWiseAdmissionReport(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body?.item.length > 0){
                actions.setGroupWiseOnlineAdmissionList(body.item);
            }else{
                actions.setGroupWiseOnlineAdmissionList([]);
                notification.error({ message: "No Student Found" })
            }
            
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setGroupWiseOnlineAdmissionList: action((state, payload) => {
        state.groupWiseOnlineAdmissionList = payload;
    }),

    fetchPaymentStatusWiseOnlineAdmissionList:thunk(async (actions, payload) => {
        const response = await paymentStatusWiseAdmissionReport(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body?.item.length > 0){
                actions.setPaymentStatusWiseOnlineAdmissionList(body.item);
            }else{
                actions.setPaymentStatusWiseOnlineAdmissionList([]);
                notification.error({ message: "No Student Found" })
            }
            
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setPaymentStatusWiseOnlineAdmissionList: action((state, payload) => {
        state.paymentStatusWiseOnlineAdmissionList = payload;
    }),



    onlineAdmissionReportPaidUnpaidList :[],

    fetchOnlineAdmissionReportPaidUnpaid:thunk(async (actions, payload) => {
        const response = await onlineAdmissionReportPaidUnpaid(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body?.item.length > 0){
                actions.setOnlineAdmissionReportPaidUnpaid(body.item);
            }else{
                actions.setOnlineAdmissionReportPaidUnpaid([]);
                notification.error({ message: "No Student Found" })
            }
            
        } else {
            actions.setOnlineAdmissionReportPaidUnpaid([]);
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    
    setOnlineAdmissionReportPaidUnpaid: action((state, payload) => {
        state.onlineAdmissionReportPaidUnpaidList = payload;
    }),


    onlineAdmissionReportGroupByPaidUnpaid :[],
    fetchOnlineAdmissionReportGroupByPaidUnpaid:thunk(async (actions, payload) => {
        const response = await onlineAdmissionReportGroupByPaidUnpaid(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body?.item.length > 0){
                actions.setOnlineAdmissionReportGroupByPaidUnpaid(body.item);
            }else{
                actions.setOnlineAdmissionReportGroupByPaidUnpaid([]);
                notification.error({ message: "No Student Found" })
            }
            
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setOnlineAdmissionReportGroupByPaidUnpaid: action((state, payload) => {
        state.onlineAdmissionReportGroupByPaidUnpaid = payload;
    }),

    
    onlineAdmissionGeneralList :[],
    fetchOnlineAdmissionGeneralList:thunk(async (actions, payload) => {
        const response = await onlineAdmissionGeneralList(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body?.item.length > 0){
                actions.setOnlineAdmissionGeneralList(body.item);
            }else{
                actions.setOnlineAdmissionGeneralList([]);
                notification.error({ message: "No Student Found" })
            }
            
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setOnlineAdmissionGeneralList: action((state, payload) => {
        state.onlineAdmissionGeneralList = payload;
    }),

    findSingleApplicant :null,
    fetchfindSingleApplicant:thunk(async (actions, payload) => {
        actions.setfindSingleApplicant(null);
        actions.setLoading(true);
        const response = await fetchfindSingleApplicant(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            if (body.messageType == 1) {
                actions.setfindSingleApplicant(body.item);
            } else{
                actions.setfindSingleApplicant(null);
                notification.error({ message: "No Student Found" })
            }
            
        } else {
            actions.setLoading(false);
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setfindSingleApplicant: action((state, payload) => {
        state.findSingleApplicant = payload;
    }),

    updateApplicantInfo: thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await updateApplicantInfo(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }

        } else {
            actions.setLoading(false);
            notification.error({ message: "Somethng went error" })
        }
    }),
}