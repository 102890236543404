
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton, Table, Avatar } from 'antd'
import { DeleteOutlined, DownloadOutlined, EyeInvisibleOutlined, EyeOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';
import TableView from '../../../../contents/AntTableResponsive';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { Tabs } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import $ from 'jquery';

const { Option } = Select;
export default function StudentProfileReport() {

    const { TabPane } = Tabs;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [classWiseReportForm] = Form.useForm();
    const fetchStudentOverviewReport = useStoreActions((state) => state.student.fetchStudentOverviewReport);
    const studentOverviewListReport = useStoreState((state) => state.student.studentOverviewListReport);
    const fetchstudentAllInfo = useStoreActions((state) => state.masterSettings.fetchstudentAllInfo);
    const studentAllInfo = useStoreState(state => state.masterSettings.studentAllInfo)
    const instituteInfo = useStoreState(state => state.auth.instituteInfo)
    const [modalHeader, setModalHeader] = useState<any>();
    const [currentYear, setCurrentYear] = useState<any>();

    const loading = useStoreState((state) => state.student.loading);

    const formSubmit = (value) => {
        let url = ('academicYear='+value?.academicYear )+ ( value?.classconfigid!==undefined?'&classconfigid='+value?.classconfigid:'') + (value?.classid!==undefined?'&classid='+value?.classid:'') + (value?.fatherOccupation!==undefined?'&fatherOccupation='+value?.fatherOccupation:'') + (value?.motherOccupation!==undefined?'&motherOccupation='+value?.motherOccupation:'')
        fetchStudentOverviewReport(url);
    }
    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Gender', dataIndex: 'studentGender', key: 'studentGender', showOnResponse: true, showOnDesktop: true },
        { title: 'Religion', dataIndex: 'studentReligion', key: 'studentReligion', showOnResponse: true, showOnDesktop: true },
        { title: 'Date of Birth', dataIndex: 'studentDOB', key: 'studentDOB', showOnResponse: true, showOnDesktop: true },
        { title: 'Blood Group', dataIndex: 'bloodGroup', key: 'bloodGroup', showOnResponse: true, showOnDesktop: true },
        { title: 'Birth Certificate', dataIndex: 'birthCertificateNo', key: 'birthCertificateNo', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        { title: 'Class', dataIndex: 'className', key: 'className', showOnResponse: true, showOnDesktop: true },
        { title: 'Shift', dataIndex: 'shiftName', key: 'shiftName', showOnResponse: true, showOnDesktop: true },
        { title: 'Section', dataIndex: 'sectionName', key: 'sectionName', showOnResponse: true, showOnDesktop: true },
        { title: 'Group', dataIndex: 'groupName', key: 'groupName', showOnResponse: true, showOnDesktop: true },
        { title: 'Class Config', dataIndex: 'classConfigName', key: 'classConfigName', showOnResponse: true, showOnDesktop: true },
        { title: 'Category', dataIndex: 'studentCategoryName', key: 'studentCategoryName', showOnResponse: true, showOnDesktop: true },
        { title: 'Father Name', dataIndex: 'fatherName', key: 'fatherName', showOnResponse: true, showOnDesktop: true },
        { title: 'Father Mobile', dataIndex: 'fatherMobile', key: 'fatherMobile', showOnResponse: true, showOnDesktop: true },
        { title: 'Father NID', dataIndex: 'fatherNid', key: 'fatherNid', showOnResponse: true, showOnDesktop: true },
        { title: 'Father Occupation', dataIndex: 'fatherOccupation', key: 'fatherOccupation', showOnResponse: true, showOnDesktop: true },
        { title: 'Father Working Place', dataIndex: 'fatherWorkingPlace', key: 'fatherWorkingPlace', showOnResponse: true, showOnDesktop: true },
        { title: 'Father Designation', dataIndex: 'fatherDesignation', key: 'fatherDesignation', showOnResponse: true, showOnDesktop: true },
        { title: 'Mother Name', dataIndex: 'motherName', key: 'motherName', showOnResponse: true, showOnDesktop: true },
        { title: 'Mother Mobile', dataIndex: 'motherMobile', key: 'motherMobile', showOnResponse: true, showOnDesktop: true },
        { title: 'Mother NID', dataIndex: 'motherNid', key: 'motherNid', showOnResponse: true, showOnDesktop: true },
        { title: 'Mother Occupation', dataIndex: 'motherOccupation', key: 'motherOccupation', showOnResponse: true, showOnDesktop: true },
        { title: 'Mother Working Place', dataIndex: 'motherWorkingPlace', key: 'motherWorkingPlace', showOnResponse: true, showOnDesktop: true },
        { title: 'Mother Designation', dataIndex: 'motherDesignation', key: 'motherDesignation', showOnResponse: true, showOnDesktop: true },
        { title: 'Guardian Mobile', dataIndex: 'guardianMobile', key: 'guardianMobile', showOnResponse: true, showOnDesktop: true },
        { title: 'Mailing Address', dataIndex: 'mailingAddress', key: 'mailingAddress', showOnResponse: true, showOnDesktop: true },
        { title: 'Present Village', dataIndex: 'presentVillege', key: 'presentVillege', showOnResponse: true, showOnDesktop: true },
        { title: 'Present Post Office', dataIndex: 'presentPostOffice', key: 'presentPostOffice', showOnResponse: true, showOnDesktop: true },
        { title: 'Present Thana', dataIndex: 'presentThanaName', key: 'presentThanaName', showOnResponse: true, showOnDesktop: true },
        { title: 'Present District', dataIndex: 'presentDistrictName', key: 'presentDistrictName', showOnResponse: true, showOnDesktop: true },
        { title: 'Pernanent Village', dataIndex: 'permanentVillege', key: 'permanentVillege', showOnResponse: true, showOnDesktop: true },
        { title: 'Permanent Post Office', dataIndex: 'permanentPostOffice', key: 'permanentPostOffice', showOnResponse: true, showOnDesktop: true },
        { title: 'Permanent Thana', dataIndex: 'permanentThanaName', key: 'permanentThanaName', showOnResponse: true, showOnDesktop: true },
        { title: 'Permanent District', dataIndex: 'permanentDistrictName', key: 'permanentDistrictName', showOnResponse: true, showOnDesktop: true },
        {
            title: 'Action', dataIndex: 'fatherName', key: 'fatherName', fixed: 'right', showOnResponse: true, showOnDesktop: true, render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Button type='primary' icon={<EyeOutlined />} onClick={() => {
                        setIsModalVisible(true);
                        setModalHeader(record.studentName);
                        fetchstudentAllInfo({
                            customStudentId: record?.customStudentId,
                            instituteId: instituteInfo?.instiltuteId,
                        })
                    }} />
                </Space>
            ),
        },
    ];



    return (
        <>
            <style>
                {`
                .ant-table-cell-fix-left, .ant-table-cell-fix-right {
                    position: -webkit-sticky !important;
                    position: sticky !important;
                    z-index: 0 !important;
                    background: #fff;
                }
                `}
            </style>
            <Card title="Student Profile">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={classWiseReportForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear onChange={(e) => setCurrentYear(e)} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="classid"
                                        label="Select Class"
                                        className="title-Text"
                                       
                                    // rules={[
                                    //     { required: true, message: "Please select class" },
                                    // ]}
                                    >
                                        <SelectClass />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="classconfigid"
                                        label="Select Section"
                                        className="title-Text"
                                        
                                    // rules={[
                                    //     { required: true, message: "Please select section" },
                                    // ]}
                                    >
                                        <SelectClassConfig />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}></Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="fatherOccupation"
                                        label="Father Occupation"
                                        className="title-Text"
                                       
                                    >
                                        <Select placeholder="Select Father Occupation"  allowClear>
                                            <Option value="Business">Business</Option>
                                            <Option value="Service">Service</Option>
                                            <Option value="Private Service">Private Service</Option>
                                            <Option value="Govt. Service">Govt. Service</Option>
                                            <Option value="Teacher">Teacher</Option>
                                            <Option value="Banker">Banker</Option>
                                            <Option value="Doctor">Doctor</Option>
                                            <Option value="Engineer">Engineer</Option>
                                            <Option value="Lawyer">Lawyer</Option>
                                            <Option value="Journalist">Journalist</Option>
                                            <Option value="Farmer">Farmer</Option>
                                            <Option value="Driver">Driver</Option>
                                            <Option value="Phycian">Phycian</Option>
                                            <Option value="Student">Student</Option>
                                            <Option value="Army">Army</Option>
                                            <Option value="Police Officer">Police Officer</Option>
                                            <Option value="Navy">Navy</Option>
                                            <Option value="Airforce">Airforce</Option>
                                            <Option value="Retired Person">Retired Person</Option>
                                            <Option value="NRB">NRB</Option>
                                            <Option value="BGB">BGB</Option>
                                            <Option value="N/A">N/A</Option>
                                            <Option value="NSI">NSI</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>                               
                                 <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="motherOccupation"
                                        label="Mother Occupation"
                                        className="title-Text"
                                       
                                    >
                                        <Select placeholder="Select Mother Occupation" allowClear >
                                            <Option value="Business">Business</Option>
                                            <Option value="Service">Service</Option>
                                            <Option value="Private Service">Private Service</Option>
                                            <Option value="Govt. Service">Govt. Service</Option>
                                            <Option value="Teacher">Teacher</Option>
                                            <Option value="Banker">Banker</Option>
                                            <Option value="Doctor">Doctor</Option>
                                            <Option value="Engineer">Engineer</Option>
                                            <Option value="Lawyer">Lawyer</Option>
                                            <Option value="Journalist">Journalist</Option>
                                            <Option value="Farmer">Farmer</Option>
                                            <Option value="Driver">Driver</Option>
                                            <Option value="Phycian">Phycian</Option>
                                            <Option value="Student">Student</Option>
                                            <Option value="Army">Army</Option>
                                            <Option value="Police Officer">Police Officer</Option>
                                            <Option value="Navy">Navy</Option>
                                            <Option value="Airforce">Airforce</Option>
                                            <Option value="Retired Person">Retired Person</Option>
                                            <Option value="NRB">NRB</Option>
                                            <Option value="BGB">BGB</Option>
                                            <Option value="N/A">N/A</Option>
                                            <Option value="NSI">NSI</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: studentOverviewListReport.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: studentListColumn,
                                rowKey: "identificationId",
                                dataSource: studentOverviewListReport,
                                filterData: studentOverviewListReport,
                                pagination: true,
                                bordered: true,
                            }}
                            mobileBreakPoint={768}
                        />
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <Space size={'large'} style={{ float: "right" }}>
                            < Button
                                type='primary'
                                style={{
                                    marginBottom: 20,
                                    marginRight: 20,
                                }}
                                icon={<FileExcelOutlined />}
                                onClick={() => {
                                    const excel: any = new Excel();
                                    excel
                                        .addSheet(`${$(".yearSelect").text()}__${$(".sectionSelect").text()}`)
                                        .addColumns(studentListColumn.slice(0, studentListColumn?.length - 1))
                                        .addDataSource(studentOverviewListReport)
                                        .saveAs(`Student Profile of ${$(".sectionSelect").text()}__${$(".yearSelect").text()}.xlsx`);
                                }}
                            >
                                Download Excel
                            </ Button >
                        </Space>
                    </Col>
                </Row>
            </Card>
            <Modal
                title={modalHeader}
                visible={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                okText="Ok"
                centered
                maskClosable={false}
                width="70%"
                destroyOnClose={true}
            >

                <Row>
                    <Col span={24}>


                        <Tabs tabPosition="left">
                            <TabPane tab="Basic Information" key="1">
                                <Row>
                                    <Col span={24}>
                                        <Form
                                            name="basic"
                                            labelCol={{ span: 10 }}
                                            wrapperCol={{ span: 14 }}
                                            autoComplete="off"
                                        >
                                            <Form.Item
                                                label="Name"
                                                name="studentName"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.studentName}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Gender"
                                                name="studentGender"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.studentGender}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Religion"
                                                name="studentReligion"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.studentReligion}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Birthday"
                                                name="studentDOB"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.studentDOB}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Blood Group"
                                                name="bloodGroup"
                                                labelAlign="left"


                                            >
                                                <span>{studentAllInfo?.bloodGroup}</span>
                                            </Form.Item>
                                            <Form.Item
                                                label="Birth Certificate No"
                                                name="birthCertificateNo"
                                                labelAlign="left"


                                            >
                                                <span>{studentAllInfo?.birthCertificateNo}</span>
                                            </Form.Item>



                                            <div>
                                                <Row gutter={8}>
                                                    <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                                                        <span>Student Photo: </span>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                                                        : <Avatar
                                                            src={'data:image/png;base64,' + studentAllInfo?.imageName}
                                                            size={'large'}
                                                            style={{ marginRight: 5 }}
                                                            className="pointer topUserImage"
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>

                            </TabPane>

                            <TabPane tab="Guardian Information" key="2">
                                <Row>
                                    <Col span={24}>
                                        <Form
                                            name="guardianInfo"
                                            labelCol={{ span: 10 }}
                                            wrapperCol={{ span: 14 }}

                                            autoComplete="off"
                                        >
                                            <Form.Item
                                                label="Father's Name"
                                                name="fatherName"
                                                labelAlign="left"
                                            >
                                                <span>{studentAllInfo?.fatherName}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Father's Profession"
                                                name="fatherOccupation"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.fatherOccupation}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Father's Designation"
                                                name="fatherDesignation"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.fatherDesignation}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Father's Workplace"
                                                name="fatherWorkingPlace"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.fatherWorkingPlace}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Father's NID"
                                                name="fatherNid"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.fatherNid}</span>
                                            </Form.Item>

                                            <div>
                                                <Row gutter={8}>
                                                    <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                                                        <span>Father's Photo: </span>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                                                        <Avatar
                                                            src={'data:image/png;base64,' + studentAllInfo?.fatherPhoto}
                                                            size={'large'}
                                                            style={{ marginRight: 5 }}
                                                            className="pointer topUserImage"
                                                        />
                                                    </Col>
                                                </Row>
                                                <br />
                                            </div>

                                            <Form.Item
                                                label="Mother's Name"
                                                name="motherName"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.motherName}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Mother's Profession"
                                                name="motherOccupation"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.motherOccupation}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Mother's Designation"
                                                name="motherDesignation"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.motherDesignation}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Mother's Workplace"
                                                name="motherWorkingPlace"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.motherWorkingPlace}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Mother's NID"
                                                name="motherNid"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.motherNid}</span>
                                            </Form.Item>

                                            <div>
                                                <Row gutter={8}>
                                                    <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                                                        <span>Mother's Photo: </span>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                                                        <Avatar
                                                            src={'data:image/png;base64,' + studentAllInfo?.motherPhoto}
                                                            size={'large'}
                                                            style={{ marginRight: 5 }}
                                                            className="pointer topUserImage"
                                                        />
                                                    </Col>
                                                </Row>
                                                <br />
                                            </div>

                                            <Form.Item
                                                label="Guardian's Number"
                                                name="guardianMobile"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.guardianMobile}</span>
                                            </Form.Item>

                                        </Form>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Address Information" key="3">
                                <Row>
                                    <Col span={24}>
                                        <Form
                                            name="address"
                                            labelCol={{ span: 10 }}
                                            wrapperCol={{ span: 14 }}
                                            autoComplete="off"
                                        >
                                            <Form.Item
                                                label="Mailing Address"
                                                name="mailingAddress"
                                                labelAlign="left"
                                            >
                                                <span>{studentAllInfo?.mailingAddress}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Present Village"
                                                name="presentVillege"
                                                labelAlign="left"
                                            >
                                                <span>{studentAllInfo?.presentVillege}</span>
                                            </Form.Item>
                                            <Form.Item
                                                label="Present Post Office"
                                                name="presentPostOffice"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.presentPostOffice}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Present District"
                                                name="presentPostOffice"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.presentDistrictName}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Present Thana"
                                                name="presentThanaId"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.presentThanaName}</span>
                                            </Form.Item>
                                            <Form.Item
                                                label="Permenant Village"
                                                name="permanentVillege"
                                                labelAlign="left"
                                            >
                                                <span>{studentAllInfo?.permanentVillege}</span>
                                            </Form.Item>
                                            <Form.Item
                                                label="Permenant Post Office"
                                                name="permanentPostOffice"
                                                labelAlign="left"
                                            >
                                                <span>{studentAllInfo?.permanentPostOffice}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Permenant District"
                                                name="presentPostOffice"
                                                labelAlign="left"
                                            >
                                                <span>{studentAllInfo?.permanentDistrictName}</span>
                                            </Form.Item>

                                            <Form.Item
                                                label="Permenant Thana"
                                                name="permanentThanaId"
                                                labelAlign="left"

                                            >
                                                <span>{studentAllInfo?.permanentThanaName}</span>
                                            </Form.Item>


                                        </Form>
                                    </Col>
                                </Row>
                            </TabPane>
                        </Tabs>

                    </Col>
                </Row>

            </Modal>
        </>
    )
}