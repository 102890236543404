import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag } from 'antd';
import TableView from '../../../../contents/AntTableResponsive';
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';


export default function ExamConfiguration(props) {

  const { Option } = Select;
  const [examConfigurationForm] = Form.useForm();
  const [updateExamConfigurationForm] = Form.useForm();
  //
  const examList = useStoreState((state) => state.exam.examList);
  const fetchExamList = useStoreActions((state) => state.exam.fetchExamList);
  const fetchExamfigurationList = useStoreActions((state) => state.exam.fetchExamfigurationList);
  const examConfigureList = useStoreState((state) => state.exam.examConfigureList);
  const [examonfigurationListData, setExamConfigurationListData] = useState<any>();
  const fetchShortCodeClassList = useStoreActions((state) => state.exam.fetchShortCodeClassList);
  const shortCodeClasslist = useStoreState((state) => state.exam.shortCodeClasslist);
  const createExamConfiguration = useStoreActions((state) => state.exam.createExamConfiguration);
  const updateExamConfigurationMethod = useStoreActions((state) => state.exam.updateExamConfigurationMethod);
  const [classListValue, setClassListValue] = useState<any>("");
  const [meritListValue, setMeritListValue] = useState<any>("");
  const [examConfigId, setExamConfigId] = useState<any>("");
  const [examDefaultId, setexamDefaultId] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [chooseSubjectValue, setChooseSubjectValue] = useState<any>([]);
  const loading = useStoreState((state) => state.exam.loading);
  /////////



  useEffect(() => {
    fetchExamList();
    fetchExamfigurationList()
    fetchShortCodeClassList()
    setExamConfigurationListData(examConfigureList)
  }, []);

  const onChangeClassList = (val) => {
    setClassListValue(val);
  }

  const onChangeMeritList = (val) => {
    setMeritListValue(val);
  }



  const examConfigurationListColumn = [
    { title: 'Class', dataIndex: 'className', key: 'examConfigId', showOnResponse: true, showOnDesktop: true },
    { title: 'Exam', dataIndex: 'examName', key: 'examConfigId', showOnResponse: true, showOnDesktop: true },
    { title: 'Merit Process Type', dataIndex: 'meritProcessTypeName', key: 'examConfigId', showOnResponse: true, showOnDesktop: true },
    {
      title: 'Action', dataIndex: 'gradeRange', key: 'examConfigId', showOnResponse: true, showOnDesktop: true,
      render: (text, record, index) => (
        <Tooltip title="Edit">
          <Button type='primary' icon={<EditOutlined />} onClick={() => {
            setIsModalVisible(true);
            setExamConfigId(record.examConfigId);
            setexamDefaultId(record.examDefaultId);
            updateExamConfigurationForm.setFieldsValue({
              className: record.className,
              examName: record.examName,
              meritSelect: record.meritProcessType,
              grandFinalProcessType: record.grandFinalProcessType,
            });
          }} />
        </Tooltip>
      ),
    },

  ];

  const chooseExam = (val, e) => {
    if (e.target.checked == true) {
      chooseSubjectValue.push(val);
    } else {
      const index = chooseSubjectValue.indexOf(5);
      chooseSubjectValue.splice(index, 1);
    }
  }

  const saveExamConfiguretion = (val) => {
    console.log('chooseSubjectValue', chooseSubjectValue);
    let postData = {
      classId: val.classList,
      meritProcessType: val.meritSelect,
      examIds: chooseSubjectValue,
    }
    createExamConfiguration(postData);
  }

  const updateExamConfiguration = (val) => {
   
    let updateData = {
      examConfigId: examConfigId,
      grandFinalProcessType: val.grandFinalProcessType===undefined?null:val.grandFinalProcessType,
      meritPositionProcessType: val.meritSelect,
    }

    updateExamConfigurationMethod(updateData);
    setIsModalVisible(false);
    setexamDefaultId(null)
    updateExamConfigurationForm.resetFields();
  }

  return (
    <>
      <Row>
        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 10 }}>
          <Form
            layout="vertical"
            id="classConfigInfo"
            onFinish={saveExamConfiguretion}
            form={examConfigurationForm}
          >
            <Card className="p-0" title="Exam Configure" bordered={false}>
              <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                  <Form.Item
                    name="classList"
                    label="Class Name"
                    className="title-Text"
                    rules={[
                      { required: true, message: "Select class list" },
                    ]}
                  >
                    <Select placeholder="Class List" style={{ width: "100%" }} onChange={(val) => onChangeClassList(val)}>
                      {shortCodeClasslist ? (
                        shortCodeClasslist.map((type, idx) => (
                          <Option key={type.id} value={type.id}>
                            {type.name}
                          </Option>
                        ))
                      ) : (
                        <Option value="fetching">Fetching Class</Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                  <Form.Item
                    name="meritSelect"
                    label="Merit Process"
                    className="title-Text"
                    rules={[
                      { required: true, message: "Select Merit Process Type" },
                    ]}
                  >
                    <Select placeholder="Merit Process Type" style={{ width: "100%" }} onChange={(val) => onChangeMeritList(val)}>
                      <Option value={1}>Total Mark (Sequential)</Option>
                      <Option value={2}>Total Mark (Non Sequential)</Option>
                      <Option value={3}>Grade Point (Sequential)</Option>
                      <Option value={4}>Grade Point (Non Sequential)</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                  <Row>
                    {examList ? (
                      examList.map((type, idx) => (
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 24 }}>
                          <Checkbox value={type.id} onChange={(e) => chooseExam(type.id, e)}>{type.name}</Checkbox>
                        </Col>
                      ))
                    ) : ''}

                  </Row>
                </Col>
                <Col md={{ span: 24 }}>
                  <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className="float-right" style={{ float: "right" }}>Save</Button>
                </Col>
              </Row>
            </Card>

          </Form>
        </Col>
        <Skeleton loading={loading} paragraph={{ rows: 10 }} />
        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 14 }}>
          <Card className="p-0" title="Exam Configuration List" bordered={false}>
            <TableView
              antTableProps={{
                showHeader: true,
                columns: examConfigurationListColumn,
                rowKey: "subjectSerial",
                dataSource: examConfigureList,
                filterData: examConfigureList,
                pagination: true,
                bordered: true
              }}
              mobileBreakPoint={768}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="Class Edit"
        visible={isModalVisible}
        //  onOk={handleOk}
        okButtonProps={{ form: 'update', htmlType: 'submit' }}
        onCancel={() => {setIsModalVisible(false); setexamDefaultId(null)}}
        cancelText="Close"
        okText="Update"
        centered
      >
        <Form
          layout="vertical"
          id="update"
          onFinish={updateExamConfiguration}
          form={updateExamConfigurationForm}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="className"
                label="Class Name"
                className="title-Text"
                rules={[
                  { required: true, message: "Please write class name" },
                ]}
              >
                <Input placeholder="Enter class name" disabled={true} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="examName"
                label="Exam Name"
                className="title-Text"
                rules={[
                  { required: true, message: "Please write exam Name" },
                ]}
              >
                <Input placeholder="Enter exam Name" disabled={true} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="meritSelect"
                label="Merit Position Type"
                className="title-Text"

                rules={[
                  { required: true, message: "Select Merit Process Type" },
                ]}
              >
                <Select placeholder="Merit Process Type" style={{ width: "100%" }} >
                  <Option value={1}>Total Mark (Sequential)</Option>
                  <Option value={2}>Total Mark (Non Sequential)</Option>
                  <Option value={3}>Grade Point (Sequential)</Option>
                  <Option value={4}>Grade Point (Non Sequential)</Option>
                </Select>
              </Form.Item>
            </Col>
            {examDefaultId === 99 &&
              <Col span={24}>
                <Form.Item
                  name="grandFinalProcessType"
                  label="Grand Final Process Type"
                  className="title-Text"

                >
                  <Select placeholder="Grand Final Process Type" style={{ width: "100%" }} >
                    <Option value={1}>Type 1</Option>
                    <Option value={2}>Type 2</Option>
                    <Option value={3}>Type 3</Option>
                    <Option value={4}>Type 4</Option>
                  </Select>
                </Form.Item>
              </Col>
            }
          </Row>
        </Form>
      </Modal>
    </>
  )

}
