import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton, notification } from 'antd'
import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Radio } from 'antd';
import { SelectSanmasikClass } from '../../../select/SelectSanmasikClass';
import { SelectSanmasikSubjectbyClassConfig } from '../../../select/SelectSanmasikSubjectbyClassConfig';
import { SelectPIMultiplebyChapter } from '../../../select/SelectPIMultiplebyChapter';
import { SelectAcademicYear2 } from '../../../select/SelectAcademicYear2';
import { SelectClassConfig2 } from '../../../select/SelectClassConfig2';
import { SelectSanmasikChpater } from '../../../select/SelectSanmasikChpater';


export default function SanMasikPiInput(props) {

    const saveSanmasikStudentPiInput = useStoreActions((state) => state.customerSupport.saveSanmasikStudentPiInput);
    const fetchsanmasikSubjectListbyClassConfig = useStoreActions((state) => state.customerSupport.fetchsanmasikSubjectListbyClassConfig);
    const fetchpiInfoListbyChapter = useStoreActions((state) => state.customerSupport.fetchpiInfoListbyChapter);
    const fetchchapterInfoList = useStoreActions((state) => state.customerSupport.fetchchapterInfoList);
    const fetchstudentListForSavePiInfo = useStoreActions((state) => state.customerSupport.fetchstudentListForSavePiInfo);
    const chapterInfoList = useStoreState((state) => state.customerSupport.chapterInfoList);
    const studentListForSavePiInfo = useStoreState((state) => state.customerSupport.studentListForSavePiInfo);
    const studentListForUpdatePiInfo = useStoreActions((state) => state.customerSupport.studentListForUpdatePiInfo);
    const deleteChapterInfo = useStoreActions((state) => state.customerSupport.deleteChapterInfo);
    const [sanmasikForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const loading = useStoreState((state) => state.customerSupport.loading2);

    const [tableData, setTableData] = useState<any>([])

    useEffect(() => {
        setTableData(studentListForSavePiInfo?.studentList)
    }, [studentListForSavePiInfo])

    const sanmasikClassForm = (value) => {
        let postData = {
            "academicYear": value.academicYear,
            "chapterId": chapterId,
            "classConfigurationId": sectionId,
            "piIds": value.piIds,
            "sanmasikSubjectId": subjectId
        }
        fetchstudentListForSavePiInfo(postData);
    }


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [updateId, setupdateId] = useState<any>();

    const updateExamForm = (value) => {
        let updatePostData = {
            chapterId: updateId,
            lessonNo: value.lessonNo,
            chapterNo: value.chapterNo,
            chapterName: value.chapterName,
            pageNo: value.pageNo,
        }
        studentListForUpdatePiInfo(updatePostData);
        setIsModalVisible(false);
    }
    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const columns = [
        {
            title: 'Id',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true
        },
        ...(studentListForSavePiInfo?.piNo1 !== '') ?
            [
                {
                    title: <div style={{ textAlign: "center" }}>
                        <strong>{studentListForSavePiInfo?.piNo1}</strong>
                        <br />
                        <Radio.Group name="radiogroup" value={null} onChange={e => { onchangeRadioAll(e.target.value, studentListForSavePiInfo?.piId1, 0) }}>
                            <Radio value={"ractangle"}>◻</Radio>
                            <Radio value={"circle"}>◯</Radio>
                            <Radio value={"triangle"}>△</Radio>
                        </Radio.Group>
                    </div>,
                    dataIndex: 'identificationId',
                    key: 'identificationId',
                    showOnResponse: true,
                    showOnDesktop: true,
                    render: (text: any, record: any, index) => {
                        if (record?.marks?.length > 0 && record?.marks[0]?.piId!==undefined) {
                            let val = record?.marks?.find(item => item?.piId === studentListForSavePiInfo?.piId1)
                            return <Radio.Group name="radiogroup" value={val?.studentAssessment} onChange={e => { onchangeRadioSingleExist(e.target.value, record?.marks, studentListForSavePiInfo?.piId1, index) }}>
                                <Radio value={"ractangle"}>◻</Radio>
                                <Radio value={"circle"}>◯</Radio>
                                <Radio value={"triangle"}>△</Radio>
                            </Radio.Group>
                        } else return <Radio.Group name="radiogroup" value={null} onChange={e => { onchangeRadioSingle(e.target.value, studentListForSavePiInfo?.piId1, index, 0) }}>
                            <Radio value={"ractangle"}>◻</Radio>
                            <Radio value={"circle"}>◯</Radio>
                            <Radio value={"triangle"}>△</Radio>
                        </Radio.Group>


                    }
                }]
            : [],
        ...(studentListForSavePiInfo?.piNo2 !== '') ?
            [
                {
                    title: <div style={{ textAlign: "center" }}>
                        <strong>{studentListForSavePiInfo?.piNo2}</strong>
                        <br />
                        <Radio.Group name="radiogroup" value={null} onChange={e => { onchangeRadioAll(e.target.value, studentListForSavePiInfo?.piId2, 1) }}>
                            <Radio value={"ractangle"}>◻</Radio>
                            <Radio value={"circle"}>◯</Radio>
                            <Radio value={"triangle"}>△</Radio>
                        </Radio.Group>
                    </div>,
                    dataIndex: 'identificationId',
                    key: 'identificationId',
                    showOnResponse: true,
                    showOnDesktop: true,
                    render: (text: any, record: any, index) => {
                        if (record?.marks?.length > 1&& record?.marks[1]?.piId!==undefined) {
                            let val = record?.marks?.find(item => item?.piId === studentListForSavePiInfo?.piId2)
                            return <Radio.Group name="radiogroup" value={val?.studentAssessment} onChange={e => { onchangeRadioSingleExist(e.target.value, record?.marks, studentListForSavePiInfo?.piId2, index) }}>
                                <Radio value={"ractangle"}>◻</Radio>
                                <Radio value={"circle"}>◯</Radio>
                                <Radio value={"triangle"}>△</Radio>
                            </Radio.Group>
                        } else return <Radio.Group name="radiogroup" value={null} onChange={e => { onchangeRadioSingle(e.target.value, studentListForSavePiInfo?.piId2, index, 1) }}>
                            <Radio value={"ractangle"}>◻</Radio>
                            <Radio value={"circle"}>◯</Radio>
                            <Radio value={"triangle"}>△</Radio>
                        </Radio.Group>
                    }
                }]
            : [],

        ...(studentListForSavePiInfo?.piNo3 !== '') ?
            [
                {
                    title: <div style={{ textAlign: "center" }}>
                        <strong>{studentListForSavePiInfo?.piNo3}</strong>
                        <br />
                        <Radio.Group name="radiogroup" value={null} onChange={e => { onchangeRadioAll(e.target.value, studentListForSavePiInfo?.piId3, 2) }}>
                            <Radio value={"ractangle"}>◻</Radio>
                            <Radio value={"circle"}>◯</Radio>
                            <Radio value={"triangle"}>△</Radio>
                        </Radio.Group>
                    </div>,
                    dataIndex: 'identificationId',
                    key: 'identificationId',
                    showOnResponse: true,
                    showOnDesktop: true,
                    render: (text: any, record: any, index) => {
                        if (record?.marks?.length > 2 && record?.marks[2]?.piId!==undefined) {
                            let val = record?.marks?.find(item => item?.piId === studentListForSavePiInfo?.piId3)
                            return <Radio.Group name="radiogroup" value={val?.studentAssessment} onChange={e => { onchangeRadioSingleExist(e.target.value, record?.marks, studentListForSavePiInfo?.piId3, index) }}>
                                <Radio value={"ractangle"}>◻</Radio>
                                <Radio value={"circle"}>◯</Radio>
                                <Radio value={"triangle"}>△</Radio>
                            </Radio.Group>
                        } else return <Radio.Group name="radiogroup" value={null} onChange={e => { onchangeRadioSingle(e.target.value, studentListForSavePiInfo?.piId3, index, 2) }}>
                            <Radio value={"ractangle"}>◻</Radio>
                            <Radio value={"circle"}>◯</Radio>
                            <Radio value={"triangle"}>△</Radio>
                        </Radio.Group>
                    }
                }]
            : [],
        ...(studentListForSavePiInfo?.piNo4 !== '') ?
            [
                {
                    title: <div style={{ textAlign: "center" }}>
                        <strong>{studentListForSavePiInfo?.piNo4 }</strong>
                        <br />
                        <Radio.Group name="radiogroup" value={null} onChange={e => { onchangeRadioAll(e.target.value, studentListForSavePiInfo?.piId4, 3) }}>
                            <Radio value={"ractangle"}>◻</Radio>
                            <Radio value={"circle"}>◯</Radio>
                            <Radio value={"triangle"}>△</Radio>
                        </Radio.Group>
                    </div>,
                    dataIndex: 'identificationId',
                    key: 'identificationId',
                    showOnResponse: true,
                    showOnDesktop: true,
                    render: (text: any, record: any, index) => {
                        if (record?.marks?.length > 3 && record?.marks[3]?.piId!==undefined) {
                            let val = record?.marks?.find(item => item?.piId === studentListForSavePiInfo?.piId4)
                            return <Radio.Group name="radiogroup" value={val?.studentAssessment} onChange={e => { onchangeRadioSingleExist(e.target.value, record?.marks, studentListForSavePiInfo?.piId4, index) }}>
                                <Radio value={"ractangle"}>◻</Radio>
                                <Radio value={"circle"}>◯</Radio>
                                <Radio value={"triangle"}>△</Radio>
                            </Radio.Group>
                        } else return <Radio.Group name="radiogroup" value={null} onChange={e => { onchangeRadioSingle(e.target.value, studentListForSavePiInfo?.piId4, index, 3) }}>
                            <Radio value={"ractangle"}>◻</Radio>
                            <Radio value={"circle"}>◯</Radio>
                            <Radio value={"triangle"}>△</Radio>
                        </Radio.Group>
                    }
                }]
            : [],
        ...(studentListForSavePiInfo?.piNo5 !== '') ?
            [
                {
                    title: <div style={{ textAlign: "center" }}>
                        <strong>{studentListForSavePiInfo?.piNo5}</strong>
                        <br />
                        <Radio.Group name="radiogroup" value={null} onChange={e => { onchangeRadioAll(e.target.value, studentListForSavePiInfo?.piId5, 4) }}>
                            <Radio value={"ractangle"}>◻</Radio>
                            <Radio value={"circle"}>◯</Radio>
                            <Radio value={"triangle"}>△</Radio>
                        </Radio.Group>
                    </div>,
                    dataIndex: 'identificationId',
                    key: 'identificationId',
                    showOnResponse: true,
                    showOnDesktop: true,
                    render: (text: any, record: any, index) => {
                        if (record?.marks?.length > 4 && record?.marks[4]?.piId!==undefined) {
                            let val = record?.marks?.find(item => item?.piId === studentListForSavePiInfo?.piId5)
                            return <Radio.Group name="radiogroup" value={val?.studentAssessment} onChange={e => { onchangeRadioSingleExist(e.target.value, record?.marks, studentListForSavePiInfo?.piId5, index) }}>
                                <Radio value={"ractangle"}>◻</Radio>
                                <Radio value={"circle"}>◯</Radio>
                                <Radio value={"triangle"}>△</Radio>
                            </Radio.Group>
                        } else return <Radio.Group name="radiogroup" value={null} onChange={e => { onchangeRadioSingle(e.target.value, studentListForSavePiInfo?.piId5, index, 4) }}>
                            <Radio value={"ractangle"}>◻</Radio>
                            <Radio value={"circle"}>◯</Radio>
                            <Radio value={"triangle"}>△</Radio>
                        </Radio.Group>
                    }
                }]
            : [],
    ];

    const onchangeRadioSingleExist = (val, marks, piId, index) => {
        const newData = [...tableData];
        let markindex = marks.findIndex(x => x.piId === piId);
        newData[index]["marks"][markindex]['studentAssessment'] = val
        setTableData(newData);
    }

    const onchangeRadioSingle = (val, piId, index, markindex) => {
        const newData = [...tableData];
        newData[index]["marks"][markindex] = {
            "piId": piId,
            "studentAssessment": val
        }
        setTableData(newData);
    }
    const onchangeRadioAll = (val, piId, markindex) => {
        let markvalue = {
            "piId": piId,
            "studentAssessment": val
        };
        const newData = tableData?.map(item => {
            item.marks[markindex] = markvalue
            return item;
        })
        setTableData(newData);
    }
    const [sectionId, setsectionId] = useState<any>(null);
    const [subjectId, setsubjectId] = useState<any>(null);
    const [chapterId, setchapterId] = useState<any>(null);
    const onChangeFetchSubject = (val) => {
        setsectionId(val);
        fetchsanmasikSubjectListbyClassConfig(val);
    }
    const onChangeFetchbySubject = (val) => {
        setsubjectId(val);
        fetchchapterInfoList(val)
    }
    const onChangeFetchbyChapter = (val) => {
        setchapterId(val);
        fetchpiInfoListbyChapter(val);
    }

    const saveData = (e) => {
        if (selectedRowKeys?.length === 0) {
            notification.error({ message: "Please select student" });
            return;
        }
        let studentList = selectedValue?.map(item => {
            return {
                identificationId: item?.identificationId,
                marks: item?.marks
            }
        })
        let postdata = {
            "chapterId": chapterId,
            "sanmasikSubjectId": subjectId,
            "studentList": studentList
        }
        saveSanmasikStudentPiInput(postdata);
        setselectedRowKeys([]);
        setselectedValue([]);

    }
    return (
        <Card title="Chapter Info">

            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24, offset: 3 }} xl={{ span: 24, offset: 3 }}>
                    <Form
                        layout="vertical"
                        id="sanmasikClass"
                        onFinish={sanmasikClassForm}
                        form={sanmasikForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="academicYear"
                                    label="Academic Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select year" },
                                    ]}
                                >
                                    <SelectAcademicYear2 />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="classConfigurationId"
                                    label="Section"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select section" },
                                    ]}
                                >
                                    <SelectClassConfig2 onChange={e => onChangeFetchSubject(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="subjectId"
                                    label="Subject"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select competency name" },
                                    ]}
                                >
                                    <SelectSanmasikSubjectbyClassConfig onChange={e => onChangeFetchbySubject(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}></Col>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="chapterId"
                                    label="Chapter"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select chapter" },
                                    ]}
                                >
                                    <SelectSanmasikChpater onChange={e => onChangeFetchbyChapter(e)} />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="piIds"
                                    label="PI List"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select PI No" },
                                    ]}
                                >
                                    <SelectPIMultiplebyChapter />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Skeleton loading={loading} paragraph={{ rows: 4 }} />
            {tableData?.length > 0 &&
                <Row className="m-t-mo-30">
                    <Col span="24">
                        <div className="datatable-responsive-demo" >
                            {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}
                            <Table style={{ maxHeight: 600, marginBottom: 10 }} bordered={true} dataSource={tableData} columns={columns} className="p-datatable-responsive-demo" pagination={false} rowSelection={rowSelection} rowKey={"identificationId"} />
                        </div>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                        <Button type="primary" className="mt-0 pull-right" onClick={(e) => saveData(e)} icon={<SaveOutlined />} >Save</Button>
                    </Col>
                </Row>
            }

        </Card>
    )
}