import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../select/SelectAcademicYear';
import TableView from '../../../contents/AntTableResponsive';

import $ from 'jquery';
import jsPDF from "jspdf";

import { FilePdfOutlined } from '@ant-design/icons';
import ReactExport from "react-export-excel";
import { SelectClass } from '../../select/SelectClass';
require('jspdf-autotable');
const { Option } = Select;

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

export default function AlumniStudentView() {


    const [paidUnpaidReportForm] = Form.useForm();
    const fetchAlumniapplicantList = useStoreActions((state) => state.alumniStudent.fetchAlumniApplicantList);
    const alumniapplicantList = useStoreState((state) =>  state.alumniStudent.alumniApplicantList);
    const loading = useStoreState((state) =>  state.alumniStudent.loading);

    const formSubmit = (value) => {

        fetchAlumniapplicantList(value);
    }

    const studentListColumn = [
        { title: 'Applicant Id', dataIndex: 'applicantId', key: 'applicantId', showOnResponse: true, showOnDesktop: true  },
        { title: 'Applicant Name', dataIndex: 'applicantName', key: 'applicantName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Father Name', dataIndex: 'fatherName', key: 'fatherName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Number of Guest', dataIndex: 'numberOfGuest', key: 'numberOfGuest', showOnResponse: true, showOnDesktop: true  },
        { title: 'Gender', dataIndex: 'gender', key: 'gender', showOnResponse: true, showOnDesktop: true  },
        { title: 'Religion', dataIndex: 'religion', key: 'religion', showOnResponse: true, showOnDesktop: true  },
        { title: 'Last Exam', dataIndex: 'lastExam', key: 'lastExam', showOnResponse: true, showOnDesktop: true  },
        { title: 'Last Exam Year', dataIndex: 'lastExamYear', key: 'lastExamYear', showOnResponse: true, showOnDesktop: true  },
        { title: 'Contact No', dataIndex: 'contactNo', key: 'contactNo', showOnResponse: true, showOnDesktop: true  },
        { title: 'Paid Amount', dataIndex: 'paidAmount', key: 'paidAmount', showOnResponse: true, showOnDesktop: true  },
        { title: 'PaymentStatus', dataIndex: 'paymentStatusString', key: 'paymentStatusString', showOnResponse: true, showOnDesktop: true  },
        {
            title: 'Photo',
            dataIndex: 'applicantId',
            key: 'applicantId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => {
                let imgDataLink = record.imageLink;
                return (
                    <div style={{ display: "flex" }}>
                        <img src={imgDataLink} height='35' width='30' />
                    </div>
                )
               
            },
        },

    ];

  return (
    <>
    <Card title="Alumni Applicant List">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 2}} xl={{ span: 20, offset: 2 }}>
                        <Form
                            layout="vertical"
                            id="paidUnpaidReport"
                            onFinish={formSubmit}
                            form={paidUnpaidReportForm}
                        >
                            <Row>
                                
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="year"
                                        label="Application Year"
                                        className="title-Text"
                                    >

                                  <Select placeholder='Select Year'  style={{ width: "100%" }} defaultValue={null} allowClear>
                                        <Option value="2024">2024</Option>
                                        <Option value="2025">2025</Option>
                                        <Option value="2026">2026</Option>
                                        <Option value="2027">2027</Option>
                                        <Option value="2028">2028</Option>
                                   </Select>
                                    

                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="paymentStatus"
                                        label="Payment Status"
                                        className="title-Text"
                                    >
                                   <Select placeholder='Payment Status'  style={{ width: "100%" }} defaultValue={null} allowClear>
                                        <Option value="1">Paid</Option>
                                        <Option value="0">Unpaid</Option>
                                        <Option value="2">All</Option>
                                   </Select>

                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
                
                <Row style={{ display: alumniapplicantList.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns:studentListColumn,
                                rowKey:"alumniId",
                                dataSource: alumniapplicantList,
                                filterData: alumniapplicantList,
                                pagination: true,
                                bordered: true,                           
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                    </Col>

                    <Col span={24}>
                        
                        <Space size="small" style={{ float: "right" }}>
                        
                        <ExcelFile element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Alumni Applicant List`}
                            >
                                <ExcelSheet data={alumniapplicantList} name="Class Wise Report">
                                    <ExcelColumn label="Applicant Id" value="applicantId" />
                                    <ExcelColumn label="Applicant Name" value="applicantName" />
                                    <ExcelColumn label="Father Name" value="fatherName" />
                                    <ExcelColumn label="Number of Guest" value="numberOfGuest" />
                                    <ExcelColumn label="Gender" value="gender"/>
                                    <ExcelColumn label="Religion" value="religion" />
                                    <ExcelColumn label="Last Exam" value="lastExam"/>
                                    <ExcelColumn label="Last Exam Year" value="lastExamYear" />
                                    <ExcelColumn label="Contact No" value="contactNo" />
                                    <ExcelColumn label="Paid Amount" value="paidAmount" />
                                    <ExcelColumn label="Payment Status" value="paymentStatusString" />
                                </ExcelSheet>

                            </ExcelFile>

                        </Space>
                    </Col>
                    
                </Row>

            </Card>
    
    </>
  )
}
