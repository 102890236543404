import { Button, Card, Col, DatePicker, Form, Row, Skeleton, Space, Table } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import TableResponsive from "../../../../contents/AntTableResponsive";
import ReactExport from "react-export-excel";
import Text from 'antd/lib/typography/Text';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, lpowerdbypdf, ldatepdf, pdfGenerate } from '../../../utils/pdf';
require('jspdf-autotable');
const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;


let fd = '';
let td = '';

let finalfeeAmount = 0;
export default function BkashSummary(props) {
    const bkashSummary = useStoreState((state) => state.saccount.bkashSummary);
    const fetchbkashSummary = useStoreActions((state) => state.saccount.fetchbkashSummary);
    const loading = useStoreState((state) => state.saccount.loading);



    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
        }
        fetchbkashSummary(postData);
        fd = moment(value?.fromDate).format("YYYY-MM-DD");
        td = moment(value?.toDate).format("YYYY-MM-DD");
    };

    const columns = [

        {
            title: 'Date',
            dataIndex: 'trnDate',
            key: 'trnDate',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Fee Transaction Amount',
            dataIndex: 'feeAmount',
            key: 'feeAmount',
            showOnResponse: true,
            showOnDesktop: true,
        }
    ];


    function exportPdf() {
        var details = `Bkash Summary from ${fd} to ${td}`;

        var doc = new jsPDF("p", "mm", "a4");


        pdfDataL(doc, details);

        var col = ["Date", "Fee Transaction Amount"];

        var rows: any = [];
        bkashSummary.forEach(element => {
            var temp = [element.trnDate, element.feeAmount];
            rows.push(temp);
        });

        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };

        doc.autoTable(col, rows, {
            headerStyles: {
                lineWidth: .01,
                lineColor: [224, 224, 224],
                overflow: 'linebreak'
            },
            columnStyles: {
                0: { overflow: 'linebreak', columnWidth: 90, halign: "center" },
                1: { overflow: 'linebreak', columnWidth: 90, halign: "center" },
            },
            theme: "grid",
            startY: 45,
            addPageContent: pageContent
        });
        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
            { title: "", dataKey: "b3" },
            { title: "", dataKey: "b4" },
            { title: "", dataKey: "b5" },
            { title: "", dataKey: "b6" },
            { title: "", dataKey: "b7" },
            { title: "", dataKey: "b8" },
        ], [
            {
                b1: "Total",
                b2: finalfeeAmount,

            }
        ], {
            startY: doc.autoTable.previous.finalY + 0,
            showHeader: "never",
            theme: 'grid',

            columnStyles: {
                b1: {
                    halign: "center",
                    fontStyle: 'bold',
                    columnWidth: 90
                },
                b2: {
                    halign: "center",
                    fontStyle: 'bold',
                    columnWidth: 90
                },
            },

            // addPageContent: pageContent,
        });
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }

        doc.save(details + ".pdf");

    }


    return (
        <>
            <Card title="Bkash Summary">
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>


                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    </Row>
                </Form>

                {< Skeleton loading={loading} paragraph={{ rows: 10 }} />}
                {bkashSummary?.length > 0 && !loading &&
                    <>
                        <TableResponsive
                            antTableProps={{
                                showHeader: true,
                                columns: columns,
                                dataSource: bkashSummary,
                                filterData: bkashSummary,
                                pagination: true,
                                bordered: true,
                                rowKey: "trnDate",
                                summary: function () {
                                    finalfeeAmount = 0;

                                    bkashSummary?.forEach(({ feeAmount }) => {
                                        finalfeeAmount += feeAmount;
                                    });

                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell index={0}> <Text strong>Total</Text> </Table.Summary.Cell>
                                                <Table.Summary.Cell index={1}>
                                                    <Text type="danger" strong>{finalfeeAmount}</Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>

                                        </>
                                    );
                                }
                            }}
                            mobileBreakPoint={768}

                        />
                        <Space style={{ float: 'right' }} size="middle">

                            <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Bkash summary from ${fd} to ${td}`}
                            >
                                <ExcelSheet data={bkashSummary} name="Bkash Summary">
                                    <ExcelColumn label="Date" value="trnDate" />
                                    <ExcelColumn label="Fee Transaction Amount" value="feeAmount" />

                                </ExcelSheet>
                            </ExcelFile>
                            <Button type="primary" icon={<FilePdfOutlined />} onClick={exportPdf} > Download PDF </Button>
                        </Space>
                    </>
                }
            </Card>
        </>
    )
}
