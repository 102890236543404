import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import StaffSubjectAssignAdd from './StaffSubjectAssignAdd.page';
// import PartnerInformationList from './PartnerInformationList';

export default function StaffSubjectAssignWrapper() {


    return (
        <>
            <Card title="Subject Teacher Assign ">
                <StaffSubjectAssignAdd />
            </Card>
        </>
    )
}