import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import SessionYear from '../Students/settings/SessionYear.page';
import { SelectAcademicYear } from '../../select/SelectAcademicYear';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';



export default function DefaultSignature() {

    const [defaultSignatureForm] = Form.useForm();
    const { Option } = Select;
    const fetchDefaultSignatureList = useStoreActions((state) => state.masterSettings.fetchDefaultSignatureList);
    const defaultSignatureList =  useStoreState((state) =>  state.masterSettings.defaultSignatureList);
    const saveDefaultSignature = useStoreActions((state) => state.masterSettings.saveDefaultSignature);
    const deleteDefaultSignature = useStoreActions((state) => state.masterSettings.deleteDefaultSignature);
    const updateSignatureDefaultPhoto = useStoreActions((state) => state.masterSettings.updateSignatureDefaultPhoto);
     const [signaturePositionText, setSignaturePositionText] = useState("");
     const loading = useStoreState((state) =>  state.masterSettings.loading);

    let signaturePositionList = [
        {id: 1,value : 11, optionName: "Student ID Card"},
        {id: 2,value : 12, optionName: "HR ID Card"},
        {id: 3,value : 13, optionName: "Mark Sheet Right"},
        {id: 4,value : 14, optionName: "Mark Sheet Middle"},
        {id: 5,value : 15, optionName: "Mark Sheet Left"},
        {id: 6,value : 16, optionName: "Admit Card Right"},
        {id: 16,value : 26, optionName: "Admit Card Middle"},
        {id: 7,value : 17, optionName: "Admit Card Left"},
        {id: 8,value : 18, optionName: "Student Fee Receipt Bottom"},
        {id: 9,value : 19, optionName: "Testimonial Right"},
        {id: 10,value : 20, optionName: "Testimonial Left"},
        {id: 11,value : 21, optionName: "TC Left"},
        {id: 12,value : 22, optionName: "TC Right"},
        {id: 13,value : 23, optionName: "Class Test Mark Sheet Middle"},
        {id: 14,value : 24, optionName: "Class Test Mark Sheet Right"},
        {id: 15,value : 25, optionName: "Online Admission"},
    ]

    function clearFileInput(ctrl) {
        try {
            ctrl.value = null;
        } catch (ex) { }
        if (ctrl.value) {
            ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
        }
    }
    useEffect(() => {
        fetchDefaultSignatureList();
    }, []);


    const submitDefaultSignature = (value) => {
        if (signatureData===null){
            notification.error({message:"Please upload signature"});
            return
        }
        let optionName:any = "";
        let optionValue:any = "";
        if(value.signaturePosition){
            signaturePositionList.map((item, index) => {
                if (value.signaturePosition == item.id) {
                    optionName = item.optionName;
                    optionValue = item.value
                }
            })
        }
        let postData = {
            signStatus: value.status,
            signatureTitle: value.signatureTitle,
            usedId: optionValue,
            usedName: optionName,
        }
        saveDefaultSignature({payload:postData, image:signatureData});
        defaultSignatureForm.resetFields();
        setsignatureData(null);
        let file:any = document?.getElementById('upload-file55');
        if (file) {
            file.value=null
        }
      
    }
    const [signatureData, setsignatureData] = useState<any>(null)
   
    const onchangeFile = (e) => {
        setsignatureData(e.target.files[0])
    }

    const onchangeUpdateFile = (e, id) => {
        let postdata:any = {
            signatureId : id,
            signatureData : e.target.files[0]
        }
        updateSignatureDefaultPhoto(postdata)
    }

    const defaultSignaturedelete = (e) => {
        deleteDefaultSignature(e);
    }

    let columns = [
        {title: 'Position Name', dataIndex: 'usedName', key: 'usedName', showOnResponse: true, showOnDesktop: true},
        {title: 'Signature Title', dataIndex: 'signatureTitle', key: 'signatureTitle', showOnResponse: true, showOnDesktop: true},
        {title: 'Sign Status', dataIndex: 'signStrStatus', key: 'signStrStatus', showOnResponse: true, showOnDesktop: true},
      
        {title: 'Sign Image', dataIndex: 'signImage', key: 'signImage', showOnResponse: true, showOnDesktop: true, render: (text: any, record: any, index) => (
           <div>
                <img src={"data:image/png;base64, "+record?.signImage} alt="Sign Image" height="50" width="130" />
           </div>
        )},        
        {title: 'Update Image', dataIndex: 'usedId', key: 'usedId', showOnResponse: true, showOnDesktop: true, render: (text: any, record: any, index) => (
           <div>
               <Input type="file" onChange={(e) => onchangeUpdateFile(e, record?.signatureId)} />
           </div>
        )},
        {title: 'Delete', dataIndex: 'signatureId', key: 'signatureId', showOnResponse: true, showOnDesktop: true, render: (text: any, record: any, index) => (
            <div>
                <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => defaultSignaturedelete(record?.signatureId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>
            </div>
         )}
    ]

    return(
        <>
            <Card title="Default Signature">
                <Form
                    layout="vertical"
                    id="instructionForm"
                    onFinish={submitDefaultSignature}
                    form={defaultSignatureForm}
                >
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item
                                name="signatureTitle"
                                label="Signature Title"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter signature title" },
                                ]}
                            >
                                <Input placeholder="signature title"/>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item
                                name="signaturePosition"
                                label="Signature Position"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter signature position" },
                                ]}
                            >
                                <Select placeholder="select position">
                                    {signaturePositionList.map((item, index) => {
                                        return(
                                            <Option value={item.id}>{item.optionName}</Option>
                                        )
                                    } )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item
                                name="status"
                                label="Select Status"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select status" },
                                ]}
                            >
                                <Select placeholder="Select status">
                                    <Option value="1">Yes</Option>
                                    <Option value="0">No</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>

                            <div >
                                <div className="ant-col ant-form-item-label"><label className="ant-form-item-required" >Photo Upload</label></div>
                                <input className='ant-input' type="file" accept="image/png, image/gif, image/jpeg" id="upload-file55" onChange={(e) => onchangeFile(e)} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                            <Button type="primary" htmlType="submit" className="mt-0" icon={<EditOutlined />} style={{ float: 'right' }} >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <br />
                <Row className="m-t-mo-30">
                    <Col span="24">
                        <Skeleton loading={loading} paragraph={{rows:10}} />
                        {!loading &&
                        <div className="datatable-responsive-demo">
                            {defaultSignatureList?.length > 0 &&
                                <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: defaultSignatureList,
                                    filterData: defaultSignatureList,
                                    pagination: true,
                                    bordered: true,
                                    rowKey:"signatureId",
                                }}
                                mobileBreakPoint={768}
                            />
                            }
                        </div>
}
                    </Col>
                </Row>
            </Card>
        </>
    )
}