import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Tooltip,
  message,
  Modal,
  Checkbox,
  Skeleton,
} from "antd";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Table, Tag } from "antd";
import { useStoreActions, useStoreState } from "../../../store/hooks/easyPeasy";
import TableView from "../../../contents/AntTableResponsive";

export default function BookWriter() {

  const createBookWriter = useStoreActions(
    (state) => state.library.createBookWriter
  );

  const fetchBookWriterList = useStoreActions(
    (state) => state.library.fetchBookWriterList
  );

  const bookWriterList = useStoreState(
    (state) => state.library.bookWriterList
  );

  const [bookWriterForm] = Form.useForm();

  const loading = useStoreState((state) => state.student.loading);

  const bookWriterCreateForm = (value) => {
    let postData = {
      bookWriterName: value.bookWriterName,
      degree: value.degree,
      status: 1,
      wrokingPlace: value.wrokingPlace,
    };
    console.log("postData", postData);

    createBookWriter(postData);
    bookWriterForm.resetFields();
  };


  useEffect(() => {
    fetchBookWriterList();
    
},[])


const columns = [
 
    {
        title: 'Writer Name',
        dataIndex: 'bookWriterName',
        key: 'bookWriterName',
        showOnResponse: true,
        showOnDesktop: true
    },

    {
      title: 'Degree',
      dataIndex: 'degree',
      key: 'degree',
      showOnResponse: true,
      showOnDesktop: true
  },

  {
    title: 'Wroking Place',
    dataIndex: 'wrokingPlace',
    key: 'wrokingPlace',
    showOnResponse: true,
    showOnDesktop: true
},
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        showOnResponse: true,
        showOnDesktop: true,
        render: (text: any, record: any, index) => (
            <>
               <span>{ record.status == 1 ? 'Enable' : 'Disable'}</span>
            </>
        )
    },
    // {
    //     title: 'Action',
    //     key: 'ledgerId',
    //     showOnResponse: true,
    //     showOnDesktop: true,
    //     render: (text: any, record: any, index) => (
    //         <Space size="middle">
    //             <Tooltip title="Edit">
    //                 <Button type='primary' icon={<EditOutlined />} onClick={() => {
    //                     setIsModalVisible(true);
    //                     setselectedStatus(record.status);
    //                     setupdateClassId(record.id);
    //                     updateForm.setFieldsValue({
    //                         serialUpdate: record.serial,
    //                         nameUpdate: record.name,
    //                         status: record.status,
    //                     });
    //                 }} />
    //             </Tooltip>
    //             <Popconfirm
    //                 title="Are you sure to delete this?"
    //                 okText="Yes"
    //                 cancelText="No"
    //                 onConfirm={() => deleteClass(record?.id)}
    //             >
    //                 <Tooltip title="Delete">
    //                     <Button danger  icon={<DeleteOutlined />} />
    //                 </Tooltip>
    //             </Popconfirm>

    //         </Space>
    //     ),
    // }
  ];

  return (
    <>
    <Card title="Add Book Writer">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 3 }} xl={{ span: 20, offset: 3 }}>
            <Form
              layout="vertical"
              id="classInfo"
              onFinish={bookWriterCreateForm}
              form={bookWriterForm}
            >
              <Row>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="bookWriterName"
                    label="Writer Name"
                    className="title-Text"
                    rules={[
                      {
                        required: true,
                        message: "Please write book writer name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter book writer name" />
                  </Form.Item>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="degree"
                    label="Writer Degree"
                    className="title-Text"
                    rules={[
                      {
                        required: true,
                        message: "Please write book writer degree",
                      },
                    ]}
                  >
                    <Input placeholder="Enter book writer degree" />
                  </Form.Item>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="wrokingPlace"
                    label="Working Place"
                    className="title-Text"
                    rules={[
                      {
                        required: true,
                        message: "Please write book wroking place",
                      },
                    ]}
                  >
                    <Input placeholder="Enter book wroking place" />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Space size="small">
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<SaveOutlined />}
                    >
                      Save
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Skeleton loading={loading} paragraph={{ rows: 10 }} />

        <Row className="m-t-mo-30">
                <Col span="24">
                    <div className="datatable-responsive-demo">
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: bookWriterList,
                                filterData: bookWriterList,
                                pagination: true,
                                bordered: true,
                                rowKey:"id",
                                
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                </Col>
            </Row>

      </Card>
    </>
  );
}
