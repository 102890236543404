import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, TimePicker, DatePicker, Skeleton } from 'antd'
import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectClass } from '../../../select/SelectClass';
import { SelectSection } from '../../../select/SelectSection';
import { SelectShift } from '../../../select/SelectShift';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag } from 'antd';
import TableView from '../../../../contents/AntTableResponsive';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectPeriod } from '../../../select/SelectPeriod';
import moment from 'moment';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { FilePdfOutlined } from '@ant-design/icons';
import $ from 'jquery';
require('jspdf-autotable');


export default function DailySectionReport() {
    
    const [studentSearchForm] = Form.useForm();
    const dateFormat = 'YYYY-MM-DD';
    const fetchStudentDailySectionReport = useStoreActions((state) => state.student.fetchStudentDailySectionReport);
    const studentDailySectionReport = useStoreState((state) => state.student.studentDailySectionReport);
    const fetchStudentAttendanceAllPeriod = useStoreActions((state) => state.student.fetchStudentAttendanceAllPeriod);
    const loading = useStoreState((state) =>  state.student.loading);
    const { Option } = Select;

    const [attendanceDate,setAttenDanceDate] = useState('');
    const [sectionName,setSectionName] = useState('');
 
    const searchStudent = (value) => {
        let postData:any = {
            attendanceDate: moment(value.date).format(dateFormat),
            classConfigId: value.sectionId,
            periodId: value.period,
            status: value.status
        }

        
        fetchStudentDailySectionReport(postData);

        setAttenDanceDate(moment(value?.date).format("YYYY-MM-DD"));
        setSectionName(`${$(".sectionSelect").text()}`);



    }

    useEffect(() => {
        fetchStudentAttendanceAllPeriod();
    }, [])

    const columns = [
        {
            title: 'Student ID',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            showOnResponse: true,
            showOnDesktop: true,
        }
    ];

    function exportPdf() {

        var doc = new jsPDF("p", "mm", "a4");

        var details =sectionName+` Daily Section Report of `+ attendanceDate;
        pdfDataL(doc, "");
        doc.text(details, 105, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);            
        };


        var col = [
            "Student ID",
            "Student Name",
            "Roll",
            "Status",
        ].filter(Boolean);

        var rows:any = [];


        studentDailySectionReport.forEach(element => {
            var temp:any = [ 
                element.customStudentId,
                element.studentName, 
                element.studentRoll,
                element.status,
            ];
            rows.push(temp);
        });
       

            let first = doc.autoTable.previous;
            doc.autoTable(col, rows, {
                startY: 45,
                showHeader: "firstPage",
                theme: 'grid',
                headerStyles: {
                    // fillColor: [105, 105, 105],
                    // textColor: [255, 255, 255],
                    // fontSize: 10
                    lineWidth: .01,
                    lineColor: [224, 224, 224]
                },
                columnStyles: {
                    ledgerName: {
                        halign: "left",
                        fontSize: 8,
                        columnWidth: 75
                    },
                    debit: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 53.4
                    },
                    credit: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 53.4
                    }
                },

              
            });

  
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }


    return <>
        <Card title="Daily Section Report">
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4}} xl={{ span: 20, offset: 4 }}>
                    <Form
                        layout="vertical"
                        id="studentSearchForm"
                        onFinish={searchStudent}
                        form={studentSearchForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="date"
                                    label="Select Date"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select date" },
                                    ]}
                                >
                                    <DatePicker style={{ width: "100%" }}  format={dateFormat}/>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="sectionId"
                                    label="Section"
                                    className="sec"
                                    rules={[
                                        { required: true, message: "Please select section" },
                                    ]}
                                >
                                    <SelectClassConfig />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="period"
                                    label="Select Period"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select period" },
                                    ]}
                                >
                                    <SelectPeriod />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="status"
                                    label="Select Status"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select status" },
                                    ]}
                                >
                                    <Select placeholder="Select Status" className='statusSelection'>
                                        <Option key="1" value="5">All</Option>
                                        <Option key="2" value="1">Present</Option>
                                        <Option key="3" value="2">Absent</Option>
                                        <Option key="4" value="3">Leave</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Skeleton loading={loading} paragraph={{rows:10}}/>
            <Row className="m-t-mo-30" style={{ display: studentDailySectionReport.length > 0 ? '' : 'none' }}>
                <Col span="24">
                    <div className="datatable-responsive-demo">
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: studentDailySectionReport,
                                filterData: studentDailySectionReport,
                                pagination: true,
                                bordered: true,
                                rowKey: "customStudentId",
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                </Col>
                <br />
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24}} xl={{ span: 24}}>
                    <div className="pull-right">
                        <Button type="primary" onClick={exportPdf} icon={<FilePdfOutlined />}  >Download PDF</Button>
                    </div>
                </Col>
            </Row>
            
        </Card>
    </>
}