import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TableView from '../../../../contents/AntTableResponsive';
import { DeleteOutlined, DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';
import { SelectGroupByClass } from '../../../select/SelectGroupByClass';
require('jspdf-autotable');

export default function ExamRoutineList() {
    const { Option } = Select;
    const [fdorm] = Form.useForm();

    const fetchExamListByClassAndClassId3 = useStoreActions((state) => state.exam.fetchExamListByClassAndClassId3);
    const fetchGroupList = useStoreActions((state) => state.exam.fetchGroupList);
    const examListByClassAndClassId3 = useStoreState((state) => state.exam.examListByClassAndClassId3);

    const fetchexamRoutineList = useStoreActions((state) => state.exam.fetchexamRoutineList);
    const examRoutineList = useStoreState((state) => state.exam.examRoutineList);
    const delExamRout = useStoreActions((state) => state.exam.delExamRout);


    const cols = [
        { title: 'Year', dataIndex: 'academicYear', key: "academicYear", showOnResponse: true, showOnDesktop: true },
        { title: 'Session', dataIndex: 'examSession', key: "examSession", showOnResponse: true, showOnDesktop: true },
        { title: 'Class', dataIndex: 'className', key: "className", showOnResponse: true, showOnDesktop: true },
        { title: 'Exam', dataIndex: 'examName', key: "examName", showOnResponse: true, showOnDesktop: true },
        { title: 'Group', dataIndex: 'groupName', key: "groupName", showOnResponse: true, showOnDesktop: true },
        { title: 'Date', dataIndex: 'examDate', key: "examDate", showOnResponse: true, showOnDesktop: true },
        { title: 'Time', dataIndex: 'examTime', key: "examTime", showOnResponse: true, showOnDesktop: true },
        { title: 'Room', dataIndex: 'roomNo', key: "roomNo", showOnResponse: true, showOnDesktop: true },
        { title: 'Subject', dataIndex: 'subjectName', key: "subjectName", showOnResponse: true, showOnDesktop: true },
        {
            title: 'Action', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true, render: (text, record: any, index) =>
                <>
                    <Space size="middle">
                        <Popconfirm
                            title="Are you sure you want to delete?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => {
                                delExamRout(record?.examRoutineId);
                                setTimeout(() => {
                                    fdorm.submit();
                                }, 1000);
                            }}
                        >
                            <Tooltip title="Delete">
                                <Button danger icon={<DeleteOutlined />} />
                            </Tooltip>
                        </Popconfirm>
                    </Space>
                </>
        },
    ];
    const loading = useStoreState((state) => state.exam.loading);

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");

        var details = `Exam Routine List`;
        pdfDataL(doc, "");
        doc.text(details, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        var col = [
            "Year",
            "Session",
            "Class",
            "Exam",
            "Group",
            "Date",
            "Time",
            "Room",
            "Subject",
        ].filter(Boolean);

        var rows: any = [];


        examRoutineList.forEach(element => {
            var temp: any = [
                element.academicYear,
                element.examSession,
                element.className,
                element.examName,
                element.groupName,
                element.examDate,
                element.examTime,
                element.roomNo,
                element.subjectName,
            ];
            rows.push(temp);
        });


        let first = doc.autoTable.previous;
        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
            { title: "", dataKey: "b3" },
            { title: "", dataKey: "b4" },
            { title: "", dataKey: "b5" },
            { title: "", dataKey: "b6" },
            { title: "", dataKey: "b7" },
            { title: "", dataKey: "b8" },
        ], [
            {
                b1: "Academic Year",
                b2: $(".yearSelect").text(),
                b3: 'Class',
                b4: $(".classSelect").text(),
                b5: 'Exam',
                b6: $(".examSelectx").text(),
                b7: 'Group',
                b8: $(".classSelectByGroup").text(),
            }
        ], {
            startY: 45,
            showHeader: "never",
            theme: 'grid',
            columnStyles: {
                b1: {
                    fontStyle: 'bold',
                },
                b3: {
                    fontStyle: 'bold',
                },
                b5: {
                    fontStyle: 'bold',
                },
                b7: {
                    fontStyle: 'bold',
                },
            },

            // addPageContent: pageContent,
        });
        doc.autoTable(col, rows, {
            startY: doc.autoTable.previous.finalY + 5,
            showHeader: "firstPage",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                ledgerName: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 75
                },
                debit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                },
                credit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                }
            },


        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    const onSubmit = (value) => {
        fetchexamRoutineList(value);
    }

    const onClassChange = (value) => {
        fetchExamListByClassAndClassId3(value);
        fetchGroupList(value);
    }




    return (
        <>
            <>
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24, offset: 1 }} xl={{ span: 24, offset: 1 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={onSubmit}
                            form={fdorm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Select Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="classId"
                                        label="Select Class"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select class" },
                                        ]}
                                    >
                                        <SelectClass onChange={(e) => onClassChange(e)} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="grouupIds"
                                        label="Group"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select group" },
                                        ]}
                                    >
                                        <SelectGroupByClass />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="examId"
                                        label="Select Exam"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select exam" },
                                        ]}
                                    >
                                        <Select placeholder="Exam List" style={{ width: "100%" }} className="examSelectx">
                                            {examListByClassAndClassId3 ? (
                                                examListByClassAndClassId3.map((type, idx) => (
                                                    <Option key={type.examId} value={type.examId}>
                                                        {type.examName}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching exam</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: examRoutineList.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: cols,
                                rowKey: "examRoutineId",
                                dataSource: examRoutineList,
                                filterData: examRoutineList,
                                pagination: true,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                        <Button type="primary" htmlType="submit" icon={<DownloadOutlined />} onClick={() => exportPdf()} className="float-right">Download</Button>
                    </Col>
                </Row>
            </>
        </>
    )

}