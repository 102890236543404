import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag } from 'antd';
import { SelectSanmasikClass } from '../../../select/SelectSanmasikClass';
import { SelectSanmasikSubject } from '../../../select/SelectSanmasikSubject';
import { SelectSanmasikCompetency } from '../../../select/SelectSanmasikCompetency';


export default function SanMasikPi(props) {

    const savepiInfo = useStoreActions((state) => state.customerSupport.savepiInfo);
    const fetchsanmasikSubjectList = useStoreActions((state) => state.customerSupport.fetchsanmasikSubjectList);
    const fetchsanmasikCompetencyList = useStoreActions((state) => state.customerSupport.fetchsanmasikCompetencyList);
    const fetchpiInfoList = useStoreActions((state) => state.customerSupport.fetchpiInfoList);
    const piInfoList = useStoreState((state) => state.customerSupport.piInfoList);
    const updatepiInfo = useStoreActions((state) => state.customerSupport.updatepiInfo);
    const deletepiInfo = useStoreActions((state) => state.customerSupport.deletepiInfo);
    const [sanmasikForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const loading = useStoreState((state) => state.customerSupport.loading);

    const sanmasikClassForm = (value) => {
        let postData = {
            subjectId: value.subjectId,
            piNo: value.piNo,
            piName: value.piName,
            circleText: value.circleText,
            competencyId: value.competencyId,
            ractangleText: value.ractangleText,
            triangleText: value.triangleText,
        }
        savepiInfo(postData);
        setTimeout(() => {
            fetchpiInfoList(subjectId)
            //  sanmasikForm.resetFields();
        }, 1500);

    }


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [updateId, setupdateId] = useState<any>();

    const updateExamForm = (value) => {
        let updatePostData = {
            piId: updateId,
            piNo: value.piNo,
            piName: value.piName,
            circleText: value.circleText,
            ractangleText: value.ractangleText,
            triangleText: value.triangleText,
        }
        updatepiInfo(updatePostData);
        setIsModalVisible(false);
        setTimeout(() => {
            fetchpiInfoList(subjectId)
            updateForm.resetFields();
        }, 1500);
    }

    const columns = [
        {
            title: 'Competency Name',
            dataIndex: 'competencyName',
            key: 'competencyName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Pi Name',
            dataIndex: 'piName',
            key: 'piName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Pi No',
            dataIndex: 'piNo',
            key: 'piNo',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Class Name',
            dataIndex: 'className',
            key: 'className',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Subject Name',
            dataIndex: 'subjectName',
            key: 'subjectName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Ractangle Text',
            dataIndex: 'ractangleText',
            key: 'ractangleText',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Circle Text',
            dataIndex: 'circleText',
            key: 'circleText',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Triangle Text',
            dataIndex: 'triangleText',
            key: 'triangleText',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Action',
            key: 'ledgerId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setupdateId(record.piId);
                            updateForm.setFieldsValue({
                                piName: record.piName,
                                piNo: record.piNo,
                                ractangleText: record.ractangleText,
                                circleText: record.circleText,
                                triangleText: record.triangleText,
                            });
                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => {
                            deletepiInfo(record?.piId);
                            setTimeout(() => {
                                fetchpiInfoList(subjectId)
                            }, 1500);
                        }
                        }
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        }
    ];
    const [classid, setclassId] = useState<any>(null);
    const [subjectId, setsubjectId] = useState<any>(null);
    const onChangeFetchSubject = (val) => {
        setclassId(val);
        fetchsanmasikSubjectList(val);
    }
    const onChangeFetchCom = (val) => {
        setsubjectId(val);
        fetchsanmasikCompetencyList(val);
        fetchpiInfoList(val);
    }
    return (
        <Card title="PI Info">

            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24, offset: 3 }} xl={{ span: 24, offset: 3 }}>
                    <Form
                        layout="vertical"
                        id="sanmasikClass"
                        onFinish={sanmasikClassForm}
                        form={sanmasikForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="classId"
                                    label="Class Name"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select class name" },
                                    ]}
                                >
                                    <SelectSanmasikClass onChange={e => onChangeFetchSubject(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="subjectId"
                                    label="Subject"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select competency name" },
                                    ]}
                                >
                                    <SelectSanmasikSubject onChange={e => onChangeFetchCom(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="competencyId"
                                    label="Competency"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select competency" },
                                    ]}
                                >
                                    <SelectSanmasikCompetency />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}></Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="piName"
                                    label="PI Name"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write PI name" },
                                    ]}
                                >
                                    <Input.TextArea placeholder="Enter PI name" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="piNo"
                                    label="PI No"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write PI No" },
                                    ]}
                                >
                                    <Input placeholder="Enter PI No" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="ractangleText"
                                    label="Ractangle Text"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write Ractangle Text" },
                                    ]}
                                >
                                    <Input.TextArea placeholder="Enter Ractangle Text" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}></Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="circleText"
                                    label="Circle Text"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write Circle Text" },
                                    ]}
                                >
                                    <Input.TextArea placeholder="Enter Circle Text" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="triangleText"
                                    label="Triangle Text"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write Triangle Text" },
                                    ]}
                                >
                                    <Input.TextArea placeholder="Enter Triangle Text" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                        Save
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            {piInfoList?.length > 0 &&
                <Row className="m-t-mo-30">
                    <Col span="24">
                        <div className="datatable-responsive-demo">
                            {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}
                            <Table bordered={true} dataSource={piInfoList} columns={columns} className="p-datatable-responsive-demo" />
                        </div>
                    </Col>
                </Row>
            }
            <Modal
                title="Edit"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
                destroyOnClose
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={updateExamForm}
                    form={updateForm}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="piName"
                                label="PI Name"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write PI name" },
                                ]}
                            >
                                <Input.TextArea placeholder="Enter PI name" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="piNo"
                                label="PI No"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write PI No" },
                                ]}
                            >
                                <Input placeholder="Enter PI No" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="ractangleText"
                                label="Ractangle Text"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write Ractangle Text" },
                                ]}
                            >
                                <Input.TextArea placeholder="Enter Ractangle Text" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="circleText"
                                label="Circle Text"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write Circle Text" },
                                ]}
                            >
                                <Input.TextArea placeholder="Enter Circle Text" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="triangleText"
                                label="Triangle Text"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write Triangle Text" },
                                ]}
                            >
                                <Input.TextArea placeholder="Enter Triangle Text" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Card>
    )
}