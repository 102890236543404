import { Button, Card, Col, Form, Input, InputNumber, Row, Space } from 'antd';
import React from 'react'
import { useStoreActions } from '../../../store/hooks/easyPeasy';
import { Link } from "react-router-dom";
import { ROUTES } from '../../../contents/routes';
import { SelectDistrict } from '../../select/SelectDistrict';

export default function PartnerInfoSave() {

const [form] = Form.useForm();

const savePartnerInfo = useStoreActions((state) => state.customerSupport.savePartnerinformation);

const formSubmit=(value)=>{
    savePartnerInfo(value);
}

  return (
    <>
    
    <Card title="Partner Registration" extra={ <strong><Link to={ROUTES.PARTNER_INFO} className="nav-text" >Partner List</Link></strong>  }>
    <Row>
    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 3 }} xl={{ span: 20, offset: 3 }}>
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="partner-info"
                    form={form}
                >
                  
                  <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="partnerName"
                                label="Partner Name "
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert partner name" },
                                ]}
                            >
                                <Input placeholder="Partner Name" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="mobileNo"
                                label="Mobile No."
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert Mobile No." },
                                ]}
                            >
                                <Input placeholder="Mobile No." />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="email"
                                label="Email "
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert email" },
                                ]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}></Col>

 
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="districtId"
                                label="District"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select district" },
                                ]}
                            >
                                <SelectDistrict/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="address"
                                label="Address"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please Enter Address" },
                                ]}
                            >
                                <Input.TextArea placeholder="Address"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="commssionPercent"
                                label="Commssion % "
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert Commssion" },
                                ]}
                            >
                                <InputNumber placeholder="Commssion" />
                            </Form.Item>
                        </Col>
                        
                    


                        <Col xs={24} sm={24} md={24} lg={16} xl={16}> </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" style={{float:"right"}}>
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} >
                                    Save
                                </Button>
                            </Space>
                        </Col>
                        </Row>
                   
                </Form>
                
                </Col>
                
                </Row>

            </Card>
    
    </>
  )
}
