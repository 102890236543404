import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../select/SelectAcademicYear';
import TableView from '../../../contents/AntTableResponsive';

import $ from 'jquery';
import jsPDF from "jspdf";

import { FilePdfOutlined } from '@ant-design/icons';
import ReactExport from "react-export-excel";
import { SelectClass } from '../../select/SelectClass';
require('jspdf-autotable');
const { Option } = Select;

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

export default function OnlineAdmissionReportPaidUnpaid() {


    const [paidUnpaidReportForm] = Form.useForm();
    const fetchOnlineAdmissionGeneralReport = useStoreActions((state) => state.onlineAdmission.fetchOnlineAdmissionGeneralList);
    const onlineAdmissionGeneralReport = useStoreState((state) =>  state.onlineAdmission.onlineAdmissionGeneralList);
    const loading = useStoreState((state) =>  state.student.loading);

    const formSubmit = (value) => {

        fetchOnlineAdmissionGeneralReport(value);
    }

    const studentListColumn = [
        { title: 'Registration Id', dataIndex: 'registrationId', key: 'registrationId', showOnResponse: true, showOnDesktop: true  },
        { title: 'Roll', dataIndex: 'roll', key: 'roll', showOnResponse: true, showOnDesktop: true  },
        { title: 'Class Name', dataIndex: 'className', key: 'className', showOnResponse: true, showOnDesktop: true  },
        { title: 'Group Name', dataIndex: 'groupName', key: 'groupName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Applicant Name', dataIndex: 'applicantName', key: 'applicantName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Gender', dataIndex: 'gender', key: 'gender', showOnResponse: true, showOnDesktop: true  },
        { title: 'Blood Group', dataIndex: 'bloodGroup', key: 'bloodGroup', showOnResponse: true, showOnDesktop: true  },
        { title: 'Date of Birth', dataIndex: 'dateOfBirth', key: 'dateOfBirth', showOnResponse: true, showOnDesktop: true  },
        { title: 'Father Name', dataIndex: 'fatherName', key: 'fatherName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Guardian Mobile', dataIndex: 'localGuardianMobile', key: 'localGuardianMobile', showOnResponse: true, showOnDesktop: true  },
        { title: 'Present Address', dataIndex: 'presentAddress', key: 'presentAddress', showOnResponse: true, showOnDesktop: true  },
        { title: 'Present District', dataIndex: 'presentDistrict', key: 'presentDistrict', showOnResponse: true, showOnDesktop: true  },
        { title: 'Admission Charge', dataIndex: 'admissionCharge', key: 'admissionCharge', showOnResponse: true, showOnDesktop: true  },
        { title: 'Payment Status', dataIndex: 'paymentStatus', key: 'paymentStatus', showOnResponse: true, showOnDesktop: true  },
        {
            title: 'Photo',
            dataIndex: 'applicantId',
            key: 'applicantId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => {
                let imgDataLink = record.studentImageLink;
                return (
                    <div style={{ display: "flex" }}>
                        <img src={imgDataLink} height='35' width='30' />
                    </div>
                )
               
            },
        },

    ];

  return (
    <>
    <Card title="Online Admission General Applicant List">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 2}} xl={{ span: 20, offset: 2 }}>
                        <Form
                            layout="vertical"
                            id="paidUnpaidReport"
                            onFinish={formSubmit}
                            form={paidUnpaidReportForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]} 
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="paidStatus"
                                        label="Payment Status"
                                        className="title-Text"
                                    >
                                   <Select placeholder='Payment Status'  style={{ width: "100%" }} defaultValue={null} allowClear>
                                        <Option value="1">Paid</Option>
                                        <Option value="0">Unpaid</Option>
                                   </Select>

                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="quoata"
                                        label="Quoata"
                                        className="title-Text"
                                    >

                                  <Select placeholder='Select Quoata'  style={{ width: "100%" }} defaultValue={null} allowClear>
                                        <Option value="Teacher of Udayan">Teacher of Udayan</Option>
                                        <Option value="Freedom Fighter">Freedom Fighter</Option>
                                        <Option value="Catchment Area">Catchment Area</Option>
                                        <Option value="Ministry Of Education">Ministry Of Education</Option>
                                        <Option value="Teacher/Officer of Dhaka University">Teacher/Officer of Dhaka University</Option>
                                   </Select>
                                    

                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="version"
                                        label="Version"
                                        className="title-Text"
                                        
                                    >

                                   <Select placeholder="Select Version" style={{ width: "100%" }} defaultValue={null} allowClear>
                                        <Option value="Bangla">Bangla</Option>
                                        <Option value="English">English</Option>
                                   </Select>
                                    
                                </Form.Item>
                                
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="classId"
                                        label="Class"
                                        className="title-Text"
                                    >

                                  <SelectClass />
                                    
                                </Form.Item>
                                
                                </Col>


                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
                
                <Row style={{ display: onlineAdmissionGeneralReport.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns:studentListColumn,
                                rowKey:"applicantId",
                                dataSource: onlineAdmissionGeneralReport,
                                filterData: onlineAdmissionGeneralReport,
                                pagination: true,
                                bordered: true,                           
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                    </Col>

                    <Col span={24}>
                        
                        <Space size="small" style={{ float: "right" }}>
                        
                        <ExcelFile element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Online Applicant List`}
                            >
                                <ExcelSheet data={onlineAdmissionGeneralReport} name="Class Wise Report">
                                    <ExcelColumn label="Registration ID" value="registrationId" />
                                    <ExcelColumn label="Applicant Name" value="applicantName" />
                                    <ExcelColumn label="Class Name" value="className" />
                                    <ExcelColumn label="Group Name" value="groupName" />
                                    <ExcelColumn label="Roll" value="roll" />
                                    <ExcelColumn label="Gender" value="gender"/>
                                    <ExcelColumn label="Date of Birth" value="dateOfBirth" />
                                    <ExcelColumn label="Blood Group" value="bloodGroup"/>
                                    <ExcelColumn label="Father Name" value="fatherName" />
                                    <ExcelColumn label="Guardian Mobile" value="localGuardianMobile" />
                                    <ExcelColumn label="Present Address" value="presentAddress" />
                                    <ExcelColumn label="Present District" value="presentDistrict" />
                                    <ExcelColumn label="Admission Charge" value="admissionCharge" />
                                    <ExcelColumn label="Payment Status" value="paymentStatus" />  
                                </ExcelSheet>

                            </ExcelFile>

                        </Space>
                    </Col>
                    
                </Row>

            </Card>
    
    </>
  )
}
