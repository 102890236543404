import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton, DatePicker, Table } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import moment from 'moment';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, lpowerdbypdf, ldatepdf, pdfGenerate } from '../../../utils/pdf';
require('jspdf-autotable');
let fd = '';
let td = '';
export default function FeeHeadCollectionDateToDateFeeHeadSubHead() {
    const [studentWiseReportFormDateToDate] = Form.useForm();
    const fetchfeeHeadSubHead = useStoreActions((state) => state.saccount.fetchfeeHeadSubHead);
    const feeHeadSubHead = useStoreState((state) => state.saccount.feeHeadSubHead);
    const loading = useStoreState((state) => state.saccount.loading);

    const formSubmit = (value) => {

        let postData: any = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
        }
        fd = moment(value?.fromDate).format("YYYY-MM-DD");
        td = moment(value?.toDate).format("YYYY-MM-DD");
        fetchfeeHeadSubHead(postData);

    }



    function exportPdf() {
        var details = `Fee sub head details from ${fd} to ${td}`;
        var doc = new jsPDF("l", "mm", "a4");


        pdfDataL(doc, details);

        var col = ["Fee Head", "Details", "Total Amount"];

        var rows: any = [];

        var temp: any = []


        feeHeadSubHead?.forEach((element: any) => {
            var temp = [element?.feeHeadName, element.details, element.totalAmount];
            rows.push(temp);
        });

        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };

        doc.autoTable(col, rows, {
            columnStyles: {
                0: { overflow: 'linebreak' },
                1: {  columnWidth: 140, overflow: 'linebreak' },
                2: {  overflow: 'linebreak' },
            },
            headerStyles: {
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },

            theme: "grid",
            startY: 45,
            addPageContent: pageContent
        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }

        doc.save(details + ".pdf");

    }

    const studentListColumn = [
        {
            title: "Fee Head",
            dataIndex: "feeHeadName",
            key: "feeHeadName",
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: "Total Amount",
            dataIndex: "totalAmount",
            key: "totalAmount",
            showOnResponse: true,
            showOnDesktop: true,
        },
    ];

    const cols = [
        {
            title: "Fee Sub Head",
            dataIndex: "feeSubheadName",
            key: "feeSubheadName",
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            showOnResponse: true,
            showOnDesktop: true,
        },
    ];


    return (
        <>
            <>
                <style>
                    {`
                .ant-table-wrapper {
                    overflow: hidden !important;
                }
                `}
                </style>
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={studentWiseReportFormDateToDate}
                        >

                            <Row gutter={8}>
                                <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>


                                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                    <Form.Item
                                        name="fromDate"
                                        label="From Date"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please enter from date" },
                                        ]}
                                    >

                                        <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                    <Form.Item
                                        name="toDate"
                                        label="To Date"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please enter to date" },
                                        ]}
                                    >

                                        <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }}>  </Skeleton>
                {feeHeadSubHead !== null &&
                    <Row >
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>

                        <Table
                                columns={studentListColumn}
                                rowKey={"feeHeadId"}
                                expandable={{
                                    defaultExpandAllRows: false,
                                    expandedRowRender: (record: any) => <Table dataSource={record?.feeSubheadList} columns={cols} pagination={false} />,
                                }}
                                dataSource={feeHeadSubHead}
                                pagination={false}

                            />
                            <br />
                        </Col>
                        <Col span={24}>
                            <Space size="small" style={{ float: "right" }}>
                                <Button type="primary" icon={<FilePdfOutlined />} onClick={exportPdf} > Download PDF </Button>
                            </Space>
                        </Col>
                    </Row>
                }


            </>
        </>
    )
}
