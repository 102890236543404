import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    notification,
    Popconfirm,
    Row,
    Space,
    Table,
    Tooltip,
} from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { SelectLedgerDFPS } from "../../../select/SelectLedgerDFPS";
import { useStoreActions, useStoreState } from "../../../../store/hooks/easyPeasy";
import * as FileSaver from "file-saver";

export default function Contra(props) {

    const doContraVoucher = useStoreActions((state) => state.ledger.doContraVoucher);
    const download = useStoreActions((state) => state.ledger.download);
    const trnid = useStoreState((state) => state.ledger.trnid);


    const [form] = Form.useForm();


    const submitFom = (value) => {
        console.log(value)
        if (value.fromLedger==value.toLedger){
            notification.error({ message: "Ledgers cannot be same" });
            return;
        } else  {
        let trnDate = moment(value?.date).format("YYYY-MM-DD");
        var postData = {
            fromLedgerId: value.fromLedger,
            toLedgerId: value.toLedger,
            trnDate: trnDate,
            trnAmount: parseFloat(value.totalAmount),
            voucherNo: value.voucherNo,
            voucherNote: value.note,
        };
        console.log(postData);
        doContraVoucher(postData);

        form.resetFields();
        setcashDeb(null)
        setcashcre(null)
    }


    };

    const [cashdeb, setcashDeb] = useState<any>();

    const onchangecashdeb = (value) => {
        setcashDeb(value)
    }
    const [cashcre, setcashcre] = useState<any>();

    const onchangecashcre = (value) => {
        setcashcre(value)
    }


    return (
        <Card title="Contra Transaction">

            <Card title="Transaction  Form">
                <Form
                    layout="vertical"
                    onFinish={submitFom}
                    id="basic-info"
                    form={form}
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="fromLedger"
                                label="From Ledger:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select ledger" },
                                ]}
                            >
                                <SelectLedgerDFPS onChange={(val) => onchangecashdeb(val)} selected={cashdeb} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="toLedger"
                                label="To Ledger:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select ledger" },
                                ]}
                            >
                                <SelectLedgerDFPS onChange={(val) => onchangecashcre(val)} selected={cashcre} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="totalAmount"
                                label="Amount:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input amount" },
                                ]}
                            >
                                <Input
                                    placeholder="Amount"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="date"
                                label="Date:"
                                className="title-Text"
                                initialValue={moment((new Date()).toISOString())}
                                rules={[
                                    { required: true, message: "Please select date" },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={'DD/MM/YYYY'} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="voucherNo"
                                label="Voucher No:"
                                className="title-Text"
                                rules={[
                                    { required: false, message: "Please input ledger name" },
                                ]}
                            >
                                <Input
                                    placeholder="Voucher No:"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="note"
                                label="Note:"
                                className="title-Text"
                                rules={[
                                    { required: false, message: "Please input ledger name" },
                                ]}
                            >
                                <Input
                                    placeholder="Note:"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ marginTop: 30, height: 40 }} icon={<SaveOutlined />} >
                                    Save
                                </Button>
                            </Space>
                        </Col>
                        {trnid != null &&
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <Space size="small" >
                                    <Button type="primary" style={{ marginTop: 30, height: 40 }} icon={<SaveOutlined />} onClick={() => { download(trnid); }} >
                                        Download Voucher
                                    </Button>
                                </Space>
                            </Col>
                        }
                    </Row>
                </Form>
            </Card>
        </Card>
    );
}
