import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import { v4 as uuidv4 } from "uuid";
import { stat } from 'fs';
import Password from 'antd/lib/input/Password';
import Item from 'antd/lib/list/Item';

export default function UsersList() {

    const [addUserForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const { Option } = Select;
    const fetchMasterSettingUserList = useStoreActions((state) => state.masterSettings.fetchMasterSettingUserList)
    const masterSettingUserList = useStoreState((state) => state.masterSettings.masterSettingUserList);
    const fetchMasterSettingStaffList = useStoreActions((state) => state.masterSettings.fetchMasterSettingStaffList);
    const masterSettingStaffList = useStoreState((state) => state.masterSettings.masterSettingStaffList);
    const saveMaseterSettingUser = useStoreActions((state) => state.masterSettings.saveMaseterSettingUser);
    const deleteMasterSettingUser = useStoreActions((state) => state.masterSettings.deleteMasterSettingUser);
    const updateMaseterSettingUser = useStoreActions((state) => state.masterSettings.updateMaseterSettingUser);
    const [staffList, setStaffList] = useState<any>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedStatus, setselectedStatus] = useState<boolean>(true);
    const loading = useStoreState((state) =>  state.masterSettings.loading);
  
    useEffect(() => {
        fetchMasterSettingUserList();
        fetchMasterSettingStaffList();
    }, []);

    useEffect(() => {
       setStaffList(masterSettingStaffList);
    }, [masterSettingStaffList])


    const submitMasterSettingUser = (value) => {
        console.log('value', value);
        let postData:any = {
            "mobileNo": value.mobileNumber,
            "nickName": value.nickName,
            "password": value.password,
            "roleList": value.roles,
            "staffId": value.selectStaff,
            "userName": value.userName
        }
        saveMaseterSettingUser(postData)
    }

    const onchangeStaff = (e) => {
        staffList.map(item => {
            if (item.staffId == e) {
                addUserForm.setFieldsValue({
                    userName:item.mobileNumber,
                    nickName:item.staffName,
                    mobileNumber:item.mobileNumber,
                    
                })
            }
        })
    }


    const userDelete = (e) => {
        deleteMasterSettingUser(e)
    }

    const updateSubmitForm = (value) => {
        var postData = {
            userName: value.userNameUpdate,
            nickName: value.nickNameUpdate,
            mobileNo: value.mobileNumberUpdate,
            roleList: value.rolesUpdate,
            enabled: value.status 
          };
          updateMaseterSettingUser(postData);
          setIsModalVisible(false);
    }

    let columns = [
        {title: 'Serial', dataIndex: 'serial', key: uuidv4(), showOnResponse: true, showOnDesktop: true, render: (text: any, record: any, index) => (
            <span>{index + 1}</span>
        )},
        {title: 'User Name', dataIndex: 'username', key: 'username', showOnResponse: true, showOnDesktop: true},
        {title: 'Nick name', dataIndex: 'nickName', key: 'nickName', showOnResponse: true, showOnDesktop: true},
        {title: 'Mobile', dataIndex: 'mobileNo', key: 'mobileNo', showOnResponse: true, showOnDesktop: true},
        {title: 'Status', dataIndex: 'status', key: 'status', showOnResponse: true, showOnDesktop: true},
        {title: 'Staff Id', dataIndex: 'hrId', key: 'hrId', showOnResponse: true, showOnDesktop: true},
        {title: 'Role List', dataIndex: 'roles', key: 'roles', showOnResponse: true, showOnDesktop: true, render: (text: any, record: any, index) => (
            <div><span>{record?.roles.join()}</span></div>
        )},
        {title: 'Action', dataIndex: 'signatureId', key: 'signatureId', showOnResponse: true, showOnDesktop: true, render: (text: any, record: any, index) => (
            <Space size="middle">
                <Button type='primary' disabled={record.username==='arif'?true:false} icon={<EditOutlined />} onClick={() => {
                    setIsModalVisible(true);
                    setselectedStatus(record.status);
                    updateForm.setFieldsValue({
                        mobileNumberUpdate:record.mobileNo,
                        nickName:record.staffNameUpdate,
                        mobileNumber:record.mobileNo,
                        userNameUpdate:record.username,
                        nickNameUpdate:record.nickName,
                        rolesUpdate:record.roles,
                        status:record.enabled
                    });
                }}/>
                <Popconfirm
                    title="Are you sure to delete this?"
                    okText="Yes"
                    disabled={record.username==='arif'?true:false}
                    cancelText="No"
                    onConfirm={() => userDelete(record?.username)}
                >
                        <Tooltip title="Delete" >
                            <Button disabled={record.username==='arif'?true:false} danger icon={<DeleteOutlined />} />
                        </Tooltip>
                </Popconfirm>
            </Space>
         )}
    ]

    const [current, setcurrent]= useState<any>(1)

    const handlePagination= (val)=>{
        setcurrent(val)
    }
    return(
        <>
            <Card title="User List">
                <Form
                    layout="vertical"
                    id="instructionForm"
                    onFinish={submitMasterSettingUser}
                    form={addUserForm}
                >
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 16, offset: 4 }} xl={{ span: 16, offset: 4 }}>
                            <Row>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="selectStaff"
                                        label="Select Staff"
                                        className="title-Text"
                                        
                                    >
                                        <Select placeholder="select staff" onChange={(e) => onchangeStaff(e)} 
                                        showSearch 
                                        filterOption={(input, option:any) =>
                                            option !== undefined &&
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                          }
                                          >
                                          {
                                              staffList.map((item, index) => {
                                                return  <Option value={item.staffId}>{item.staffName}</Option>
                                              })
                                          }
                                        </Select>
                                        
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="userName"
                                        label="User Name"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please enter user name" },
                                        ]}
                                    >
                                        <Input placeholder="user name" />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="nickName"
                                        label="Nick Name"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please enter nick name" },
                                        ]}
                                    >
                                        <Input placeholder="nick name" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="mobileNumber"
                                        label="Mobile No"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please enter mobile number" },
                                        ]}
                                    >
                                        <Input placeholder="mobile number" />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="password"
                                        label="Password"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please password" },
                                        ]}
                                    >
                                        <Password placeholder="password" className="custom-password" />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="roles"
                                        label="Roles"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select Roles" },
                                        ]}
                                    >
                                        <Select placeholder="select roles" mode="multiple">
                                            <Option value="ROLE_ADMIN">ROLE_ADMIN</Option>
                                            <Option value="ROLE_OPERATOR">ROLE_OPERATOR</Option>
                                            <Option value="ROLE_ACCOUNTANT">ROLE_ACCOUNTANT</Option>
                                            <Option value="ROLE_TEACHER">ROLE_TEACHER</Option>
                                            <Option value="ROLE_EXAM_CONTROLLER">ROLE_EXAM_CONTROLLER</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                    <Button type="primary" htmlType="submit" className="mt-0" icon={<EditOutlined />} style={{ float: 'right' }} >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
                <br />
                <Row className="m-t-mo-30">
                    <Col span="24">
                    <Skeleton loading={loading} paragraph={{rows:10}} />
                        {!loading &&
                        <div className="datatable-responsive-demo">
                            {masterSettingUserList?.length > 0 &&
                                <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: masterSettingUserList,
                                    filterData: masterSettingUserList,
                                    pagination: { onChange: handlePagination, current:current },
                                    bordered: true,
                                    rowKey:"id",
                                }}
                                mobileBreakPoint={768}
                            />
                            }
                        </div>
}
                    </Col>
                </Row>
            </Card>
            <Modal
                title="Update"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
                maskClosable={false}
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={updateSubmitForm}
                    form={updateForm}
                    
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="userNameUpdate"
                                label="User Name"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter user name" },
                                ]}
                            >
                                <Input placeholder="user name" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="nickNameUpdate"
                                label="Nick Name"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter nick name" },
                                ]}
                            >
                                <Input placeholder="nick name" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="mobileNumberUpdate"
                                label="Mobile No"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter mobile number" },
                                ]}
                            >
                                <Input placeholder="mobile number" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="status"
                                label="Status:"
                                valuePropName="checked"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input serial" },
                                ]}
                            >
                                <Checkbox >Enabled</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="rolesUpdate"
                                label="Roles"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select Roles" },
                                ]}
                            >
                                <Select placeholder="select roles" mode="multiple">
                                    <Option value="ROLE_ADMIN">ROLE_ADMIN</Option>
                                    <Option value="ROLE_OPERATOR">ROLE_OPERATOR</Option>
                                    <Option value="ROLE_ACCOUNTANT">ROLE_ACCOUNTANT</Option>
                                    <Option value="ROLE_TEACHER">ROLE_TEACHER</Option>
                                    <Option value="ROLE_EXAM_CONTROLLER">ROLE_EXAM_CONTROLLER</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}