import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Pagination } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import TableView from '../../../contents/AntTablePagination';
import { debounce } from 'lodash';
import { Excel } from 'antd-table-saveas-excel';
import { EditOutlined, FileExcelOutlined } from '@ant-design/icons';
const { Search } = Input;
export default function OFPSInstiuteList() {

    const fetchofpsinstiuteList = useStoreActions((state) => state.customerSupport.fetchofpsinstiuteList);
    const fetchofpsinstiuteList2 = useStoreActions((state) => state.customerSupport.fetchofpsinstiuteList2);
    const ofpsinstiuteList = useStoreState((state) => state.customerSupport.ofpsinstiuteList);
    const fetchofpsinstiuteListNoPag = useStoreActions((state) => state.customerSupport.fetchofpsinstiuteListNoPag);
    const ofpsinstiuteListnoPag = useStoreState((state) => state.customerSupport.ofpsinstiuteListnoPag);
    const ofpsAmountConfigurationUpdate = useStoreActions((state) => state.customerSupport.ofpsConfigurationAmountUpdate);
    
    const [numberOfpage, setnumberOfpage] = useState<number>(1);

    useEffect(() => {
        fetchofpsinstiuteList({
            numberOfpage: numberOfpage,
            pageSize: 10,
        });
        fetchofpsinstiuteListNoPag()
    }, []);

    const [updateForm] = Form.useForm();
    const [configId, setConfigId] = useState<any>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const updateInfoForm = (value) => {
        let payload = {
            "configId": configId,
            "serviceCharge": value.serviceCharge,
            "step": value.step,
        }
        ofpsAmountConfigurationUpdate(payload);
        updateForm.resetFields();
        setIsModalVisible(false);
    
    };


    const columns = [
        { title: 'Institute ID', dataIndex: 'instituteId', key: "instituteId", showOnResponse: true, showOnDesktop: true },
        { title: 'Institute Name', dataIndex: 'instituteName', key: "instituteName", showOnResponse: true, showOnDesktop: true },
        { title: 'Package', dataIndex: 'packageName', key: "packageName", showOnResponse: true, showOnDesktop: true },
        { title: 'Service Charge', dataIndex: 'serviceCharge', key: "serviceCharge", showOnResponse: true, showOnDesktop: true },
        { title: 'Step', dataIndex: 'step', key: "step", showOnResponse: true, showOnDesktop: true },
        { title: 'Type', dataIndex: 'instituteType', key: "instituteType", showOnResponse: true, showOnDesktop: true },
        { title: 'Address', dataIndex: 'address', key: "address", showOnResponse: true, showOnDesktop: true },
        { title: 'Live Date', dataIndex: 'liveDate', key: "liveDate", showOnResponse: true, showOnDesktop: true },
        { title: 'Status', dataIndex: 'instituteStatus', key: "instituteStatus", showOnResponse: true, showOnDesktop: true },
        {
            title: 'Edit', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true, render: (text, record: any, index) =>
                <>
                    <Space size="middle">
                        <Tooltip title="Edit Service Charge">
                            <Button type='primary' icon={<EditOutlined />} onClick={() => {
                                setIsModalVisible(true);
                                setConfigId(record?.configId);
                                updateForm.setFieldsValue({
                                    serviceCharge: record.serviceCharge,
                                    step: record.step,
                                });
                                
                            }}
                            />
                        </Tooltip>
                    </Space>
                </>
        }
    ];

    const onChangePage = (page: number) => {
        setnumberOfpage(page);
        fetchofpsinstiuteList({
            numberOfpage: page,
            pageSize: 10,
        });
    };

    const onSearch = (value) => {
        setsearchv(value);
        if (value === '') {
            setnumberOfpage(1)
            fetchofpsinstiuteList({
                numberOfpage: 1,
                pageSize: 10,
            });
        } else {
            fetchofpsinstiuteList2({
                instituteName: value,
                numberOfpage: 1,
            });
        }
    }
    const debouncedOnchange = debounce(onSearch, 500);



    const [searchv, setsearchv] = React.useState<any>('');

    return (
        <>
            <Card title="OFPS Institute List">
                <Row className="m-t-mo-30">
                    <Col span={24}>

                        <div className="datatable-responsive-demo">
                            <div className="globalSearch" style={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center", background: '#b8d7cd' }}>
                                <span style={{ marginLeft: 10 }}>Total Found: {ofpsinstiuteList?.numberOfItems}</span>
                                <Input
                                    style={{ margin: "10px 10px 10px 0", width: 250 }}
                                    placeholder="Institute Name"
                                    onChange={(e) => debouncedOnchange(e.target.value)}
                                    // enterButton="Search"
                                    size="large"
                                // onSearch={onSearch}
                                />
                            </div>
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: ofpsinstiuteList?.instituteList,
                                    filterData: ofpsinstiuteList?.instituteList,
                                    pagination: false,
                                    bordered: true,
                                    rowKey: "instituteId",
                                }}
                                mobileBreakPoint={768}
                            />
                            <br />
                            <div style={{ float: "right" }}>
                                <Pagination defaultCurrent={1} current={numberOfpage} disabled={searchv === '' ? false : true} onChange={onChangePage} total={ofpsinstiuteList?.numberOfItems} showSizeChanger={false} />
                            </div>

                        </div>

                    </Col>

                </Row>
                <div style={{
                    float: "right"
                }}>
                    <br />
                    < Button
                        type='primary'

                        icon={<FileExcelOutlined />}
                        onClick={() => {
                            const excel: any = new Excel();
                            excel
                                .addSheet(`Sheet1`)
                                .addColumns(columns)
                                .addDataSource(ofpsinstiuteListnoPag)
                                .saveAs(`OFPS Institute List.xlsx`);
                        }}
                    >
                        Download Excel
                    </ Button >
                </div>
            </Card>

            <Modal
                title="Update Service Charge"
                visible={isModalVisible}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"
                    onFinish={updateInfoForm}
                    id="update"
                    form={updateForm}
                >
                    <Form.Item
                        name="serviceCharge"
                        label="Service Charge"
                        className="title-Text"
                        rules={[
                          { required: true, message: "Service Charge is required." },
                      ]}
        
                    >
                        <InputNumber placeholder='Service Charge' />

                    </Form.Item>                    
                    
                    <Form.Item
                        name="step"
                        label="Step"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Step is required." },
                        ]}
                    >
                        <InputNumber placeholder="Step" />

                    </Form.Item>
                    
                </Form>

            </Modal>
        </>
    )
}