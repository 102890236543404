import {EditOutlined,FileExcelOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Row, Select, Skeleton, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import NoImage from './no.png'
import { SelectReligion } from '../../../select/SelectReligion';
import { SelectDesignation } from '../../../select/SelectDesignation';
import { SelectBloodGroup } from '../../../select/SelectBloodGroup';
import { SelectGender } from '../../../select/SelectGender';
import { Typography } from 'antd';
import ReactExport from "react-export-excel";
import moment from 'moment';
import { SelectThana } from '../../../select/SelectThana';
import { SelectDistrict } from '../../../select/SelectDistrict';
import { SelectDistrict2 } from '../../../select/SelectDistrict2';
import { SelectThana2 } from '../../../select/SelectThana2';
const { Option } = Select;

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;


export default function StaffUpdate(props) {

    const [updateForm] = Form.useForm();
    const { Title } = Typography;
    const staffBasicInfoList = useStoreState((state) => state.staff.staffBasicInfoList);
    const fetchstaffBasicInfoList = useStoreActions((state) => state.staff.fetchstaffBasicInfoList);
    const updateSingleStaffBasicInfo = useStoreActions((state) => state.staff.updateSingleStaffBasicInfo);
    const updateSingleStaffPhoto = useStoreActions((state) => state.staff.updateSingleStaffPhoto);
    const loading = useStoreState((state) => state.staff.loading);

    useEffect(() => {
        fetchstaffBasicInfoList();
    }, [])



    const updateFomrSubmit = (value) => {
        value.staffId = staffId;
        value.birthDate = value.birthDate === null ? null : moment(value?.birthDate).format("YYYY-MM-DD");
        value.employmentDate = value.employmentDate === null ? null : moment(value?.employmentDate).format("YYYY-MM-DD");
        value.promotionDate = value.promotionDate === null ? null : moment(value?.promotionDate).format("YYYY-MM-DD");
        value.lastPromotionDate = value.lastPromotionDate === null ? null : moment(value?.lastPromotionDate).format("YYYY-MM-DD");
        value.staffStatus = value.staffStatus === true ? 1 : 0;
        value.presentThanaId = thana;
        value.permanentThanaId = thana2;
        updateSingleStaffBasicInfo(value)

        setIsModalVisible(false);
    }

    const [staffId, setstaffId] = useState<any>();
    const [gender, setgender] = useState<any>();
    const [religion, setreligion] = useState<any>();
    const [designationId, setdesignationId] = useState<any>();
    const [bloodGroup, setbloodGroup] = useState<any>();

    const uploadImage = (val, id) => {
        let image_as_files = val.target.files[0];
        let data = {
            file: image_as_files,
            staffId: id
        }
        updateSingleStaffPhoto(data)
    }

    const columns = [

        {
            title: 'Serial',
            dataIndex: 'staffSerial',
            key: 'staffSerial',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Teachers Name',
            dataIndex: 'staffName',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'ID',
            dataIndex: 'customStaffId',
            key: 'customStaffId',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Designation',
            dataIndex: 'designationName',
            key: 'designationName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Mobile No.',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Father's Name ",
            dataIndex: 'fatherName',
            key: 'fatherName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Mother's Name",
            dataIndex: 'motherName',
            key: 'motherName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Blood Group',
            dataIndex: 'bloodGroup',
            key: 'bloodGroup',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Edit',
            key: 'staffId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setstaffId(record.staffId);
                            setgender(record.gender);
                            setreligion(record.religion);
                            setdesignationId(record.designationId);
                            setbloodGroup(record.bloodGroup);
                            thanaListFetch(record.presentDistrictId);
                            setTimeout(() => {
                                setThana(record.presentThanaId);
                                setThana2(record.permanentThanaId);
                                setDistrict(record.presentDistrictId);
                                setDistrict2(record.permanentDistrictId);
                                updateForm.setFieldsValue({
                                    permanentThanaId: record.permanentThanaId,
                                    presentThanaId: record.presentThanaId,
                                });

                            }, 1000);
                            updateForm.setFieldsValue({
                                staffName: record.staffName,
                                staffStatus: record.staffStatus === 1 ? true : false,
                                customStaffId: record.customStaffId,
                                fatherName: record.fatherName,
                                motherName: record.motherName,
                                mobileNumber: record.mobileNumber,
                                alternativeMobile: record.alternativeMobile,
                                email: record.email,
                                religion: record.religion,
                                gender: record.gender,
                                passportNo: record.passportNo,
                                nationalId: record.nationalId,
                                employeementStatus: record.employeementStatus,
                                promotionStatus: record.promotionStatus,
                                mobileBankingName:record.mobileBankingName,
                                mobileBankingInfo: record.mobileBankingInfo,
                                subjectSpecialist: record.subjectSpecialist,
                                bloodGroup: record.bloodGroup,
                                presentAddress: record.presentAddress,
                                permanentAddress: record.permanentAddress,
                                designationId: record.designationId,
                                staffSerial: record.staffSerial,
                                joiningPost: record.joiningPost,
                                lastPromotedPost: record.lastPromotedPost,
                                mainJobResponsibility: record.mainJobResponsibility,
                                birthDate: record.birthDate == null ? null : moment(record.birthDate, 'YYYY-MM-DD'),
                                employmentDate: record.employmentDate == null ? null : moment(record.employmentDate, 'YYYY-MM-DD'),
                                promotionDate: record.promotionDate == null ? null : moment(record.promotionDate, 'YYYY-MM-DD'),
                                lastPromotionDate: record.lastPromotionDate == null ? null : moment(record.lastPromotionDate, 'YYYY-MM-DD'),
                            });
                        }}
                        />
                    </Tooltip>

                </Space>
            ),
        },
        {
            title: 'Image',
            key: 'imageName',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <div style={{ textAlign: "center" }}>
                    <img src={record.imageName == '' ? NoImage : ("data:image/png;base64," + record.imageName)} alt="Staff Image" style={{ height: 50, width: 50 }} />
                    <br />
                    <input type="file" name="my_file" id="my_file" accept="image/*" onChange={(e) => uploadImage(e, record.staffId)} />
                </div>
            ),
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [disttrict, setDistrict] = useState<any>();
    const [thana, setThana] = useState<any>();
    const [disttrict2, setDistrict2] = useState<any>();
    const [thana2, setThana2] = useState<any>();
    const thanaListFetch = useStoreActions((state) => state.common.thanaListFetch);
    const thanaListFetch2 = useStoreActions((state) => state.common.thanaListFetch2);

    const districsIdStore = (val) => {
        setDistrict(val);
    }
    const districsIdStore2 = (val) => {
        setDistrict2(val);
    }

    return (
        <>
            {staffBasicInfoList === null && <Skeleton loading={loading} paragraph={{ rows: 10 }} />}
            {staffBasicInfoList !== null &&
                <Card title="Staff Information Update" >
                    <div className="datatable-responsive">
                        <Table
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: staffBasicInfoList,
                                filterData: staffBasicInfoList,
                                pagination: true,
                                bordered: true,
                                rowKey: 'staffId'
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>



                    <Space size="small" style={{ float: "right", marginTop:12 }}>

                <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Staff List`}
                            >
                                <ExcelSheet data={staffBasicInfoList} name="Staff List">
                                    <ExcelColumn label="Staff ID" value="customStaffId" />
                                    <ExcelColumn label="Staff Name" value="staffName" />
                                    <ExcelColumn label="Staff Status" value="staffStatus" />
                                    <ExcelColumn label="Gender" value="gender" />
                                    <ExcelColumn label="Religion" value="religion" />
                                    <ExcelColumn label="Date of Birth" value="birthDate" />
                                    <ExcelColumn label="Email" value="email" />
                                    <ExcelColumn label="Mobile" value="mobileNumber" />
                                    <ExcelColumn label="Alternative Mobile" value="alternativeMobile" />
                                    <ExcelColumn label="Blood Group" value="bloodGroup" />
                                    <ExcelColumn label="Designation" value="designationName" />
                                    <ExcelColumn label="Father Name" value="fatherName" />
                                    <ExcelColumn label="Mother Name" value="motherName" />

                                    <ExcelColumn label="Employeement Status" value="employeementStatus" />
                                    <ExcelColumn label="Employment Date" value="employmentDate" />
                                    <ExcelColumn label="National ID" value="nationalId" />
                                    <ExcelColumn label="Passport No" value="passportNo" />
                                    <ExcelColumn label="Subject Specialist" value="subjectSpecialist" />
                                    <ExcelColumn label="Mobile Banking Info" value="mobileBankingInfo" />

                                    <ExcelColumn label="Address Details" value="staffAddress" />
                                    
                                    <ExcelColumn label="Staff Image" value="imageName" />
                                    <ExcelColumn label="Signature Image" value="signatureName" />
                                    <ExcelColumn label="Present Address" value="presentAddress" />
                                    <ExcelColumn label="Present Thana" value="presentThanaName" />
                                    <ExcelColumn label="Present District" value="presentDistrictName" />
                                    <ExcelColumn label="Permanent Address" value="permanentAddress" />
                                    <ExcelColumn label="Permanent Thana" value="permanentThanaName" />
                                    <ExcelColumn label="Permanent District" value="permanentDistrictName" />
                                   
                                    <ExcelColumn label="Promotion Date" value="promotionDate" />
                                    <ExcelColumn label="Promotion Status" value="promotionStatus" />
                                    <ExcelColumn label="Joining Post" value="joiningPost" />
                                    
                                    <ExcelColumn label="Last Promotion Date" value="lastPromotionDate" />
                                    <ExcelColumn label="Last Promoted Post" value="lastPromotedPost" />
                                    <ExcelColumn label="Main Job Responsibility" value="mainJobResponsibility" />
                                   
                                    
                                </ExcelSheet>
                            </ExcelFile>

                </Space>



                </Card>
            }
            <Modal
                title="Update Staff Info"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => { setIsModalVisible(false); updateForm.resetFields() }}
                cancelText="Close"
                okText="Update"
                centered
                width={"60%"}
            >
                <Form
                    layout="vertical"

                    onFinish={updateFomrSubmit}
                    id="update"
                    form={updateForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="customStaffId"
                                label="ID:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input staff id" },
                                ]}
                            >
                                <Input placeholder='Staff Id' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="staffName"
                                label="Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input staff name" },
                                ]}
                            >
                                <Input placeholder='Staff Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="designationId"
                                label="Designation:"
                                className="title-Text"

                                rules={[
                                    { required: true, message: "Please select designation" },
                                ]}
                            >
                                <SelectDesignation selected={designationId} onChange={(e) => setdesignationId(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="fatherName"
                                label="Father's Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input father name" },
                                ]}
                            >
                                <Input placeholder='Father Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="motherName"
                                label="Mother's Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input mother name" },
                                ]}
                            >
                                <Input placeholder='Mother name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="religion"
                                label="Religion:"
                                className="title-Text"

                                rules={[
                                    { required: true, message: "Please select religion" },
                                ]}
                            >
                                <SelectReligion selected={religion} onChange={(e) => setreligion(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="bloodGroup"
                                label="Blood Group:"
                                className="title-Text"

                                rules={[
                                    { required: true, message: "Please select blood group" },
                                ]}
                            >
                                <SelectBloodGroup selected={bloodGroup} onChange={(e) => setbloodGroup(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="gender"
                                label="Gender:"
                                className="title-Text"

                                rules={[
                                    { required: true, message: "Please select gender" },
                                ]}
                            >
                                <SelectGender selected={gender} onChange={(e) => setgender(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="birthDate"
                                label="Date of Birth:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select birth date" },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="mobileNumber"
                                label="Mobile No:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input mobile no." },
                                ]}
                            >
                                <Input placeholder='Mobile Number' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="alternativeMobile"
                                label="Alternative Mobile:"
                                className="title-Text"

                            >
                                <Input placeholder='Alternative Mobile' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="email"
                                label="Email:"
                                className="title-Text"

                            >
                                <Input placeholder='Email' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="nationalId"
                                label="National ID:"
                                className="title-Text"

                            >
                                <Input placeholder='National ID' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="passportNo"
                                label="Passport No:"
                                className="title-Text"

                            >
                                <Input placeholder='Passport No' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="employmentDate"
                                label="Joining Date:"
                                className="title-Text"

                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="joiningPost"
                                label="Joining Post:"
                                className="title-Text"

                            >
                                <Input placeholder='Joining Post' />
                            </Form.Item>
                        </Col>
                        
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="employeementStatus"
                                label="Employment Staus:"
                                className="title-Text"

                                style={{ width: "100%" }}
                            >
                                <Select placeholder="Employment Status" allowClear style={{ width: "100%" }}>
                                    <Option value="Permanent">Permanent</Option>
                                    <Option value="Contractual">Contractual</Option>
                                    <Option value={"Part Time"}>Part Time</Option>
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="promotionDate"
                                label="Promotion Date:"
                                className="title-Text"

                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="promotionStatus"
                                label="Promotion Staus:"
                                className="title-Text"

                                style={{ width: "100%" }}
                            >
                                <Select placeholder="Promotion Status" allowClear style={{ width: "100%" }}>
                                    <Option value="Promoted">Promoted</Option>
                                    <Option value="Not Promoted">Not Promoted</Option>
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="lastPromotionDate"
                                label="Last Promotion Date:"
                                className="title-Text"

                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="lastPromotedPost"
                                label="Last Promoted Post"
                                className="title-Text"

                            >
                                <Input placeholder='Last Promoted Post' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="mainJobResponsibility"
                                label="Main Job Responsibility"
                                className="title-Text"

                            >
                                <Input placeholder='Main Job Responsibility' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="mobileBankingName"
                                label="Mobile Banking Name"
                                className="title-Text"

                            >
                                <Input placeholder='Mobile Banking Info' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="mobileBankingInfo"
                                label="Mobile Banking Number"
                                className="title-Text"

                            >
                                <Input placeholder='Mobile Banking Info' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="subjectSpecialist"
                                label="Subject Specialist:"
                                className="title-Text"

                            >
                                <Input placeholder='Subject' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="staffSerial"
                                label="Serial:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input serial" },
                                ]}
                            >
                                <Input placeholder='Staff Serial' />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="staffStatus"
                                label="Status:"
                                valuePropName="checked"
                                className="title-Text"

                            >
                                <Checkbox >Status</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <strong>Presenet Address</strong>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="presentAddress"
                                        label="Present Address:"
                                        className="title-Text"

                                    >
                                        <Input placeholder='Present Address' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="presentDistrictId"
                                        label="Present District"
                                        className="title-Text"

                                    >
                                        <SelectDistrict selected={disttrict} onChange={e => { districsIdStore(e); setThana(null) }} />

                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="presentThanaId"
                                        label="Present Thana"
                                        className="title-Text"

                                    >
                                        <SelectThana selected={thana} onChange={e => setThana(e)} />

                                    </Form.Item>
                                </Col>

                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <strong>Permanent Address</strong>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="permanentAddress"
                                        label="Permanent Address:"
                                        className="title-Text"

                                    >
                                        <Input placeholder='Permanent Address' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="permanentDistrictId"
                                        label="Permanent District"
                                        className="title-Text"

                                    >
                                        <SelectDistrict2 selected={disttrict2} onChange={e => { districsIdStore2(e); setThana2(null) }} />

                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="permanentThanaId"
                                        label="Permanent Thana"
                                        className="title-Text"
    
                                    >
                                        <SelectThana2 selected={thana2} onChange={e => setThana2(e)} />

                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>

            </Modal>
        </>
    )
}
