import { Card, Tabs } from 'antd';
import AdmitCardWithRoutine from './AdmitCardWithRoutine';
import AdmitCardSeatPlan from './AdmitCardSeatPlan';
const { TabPane } = Tabs;

export default function AdmitCardSeatPlanWrapper(props) {

    return(
        <>
            <Card title="Admit & Seat Plan Download" >
                <Tabs defaultActiveKey="1" size={"large"}>
                    <TabPane tab="Admit Without Routine" key="1">
                        <AdmitCardSeatPlan />
                    </TabPane>                  
                    <TabPane tab="Admit With Routine" key="3">
                        <AdmitCardWithRoutine />
                    </TabPane>                  
                </Tabs>
            </Card>
        </>
    )

}