import { post, get, del, postFile} from "../http";

//////////
export const fetchInventoryCategory = () => get("/inventory/category/list");
export const createInventoryCategory = (payload) => post("/inventory/category/save", payload);
export const updateInventoryCategory = (payload) => post("/inventory/category/update", payload);
export const deleteInventoryCategory = (payload) => del("/inventory/category/delete?categoryId="+payload);

export const saveInventoryItem = (payload) => post("/inventory/item/save", payload);
export const updateInventoryItem = (payload) => post("/inventory/item/update", payload);
export const deleteInventoryItem = (payload) => del("/inventory/item/delete?itemId="+payload);

export const fetchInventoryItemList = () => get("/inventory/item/list");
export const fetchInventoryItemListByCategory = (payload) => get("/inventory/item/list/by/category?categoryId="+payload);

export const saveInventoryLedgerConfiguration = (payload) => post("/inventory/ledger/configuration/save", payload);
export const fetchInventoryLedgerConfiguration = () => get("/inventory/ledger/configuration/view");

export const purchaseItemInventory = (payload) => post("/inventroy/purchase/save", payload);
export const fetchPurchaseInventoryItemViewList = (payload) => get("/inventroy/purchase/list?fromDate="+payload.fromDate+"&toDate="+payload.toDate);
export const downloadPurchaseInventory = (payload) => get("/inventroy/purchase/invoice/download?purchaseInvoiceIds="+payload);
export const deletePurchaseInventory = (payload) => del("/inventroy/purchase/delete?purchaseInvoiceId="+payload);

export const savesalestemInventory = (payload) => post("/inventory/sales/save", payload);
export const fetchSalesInventoryItemViewList = (payload) => get("/inventory/sales/list?fromDate="+payload.fromDate+"&toDate="+payload.toDate);
export const downloadSalesInventory = (payload) => get("/inventory/sales/invoice/download?salesInvoiceIds="+payload);
export const fetchviewInventorySales = (payload) => get("/inventory/sales/view/by/sales-invoice-id?salesInvoiceId="+payload);
export const deleteSalesInventory = (payload) => del("/inventory/sales/delete?salesInvoiceId="+payload);

export const recordInInventoryItem = (payload) => post("/inventory/stock-in/save",payload);
export const inventoryItemInRecordList = (payload) => get("/inventory/stock-in/list?fromDate="+payload.fromDate+"&toDate="+payload.toDate);
export const deleteInventoryItemInRecord = (payload) => del("/inventory/stock-in/delete?recordId="+payload.recordId);

export const recordOutInventoryItem = (payload) => post("/inventory/stock-out/save",payload);
export const inventoryItemOutRecordList = (payload) => get("/inventory/stock-out/list?fromDate="+payload.fromDate+"&toDate="+payload.toDate);
export const deleteInventoryItemOutRecord = (payload) => del("/inventory/stock-out/delete?recordId="+payload.recordId);


export const recordDestroyInventoryItem = (payload) => post("/inventory/destroy/save",payload);
export const inventoryItemDestroyRecordList = (payload) => get("/inventory/destroy/list?fromDate="+payload.fromDate+"&toDate="+payload.toDate);
export const deleteInventoryItemDestroyRecord = (payload) => del("/inventory/destroy/delete?recordId="+payload.recordId);

export const fetchsalerList = () => get("/inventroy/saler/list");
export const createSaler = (payload) => post("/inventroy/saler/save", payload);
export const updateSaler = (payload) => post("/inventroy/saler/update", payload);
export const deleteSaler = (payload) => del("/inventroy/saler/delete?salerId="+payload);