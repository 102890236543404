import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import SessionYear from '../Students/settings/SessionYear.page';
import { SelectAcademicYear } from '../../select/SelectAcademicYear';
import { EditOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';

const {Option} = Select;

export default function ShebaSchoolInfo() {

    const fetchShebaSchoolInfo = useStoreActions((state) => state.customerSupport.fetchShebaSchoolInfo);
    const shebaSchoolInfo =  useStoreState((state) =>  state.customerSupport.shebaSchoolInfo);
    const saveShebaSchoolInfo = useStoreActions((state) =>  state.customerSupport.saveShebaSchoolInfo);
    const [shebaSchoolInfoForm] = Form.useForm();

    useEffect(() => {
      fetchShebaSchoolInfo();
    }, []);

    useEffect(() => {
      shebaSchoolInfoForm.setFieldsValue({
        spgPaymentStatus: shebaSchoolInfo.spgPaymentStatus,
        shebaSchoolMessage: shebaSchoolInfo.shebaSchoolMessage,
      });

  }, [shebaSchoolInfo])



    const updateShebaSchoolInfo = (value) => {
        
      let postData:any = {
          
          spgPaymentStatus: value.spgPaymentStatus,
          shebaSchoolMessage: value.shebaSchoolMessage,
       
        }
        saveShebaSchoolInfo(postData);
    }


    return(
        <>
            <Card title="Sheba Info Update">
                <Form
                    layout="vertical"
                    id="shebaSchoolInfoForm"
                    onFinish={updateShebaSchoolInfo}
                    form={shebaSchoolInfoForm}
                >
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="shebaSchoolMessage"
                                label="Sheba School Message"
                                className="title-Text"
                            >
                                <TextArea rows={10} placeholder="Sheba School Message"  />
                            </Form.Item>
                        </Col>
                    </Row>
    
                    <Row>

                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="spgPaymentStatus"
                                label="Spg Payment Status"
                                className="title-Text"
                            >
                               <Select placeholder="Spg Payment Status">
                                <Option value={1}>On</Option>
                                <Option value={0}>Off</Option>
                               </Select>
                            </Form.Item>
                        </Col>

                        </Row>     

                    
                        <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                            <Button type="primary" htmlType="submit" icon={<EditOutlined />} style={{ float: 'right' }} >
                                Update
                            </Button>
                        </Col>
                        </Row>
                    
                </Form>
            </Card>
        </>
    )
}