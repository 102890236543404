import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification } from 'antd'
import { useStoreActions, useStoreState } from '../../../../../store/hooks/easyPeasy';
import TableView from '../../../../../contents/AntTableResponsive';
import { DeleteOutlined, EditOutlined, FilePdfOutlined, SaveOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { SelectClass } from '../../../../select/SelectClass';
import { render } from '@testing-library/react';
import { SelectClassConfig } from '../../../../select/SelectClassConfig';
import { SelectAcademicYear } from '../../../../select/SelectAcademicYear';


export default function GrandFinal(props) {

    const [createForm] = Form.useForm();
    const { Option } = Select;

    const fetchClassConfigurationListByclassIdForGrandFinalProcess = useStoreActions((state) => state.exam.fetchClassConfigurationListByclassIdForGrandFinalProcess);
    const classConfigurationListByclassIdForGrandFinalProcess = useStoreState((state) => state.exam.classConfigurationListByclassIdForGrandFinalProcess);
    const processgeneralFinalExam = useStoreActions((state) => state.exam.processgeneralFinalExam);
    const onChangeClassList = (e) => {
        fetchClassConfigurationListByclassIdForGrandFinalProcess(e);
    }

    const processSubmit = (value) => {

        let payload: any = {
            examconfigId: value.examConfig,
            academicYear: value.academicYear,
        }

        processgeneralFinalExam(payload);
    }

    return(
        <>
            <Card title="Grand Final Exam Result Process">
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 18, offset: 4 }}>
                    <Form
                        layout="vertical"
                        id="sessionInfo"
                        onFinish={processSubmit}
                        form={createForm}
                    >
                            <Row>


                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select academicYear" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="section"
                                        label="Select Class"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select class" },
                                        ]}
                                    >
                                        <SelectClass onChange={(e) =>{ onChangeClassList(e); createForm.setFieldsValue({examConfig:null})}} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="examConfig"
                                        label="Select Exam"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select exam" },
                                        ]}
                                    >
                                        <Select placeholder="Semester list" style={{ width: "100%" }}>
                                            {classConfigurationListByclassIdForGrandFinalProcess ? (
                                                    classConfigurationListByclassIdForGrandFinalProcess.map((type, idx) => (
                                                    <Option key={type.examConfigId} value={type.examConfigId}>
                                                        {type.examName}
                                                    </Option>
                                                    ))
                                                ) : (
                                                    <Option value="fetching">Fetching Exams</Option>
                                                )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SettingOutlined />} >
                                            Process
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </>
    )
}